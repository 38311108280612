import BaseModel from 'client/toolbox/generated/models/space-member';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { attr, belongsTo } from '@ember-data/model';
/* global libcryptobox */

export default class SpaceMember extends BaseModel {
  @attr('string') email;
  @alias('givenName') firstName;
  @alias('surname') lastName;
  @attr role;
  @attr certificate;
  @belongsTo('space', { async: true, inverse: 'space' }) space;
  maximumLevel = libcryptobox.LicensePool.User;

  @service account;

  constructor() {
    super(...arguments);
  }


  get fullName() {
    return this.firstName + ' ' + this.lastName;
  }


  get isMySelf() {
    return this.email === this.account.userEmail;
  }


  get isAdmin() {
    return this.role === libcryptobox.Role.Owner;
  }


  get isMember() {
    return this.role === libcryptobox.Role.Member;
  }


  get isReader() {
    return this.role === libcryptobox.Role.Auditor;
  }


  get isViewer() {
    return this.role === libcryptobox.Role.Viewer;
  }


  get isMaxAdmin() {
    return this.maximumLevel === libcryptobox.LicensePool.User;
  }


  get isMaxMember() {
    return this.maximumLevel === libcryptobox.LicensePool.Invitee;
  }

  save() {
    return super.save().catch((error) => {
      console.warn('error save', error);
      if (!this.isValid) {
        this.unloadRecord();
      }
    });
  }
}
