import Component from "@glimmer/component";
import { action } from '@ember/object';


export default class OtpValidationComponent extends Component {
  constructor() {
    super(...arguments);
  }

  @action 
  resendCode(event) {
    event.stopPropagation();
    event.preventDefault();
    if (this.args.resendCode) this.args.resendCode();
  }

  @action
  formSubmit(event) {
    event.preventDefault();
  }

}
