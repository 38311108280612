import { helper } from '@ember/component/helper';

export function firstLetters(params) {
  if (!params[0]) {
	return "";
  }
  let names = params[0].split(' ');
  let firstname = names[0];
  let lastname = names[1];

  if (lastname) {	
	return `${Array.from(firstname)[0]} ${Array.from(names[1])[0]}`;
  } else {
	return `${Array.from(firstname)[0]}`;
  }
}

export default helper(firstLetters);
