import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import Controller from '@ember/controller';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
/* global libcryptobox */

export default class Claimant extends Controller {
  @tracked sas;
  @tracked isDepositaryConnected = false;
  @tracked connectionError;

  @service modalManager;
  @service connection;
  @service intl;
  @service router;

  @computed('model.{rdvUserId,recoveryId}')
  get initRdv() {
    const recoveryId = this.model.recoveryId;
    const rdvUserId = this.model.rdvUserId;
    const server = this.connection.get('serverAddress');

    const notifier = {
      onSas: (sas) => {
        this.sas = sas;
        this.isDepositaryConnected = true;
      },
    };

    tBoxClient.recovery
      .claimantDepositConnect(notifier, server, recoveryId, rdvUserId)
      .then(() => {
        this.router.transitionTo(
          'account-recovery.new-password',
          recoveryId,
          rdvUserId,
        );
      })
      .catch((error) => {
        document.querySelector('.sas').classList.add('error');
        let errorMessage;
        if (error.code === libcryptobox.ErrorCode.RecoverySessionBroken) {
          errorMessage = this.intl.t('recovery.rdv.claimant.errors.code');
        }
        if (error.code === libcryptobox.ErrorCode.ServerError) {
          this.connectionError = true;
        }
        if (error.code === libcryptobox.ErrorCode.RecoveryBadID) {
          this.modalManager.open('no-recovery-session');
        }
        document.querySelector('.sas .error-message').innerText = errorMessage;
      });

    return true;
  }

  @action
  refresh() {
    window.location.reload();
  }

  @action
  redirectToLogin() {
    if (this.modalManager.isModalOpen('no-recovery-session')) {
      this.router.transitionTo('login');
    }
  }
}
