import { inject as service } from '@ember/service';
import { A } from '@ember/array';
import Controller from '@ember/controller';
import { union } from '@ember/object/computed';
import { action, computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';

/*global libcryptobox*/

export default class CreateGroupController extends Controller {
  @tracked addedMembers = A([]);
  @tracked whiteListedUsers = A([]);
  @tracked usersToInvite = A([]);
  @tracked currentDeviceSecurityLevels;

  @union('usersToInvite', 'whiteListedUsers') invitedUsers;

  @tracked addingTag;
  @tracked creatingGroup = false;
  @tracked searchEmail = null;
  @tracked disableCancel = false;
  @tracked confirmDisable = false;
  @tracked wsImage = null;

  @service commonService;
  @service store;
  @service account;
  @service intl;
  @service modalManager;
  @service notify;
  @service connection;
  @service router;

  @action onWsImage(img) {
    this.wsImage = img;
  }

  _inviteUsers(invitedUsers, spaceId) {
    const invitedUsersLength = invitedUsers.length;
    if (invitedUsersLength) {
      invitedUsers.forEach((invitedUser, index) => {
        tBoxClient.preaddedSpaceMember
          .create(spaceId, invitedUser.email, invitedUser.role)
          .then(() => {
            if (index + 1 === invitedUsersLength) {
              this.notify.success(
                this.intl.t('toasts.inviteUser.message', {
                  count: invitedUsersLength,
                  appname: this.intl.t('appname'),
                }),
                {
                  title: this.intl.t('toasts.inviteUser.title', {
                    count: invitedUsersLength,
                  }),
                  icon: 'group',
                },
              );
            }
          });
      });
    }
  }

  @computed('currentDeviceSecurityLevels')
  get securityLevels() {
    return this.currentDeviceSecurityLevels;
  }

  set securityLevels(newSL) {
    this.currentDeviceSecurityLevels = newSL;
  }

  get disabledValidate() {
    return !this.model.name || this.notEnoughInvites;
  }

  get thumbnailCapable() {
    const thumbnail = libcryptobox.ServerCapability.Thumbnail;
    return this.account.serverSettings.capabilities.includes(thumbnail);
  }

  @computed('account.status.createSpace')
  get hasRightToCreate() {
    if (!this.account.status.createSpace) {
      this.router.transitionTo('my-groups');
    }

    return true;
  }

  @computed('usersToInvite.[]', 'remainingInvites')
  get notEnoughInvites() {
    return this.usersToInvite.length > this.remainingInvites;
  }

  @action
  showWarningModal() {
    if (!this.model.messagingEmailNotification) {
      this.modalManager.open('messagingEmailNotificationWarning');
    }
  }

  @action
  async createGroup() {
    if (this.addingTag || this.searchEmail || this.creatingGroup) {
      return;
    }

    try {
      this.disableCancel = true;
      this.creatingGroup = true;
      this.commonService.creationGroupRegistry = true;

      const newSpace = this.model;
      newSpace.setProperties({
        id:uuidv4(),
        documentCount: 0,
        userCount: 1,
        userRole: libcryptobox.Role.Owner,
        date: DateTime.now().toISO(),
      });

      const newSpaceSl = await newSpace.get('securityLevels');
      const selectedSecurityLevels = this.securityLevels?.filter(
        (sl) => sl.checked,
      );
      const securityLevelRecords = selectedSecurityLevels.map((sl) =>
        this.store.peekRecord({ type: 'securityLevel', id: sl.id }),
      );
      newSpaceSl.pushObjects(securityLevelRecords);

      const createdSpace = await newSpace.save();
      if (this.addedMembers.length) {
        await Promise.all(
          this.addedMembers.map(async (member, index) => {
            const newSpaceMember = this.store.createRecord('spaceMember', {
              email: member.email,
              firstName: member.firstName,
              lastName: member.lastName,
              certificate: member.certificate,
              role: member.role,
              space: createdSpace,
              maximumLevel: member.maximumLevel,
            });

            await newSpaceMember.save({
              adapterOptions: { fingerprint: member.fingerprint },
            });

            if (index === this.addedMembers.length - 1) {
              this.notify.success(
                this.intl.t('toasts.addUser.message', {
                  count: this.addedMembers.length,
                }),
                {
                  title: this.intl.t('toasts.addUser.title', {
                    count: this.addedMembers.length,
                  }),
                  linkProperties: {
                    route: 'my-groups.group.members',
                    arg: createdSpace.id,
                  },
                  icon: 'group',
                },
              );
              newSpace.reload();
            }
          }),
        );
      }

      this.disableCancel = false;
      this.commonService.creationGroupRegistry = false;
      this.creatingGroup = false;

      if (createdSpace.id) {
        if (this.thumbnailCapable && this.wsImage) {
          await tBoxClient.space.setThumbnail(createdSpace.id, this.wsImage);
          this.wsImage = null;
        }
        if (this.account.status.canInvite) {
          this._inviteUsers(this.invitedUsers, createdSpace.id);
        }
        this.notify.success(this.intl.t('toasts.createSpace.message'), {
          title: this.intl.t('toasts.createSpace.title'),
          linkProperties: {
            route: 'my-groups.group.documents',
            arg: createdSpace.id,
          },
          icon: 'group',
        });
      }
      this.router.transitionTo('my-groups');
    } catch (error) {
      this.commonService.creationGroupRegistry = false;
      console.error('Erreur lors de la création :', error);
    }
  }

  @action
  cancelCreation() {
    this.model.unloadRecord();
    this.router.transitionTo('my-groups');
    this.searchEmail = '';
    this.addedMembers.clear();
    this.invitedUsers.clear();
    this.wsImage = null;
  }

  @action
  async createTag(term, event) {
    let tags = this.model.tags;
    if (event.keyCode === 13 && term.searchText === '') {
      await this.createGroup();
    } else if (event.keyCode === 13 && tags.indexOf(term.searchText) === -1) {
      tags.pushObject(term.searchText);
    }
  }

  @action
  onChangeSecurityLevelSelector(slList) {
    this.securityLevels = slList;
  }

  @action
  onThumbnailLoading(loading) {
    this.confirmDisable = loading;
  }
}
