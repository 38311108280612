// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr, belongsTo } from '@ember-data/model';

// The recipient of an instant message.
// See MessageRecord.
export default class extends Model {
  // Email.
  // @type string
  @attr email;
  
  // First name.
  // @type string
  @attr givenName;
  
  // Last name.
  // @type string
  @attr surname;
  
  // Related message ID.
  // @type string
  @attr messageId;
  
  // Whether the user has read the message.
  // @type boolean
  @attr hasRead;
  
  @belongsTo('message', { async: true, inverse: null }) message;
}

