// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr } from '@ember-data/model';

// An invite.
// See InviteCollection.
export default class extends Model {
  // Invitee email.
  // @type string
  @attr guestEmail;
  
  // True if the invitee is a whitelisted user (ie. does not require an invite to register).
  // @type boolean
  @attr shallowInvite;
  
  // History.
  // @type Array<InviteEvent>
  @attr eventHistory;
  
  // Number of preadded space.
  // @type number
  @attr spaceCount;
}

