import pdfjsLib from 'pdfjs-dist';
import ENV from 'client/config/environment';

export function initialize() {
  if (typeof window === 'undefined') return;
  const BASE_PATH = `/vendor/pdfjs-dist/build/pdf.worker.mjs`;
  pdfjsLib.GlobalWorkerOptions.workerSrc = ENV.APP.isElectronApp
    ? BASE_PATH
    : `/static${BASE_PATH}`;
}

export default {
  name: 'pdfjs',
  initialize,
};
