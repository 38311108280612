import { computed } from '@ember/object';
import Controller from '@ember/controller';
import DS from 'ember-data';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class Conversation extends Controller {
  @tracked showAcknowledgement = false;
  @tracked isDragOver = false;
  @tracked showUsers = false;

  @tracked model;

  constructor() {
    super(...arguments);
    window.addEventListener('dragover', this._preventEvent, false);
    window.addEventListener('drop', this._preventEvent, false);
  }

  _preventEvent(e) {
    e.preventDefault();
  }


  @computed('model.members')
  get currentMember() {
    return DS.PromiseObject.create({
      promise: this.model.get('members').then((members) => {
        return members.findBy('isMySelf', true);
      }),
    });
  }

  @action
  closeAcknowledgement() {
    this.showAcknowledgement = false;
    this.model.selectedMessage = null;
  }
}
