import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { tBoxClient } from 'client/initializers/init-toolbox';
/* global libcryptobox */

export default class ImagePickerComponent extends Component {
  @tracked image = null;
  @tracked isLoading = false;
  @tracked _isHovering = false;

  constructor() {
    super(...arguments);

    if (this.args.space && this.args.space.thumbnailHash) {
      tBoxClient.space.getThumbnail(this.args.space.id).then((thumbnail) => {
        this.image = this.getObjectUrlImage(thumbnail);
      });
    }
  }

  get showUpload() {
    return this._isHovering || !this.image;
  }

  setLoading(loading) {
    this.isLoading = loading;
    this.args.onIsLoading(loading);
  }

  @action
  onClickRemove(event) {
    event.stopPropagation();
    event.preventDefault();
    this.image = null;
    this.args.onNewImage(null);
  }

  @action
  onMouseEnter() {
    this._isHovering = true;
  }

  @action
  onMouseLeave() {
    this._isHovering = false;
  }

  @action
  onMouseEnterRemove(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  @action
  onChange(event) {
    this.onMouseLeave();
    if (event) {
      const file = event.target.files[0];
      if (file) {
        file.CBRelativePath = '';
        this.setLoading(true);
        const fileReader = new FileReader();
        fileReader.onload = () => {
          if (fileReader.result) {
            const buffer = tBoxClient.encodeUint8Array(new Uint8Array(fileReader.result));

            if (buffer) {
              tBoxClient.space.createThumbnail(buffer).then(result => {
                if (result) {
                  const newImage = this.getObjectUrlImage(result);
                  if (newImage) {
                    this.image = newImage;
                    this.args.onNewImage(result);
                  }
                }
                this.setLoading(false);
              });
            } else {
              this.setLoading(false);
            }
          } else {
            this.setLoading(false);
          }
        };

        fileReader.readAsArrayBuffer(file);
      }
    }
  }

  getObjectUrlImage(data) {
    const img = new Blob([tBoxClient.decodeUint8Array(data)]);
    if (img) {
      return URL.createObjectURL(img);
    }
  }
}
