// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr } from '@ember-data/model';

// Login progress information.
// See LoginProgressCollection.
export default class extends Model {
  // Whether the user agent is logging the end user.
  // @type boolean
  @attr isLoggingIn;
  
  // Number of loaded space.
  // @type number
  @attr loadedSpaceCount;
  
  // Total number of space to load.
  // @type number
  @attr spaceCount;
}

