import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { tBoxClient } from 'client/initializers/init-toolbox';

export default class PreaddMemberService extends Service {
  @tracked preaddedMembers = [];
  @tracked maxIndex = 1;
  @tracked preaddUser = null;

  @service store;

  async loadPreaddedMembers() {
    await this.store
      .findAll('preadded-space-member', {
        reload: true,
        adapterOptions: { subscribe: this },
      })
      .then(async (members) => {
        members = members.filter(member => member.status !== 'invited');
        return members.reduce((rv, x) => {
          const user = rv.findBy('email', x.email);

          if (user) {
            user.invites.pushObject(x);
          } else {
            rv.pushObject({
              email: x.email,
              invites: [x],
            });
          }
          return rv;
        }, []);
      })
      .then((members) => {
        members.forEach(async (member) => {
          member.user = await tBoxClient.user.get(member.email);
        });

        const member = members.findBy('email', this.preaddUser);

        if (member) {
          members.removeObject(member);
          members.unshiftObject(member);
        }

        this.maxIndex = members.length;
        this.preaddedMembers = members;
      });
  }
}
