import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action, computed } from "@ember/object";
import { inject as service } from '@ember/service';


export default class DepositBoxSummaryComponent extends Component {

    @tracked comment = "";

    @tracked highlight;
    
    @service('transfers') transferManager;

    @service intl;

    @computed('args.depositBox.creationDate')
    get depositBoxCreationDate() {
        return this.args.depositBox.creationDate;
    }

    @computed('window.innerWidth', 'screen.width')
    get addFileMessage() {
        var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
        return width <= 785 ? "" : this.intl.t('depositBoxForm.addFileDragAndDrop');
    }

    get addFileMessageAdd() {
        var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
        return width <= 785 ? this.intl.t('depositBoxForm.addFileDragAndDropAdd').charAt(0).toUpperCase() : this.intl.t('depositBoxForm.addFileDragAndDropAdd');
    }

    @computed('args.depositBox.creationDate')
    get depositBoxAuthorName() {
        return this.args.depositBox.author.givenName + " " + this.args.depositBox.author.surname;
    }

    get dateFormat() {
        return this.intl.t('depositBox.dateHourFormat');
    }
    
    @computed('transferManager.failed.length', 'transferManager.active.length', 'transferManager.active.@each.{state}')
    get validateDisabled() {
        const failedTransfer = this.transferManager.failed.filter((item) => item.error.code !== libcryptobox.ErrorCode.InvalidParameter);
        return failedTransfer.length >= 1 || this.transferManager.active.length >= 1;
    }
    
    @action
    onFileDropped(fileList) {
        this.args.onFileDropped(fileList);
    }

    @action
    onMouseOver() {
      this.highlight = true;
    }

    @action
    onMouseOut() {
      this.highlight = false;
    }

    @action
    validate() {
        this.args.onClickValidate(this.comment);
    }
}

