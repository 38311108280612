import { DateTime } from 'luxon';

/**
 * Adds a specified number of days to a given date.
 *
 * @param {Date|string} date - The date to which days are added. It can be a JavaScript Date object or a string in ISO 8601 format.
 * @param {number} days - The number of days to add.
 * @returns {string|null} A new ISO 8601 formatted string representing the date after adding the specified number of days, or null if the date is invalid.
 * @throws {Error} If the input date is not a valid Date object or ISO 8601 string.
 *
 * @example
 * // Example Usage:
 * // Adds 1 day to '2020-01-01T00:00:00.000Z', result is a ISO 8601 formatted string representing '2020-01-02T00:00:00.000Z'
 * const result = addDaysToDate('2020-01-01T00:00:00.000Z', 1);
 * console.log(result);
 */
export function addDaysToDate(date, days) {
  if (!date) return;
  if (!days) return date;

  try {
    const parsedDate = DateTime.fromJSDate(date).plus({ days });
    return parsedDate.toJSDate();
  } catch (error) {
    throw new Error(
      'Invalid date input. Please provide a valid Date object or ISO 8601 formatted string.'
    );
  }
}
