// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr, belongsTo } from '@ember-data/model';

// PermissionsRecord defines the permissions of the authenticated user in a space.
export default class extends Model {
  // The space ID.
  // @type string
  @attr spaceId;
  
  // The space may have metadata (name, description, tags, security levels etc.).
  // @type boolean
  @attr canListMetadata;
  
  // The user is allowed to edit the metadata.
  // @type boolean
  @attr canUpdateMetadata;
  
  // The user is allowed to restore the space.
  // @type boolean
  @attr canRestore;
  
  // The space may have other members than the authenticated users.
  // @type boolean
  @attr canListSpaceMembers;
  
  // The user is allowed to edit the space members.
  // @type boolean
  @attr canUpdateSpaceMembers;
  
  // The space is suitable for messaging.
  // @type boolean
  @attr canListMessages;
  
  // The space may have user categories.
  // @type boolean
  @attr canListCategories;
  
  // The user is allowed to enter the file history.
  // @type boolean
  @attr canListFileRevisions;
  
  // The space may have UI notifications for the user.
  // @type boolean
  @attr canListNotifications;
  
  // The space may have file subscriptions for the user.
  // @type boolean
  @attr canListFileSubscriptions;
  
  // The user may update the file subscriptions.
  // @type boolean
  @attr canUpdateFileSubscriptions;
  
  // The user is allowed to upload files and create folders.
  // @type boolean
  @attr canUploadFile;
  
  // The user is allowed to copy files from the space, provided that PermissionsRecord's CanUploadFile is true in the destination space.
  // @type boolean
  @attr canCopyFile;
  
  // The user is allowed to move files from the space, provided that PermissionsRecord's CanUploadFile is true in the destination space.
  // @type boolean
  @attr canMoveFile;
  
  // The user is allowed to rename files and folders.
  // @type boolean
  @attr canRenameFile;
  
  // The user is allowed to delete files and folders.
  // @type boolean
  @attr canRemoveFile;
  
  // The user is allowed to download a file and export it outside of the application. If false, the application may still download a file to create a preview but should not allow the user to share it.
  // @type boolean
  @attr canExportFile;
  
  // The user is allowed to create a sharing link.
  // @type boolean
  @attr canCreateFileSharing;
  
  // The user is allowed to create a sharing link that includes the decryption key. If PermissionsRecord's CanCreateFileSharing is true and this field is false, the user can only creates sharing links with separate keys.
  // @type boolean
  @attr canCreateFileSharingIncludingKey;
  
  // The user is allowed to view all the sharing links in the space.
  // @type boolean
  @attr canListAllFileSharings;
  
  // The user is allowed to view her own sharing links. Always true if PermissionsRecord's CanListAllFileSharings is set.
  // @type boolean
  @attr canListOwnFileSharings;
  
  // The user is allowed to edit a sharing link.
  // @type boolean
  @attr canUpdateFileSharing;
  
  // The user is allowed to delete a sharing link.
  // @type boolean
  @attr canDeleteFileSharing;
  
  // The user is allowed to create internal link.
  // @type boolean
  @attr canCreateLink;
  
  @belongsTo('space', { async: true, inverse: null }) space;
}

