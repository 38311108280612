import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
import { action, computed } from '@ember/object';
import { DateTime } from 'luxon';

export default class HistorySidePanelComponent extends Component {
  @service intl;
  @service commonService;
  @service store;
  @service historyService;

  @tracked detailsIsVisible = false;
  @tracked displayOldHistory = false;
  @tracked selectedTimeNode = null;
  @tracked selectedHistoryDate = null;
  @tracked searchedDate = '';
  @tracked selectedPage = 1;
  @tracked maxDate = new Date();
  @tracked limitHistoryPage = 100;
  @tracked elementsPerPage = 100;
  @tracked offsetHistoryPage = -1;
  @tracked histories = A([]);
  @tracked firstFileRevision = null;
  @tracked isloadingHistory = false;
  @tracked isShowMoreBtn = false;

  @computed('args.displayHistory', 'files.[]', 'searchedDate')
  get reloadComponent() {
    this.loadHistory;
  }

  get displayHistory() {
    return this.args.displayHistory;
  }

  @computed(
    'commonService.workspaceRevision',
    'histories',
    'limitHistoryPage',
    'model.files.[]',
    'offsetHistoryPage',
    'searchedDate',
    'space',
    'workspaceID',
  )
  get loadHistory() {
    this.isloadingHistory = true;
    try {
      return this.historyService
        .loadHistory({
          workspaceId: this.workspaceID,
          workspaceRevision: this.commonService.workspaceRevision,
          limit: this.limitHistoryPage,
          notAfterDate: this.searchedDate,
          offset: this.offsetHistoryPage,
          space: this.space,
        })
        .then((histories) => {
          // verify if history length is equal to limitHistoryPage
          this.isShowMoreBtn = histories.length === this.limitHistoryPage;
          this.histories.addObjects(histories);
          this.isloadingHistory = false;
        });
    } catch (error) {
      this.isloadingHistory = false;
      return [];
    }
  }

  get model() {
    return this.args.model;
  }

  get tLuxonFormat() {
    return this.intl.t('dateFormat');
  }

  get files() {
    return this.model.files;
  }

  get workspaceID() {
    return this.model.space.id;
  }

  get space() {
    return this.model.space;
  }

  get listOfDatesPage() {
    if (this.searchedDayHistory.length >= this.elementsPerPage) {
      return [];
    }

    if (this.searchedDayHistory.length) {
      const remainLength =
        this.elementsPerPage*(this.selectedPage - 1) -
        this.historiesFiltered.length;
      return this.listOfDates.slice(0, remainLength + 1);
    }

    return this.historiesFiltered
      .concat(this.listOfDates)
      .sortBy('number')
      .reverse()
      .slice(0, this.elementsPerPage);
  }

  get searchedDayHistory() {
    if (this.historiesFiltered.length === 0) {
      return [];
    }

    return this.historiesFiltered.slice(0, this.elementsPerPage);
  }

  get historiesFiltered() {
    let result = [];

    if (this.searchedDate) {
      const maxRevision = this.histories.get('firstObject.number');
      const h = this.histories.filter(history => history.number !== maxRevision);

      result = h.filter((history) => {
        return (
          DateTime.fromISO(history.get('date')).startOf('day').toISO() ===
          this.searchedDate
        );
      });
    }
    return result;
  }

  get listOfDates() {
    let histories = this.histories;
    const maxRevision = this.histories.get('firstObject.number');

    histories = histories.filter((history) => history.number !== maxRevision);
    if (this.searchedDate) {
      const dateSelection = DateTime.fromJSDate(this.searchedDate);
      histories = histories.filter((history) => {
        return (
          DateTime.fromISO(history.date).startOf('day') <
          DateTime.fromISO(dateSelection).startOf('day')
        );
      });
    }

    return histories;
  }

  get showLoadMore() {
    if (this.searchedDate) {
      const lastObjectNumber = this.searchedDayHistory.get('lastObject.number');

      if (this.searchedDayHistory.length === 0 || !this.isShowMoreBtn) {
        return false;
      }

      return lastObjectNumber !== 1;
    } else {
      const histories = this.listOfDatesPage.concat(this.historiesFiltered);

      if (histories.length === 0 || !this.isShowMoreBtn) {
        return false;
      }

      const lastObjectNumber = histories.get('lastObject.number');

      return lastObjectNumber !== 1;
    }
  }

  @action
  loadMore() {
    const lastObjectNumber = this.searchedDate
      ? this.searchedDayHistory.get('lastObject.number')
      : this.listOfDatesPage.lastObject.number;

    if (lastObjectNumber === 1) {
      return;
    }
    this.elementsPerPage += 100;
    this.offsetHistoryPage = lastObjectNumber;
  }

  @action
  toggleShowHistory() {
    this.toggleProperty('displayHistory');
    if (this.displayHistory) {
      this.detailsIsVisible = false;
    }
  }

  @action
  filterDate(date) {
    this.searchedDate = DateTime.fromJSDate(date).toISO();
  }

  @action
  openPicker() {
    document.querySelector('#select-date-input').click();
  }
}
