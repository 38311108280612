import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';

export default class Tutorial extends Controller {
  @service account;
  @service intl;

  /**
   * Fix for fingerprint image for production environement
   * For more detail and cleaner solution: https://discuss.emberjs.com/t/resolving-fingerprinted-assets-using-generateassetmap/8783 - https://ember-cli.com/asset-compilation
   */
  @computed('intl.locale')
  get isFr() {
    return this.intl.locale[0] === 'fr-fr';
  }
}
