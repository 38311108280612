/* global ipc */
import ENV from '../config/environment';

export async function getPipeName() {
  let pipeName = '/tmp/cryptobox-rpc-webapp';
  if ((await ipc.os.platform()) === 'win32') {
    pipeName = '\\\\.\\pipe\\cryptobox-rpc-webapp';
  }
  return pipeName;
}

export async function getSettings(application) {
  const name = await ipc.username.sync();
  let cacheKey = await ipc.keytar.getPassword('CryptoboxDesktopKey', name);

  if (!cacheKey) {
    cacheKey = await ipc.newCacheKey();
    await ipc.keytar.setPassword('CryptoboxDesktopKey', name, cacheKey);
  }

  const cacheDir = await ipc.getToolboxCacheDir();

  return {
    cacheDir: cacheDir,
    cacheKey: cacheKey.substring(0, cacheKey.length - 1),
    deviceInfo: { appVersion: ENV.APP.version, appName: 'Desktop' },
  };
}
