import { set } from '@ember/object';
import Controller from '@ember/controller';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { A } from '@ember/array';
import { debounce } from '@ember/runloop';
import diacritic from 'diacritic';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';
/* global libcryptobox */

export default class Conversation extends Controller {
  @tracked memberName = '';
  @tracked showAcknowledgement = false;
  @tracked showUsers = false;
  @tracked newMembers = A();
  @tracked showUnread = false;
  @tracked isDragOver = false;
  @tracked addMembers;
  @tracked users;
  @tracked model;

  @service messaging;
  @service store;
  @service account;
  @service router;

  constructor() {
    super(...arguments);
    //Activate observer on messaging
    this.messaging.get('messagingStatus');
    window.addEventListener('dragover', this._preventEvent, false);
    window.addEventListener('drop', this._preventEvent, false);
  }

  _preventEvent(e) {
    e.preventDefault();
  }

  _close() {
    this.showUsers = false;
    this.addMembers = false;
  }

  _checkAllRead(messages) {
    const lastRead = parseInt(this.model.get('cursor.lastRead'));
    messages =
      messages.length !== 0
        ? messages?.filter(
            (message) =>
              message.get('parsedTimestamp') > lastRead &&
              !message.get('deletable'),
          )
        : messages;
    this.showUnread = !messages?.isEvery('isRead');
  }

  get allReadMessage() {
    const messages = this.model.get('messages');

    if (messages) {
      debounce(this, this._checkAllRead, messages, 2000);
    }

    return true;
  }

  @computed('newMembers.[]')
  get formIsValid() {
    const length = this.newMembers.length;

    return length > 0;
  }

  @computed('account.userEmail', 'membersConversation.@each.role')
  get canManage() {
    const userEmail = this.account.userEmail;
    let userMember;
    this.membersConversation.then(
      (members) => (userMember = members.findBy('email', userEmail)),
    );
    return userMember?.role === libcryptobox.Role.Owner;
  }

  @computed(
    'memberName',
    'membersConversation',
    'model.{conversation,users}',
    'newMembers',
    'users',
  )
  get membersList() {
    const searchTerm = diacritic.clean(this.memberName.toLowerCase());
    const members = this.membersConversation;
    const membersMail = members.map((member) => member.get('email'));
    const contactList = this.users
      ?.sortBy('fullName')
      .reject((contact) => membersMail.indexOf(contact.get('email')) > -1);
    const filtredContactsList = [];

    if (searchTerm === '') {
      return contactList;
    }
    contactList.forEach((contact) => {
      const ctName = diacritic.clean(contact.get('fullName').toLowerCase());
      const ctEmail = diacritic.clean(contact.get('email').toLowerCase());

      if (ctName.indexOf(searchTerm) !== -1) {
        return filtredContactsList.push(contact);
      } else if (ctEmail.indexOf(searchTerm) !== -1) {
        return filtredContactsList.push(contact);
      }
    });
    return filtredContactsList;
  }

  @computed('model.cursor.{latest,lastRead}')
  get showNewMessagesWarning() {
    const cursor = this.model.get('cursor');
    return cursor.get('latest') !== cursor.get('lastRead');
  }

  @computed(
    'account.serverSettings.hasMessaging',
    'messaging.isOffline',
    'messaging.messagingStatus.isConnected',
    'model.{isDeleted,messages}',
  )
  get checkStatus() {
    if (!this.account.serverSettings.hasMessaging)
      this.router.transitionTo('my-groups');
    if (this.model.get('isDeleted')) this.router.transitionTo('messages.index');
    if (!this.messaging.get('isOffline')) return true;
    const model = this.model.get('messages');

    if (model) {
      model.then((messages) => {
        messages.filter(message => message.isSending).forEach((message) => {
          message.set('status', libcryptobox.MessageStatus.Rejected);
        });
      });
    }

    return true;
  }

  calculatePosition(trigger, content) {
    const { top, left, height } = trigger.getBoundingClientRect();
    const { height: contentHeight } = content.getBoundingClientRect();

    const style = {
      left: left - 410,
      top: top + window.pageYOffset + height / 2 - contentHeight / 2,
    };

    return { style };
  }

  @computed('model.{id,memberCount}')
  get membersConversation() {
    return this.store.query('space-member', { spaceId: this.model.get('id') });
  }

  @action
  addMember(member) {
    if (this.newMembers.indexOf(member) === -1) {
      this.newMembers.pushObject(member);
      member.set('isSelected', true);
    }
  }

  @action
  async addMembersToGroup() {
    if (!this.formIsValid) {
      return;
    }

    const space = this.model;
    const members = await this.membersConversation;
    const membersMail = members.map((member) => member.get('email'));

    this.newMembers.forEach((member) => {
      if (membersMail.indexOf(member.get('email')) > -1) return;

      this.store
        .createRecord('space-member', {
          space: space,
          email: member.get('email'),
          givenName: member.get('givenName'),
          surname: member.get('surname'),
          role: libcryptobox.Role.Owner,
        })
        .save();
    });

    this.newMembers = A();
    this._close();
  }

  @action
  closeAddMembers() {
    this._close();
  }

  @action
  leaveSpace() {
    tBoxClient.spaceMember
      .remove(
        this.model.get('id'),
        this.model
          .get('members')
          .findBy('email', this.account.userEmail)
          .get('id'),
      )
      .then(() => this.router.transitionTo('messages.index'));
  }

  @action
  removeMember(member) {
    member.set('isSelected', false);
    this.newMembers.removeObject(member);
  }

  @action
  removeUser(user) {
    const space = this.model;
    space.get('members').findBy('id', user.get('id')).destroyRecord();
  }

  @computed('model.members')
  get currentMember() {
    return DS.PromiseObject.create({
      promise: this.model.get('members').then((members) => {
        return members.findBy('isMySelf', true);
      }),
    });
  }

  @action
  scrollToUnread() {
    const lastRead = this.model.get('cursor.lastRead');
    const messages = this.model.messages.filter(
      (message) =>
        message.get('timestamp') > lastRead && !message.get('deletable'),
    );
    const firstUnreadId = messages.get('firstObject.elementId');

    if (firstUnreadId) {
      document.querySelector('.container-conversationStream').scrollTop =
        document.querySelector('#' + firstUnreadId).offsetTop;
    }
  }

  @action
  async showAddMembers() {
    const users = await this.store.query('user', {});
    users.forEach((user) => user.set('isSelected', false));
    this.users = users;
    this.addMembers = true;
  }

  @action
  showMembers() {
    this.showUsers = true;
    this.showAcknowledgement = false;
  }
  @action
  willDestroy() {
    super.willDestroy(...arguments);
    this.isDragOver = false;
  }
}
