import { run, debounce } from '@ember/runloop';
import { Promise as EmberPromise } from 'rsvp';
import { computed } from '@ember/object';
import { observes } from '@ember-decorators/object';
import { tracked } from '@glimmer/tracking';
import Service, { inject as service } from '@ember/service';
import { tBoxClient } from 'client/initializers/init-toolbox';
/* global libcryptobox */

export default class Messaging extends Service {
  @tracked isEnabled = false;
  @tracked messagingIsClosed = true;
  @tracked messagingIsOpened = false;
  @tracked messagingIsOpening = false;
  @tracked directConversationsIsExpand = false;
  @tracked workspaceConversationsIsExpand = false;
  @tracked conversationSortTimestamp;
  @tracked conversationSortName;
  @tracked hideOfflineBanner = true;
  @tracked messagingStatus = null;
  @tracked readCursors = null;
  @tracked inbox = null;
  @tracked isConnecting = false;
  @tracked lastTimestamp = null;
  @tracked spaces = null;

  @service store;
  @service notificationApi;
  @service account;
  @service router;

  constructor() {
    super(...arguments);
    this.initializeService();
  }
  initializeService() {
    this.conversationSortTimestamp = ['cursor.latest:desc'];
    this.conversationSortName = ['name:asc'];
    this.messagingStatus = {};
    this.readCursors = [];
    this.inbox = [];
    this.hideOfflineBanner = true;
  }

  @computed('messagingStatus.isConnected')
  get isOffline() {
    return !this.messagingStatus.isConnected;
  }

  @computed('readCursors.@each.unreadCount', 'router.currentRoute.name')
  get unreadCount() {
    let cursors;
    if (this.router.currentRoute.name === 'messages.index.conversation') {
      const conversationId = this.router.currentRoute.params.space_id;
      cursors = this.readCursors.filter( cursor => cursor.id !== conversationId);
    } else {
      cursors = this.readCursors;
    }
    return cursors.reduce((prev, cur) => prev + cur.unreadCount, 0);
  }

  @computed('spaces.@each.kind')
  get conversations() {
    return this.spaces.filter((space) => {
      const kind = space.kind;
      return (
        kind === libcryptobox.SpaceKind.Workspace ||
        kind === libcryptobox.SpaceKind.DirectConversation ||
        kind === libcryptobox.SpaceKind.GroupConversation
      );
    });
  }

  initializeMessaging() {
    return tBoxClient.serverSettings
      .get()
      .then((serverSettings) => {
        let capabilities = serverSettings.capabilities;

        const adapterOptions = { subscribe: this };
        this.spaces = this.store.findAll('space', { adapterOptions });
        if (capabilities.includes(libcryptobox.ServerCapability.Messaging)) {
          this.connectToMessaging().then(() => {
            setTimeout(() => {
              this.hideOfflineBanner = false;
            }, 10000);
          });

          this.notificationApi.checkIfNotificationAreGranted();
          this.isEnabled = true;
          this.store
            .queryRecord('messaging-status', { adapterOptions })
            .then((status) => this.messagingStatus = status);
          this.store
            .findAll('read-cursor', { adapterOptions })
            .then((readCursors) => (this.readCursors = readCursors));
          this.store
            .query('message', { inbox: true, adapterOptions })
            .then((inbox) => (this.inbox = inbox));
          return true;
        } else {
          this.isEnabled = false;
        }
        return false;
      })
      .catch((error) => {
        console.error(error);
        this.initializeMessaging();
      });
  }

  connectToMessaging() {
    const store = this.store;
    this.spaces = store.findAll('space');

    return new EmberPromise((resolve, reject) => {
      if (!this.messagingStatus.isConnected) {
        tBoxClient.im
          .connect()
          .then(() => {
            run(null, resolve);
          })
          .catch(() => {
            this.isConnecting = false;
            run(null, reject);
          });
      } else {
        run(null, resolve);
      }
    });
  }

  retryMessagingConnection() {
    if (this.isConnecting) {
      return false;
    }
    this.isConnecting = true;
    this.connectToMessaging()
      .then(() => {
        this.isConnecting = false;
      })
      .catch((e) => {
        console.warn(e);
      });

    //timeout
    setTimeout(() => {
      if (!this.messagingStatus.isConnected) {
        this.isConnecting = false;
      }
    }, 5000);
  }

  _sendInbox() {
    this.inbox.forEach((message) => {
      if (this.lastTimestamp !== message.timestamp) {
        this.store
          .findRecord('space', message.space.get('id'))
          .then((conversation) => {
            let notificationTitle;
            if (
              conversation.get('kind') ===
              libcryptobox.SpaceKind.DirectConversation
            ) {
              notificationTitle = conversation.computedName;
            } else {
              let conversationName = conversation.name;
              notificationTitle = `${message.author.givenName} ${message.author.surname} (${conversationName})`;
            }
            this.notificationApi.sendNotification(notificationTitle, {
              body:
                message.text ||
                message.attachments.at(0).fileName,
              fallbackIcon: 'empty-message-bubble',
              disableFallback: true,
            });
            this.lastTimestamp = message.timestamp;
          });
      }
    });
  }

  @observes('inbox.[]')
  onMessageReception() {
    debounce(this, this._sendInbox, null, 500);
  }

  uploadAttachments(files, conversation) {
    let store = this.store;
    conversation.members.then(async (members) => {
      let author = members.findBy('email', this.account.userEmail);
      for (let file of files) {
        let message = store.createRecord('message', {
          space: conversation,
          text: '',
          author: store.createRecord('user', {
            email: author.email,
            givenName: author.givenName,
            surname: author.surname,
          }),
          status: libcryptobox.MessageStatus.Pending,
        });

        let attachment = store.createRecord('attachment', {
          message: message,
          fileName: file.name,
          fileSize: file.size,
          fileData: file,
        });

        message
          .save()
          .then(() => attachment.save())
          .then(() => {
            message.set('isUploading', true);
            const promise = message.post();
            message.set('cancelUpload', promise.cancel);
            return promise;
          })
          .then(
            () => {},
            (rejection) => {
              console.warn(rejection);
              attachment.unloadRecord();
              message.unloadRecord();
            },
          );
      }
    });
  }
}
