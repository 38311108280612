import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<button\n  class=\"action-panel-item\n    {{@class}}\n    {{if @disabled \"action-panel-item--disabled\"}}\"\n  {{on \"click\" this.onClick}}\n  data-testid={{@data-testid}}\n  id={{@id}}\n  disabled={{if @disabled true false}}\n>\n  {{inline-svg @svg}}\n  {{@title}}\n</button>\n\n{{yield}}", {"contents":"<button\n  class=\"action-panel-item\n    {{@class}}\n    {{if @disabled \"action-panel-item--disabled\"}}\"\n  {{on \"click\" this.onClick}}\n  data-testid={{@data-testid}}\n  id={{@id}}\n  disabled={{if @disabled true false}}\n>\n  {{inline-svg @svg}}\n  {{@title}}\n</button>\n\n{{yield}}","moduleName":"client/components/action-panel/item.hbs","parseOptions":{"srcName":"client/components/action-panel/item.hbs"}});
import Component from "@glimmer/component";

export default class ItemComponent extends Component {
  defaultOnClick = () => {};

  get onClick() {
    if (this.args.disabled) {
      return () => {};
    }
    return this.args.onClick || this.defaultOnClick;
  }
}
