import Service, { inject as service } from '@ember/service';
import { throttle } from '@ember/runloop';
import { AUTOMATIC_LOGOUT_COUNTDOWN_SEC } from '../utils/constants';
import { tracked } from '@glimmer/tracking';

const LAST_USER_ACTIVITY_TIMESTAMP = 'last-user-activity-tp';
const AUTOMATIC_LOGOUT_MODAL_NAME = 'automatic-logout-modal';

export default class UserActivityService extends Service {
  @service account;
  @service transfers;
  @service modalManager;
  @service notify;
  @service intl;

  @tracked interval;

  @tracked externalLogout = false;
  @tracked monitoring = false;
  @tracked warning = false;

  @tracked pleaseLogOut = false;
  @tracked depositaryPage = false;

  @tracked toast = null;

  constructor() {
    super(...arguments);

    document.addEventListener(
      'visibilitychange',
      this._onVisibilityChange.bind(this),
    );

    const events = [
      'click',
      'keypress',
      'touchstart',
      'mousemove',
      'mousedown',
      'wheel',
    ];
    this._registerEventListeners(events);
  }

  /*
   * Start user activity tracking, a local storage value will be shared across tabs
   * with last activity timestamp. (click, mousemove, download, upload...)
   * After inactivity, value set in webIdleLogoutTimeoutMinutes (ServerSettings)
   * the user user activity tracking is stoped and user will be logged out
   * If webIdleLogoutTimeoutMinutes is null or 0, user activity tracking is disabled
   */
  start() {
    if (!this.timeoutMinutes) return;
    this.externalLogout = false;
    this._startMonitoring();
    this.warning = false;

    this._updateLastUserActivityTimestamp();
    this._startInterval();
    this._closeToastSessionExpired();
  }

  /*
   * Stop user activity tracking
   */
  stop() {
    this.externalLogout = true;
    this._stop();
  }

  /*
   * Confirm if logout should happen or user activity tracking should reset
   * This function is called via the modal
   * @shouldLogOut is a boolean, true if user should be loged out, false otherwise
   */
  confirmLogOut(shouldLogOut, noToast) {
    this._stop();
    if (this.pleaseLogOut || shouldLogOut || this.externalLogout) {
      if (shouldLogOut || this.pleaseLogOut) {
        this.modalManager.closeAll();
        this.account.logout(noToast ? 'user' : 'timeout-activity');
        this.pleaseLogOut = false;
      }
    } else {
      this.start();
    }
  }

  get windowIsVisible() {
    return document.visibilityState === 'visible';
  }

  get timeoutMinutes() {
    if (this.account.serverSettings) {
      return this.account.serverSettings.webIdleLogoutTimeoutMinutes;
    }
    return null;
  }

  get timeoutMilliseconds() {
    return this.timeoutMinutes * 60 * 1000;
  }

  get countdownTimeoutMilliseconds() {
    return AUTOMATIC_LOGOUT_COUNTDOWN_SEC * 1000;
  }

  get lastUserActivityTimestamp() {
    return localStorage.getItem(LAST_USER_ACTIVITY_TIMESTAMP);
  }

  get millisecondsSinceLastUserActivity() {
    const last = this.lastUserActivityTimestamp;
    const now = Date.now();
    return Math.round(now - last);
  }

  get timeoutLeftSeconds() {
    if (!this.timeoutMilliseconds) return null;
    if (!this.millisecondsSinceLastUserActivity) return null;
    if (this._isActivityTimeoutCountdown()) return null;

    return Math.round(
      (this.timeoutMilliseconds - this.millisecondsSinceLastUserActivity) / 1000,
    );
  }

  _isActivityTimeout() {
    const elapsed = this.millisecondsSinceLastUserActivity;
    const timeout =
      this.timeoutMilliseconds - this.countdownTimeoutMilliseconds;
    return elapsed >= timeout;
  }

  /*
   * setIsDepositaryPage is a function who set a boolean to true
   * if the user is on the depositary page
   *
   */
  setIsDepositaryPage(status) {
    this.depositaryPage = status;
  }

  _isActivityTimeoutCountdown() {
    const elapsed = this.millisecondsSinceLastUserActivity;
    const timeout = this.timeoutMilliseconds;
    return elapsed > timeout;
  }

  _startInterval() {
    this._stopInterval();
    this.tracking = true;
    this.interval = setInterval(this._intervalCallback.bind(this), 1000);
  }

  _stopInterval() {
    this.tracking = false;
    if (this.interval) clearInterval(this.interval);
  }

  _intervalCallback() {
    if (this.depositaryPage || this.transfers.isTransfering) {
      this._updateLastUserActivityTimestamp();
    } else if (!this._isActivityTimeout() && this.warning) {
      this._stop();
      this.start();
    } else if (this._isActivityTimeout() && !this.warning) {
      this._warningEvent();
    } else if (this._isActivityTimeoutCountdown()) {
      this.pleaseLogOut = true;
      this._stop();
      this.confirmLogOut(true);
    }
  }

  _stop() {
    this.warning = false;
    this._stopMonitoring();
    this._stopInterval();
    this._closeModal();
  }

  _updateLastUserActivityTimestamp() {
    localStorage.setItem(LAST_USER_ACTIVITY_TIMESTAMP, Date.now());
  }

  _registerEventListeners(events) {
    for (const ev of events) {
      window.addEventListener(ev, this._eventCallback.bind(this));
    }
  }

  _eventCallback() {
    if (this.monitoring) {
      throttle(this, this._updateLastUserActivityTimestamp, 500);
    }
  }

  _onVisibilityChange() {
    if (!this.timeoutMinutes) return;
    if (this.windowIsVisible && this.account.isLoggedIn) {
      if (this._isActivityTimeout() && this.warning) {
        this._openModal();
      } else if (this._isActivityTimeoutCountdown()) {
        this.confirmLogOut(true);
      }
    }
  }

  _startMonitoring() {
    this.monitoring = true;
  }

  _stopMonitoring() {
    this.monitoring = false;
  }

  _cleanLastUserActivity() {
    localStorage.removeItem(LAST_USER_ACTIVITY_TIMESTAMP);
  }

  _warningEvent() {
    this._stopMonitoring();
    this.warning = true;
    if (this.windowIsVisible) {
      this._openModal();
    }
  }

  _closeModal() {
    if (this.modalManager.isModalOpen(AUTOMATIC_LOGOUT_MODAL_NAME)) {
      this.modalManager.close(AUTOMATIC_LOGOUT_MODAL_NAME);
    }
  }

  _openModal() {
    if (!this.modalManager.isModalOpen(AUTOMATIC_LOGOUT_MODAL_NAME))
    {
      this.modalManager.open(AUTOMATIC_LOGOUT_MODAL_NAME);
    }
  }

  openToastSessionExpired() {
    if (!this.toast) {
      const message = this.intl.t('userActivity.toast.reconnect');
      const title = this.intl.t('userActivity.toast.sessionExpired');
      this.toast = this.notify.error(message, {
        title: title,
        closeAfter: null,
      });
    }
  }

  _closeToastSessionExpired() {
    if (this.toast) {
      this.toast.close();
      this.toast = null;
    }
  }
}
