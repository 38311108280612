import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class Badge extends Component {
  @service intl;

  badgeState = [
    {
      value: 'populatedUnread',
      label: this.intl.t('depositBox.badge.populatedUnreadOrPopulatedRead'),
      color: { background: '#FFF4D6', text: '#8D6B07' },
    },
    {
      value: 'populatedRead',
      label: this.intl.t('depositBox.badge.populatedUnreadOrPopulatedRead'),
      color: { background: '#FFF4D6', text: '#8D6B07' },
    },
    {
      value: 'populatedProcessed',
      label: this.intl.t('depositBox.badge.populatedProcessed'),
      color: { background: 'var(--green-light, #E5FBF0)', text: '#157A49' },
    },
    {
      value: 'blocked',
      label: this.intl.t('depositBox.badge.blocked'),
      color: {
        background: '#FEE3E3',
        text: '#8D0707',
      },
    },
    {
      value: 'empty',
      label: this.intl.t('depositBox.badge.empty'),
      color: {
        background: 'var(--grey-0, #F2F3F8)',
        text: 'var(--grey-80, #6B6B71)',
      },
    },
  ];

  get state() {
    return this.args.state;
  }

  get currentFilter() {
    return (
      this.badgeState.find((f) => f.value === this.state) ||
      this.badgeState.find((f) => f.value === 'empty')
    );
  }

  get value() {
    return this.currentFilter.label;
  }

  get styles() {
    const { background, text } = this.currentFilter.color;
    return `background: ${background}; color: ${text};`;
  }
}
