import Component from '@glimmer/component';

export default class FileVersion extends Component {
  get version() {
    return this.args.version;
  }

  get isLastVersionNumber() {
    return this.args.isLastFileVersionNumber;
  }

  get badgeClass() {
    return this.isLastVersionNumber ? 'curr-badge' : '';
  }

  get textClass() {
    return this.isLastVersionNumber ? 'curr-version-text' : '';
  }
}
