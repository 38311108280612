import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { htmlSafe } from '@ember/template';

export default class FileItem extends Component {
  @service intl;
  @service commonService;
  @service('file-history') fileHistoryService;

  @tracked hoveredItem = null;
  @tracked windowWidth = window.innerWidth;


  get fileItem() {
    return this.args.fileItem;
  }

  get selectedDate() {
    return this.fileHistoryService.selectedDate;
  }

  get itemDays() {
    if (this.selectedDate) {
      const selectedDate = new Date(this.selectedDate).setHours(0, 0, 0, 0);
      const selectedDay = Object.entries(this.fileItem.days).find(
        ([key, _]) => {
          const currentDate = new Date(key).setHours(0, 0, 0, 0);
          return currentDate === selectedDate;
        },
      );
      if (selectedDay) {
        return [{ date: selectedDay[0], value: selectedDay[1] }];
      }
      return [];
    } else {
      return Object.entries(this.fileItem.days).map(([key, value]) => ({
        date: key,
        value,
      }));
    }
  }

  @action
  handleUnSelectDate() {
    this.fileHistoryService.oldSelectedDate =
      this.fileHistoryService.selectedDate;
    this.fileHistoryService.year = new Date().getFullYear();
    this.fileHistoryService.selectedDate = null;
    const selectedClass = 'subDomainSelected';

    document
      .querySelectorAll(`.${selectedClass}`)
      .forEach((element) => element.classList.remove(selectedClass));
  }

  @action
  handleSelectFileItem(item) {
    this.fileHistoryService.selectedFileHistoryInSideNav = item;
    this.commonService.isOpenSideNav = true;
  }

  @action
  isShow(item, value) {
    if (item?.fileVersionNumber === 0) return;
    if (value) {
      this.hoveredItem = item;
    } else {
      this.hoveredItem = null;
    }
  }


  get dynamicStyle() {
    if (this.windowWidth && this.windowWidth <= 1145) {
      return htmlSafe(`max-width: ${this.windowWidth - 410}px !important`);
    } else {
      return htmlSafe(`max-width: 540px !important`);
    }
  }
}
