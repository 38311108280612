import Controller from "@ember/controller";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import { inject as service } from "@ember/service";
import { task } from "ember-concurrency";
import { DEVICE_KEY_ASSOCIATION_MODAL_NAME, USER_ROLES } from "../utils/enums";
import { tBoxClient } from "../initializers/init-toolbox";

const OPTIONS = Object.values(USER_ROLES).map((value) => value);
const MENU = [
  {
    name: "Legacy",
    id: "legacy",
  },
  {
    name: "Security Level",
    id: "securityLevelChip",
    component: "design-system/security-level",
  },
  {
    name: "Checkbox",
    id: "checkbox",
    component: "design-system/checkbox",
  },
  {
    name: "Inputs Text",
    id: "inputsText",
    component: "design-system/inputs-text",
  },
  {
    name: "Electron Connection",
    id: "electronConnection",
    component: "design-system/electron-connection",
  },
  {
    name: "Button",
    id: "button",
    component: "design-system/button",
  },
  {
    name: "Overlay",
    id: "overlay",
    component: "design-system/overlay",
  },
  {
    name: "Loader",
    id: "loader",
    component: "design-system/loader",
  },
  {
    name: "Electron device key association",
    id: "electron-device-key-association",
    component: "design-system/electron-device-key-association",
  },
  {
    name: "Modal",
    id: "modal",
    component: "design-system/modal",
  },
];

const SECURITY_LEVEL = {
  name: "Secret Défense",
  acronym: "SD",
  color: "red",
  shape: "lockedLock",
  deleted: false,
};

function getContentData(id) {
  if (id === MENU[0].id) {
    return SECURITY_LEVEL;
  }
  return {};
}

export default class DesignSystemController extends Controller {
  @tracked checked = false;
  @tracked indeterminate = false;
  @tracked checkboxPositionFlag = false;
  @tracked listData = [{ test: 1 }, { test: 2 }];
  // DropDown
  @tracked ddOptions = OPTIONS;
  @tracked ddSelected = OPTIONS[0];
  @tracked viewableTypes = [];
  @tracked postOfficeAddress = "post office, Paris";
  @tracked menuList = MENU;
  @tracked content = { id: MENU[0].id, data: getContentData(MENU[0].id) };

  @service modalManager;

  @action
  onClickSideMenu(item) {
    this.content = {
      id: item.id,
      data: getContentData(item.id),
    };
  }

  @action
  onChange(change) {
    const { checked } = change;
    this.checked = checked;
    this.indeterminate = false;
  }

  @action
  setIndeterminate() {
    this.indeterminate = true;
  }

  @action
  toggleCheckboxPosition() {
    this.checkboxPositionFlag = !this.checkboxPositionFlag;
  }

  @action
  toggleCheckboxState() {
    this.checked = !this.checked;
  }

  @action
  onChangeDD(selected, dd) {
    this.ddSelected = selected;
    dd.actions.close();
  }

  @task(function* () {
    this.viewableTypes = yield tBoxClient.file.getViewableTypes();
  })
  loadViewableTypes;

  @action
  onChangeAddress(event) {
    const { value } = event.target;
    this.postOfficeAddress = value;
  }

  @action
  onClickOpenDeviceKeyAssociationModal() {
    this.modalManager.open(DEVICE_KEY_ASSOCIATION_MODAL_NAME);
  }

  // Action de DeviceKeyAssociationModal
  @action
  onConfirm() {}
}
