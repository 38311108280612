import Route from '@ember/routing/route';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { inject as service } from '@ember/service';

export default class Conversation extends Route {
  @service messaging;
  @service store;
  @service authService;

  beforeModel() {
    this.authService.loginIfNotAuthenticated();
  }
  waitForOpenConnection() {
    return new Promise((resolve, reject) => {
      const maxNumberOfAttempts = 10;
      const intervalTime = 500;

      let currentAttempt = 0;
      const interval = setInterval(() => {
        if (currentAttempt > maxNumberOfAttempts - 1) {
          clearInterval(interval);
          reject(new Error('Maximum number of attempts exceeded'));
        } else if (this.messaging.messagingStatus.isConnected) {
          clearInterval(interval);
          resolve();
        }
        currentAttempt++;
      }, intervalTime);
    });
  }

  async model(params) {
    const space = await this.store.findRecord('space', params.space_id, {
      include: 'messages',
      adapterOptions: { subscribe: this },
    });
    await this.waitForOpenConnection();
    await tBoxClient.message.load(params.space_id, 20);
    const msg = await this.store.query('message', { spaceId: params.space_id, adapterOptions: { subscribe: this } });
    space.set('messages', msg);
    return space;
  }

  deactivate() {
    this.store.adapterFor('space').unsubscribe(this);
  }

  resetController(controller) {
    const model = controller.get('model');
    const messages = model.get('messages');
    if (Array.isArray(messages) && messages.length !== 0 && messages.some(msg => msg.get('isRead'))) {
      model.get('cursor').then((cursor) => {
        if (cursor) {
          cursor.advance();
        }
      });
    }
  }

  async setupController(controller, model) {
    controller.set('model', model);
    controller.set('addMembers', false);
    controller.set('showUsers', false);
    controller.set('showAcknowledgement', false);
  }
}
