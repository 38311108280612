import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { FILE_SUBSCRIPTION_TYPE } from '../../../utils/constants';

function bindSubscriptionTypeWithTranslationKey(type) {
  if (type === FILE_SUBSCRIPTION_TYPE.MODIFICATIONS) {
    return 'changes';
  }
  if (type === FILE_SUBSCRIPTION_TYPE.DOWNLOADS) {
    return 'downloads';
  }

  return null;
}

const MODAL_NAME = {
  CHILD: 'child-subscription-modal',
  PARENT: 'parent-subscription-modal',
};

export default class SubscriptionCheckboxComponent extends Component {
  modalName = MODAL_NAME;
  disabled = false;
  modalIsOpen = false;

  @service intl;
  @service modalManager;

  /*
   * Modals names & texts
   * */

  get parentModalName() {
    const { type } = this.args;
    return `${MODAL_NAME.PARENT}-${type}`;
  }

  get childModalName() {
    const { type } = this.args;
    return `${MODAL_NAME.CHILD}-${type}`;
  }

  get tSubscribedParentWording() {
    const { node, type } = this.args;
    const { subscribedParent } = node.getSubscriptionData(type);
    if (!subscribedParent) {
      return '';
    }

    const isParentRoot = subscribedParent === '/';
    const tPath = isParentRoot
      ? 'subscriptions.rootSubscribed'
      : 'subscriptions.parentSubscribed';
    const params = isParentRoot
      ? {}
      : { folderName: subscribedParent.split('/').pop() };

    return this.intl.t(tPath, { ...params, type: this.tModalType });
  }

  get tSubscribedChildWording() {
    const { isSpace } = this.args;
    const tPath = isSpace
      ? 'subscriptions.overview.childSubscribed'
      : 'subscriptions.folderChildSubscribed';
    const params = { type: this.tModalType };

    return this.intl.t(tPath, params);
  }

  get tModalType() {
    const { type } = this.args;
    const tPath = `subscriptions.${bindSubscriptionTypeWithTranslationKey(
      type,
    )}`;

    return this.intl.t(tPath);
  }

  /*
   * Checkbox status
   * */

  get indeterminate() {
    const { node, type, isSpace } = this.args;
    const { subscribed, subscribedChild, subscribedParent } =
      node.getSubscriptionData(type);

    if (isSpace) {
      return !!(subscribedChild && !subscribed);
    }
    if (subscribedParent || subscribed) {
      return false;
    }
    return !!subscribedChild;
  }

  get checked() {
    const { node, type } = this.args;
    const { subscribed, subscribedParent } = node.getSubscriptionData(type);
    return !!(subscribedParent || subscribed);
  }

  set checked(value) {}

  /**/

  @action
  openModal(modalName) {
    this.modalManager.open(modalName);
  }

  async toggleCurrentNodeSubscription(toggle) {
    const { node, type } = this.args;
    await node.toggleSubscription(toggle, type);
  }

  /**/

  @action
  unSubscribeParent() {
    const { node, type } = this.args;
    node.unSubscribeParent(type);
  }

  @action
  subscribeNode() {
    void this.toggleCurrentNodeSubscription(true);
    this.modalManager.close(this.childModalName);

  }

  @action
  unSubscribeNode() {
    void this.toggleCurrentNodeSubscription(false);
    this.modalManager.close(this.childModalName);
  }

  @action
  resetFlag(name) {
    this.modalIsOpen = false;
    this.modalManager.close(name);
  }

  @action
  handleChange() {
    const { node, disabled, type } = this.args;

    if (disabled) {
      return;
    }

    const { subscribed, subscribedChild, subscribedParent } =
      node.getSubscriptionData(type);
    if (subscribedParent) {
      return this.openModal(this.parentModalName);
    }
    if (subscribedChild) {
      return this.openModal(this.childModalName);
    }

    if (subscribed) {
      return this.toggleCurrentNodeSubscription(false);
    }
    return this.toggleCurrentNodeSubscription(true);
  }
}
