// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr, belongsTo } from '@ember-data/model';

// The file synchronization state for a given space.
// See SyncStateCollection.
export default class extends Model {
  // Space's ID.
  // @type string
  @attr spaceId;
  
  // Local path to the synchronized folder.
  // @type string
  @attr path;
  
  // Status.
  // @type SyncStatus
  @attr status;
  
  @belongsTo('space', { async: true, inverse: null }) space;
}

