// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr, belongsTo } from '@ember-data/model';

// A low-level event as sent by the server.
// See EventCollection.
export default class extends Model {
  // Event kind.
  // @type EventKind
  @attr kind;
  
  // Space ID for space-related event.
  // @type string
  @attr spaceId;
  
  @belongsTo('space', { async: true, inverse: null }) space;
}

