import { inject as service } from '@ember/service';
import { computed, action } from '@ember/object';
import Component from '@glimmer/component';
import { union } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';

const MENU_COLLAPSE_BREAKPOINT = 700;
export default class NavigationBarComponent extends Component {
  @tracked isCollasped = false;

  @service modalManager;
  @service account;
  @service messaging;
  @service commonService;
  @service intl;
  @service router;
  @service('transfers') transfersManager;
  @service autoUpdate;
  @service uiNotifications;
  @service('deposit-box') depositBoxService;

  constructor() {
    super(...arguments);
    this.setCollapse();
    window.addEventListener('resize', this.setCollapse.bind(this));
  }

  @computed('allTransfer.@each.{state}')
  get displayProgessBar() {
    return this.allTransfer.some(transfer => transfer.state === 'active' || transfer.state === 'retry');
  }

  @computed('transfersManager.failed.{length}')
  get showWarningTransferIcon() {
    return this.transfersManager.failed.length > 0;
  }

  @computed('commonService.creationGroupRegistry')
  get groupInCreation() {
    return this.commonService.creationGroupRegistry;
  }

  @computed('depositBoxService.canAccessDepositBox')
  get canShowDepositBox() {
    return this.depositBoxService.canAccessDepositBox;
  }

  @union('transfersManager.active', 'transfersManager.queued', 'transfersManager.currentFiles')
  allTransfer;

  @computed('transfersManager.active.@each.{progressPercent}')
  get progression() {
    const allProgress = this.allTransfer.map( transfers => transfers.progressPercent);
    return allProgress.reduce((previous, current) => previous + current, 0) / allProgress.length;
  }

  @computed('progression')
  get bufferTotal() {
    return this.progression;
  }

  setCollapse() {
    if (document.documentElement.clientWidth <= MENU_COLLAPSE_BREAKPOINT) {
      this.isCollasped = true;
    } else {
      this.isCollasped = false;
    }
  }

  @action
  toggleMenu() {
    this.isCollasped = !this.isCollasped;
  }

  @action
  openAboutModal(event) {
    event.preventDefault();
    this.modalManager.open('about-modal');
  }

  @action
  closeAboutModal() {
    this.modalManager.close('about-modal');
  }
}
