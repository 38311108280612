import { sort } from '@ember/object/computed';
import { action, computed } from '@ember/object';
import { debounce } from '@ember/runloop';
import { observes } from '@ember-decorators/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import diacritic from 'diacritic';
import { tracked } from '@glimmer/tracking';
import getSortingChoicesFilters from '../../utils/getSortingChoicesFilters';
import getRoleChoicesFilters from '../../utils/getRoleChoicesFilters';

/* global libcryptobox */

export default class IndexController extends Controller {
  @tracked categoryFilters = [];
  @tracked nameFilter;
  @tracked scrollPosition;
  @tracked listDisplay;
  @tracked allSpaces = [];
  @tracked sorting;
  @tracked roleFilter;
  @tracked tagsFilterSelection = [];
  @tracked firstRender = true;
  @tracked isFav;
  @tracked deletedWorkspaces;
  @tracked searchByName;
  @tracked categories = [];

  @service account;
  @service store;
  @service intl;
  @service messaging;
  @service manageStorage;

  defaultSorting = {
    value: 0,
    label: this.intl.t('my.groups.nameAsc'),
    property: 'name',
    order: true,
  };

  defaultRoleFilter = {
    value: 'none',
    label: this.intl.t('my.groups.roleplaceholder'),
  };

  roleFilters = getRoleChoicesFilters(this.intl);

  sortingChoices = getSortingChoicesFilters(this.intl);

  constructor() {
    super(...arguments);
    const options = { reload: true, adapterOptions: { subscribe: this } };
    this.store.findAll('space', options);
    this.allSpaces = this.store.peekAll('space', options);
    this.getFiltersSortingFromSession();
  }

  @computed('sorting.{order,property}', 'spaces')
  get sortedSpaces() {
    const { order = true, property = 'name' } = this.sorting || {};
    const sortedSpaces = this.spaces.sortBy(property);

    return order ? sortedSpaces : sortedSpaces.reverse();
  }

  setScrollPosition() {
    if (this.scrollPosition !== null) {
      const mainContentEl = document.querySelector('#main-content');
      if (mainContentEl) {
        mainContentEl.scrollTo({
          top: this.scrollPosition,
          behavior: 'auto',
        });
      }
    }
  }

  scrollSetup() {
    const mainContentEl = document.getElementById('main-content');

    if (mainContentEl) {
      mainContentEl.addEventListener('scroll', () => {
        debounce(this, this.updateScrollPosition, 250);
      });
    }
  }

  @computed('isFav', 'deletedWorkspaces')
  get listType() {
    if (this.isFav) return 'fav';
    if (this.deletedWorkspaces) return 'deleted';
    return 'all';
  }

  getFiltersSortingFromSession() {
    this.listDisplay = this.manageStorage.getSessionStorageItem(
      'listDisplay',
      this.listType,
    );
    this.sorting =
      this.manageStorage.getSessionStorageItem('sortingWS', this.listType) ||
      this.defaultSorting;
    this.roleFilter =
      this.manageStorage.getSessionStorageItem('roleWS', this.listType) ||
      this.defaultRoleFilter;
    this.tagsFilterSelection =
      this.manageStorage.getSessionStorageItem('tagsFilterWS', this.listType) ||
      [];
    this.nameFilter = this.manageStorage.getSessionStorageItem(
      'nameFilterWS',
      this.listType,
    );
    this.scrollPosition =
      this.manageStorage.getSessionStorageItem(
        'scrollPositionWS',
        this.listType,
      ) || 0;
  }

  @action
  setCategoryFilters() {
    const catFilters =
      this.manageStorage.getSessionStorageItem('catFilterWS', this.listType) ||
      [];

    if (catFilters && this.categoryFilters.length > 0) {
      catFilters.forEach((catFilter) => {
        const cat = this.categoryFilters.find(
          (cat) => cat.catfilterId === catFilter.catfilterId,
        );
        if (cat && cat.isActive !== catFilter.isActive) {
          cat.isActive = catFilter.isActive;
        }
      });
    }
  }

  @observes('listType')
  listTypeObserver() {
    this.getFiltersSortingFromSession();
    setTimeout(this.setScrollPosition.bind(this), 0);
  }

  sortDefinitionCategories = ['name:asc'];
  @sort('categories', 'sortDefinitionCategories') sortedCategories;

  @observes('isFav', 'deletedWorkspaces')
  async loadCategories() {
    const options = { adapterOptions: { subscribe: this } };
    this.store.findAll('category', options).then((categories) => {
      this.categories = categories;
    });
  }

  reloadCategories = async () => {
    await this.loadCategories();
  };

  @computed(
    'account.personalSpaceId',
    'allSpaces.length',
    'deletedWorkspaces',
    'isFav',
    'model.length',
  )
  get spaceModel() {
    if (this.isFav) {
      return this.model.filter(item => item.id !== this.account.personalSpaceId);
    }
    return this.allSpaces.filter(item => item.id !== this.account.personalSpaceId);
  }

  @computed(
    'account.personalSpaceId',
    'allSpaces.length',
    'deletedWorkspaces',
    'model.length',
    'nameFilter',
    'spaces.length',
  )
  get noWorkspaces() {
    let spaces = this.allSpaces.filter(space =>
      space.kind ===
      libcryptobox.SpaceKind.Workspace,
    );
    spaces = spaces.filter(space => space.isDeleted === this.deletedWorkspaces);
    return (
      spaces.filter(space => space.id !== this.account.personalSpaceId).length === 0 &&
      this.nameFilter === ''
    );
  }

  @computed('nameFilter', 'spaceModel', 'spaces.length')
  get noFoundSpacesInSearch() {
    return this.nameFilter && !this.spaces.length;
  }

  @computed('allSpaces.length')
  get workspacesCount() {
    return this.allSpaces.filter(space => space.kind === libcryptobox.SpaceKind.Workspace)
      .length;
  }

  @computed('categories', 'categories.length', 'account.favoritesCategoryId')
  get userCategories() {
    if (!this.categories) return null;
    const favCatId = this.account.favoritesCategoryId;
    return this.categories.filter(category => category.id !== favCatId);
  }

  @computed('account.personalSpaceId', 'spaceModel.@each.tags')
  get tagFilterChoices() {
    let tagsFilter = [];
    const spaceModel = this.spaceModel;
    if (!spaceModel || this.spaceModel.length === 0) return null;
    const spaces = spaceModel.filter(category => category.id !== this.account.personalSpaceId);
    spaces.forEach((space) => {
      let spaceTags = space.tags;
      if (!spaceTags) return null;
      spaceTags.forEach((spaceTag) => {
        if (tagsFilter.indexOf(spaceTag) === -1) {
          tagsFilter.push(spaceTag);
          return false;
        }
      });
    });
    return tagsFilter;
  }

  @computed('userCategories.length', 'categoryFilters.@each.isActive')
  get allCatFiltersActive() {
    const activeCategory = this.categoryFilters.filter(category => category.isActive);
    return this.userCategories.length === activeCategory.length;
  }

  @computed(
    'account.personalSpaceId',
    'categoryFilters.@each.isActive',
    'deletedWorkspaces',
    'nameFilter',
    'roleFilter.value',
    'spaceModel.@each.{categories,kind,status,userRole}',
    'tagsFilterSelection.length',
  )
  get spaces() {
    const status = this.deletedWorkspaces
      ? libcryptobox.SpaceStatus.Deleted
      : libcryptobox.SpaceStatus.Active;

    if (!this.spaceModel) return null;
    let spaces = this.spaceModel.filter(space => space.id !== this.account.personalSpaceId);
    spaces = spaces
      .filter(space => space.status === status)
      .filter(space => space.kind === libcryptobox.SpaceKind.Workspace);

    if (this.nameFilter) {
      let keyword = this.nameFilter.toLowerCase().trim();
      spaces = spaces.filter((item) => {
        const it = diacritic.clean(item.name.toLowerCase());
        return it.includes(diacritic.clean(keyword));
      });
    }
    //
    if (this.roleFilter.value !== 'none') {
      spaces = spaces.filterBy(this.roleFilter.value, true);
    }

    const activeCategory = this.categoryFilters
      .filter(category => category.isActive).map(category => category.category);
    if (activeCategory.length > 0) {
      let catFilteredSpaces = [];
      spaces.forEach((space) => {
        space.categories.forEach((spaceCategory) => {
          if (
            activeCategory.indexOf(spaceCategory) !== -1 &&
            catFilteredSpaces.indexOf(space) === -1
          ) {
            catFilteredSpaces.push(space);
            return false;
          }
        });
      });
      spaces = catFilteredSpaces;
    }

    if (this.tagsFilterSelection.length > 0) {
      let tagsFilteredSpaces = [];
      spaces.forEach((space) => {
        space.tags.forEach((spaceTag) => {
          if (
            this.tagsFilterSelection.indexOf(spaceTag) !== -1 &&
            tagsFilteredSpaces.indexOf(space) === -1
          ) {
            tagsFilteredSpaces.push(space);
            return false;
          }
        });
      });
      spaces = tagsFilteredSpaces;
    }

    return spaces;
  }

  @action
  emptySearch() {
    this.nameFilter = '';
  }

  @action
  changeDisplay(isList) {
    this.listDisplay = isList;
    this.manageStorage.setSessionStorageItem(
      'listDisplay',
      isList,
      this.listType,
    );
  }

  @action
  updateSorting(sorting) {
    this.sorting = sorting;
    this.manageStorage.setSessionStorageItem(
      'sortingWS',
      sorting,
      this.listType,
    );
  }

  @action
  updateRole(role) {
    this.roleFilter = role;
    this.manageStorage.setSessionStorageItem('roleWS', role, this.listType);
  }

  @action
  updateTagsFilter(tags) {
    this.tagsFilterSelection = tags;
    this.manageStorage.setSessionStorageItem(
      'tagsFilterWS',
      tags,
      this.listType,
    );
  }

  @action
  async indexReady() {
    const options = { reload: true, adapterOptions: { subscribe: this } };
    await this.store.findAll('space', options);
    this.allSpaces = this.store.peekAll('space', options);

    this.getFiltersSortingFromSession();

    if (this.firstRender) {
      this.scrollSetup();
      this.firstRender = false;
    }

    this.setScrollPosition();
  }

  _updateNameFilter() {
    this.manageStorage.setSessionStorageItem(
      'nameFilterWS',
      this.nameFilter,
      this.listType,
    );
  }

  @observes('nameFilter')
  updateNameFilter() {
    debounce(this, this._updateNameFilter, null, 500);
  }

  @action
  updateCatFilters() {
    const catFilters = this.categoryFilters.map((cat) => {
      return { catfilterId: cat.catfilterId, isActive: cat.isActive };
    });

    this.manageStorage.setSessionStorageItem(
      'catFilterWS',
      catFilters,
      this.listType,
    );
  }

  get currentScrollPosition() {
    const myGroupsEl = document.querySelector('#my_groups');
    const mainContentEl = document.getElementById('main-content');

    if (myGroupsEl && mainContentEl) {
      const topPosition = myGroupsEl.getBoundingClientRect().top;
      const topOffset = mainContentEl.offsetTop;
      return Math.abs(topPosition - topOffset);
    }
    return null;
  }

  updateScrollPosition() {
    if (this.currentScrollPosition == null) return;
    if (this.scrollPosition !== this.currentScrollPosition) {
      this.scrollPosition = this.currentScrollPosition;

      this.manageStorage.setSessionStorageItem(
        'scrollPositionWS',
        this.scrollPosition,
        this.listType,
      );
    }
  }

  @action
  toggleFav(space, event) {
    event.preventDefault();
    event.stopPropagation();
    const favCatId = this.account.favoritesCategoryId;
    let favCat = this.store.peekRecord({ type: 'category', id: favCatId });
    favCat.toggleSpace(space);
  }

  @action
  toggleAllCatFilters(event) {
    event.preventDefault();
    const value = !this.allCatFiltersActive;
    this.categoryFilters.forEach((category) => (category.isActive = value));
  }
}
