import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class DepositBoxesRoute extends Route {
  @service intl;
  @service authService;
  @service modalManager;
  @service('deposit-box') depositBoxService;
  @service router;

  beforeModel() {
    this.authService.loginIfNotAuthenticated();
  }

  @action
  willTransition() {
    [
      'depositBox-editor-modal',
      'depositBox-delete-modal',
      'depositBox-show-link',
    ].forEach((modal) => this.modalManager.close(modal));
  }

  @action
  didTransition() {
    if (!this.depositBoxService.canAccessDepositBox) {
      this.router.transitionTo('index');
    }
  }
}
