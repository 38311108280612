import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class Tutorial extends Route {

  @service authService;
  @service intl;

  beforeModel() {
    this.authService.loginIfNotAuthenticated();
  }
  afterModel() {
    document.title = 'Cryptobox - ' + this.intl.t('navigation.tutorial');
  }
}
