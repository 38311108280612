import { inject as service } from '@ember/service';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { task, timeout } from 'ember-concurrency';

export default class GetLinkModalComponent extends Component {

  @tracked file = this.args.nodes.content;
  @tracked fileUrl = this.intl.t('documents.actions.getLink.loading');
  @tracked showToast = false;
  @tracked toastStatus;
  @tracked error = false;

  @service intl;
  @service modalManager;


  @(task(function* () {
    yield timeout(1000);
    try {
      this.fileUrl = yield tBoxClient.file.createLink(this.file.at(0).spaceId, this.file.at(0).path);
    } catch (e) {
      this.fileUrl = null;
      this.error = true;
    }
  })) initialize;

  get fileName() {
    if ( Array.isArray(this.file) && this.file.length > 0) {
      return this.file.at(0)?.name;
    }
    return '';
  }

  get title() {
    return this.intl.t('documents.actions.getLink.title', { name: this.fileName });
  }

  @action
  clearInputs() {
    this.args.isOpened(false);
  }

  @action
  async selectAndCopyText() {
    if (this.error) {
      return;
    }
    document.getElementById('fileUrl').select();
    await this.onConfirm();
  }

  @action
  notifyOpened() {
    this.args.isOpened(true);
    this.initialize.perform();
  }

  @action
  async onConfirm() {
    if (this.error) {
      return;
    }
    try {
      await navigator.clipboard.writeText(this.fileUrl);
      this.showToast = true;
      this.toastStatus = 'success';
    } catch (e) {
      this.toastStatus = 'error';
    }
    setTimeout(() => {
      this.showToast = false;
      this.modalManager.close('get-link-modal');
    }, 3000);

  }

  @action
  onClose() {
    this.fileUrl = this.intl.t('documents.actions.getLink.loading');
    this.modalManager.close('get-link-modal');
  }


}
