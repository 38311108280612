import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class FileHistoryRoutes extends Route {
  @service authService;

  beforeModel() {
    this.authService.loginIfNotAuthenticated();
  }

  model() {
    return null;
  }
}
