import { helper } from "@ember/component/helper";
import ENV from "client/config/environment";

const versionRegExp = /\d+[.]\d+[.]\d+/;

function appVersion(/*args*/) {
  return ENV.APP.version.match(versionRegExp);
}

export default helper(appVersion);
