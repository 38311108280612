// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr, belongsTo } from '@ember-data/model';

// A path breadcrumb.
// See BreadcrumbCollection.
export default class extends Model {
  // Space identifier.
  // @type string
  @attr spaceId;
  
  // Revision number.
  // @type number
  @attr revision;
  
  // Original path. It may not exist.
  // @type string
  @attr original;
  
  // Fixed path. It is always valid.
  // @type string
  @attr path;
  
  @belongsTo('space', { async: true, inverse: null }) space;
}

