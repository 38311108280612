import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class Loading extends Component {
  @service modalManager;

  @tracked loading = false;
  @tracked modalName = 'depositBox-editor-loading-modal';

  get isOpen() {
    return this.modalManager.isModalOpen(this.modalName);
  }
  @action
  closeModalAfterDelay() {
    if (this.isOpen) {
      setTimeout(() => {
        this.close();
      }, 3000);
    }
  }

  @action
  close() {
    this.modalManager.close(this.modalName);
  }
}
