/* global ipc */
import ENV from 'client/config/environment';
import { openUrl } from '@tauri-apps/plugin-opener';
import { save } from '@tauri-apps/plugin-dialog';
import { invoke } from '@tauri-apps/api/core';

export async function openExternal(url) {
  if (ENV.APP.isElectronApp) {
    await ipc.electron.shell.openExternal(url);
  } else if (ENV.APP.isTauriApp) {
    await openUrl(url);
  }
}

export async function showSaveDialog(filename) {
  if (ENV.APP.isElectronApp) {
    return ipc.electron.dialog.showSaveDialogSync({ defaultPath: filename });
  } else if (ENV.APP.isTauriApp) {
    return await save({ defaultPath: filename });
  }
}

export async function getDefaultServerUrl() {
  if (ENV.APP.isElectronApp) {
    return await ipc.getDefaultUrlServerFromRegistry();
  } else if (ENV.APP.isTauriApp) {
    return await invoke('get_default_server_url');
  }
}

export async function getCfsbUrls() {
  if (ENV.APP.isElectronApp) {
    return await ipc.getCFSBFromRegisters();
  } else if (ENV.APP.isTauriApp) {
    return await invoke('get_cfsb_urls');
  }
}
