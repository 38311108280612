import DS from 'ember-data';
import { tBoxClient } from 'client/initializers/init-toolbox';

export default DS.Adapter.extend({
  query(_store, _type, query){
    return tBoxClient.file.getAccessLog(query.spaceId, query.path, '', '', '-date', 100)
    .then(logs => {
      return logs.map((log) => {
        return {
          id: log.spaceId + log.fileId + log.date + log.sourceIp,
          date: log.date,
          userEmail: log.userEmail,
          file: log.fileId
        };
      });
    });
  },
});
