import Route from '@ember/routing/route';

export default class Claimant extends Route {
  model(params) {
    const recoveryId = this.paramsFor('account-recovery').id;
    const rdvUserId = params.rdv_user_id;
    return {
      recoveryId,
      rdvUserId,
    };
  }

  setupController(controller, model) {
    controller.set('model', model);
    controller.set('isDepositaryConnected', false);
    controller.set('sas', '');
    controller.set('connectionError', false);
  }

  afterModel() {
    document.title = 'Cryptobox - Password Recovery';
  }
}
