import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
/* global libcryptobox */

export default class ChangePasswordComponent extends Component {
  @tracked initial = null;
  @tracked registry = [];

  @tracked currentPassword;
  @tracked customMessage;
  @tracked currentPasswordIsNotValid = false;
  @tracked passwordScore;
  @tracked passwordIsNotValid;

  @service modalManager;
  @service account;
  @service intl;
  @service session;

  @tracked modalName = 'changePassword';

  _handleListener(component) {
    return () => {
      clearInterval(component.initial);
      component.initial = window.setInterval(
        component._resetPasswordField(component),
        60000,
      );
    };
  }

  _resetPasswordField(component) {
    return () => {
      component.currentPassword = '';
    };
  }

  @action
  openModal() {
    this.currentPassword = null;
    this.newPassword = null;
    this.confirmNewPassword = null;
    this.customMessage = null;
    this.currentPasswordIsNotValid = false;
    this.passwordIsNotValid = false;
    if (!this.modalManager.isModalOpen(this.modalName)) {
      this.modalManager.open(this.modalName);
    }
  }

  initRegistry() {
    if (this.registry?.length) {
      this.registry[0].value = null;
      this.registry[0].confirmPassword = null;
      this.registry[0].passwordScore = null;
      this.registry[0].resetpasswordInputState();
      this.registry[0].resetConfirmPasswordState();
    }
  }

  @action
  closeModal() {
    this.modalManager.close(this.modalName);
    this.initRegistry();
  }

  @action
  changePassword() {
    if (this.isUpdating) {
      return;
    }
    this.isUpdating = true;
    let newPwd = this.newPassword;

    this.session.changingPwd = true;
    tBoxClient.account
      .changePassword(newPwd, this.currentPassword)
      .then(() => {
        this.modalManager.close(this.modalName);
        this.session.setSession();
        this.isUpdating = false;
        this.session.changingPwd = false;
        this.initRegistry();
        this.modalManager.open('warnDevice');
      })
      .catch((error) => {
        if (error.code === libcryptobox.ErrorCode.InvalidCredentials) {
          this.currentPasswordIsNotValid = true;
          this.customMessage = this.intl.t('changePassword.wrongPassword');
        }
        this.isUpdating = false;
        this.session.changingPwd = false;
      });
  }

  @action
  setResetForm() {
    this.initial = window.setInterval(this._resetPasswordField(this), 60000);

    document.addEventListener('mousemove', this._handleListener(this));
    document.addEventListener('keydown', this._handleListener(this));
  }

  @action
  unregisterReset() {
    clearInterval(this.initial);
    document.removeEventListener('mousemove', this._handleListener(this));
    document.removeEventListener('keydown', this._handleListener(this));
  }

  @action
  updatePassword(value) {
    this.newPassword = value;
  }

  @action
  setPropertyValue(property, value) {
    this[property] = value;
  }

}
