import Route from '@ember/routing/route';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { inject as service } from '@ember/service';

export default class DepositaryChoice extends Route {
  @service connection;

  async model() {
    const server = this.connection.serverAddress;
    let recoveryId = this.paramsFor('account-recovery').id;
    const recovery = await tBoxClient.recovery.getOptions(server, recoveryId);
    return {
      recoveryId: recoveryId,
      defaultTrustee: recovery.defaultTrustee,
    };
  }

  setupController(controller, model) {
    controller.set('model', model);
    controller.set('stepDepositary', 1);
    controller.set('isDefault', model.defaultTrustee !== '');
  }

  afterModel() {
    document.title = 'Cryptobox - Password Recovery';
  }
}
