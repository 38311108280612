import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { DateTime } from 'luxon';
import { groupDataByYearAndByDates } from '../helpers/groupDataByYearAndByDates';
import { action } from '@ember/object';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { observes } from '@ember-decorators/object';

/* global libcryptobox */
export default class FileHistoryService extends Service {
  @service intl;
  @service('transfers') transfersManager;
  @service account;

  @tracked model = null;
  @tracked _modelName = '';
  @tracked trackingId;

  @tracked year = new Date().getFullYear();
  @tracked firstDateRevision = new Date(); // not be fore in heatmap data

  @tracked selectedDate;
  @tracked oldSelectedDate;

  @tracked selectedDateNumberActivities = 0;
  @tracked selectedFileHistoryData = [];
  @tracked selectedFileHistoryInSideNav;

  @tracked automaticScroll = false;

  @tracked filter = {
    value: 'none',
    label: this.intl.t('fileHistory.filterBy.all'),
  };

  @tracked heatmapData = [];
  @tracked isLoadingHeatmapData = false;

  @tracked fileHistoryData = [];

  @tracked isLoadingFileHistoryData = false;
  @tracked lastDateRevisionFetched = null;

  // DownloadList
  @tracked fileHistoryDownloadList = [];
  @tracked isLoadingFileHistoryDownloadList = false;

  @action
  iconName(name) {
    switch ( name ) {
      case libcryptobox.FileHistoryAction.Add:
        return 'add';
      case libcryptobox.FileHistoryAction.Rename:
        return 'rename';
      case libcryptobox.FileHistoryAction.Modify:
        return 'edit';
      case libcryptobox.FileHistoryAction.Move:
        return 'copy_4';
      case libcryptobox.FileHistoryAction.Delete:
        return 'delete_1';
      default:
        return 'add';
    }
  }

  get modelName() {
    if (this.model?.name) {
      return this.model.get('name');
    } else {
      return this._modelName;
    }
  }

  get emptyCurrentYearFileHistory() {
    if (
      this.isLoadingFileHistoryData ||
      this.fileHistoryData.length === 0 ||
      this.filter.value !== 'none'
    ) {
      return false;
    }

    return (
      new Date(this.fileHistoryData[0].date).getFullYear() !==
      new Date().getFullYear()
    );
  }

  @action
  authorFullName(item) {
    return !item.name && !item.surname
      ? item.email
      : `${ item.name || '' } ${ item.surname || '' }`;
  }

  @action
  formatFileName(name) {
    return name.charAt(0) === '/' ? name.substring(1) : name;
  }

  @action
  fileActionText(item) {
    const authorName = this.authorFullName(item.author);

    switch ( item.action ) {
      case 'add':
        return this.intl.t(
          'fileHistory.actionText.add.text',
          {
            authorName,
            name: this.formatFileName(item.name),
          },
          { htmlSafe: true },
        );
      case 'rename':
        return this.intl.t(
          'fileHistory.actionText.rename.text',
          {
            authorName,
            name: this.formatFileName(item.name),
            oldName: this.formatFileName(item.oldName),
          },
          { htmlSafe: true },
        );
      case 'modify':
        return this.intl.t(
          'fileHistory.actionText.edit.text',
          {
            authorName,
            name: this.formatFileName(item.name),
          },
          { htmlSafe: true },
        );
      case 'move':
        return this.intl.t(
          'fileHistory.actionText.move.text',
          {
            authorName,
            path: this.formatFileName(item.name),
          },
          { htmlSafe: true },
        );
      case 'delete':
        return this.intl.t(
          'fileHistory.actionText.delete.text',
          {
            authorName,
            name: this.formatFileName(item.name),
          },
          { htmlSafe: true },
        );
    }
  }

  get firstFileHistoryItem() {
    return this.fileHistoryData.at(0);
  }

  get fileHistoryDataTransformed() {
    return groupDataByYearAndByDates(this.fileHistoryData);
  }

  get startHeatMapDate() {
    return new Date(new Date().setFullYear(this.year, 0, 1));
  }

  get areRevisionDatesIdentical() {
    if (this.firstDateRevision && this.lastDateRevisionFetched) {
      const firstDate = DateTime.fromJSDate(this.firstDateRevision);
      const lastDate = DateTime.fromJSDate(this.lastDateRevisionFetched);
      return firstDate.equals(lastDate);
    }
    return !this.firstDateRevision && !this.lastDateRevisionFetched;
  }

  get isExportActivitiesDisabled() {
    return !this.model || !this.model.trackingId || !this.model.version;
  }

  exportActivities() {
    const dateTime = DateTime.now().toFormat('yyyy-LL-dd');
    const name = this.model.path.substring(
      this.model.path.lastIndexOf('/') + 1,
      this.model.path.length,
    );
    this.transfersManager.downloadFileHistory(
      this.model.spaceId,
      this.model.trackingId,
      name + '_export-' + dateTime + '.csv',
    );
  }

  @action
  async loadMoreFileHistoryData() {
    if (
      this.isLoadingFileHistoryData ||
      this.fileHistoryData.length < 100 ||
      this.fileHistoryData.at(-1).fileVersionNumber === 1
    ) {
      return;
    }
    await this.fetchFileHistory(
      this.fileHistoryData.at(-1)?.date,
      100,
      libcryptobox.FileHistoryDirection.Before,
    );
  }

  @action
  async getNumberDownload({ fileVersion }) {
    return await tBoxClient.file.getNumberDownloads(
      this.model.trackingId,
      fileVersion,
    );
  }

  @action
  async downloadFileHistoryVersion(file) {
    if (!file) {
      return;
    }
    void this.transfersManager.downloadFileByRevisionAndPath({
      spaceId: this.model.spaceId,
      trackingId: this.model.trackingId,
      revision: file.fileRevisionNumber || 0,
    });
  }

  @observes('selectedDate')
  async refreshFileHistoryData() {
    if (
      this.account.isLoggedIn === false ||
      this.selectedDate === this.oldSelectedDate
    ) {
      return;
    }
    const _data = await this.fetchFileHistory(
      this.selectedDate,
      100,
      libcryptobox.FileHistoryDirection.After,
    );
    this.selectedFileHistoryData = _data.filter(
      (item) =>
        item.year === new Date(this.selectedDate).getFullYear().toString(),
    );
  }

  @observes('filter')
  async refreshFileHistoryDataIfFilterChange() {
    if (this.account.isLoggedIn === false) {
      return;
    }
    await this.fetchFileHistory();
  }

  @action
  async fetchHeatMapData() {
    const spaceId = this.model.spaceId;
    const trackingId = this.model.trackingId;

    if (!spaceId || !this.trackingId) {
      return (this.heatmapData = []);
    }
    try {
      const actionType = this.filter.value;
      this.isLoadingHeatmapData = true;
      const data = await tBoxClient.file.getHistoryHeatmap(
        spaceId,
        trackingId,
        this.year,
        actionType !== 'none' ? { action: actionType } : null,
      );

      this.isLoadingHeatmapData = false;
      this.firstDateRevision = data.notBefore ? new Date(data.notBefore) : null;
      const dataFiltered = Object.entries(data.values).map(([date, value]) => ({
        date: new Date(date),
        value: value,
      }));
      if (actionType !== 'none') {
        this.heatmapData = [];
      }

      return (this.heatmapData = [...dataFiltered]);
    } catch (e) {
      console.error('Error fetching heatmap data:', e);
      throw e;
    }
  }

  @action
  async fetchFileHistory(
    before = null,
    limit = 100,
    direction = libcryptobox.FileHistoryDirection.After,
  ) {
    const { spaceId, trackingId } = this.model || {};

    if (!spaceId || !this.trackingId) {
      return (this.fileHistoryData = []);
    }

    try {
      const params = {
        ...(before && { date: before }),
        ...(this.filter.value !== 'none' && { action: this.filter.value }),
        ...(before && { direction }),
      };

      this.isLoadingFileHistoryData = true;
      const { entries: data } = await tBoxClient.file.getHistory(
        spaceId,
        trackingId,
        limit,
        params,
      );

      if (data.length === 0) {
        this.lastDateRevisionFetched = null;
        return (this.fileHistoryData = []);
      }

      if (!before) {
        this.fileHistoryData = data;
      } else {
        const combinedData = [...this.fileHistoryData, ...data];
        const seenDates = new Set();
        this.fileHistoryData = combinedData.filter((item) => {
          if (seenDates.has(item.date)) {
            return false;
          } else {
            seenDates.add(item.date);
            return true;
          }
        });
      }
      this.lastDateRevisionFetched = new Date(this.fileHistoryData.at(-1).date);

      return this.fileHistoryDataTransformed;
    } catch (e) {
      console.error('Error fetching file history data:', e);
      throw e;
    } finally {
      this.isLoadingFileHistoryData = false;
    }
  }

  @action
  resetFileHistoryData() {
    this.fileHistoryData = [];
    this.lastDateRevisionFetched = null;
    this.selectedDate = null;
    this.model = null;
    this.oldSelectedDate = null;
    this.selectedDateNumberActivities = 0;
    this.selectedFileHistoryData = [];
    this.selectedFileHistoryInSideNav = null;
    this.heatmapData = [];
    this.isLoadingHeatmapData = false;
    this.isLoadingFileHistoryData = false;
    this.automaticScroll = false;
  }

  @action
  async getDownloadListFileHistory({ version, type: downloadType }) {
    if (this.fileHistoryDownloadList.length > 0) {
      this.fileHistoryDownloadList = [];
    }

    if (!this.validateInputs(version, downloadType)) {
      return;
    }

    try {
      this.isLoadingFileHistoryDownloadList = true;
      const data = await tBoxClient.file.getDownloadList(
        this.model.trackingId,
        version,
        downloadType,
        1000,
        '',
      );
      this.fileHistoryDownloadList = data.entries;
    } catch (e) {
      console.error('Error fetching download list:', e);
    } finally {
      this.isLoadingFileHistoryDownloadList = false;
    }
  }

  validateInputs(version, downloadType) {
    if (!version || !downloadType) {
      console.error('Invalid file version or download type');
      return false;
    }
    if (
      downloadType !== libcryptobox.DownloadListType.FromSharing &&
      downloadType !== libcryptobox.DownloadListType.ByUsers
    ) {
      console.error('Invalid download type');
      return false;
    }
    return true;
  }
}
