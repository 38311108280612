import Route from '@ember/routing/route';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { inject as service } from '@ember/service';
export default class Members extends Route {
  @service store
  @service intl
  @service authService;

  beforeModel() {
    this.authService.loginIfNotAuthenticated();
  }
  async model() {
    const space = this.modelFor('my-groups.group');
    this.store.findAll('preadded-space-member', {
      reload: true,
      adapterOptions: { subscribe: this },
    });
    return await this.store.findRecord('space', space.id, {
      include: 'members',
      adapterOptions: { subscribe: this },
    });
  }

  setupController(controller, model) {
    controller.set('model', model);
    controller.set('accountTypes', this.accountTypes);
    controller.set('displayOwners', true);
    controller.set('displayMembers', true);
    controller.set('displayReaders', true);
    controller.set('showUsers', true);
  }

  async afterModel() {
    let spaceName = this.modelFor('my-groups.group').get('name');
    const model = await this.model();
    const members = await model.get('spaceMembers');
    this.set(
      'accountTypes',
      await tBoxClient.invite.getUsersType(members.map( member => member.email)),
    );
    document.title = `Cryptobox - ${ spaceName } - ${ this.intl.t(
      'group.navigation.members',
    ) }`;
  }

  deactivate() {
    this.store.adapterFor('space').unsubscribe(this);
  }


}
