import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { A } from '@ember/array';
import { task, timeout } from 'ember-concurrency';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { tracked } from '@glimmer/tracking';

export default class AddMember extends Component {
  @tracked modalName = 'AddMember';
  @tracked usersToInvite = A();
  @tracked addedMembers = A();
  @tracked searchEmail = null;
  @tracked isInProcess = false;
  @tracked resetErrors = false;
  @tracked searchUser = false;

  @service account;
  @service modalManager;
  @service store;
  @service notify;
  @service intl;

  get space() {
    return this.args.space;
  }

  @computed('usersToInvite.[]', 'addedMembers.[]')
  get hasUsers() {
    return this.usersToInvite.length > 0 || this.addedMembers.length > 0;
  }

  @computed('usersToInvite.[]', 'addedMembers.[]')
  get validateWording() {
    if (this.usersToInvite.length > 0 && this.addedMembers.length > 0)
      return this.intl.t('button.addSend');
    if (this.usersToInvite.length > 0) return this.intl.t('button.send');

    return this.intl.t('button.add');
  }

  @computed(
    'account.status.canInvite',
    'addedMembers.length',
    'isInProcess',
    'notEnoughInvites',
    'searchEmail',
    'searchUser',
    'usersToInvite.length',
  )
  get buttonIsDisabled() {
    return (
      (this.addedMembers.length === 0 &&
        (this.usersToInvite.length === 0 || !this.account.status.canInvite)) ||
      this.searchEmail ||
      this.isInProcess ||
      this.notEnoughInvites
    );
  }

  @computed('usersToInvite.[]', 'remainingInvites')
  get notEnoughInvites() {
    const toInvite = this.usersToInvite;
    const remainingInvites = this.remainingInvites;
    return toInvite.length > remainingInvites;
  }

  @task
  *_addMember() {
    yield timeout(1000);

    if (this.addedMembers.length) {
      this.addedMembers.forEach((member, index) => {
        let newSpaceMember = this.store.createRecord('spaceMember', {
          email: member.email,
          firstName: member.firstName,
          lastName: member.lastName,
          certificate: member.certificate,
          role: member.role,
          space: this.space,
          maximumLevel: member.maximumLevel,
        });

        newSpaceMember
          .save({ adapterOptions: { fingerprint: member.fingerprint } })
          .then(() => {
            if (index + 1 === this.addedMembers.length) {
              this.notify.success(
                this.intl.t('toasts.addUser.message', {
                  count: this.addedMembers.length,
                }),
                {
                  title: this.intl.t('toasts.addUser.title', {
                    count: this.addedMembers.length,
                  }),
                  icon: 'group',
                },
              );
            }
          });
      });
    }

    if (this.account.status.canInvite && this.usersToInvite.length) {
      const inviteLength = this.usersToInvite.length;
      this.usersToInvite.forEach((invitedUser, index) =>
        tBoxClient.preaddedSpaceMember
          .create(this.space.get('id'), invitedUser.email, invitedUser.role)
          .then(() => {
            if (index + 1 === inviteLength) {
              this.notify.success(
                this.intl.t('toasts.inviteUser.message', {
                  count: inviteLength,
                  appname: this.intl.t('appname'),
                }),
                {
                  title: this.intl.t('toasts.inviteUser.title', {
                    count: inviteLength,
                  }),
                  icon: 'group',
                },
              );
            }
          }),
      );
    }
    this.isInProcess = false;
    this.modalManager.close(this.modalName);
  }

  @action
  onClick() {
    this.resetErrors = true;
    this.modalManager.open(this.modalName);
    setTimeout(() => (this.resetErrors = false), 1000);
  }

  @action
  addMember() {
    if (this.buttonIsDisabled) {
      return false;
    }
    this.isInProcess = true;
    this._addMember.perform();
  }

  @action
  clearAddMemberInput() {
    this.addedMembers.clear();
    this.usersToInvite.clear();
    this.searchEmail = null;
    this.searchUser = null;
    this.isInProcess = false;
  }

  @action
  closeModal() {
    this.modalManager.close(this.modalName);
  }
}
