import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class DepositBoxesRoute extends Route {
  @service intl;
  @service router;
  @service('deposit-box') depositBoxService;

  beforeModel() {
    if (!this.depositBoxService.canAccessDepositBox) {
      this.router.transitionTo('my-groups.index');
    }
  }

  afterModel() {
    document.title = 'Cryptobox - ' + this.intl.t('depositBox.title');
  }

  @action
  refreshDepositBoxList() {
    this.refresh();
  }

}
