import BaseModel from 'client/toolbox/generated/models/file';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { alias } from '@ember/object/computed';
// import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { FILE_SUBSCRIPTION_TYPE } from '../utils/constants';
import getFileSubscriptionCollectionName from '../utils/tBoxClient/getFileSubscriptionCollectionName';
import { hasMany } from '@ember-data/model';

export default class File extends BaseModel {
  @service intl;

  @alias('isDirectory') isFolder;
  @hasMany('file-access-log', { async: true, inverse: null }) accessLogs;


  get name() {
    return this.path.split('/').pop();
  }


  parent() {
    const parts = this.path.split('/');
    parts.pop();
    const spaceId = this.belongsTo('space').id();
    const revision = this.revision;
    const path = parts.join('/');
    return this.store.queryRecord('file', { spaceId, revision, path });
  }

  @alias('author.email') authorEmail;


  get authorFullname() {
    if (this.author) {
      const name = this.author.givenName.trim();
      const surname = this.author.surname.trim();
      const email = this.author.email.trim();
      return `${name} ${surname} (${email})`;
    }
    return '';
  }


  get type() {
    if (this.isDirectory) {
      return '';
    }
    let name = this.name;
    let fileExt = name.split('.').pop().toLowerCase() || '';

    if (fileExt === '' || name.indexOf('.') === -1) {
      return '';
    }

    let fileType = this.intl.t('fileType.' + fileExt);
    if (fileType.includes('Missing translation')) {
      if (this.intl.locale[0] === 'fr-fr') {
        return `fichier ${fileExt}`;
      } else {
        return `${fileExt} file`;
      }
    }
    return fileType;
  }

  rename(newName) {
    const spaceId = this.belongsTo('space').id();
    const path = this.path;
    const newPath = path.split('/').slice(0, -1).concat([newName]).join('/');
    return tBoxClient.file
      .rename(spaceId, path, newName)
      .then(() => {
        return this.store.queryRecord('file', {
          spaceId,
          revision: -1,
          path: newPath,
        });
      })
      .then((newRecord) => {
        return {
          oldPath: path,
          oldRecord: this,
          newPath: newPath,
          newRecord: newRecord,
        };
      });
  }

  /*
   *  Subscriptions to Modifications && Downloads
   * */

  hasSubscribed(subscriptions) {
    if (!subscriptions) return;
    const path = this.path;
    return subscriptions.includes(path);
  }

  getSubscribedParent(subscriptions) {
    if (!subscriptions) return;
    const path = this.path;
    const candidates = subscriptions.filter(
      (sub) => sub === '/' || path.startsWith(sub + '/')
    );
    candidates.sort();
    return candidates.shift();
  }

  hasSubscribedChild(subscriptions) {
    if (!subscriptions) return;
    if (this.isDirectory) {
      const path = this.path;
      return subscriptions.some((subscription) =>
        subscription.startsWith(path + '/')
      );
    }
    return false;
  }

  // CURRENT NODE

  get subscribed() {
    return this.hasSubscribed(this.space.get('subscriptions'));
  }


  get isSubscribedToDownloads() {
    return this.hasSubscribed(this.space.get("downloadsSubscriptions"));
  }

  // PARENT


  get subscribedParent() {
    return this.getSubscribedParent(this.space.get("subscriptions"));
  }


  get downloadsSubscribedParent() {
    return this.getSubscribedParent(this.space.get("downloadsSubscriptions"));
  }

  // CHILD


  get subscribedChild() {
    return this.hasSubscribedChild(this.space.get("subscriptions"));
  }


  get downloadsSubscribedChild() {
    return this.hasSubscribedChild(this.space.get("downloadsSubscriptions"));
  }

   getSubscriptionData(type) {
    if (type === FILE_SUBSCRIPTION_TYPE.DOWNLOADS) {
      return {
        subscribed: this.isSubscribedToDownloads,
        subscribedParent: this.downloadsSubscribedParent,
        subscribedChild: this.downloadsSubscribedChild,
      };
    }

    return {
      subscribed: this.subscribed,
      subscribedParent: this.subscribedParent,
      subscribedChild: this.subscribedChild,
    };
  }

  toggleSubscription(subscribe, type) {
    const collectionName = getFileSubscriptionCollectionName(type);
    let isSubscribed = this.subscribed;

    if (type === FILE_SUBSCRIPTION_TYPE.DOWNLOADS) {
      isSubscribed = this.isSubscribedToDownloads;
    }

    const spaceId = this.belongsTo('space').id();
    const path = this.path;

    let action = '';
    if (subscribe === false || isSubscribed) {
      action = 'remove';
    } else if (subscribe === true || !isSubscribed) {
      action = 'add';
    } else {
      return;
    }

    return tBoxClient[collectionName][action](spaceId, [path]);
  }

  unSubscribeParent(type) {
    const spaceId = this.belongsTo('space').id();
    const path =
      type === FILE_SUBSCRIPTION_TYPE.MODIFICATIONS
        ? this.subscribedParent
        : this.downloadsSubscribedParent;

    return this.store
      .queryRecord('file', { spaceId, revision: -1, path })
      .then((file) => {
        file.toggleSubscription(false, type);
      });
  }

  /*
   *
   * */

  updateFolderInfos() {
    console.warn('[model:file] stop calling updateFolderInfos().');
  }

  reloadContent() {
    console.warn('[model:file] stop calling reloadContent().');
  }

  unload() {
    console.warn('[model:file] stop calling unload().');
  }
}
