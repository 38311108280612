// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr, belongsTo } from '@ember-data/model';

// A user notification.
// See NotificationCollection.
export default class extends Model {
  // Localized title.
  // @type string
  @attr title;
  
  // Localized text.
  // @type string
  @attr text;
  
  // Creation date.
  // @type string
  @attr date;
  
  // Target action.
  // @type string
  @attr action;
  
  // Target content type.
  // @type string
  @attr contentType;
  
  // State.
  // @type NotificationState
  @attr state;
  
  // Space identifier. May be empty.
  // @type string
  @attr spaceId;
  
  // File path. May be empty.
  // @type string
  @attr path;
  
  // Matches PreaddedSpaceMemberRecord's InviteID when NotificationRecord's Action is "invitee_registered". Empty before v4.27, use UserRecord's Email instead.
  // @type string
  @attr inviteId;
  
  // Deposit box ID. May be empty.
  // @type string
  @attr depositBoxId;
  
  // Deposit box name. May be empty.
  // @type string
  @attr depositBoxName;
  
  // True if the action and/or content-type are unknown.
  // @type boolean
  @attr isUnknown;
  
  @belongsTo('space', { async: true, inverse: null }) space;
  @belongsTo('user', { async: false, inverse: null }) user;
  @belongsTo('invite', { async: true, inverse: null }) invite;
}

