import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class AutoCompleteResultItem extends Component {
  getValue(object, attrName) {
    return object?.[attrName];
  }

  get name() {
    return this.getValue(this.args.result, this.args.resultName);
  }

  get value() {
    return this.getValue(this.args.result, this.args.resultValue);
  }

  get highlightedValue() {
    let value = '';

    if (this.args.highlightedResult) {
      value = this.getValue(this.args.highlightedResult, this.args.resultValue);
    }

    return value;
  }

  get isHighlighted() {
    return this.value === this.highlightedValue;
  }

  @action
  selectResult() {
    this.args.selectResult(this.args.result);
  }
}
