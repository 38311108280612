import { observer } from '@ember/object';
import { inject as service } from '@ember/service';
import fileTransfer from 'client/custom-objects/file-transfer';
import { tBoxClient } from 'client/initializers/init-toolbox';
import FileReader from 'client/toolbox/file-reader';
import { DateTime } from 'luxon';
/* global libcryptobox */

export default fileTransfer.extend({
  direction: 'upload',
  fileData: null,
  depositBoxFormId: null,
  //parentFolderId: '',
  parentFolderPath: '',

  store: service(),

  init() {
    this._super();
    this.initMeasure();
    if (this.state === 'active') {
      if (this.kind === 'deposit_box_form') {
        this.startDepositBoxUploadUnAuthenticated();
      } else {
        this.startUpload();
      }
    }
  },

  activate: observer('state', function () {
    if (this.state === 'active') {
      this.startUpload();
    }
  }),

  async startUpload() {
    const spaceId = this.spaceId;
    const parentFolderPath = this.parentFolderPath;
    const fileName = this.fileName;
    const file = this.fileData;

    const reader = new FileReader(tBoxClient, file, fileName);
    const upload = await tBoxClient.fileUpload.create(
      spaceId,
      parentFolderPath + '/' + fileName,
      reader,
    );
    this.watchProgress(upload);

    const cancelTransfer = () => {
      tBoxClient.fileUpload.cancel(upload.id);
    };
    const retryTransfer = () => {
      this.set('isCanceled', false);
      this.set('toasted', false);
      this.set('state', 'retry');

      tBoxClient.fileUpload.retry(upload.id);
      this.watchProgress(upload);
    };

    this.set('cancelTransfer', cancelTransfer);
    this.set('retryTransfer', retryTransfer);
  },

  lastKnownSpeed: 0,

  async startDepositBoxUploadUnAuthenticated() {
    const fileName = this.fileName;
    const file = this.fileData;

    const reader = new FileReader(tBoxClient, file, fileName);

    const upload = tBoxClient.depositBoxForm.upload(
      this.depositBoxFormId,
      reader,
    );
    let hasReceivedProgressUpdate = false;
    const onProgress = (_, loaded, total) => {
      hasReceivedProgressUpdate = true;
      this.set('loaded', loaded);
      // this.set("size", total);
    };
    const onError = (error) => {
      if (error.code === libcryptobox.ErrorCode.Canceled) {
        this.set('isCanceled', true);
      }
      this.set('toasted', true);
      this.set('error', error);
      this.set('state', 'failed');
    };
    const cancelTransfer = () => {
      tBoxClient.depositBoxForm.remove(this.depositBoxFormId, fileName);
    };
    const startTime = DateTime.now();
    const onSuccess = () => {
      const nowTime = DateTime.now();
      const timeMs = nowTime - startTime;
      this.lastKnownSpeed = this.size / timeMs;
      this.set('loaded', this.size);
      this.set('state', 'completed');
    };
    upload.onprogress = onProgress;
    this.set('cancelTransfer', cancelTransfer);
    this.set('startTime', DateTime.now());
    this.peekProgressMeasure();
    upload.then(onSuccess).catch(onError);
  },

  watchProgress(upload) {
    const onProgress = (timestamp, loaded, total) => {
      this.set('loaded', loaded);
      this.set('size', total);
    };
    const onSuccess = () => {
      this.set('loaded', this.size);
      this.set('state', 'completed');
    };
    const onError = (error) => {
      // FIXME(jbe) The toolbox returns a concurrency error after multiple
      // unsuccessful tries. It is helpless to try one more time.
      // if(error.code === libcryptobox.ErrorCode.Conflict){
      //   let observer = new DefaultObserver();
      //   tBoxClient.synchronizeSpace(spaceId, observer);
      //   // this.get('store').peekRecord('space', spaceId).reload().then(() => {
      //   observer.deferred.promise.then(() => {
      //     this.set('loaded', this.get('size'));
      //     this.set('state', 'completed');
      //   });
      //   return;
      // }
      if (error.code === libcryptobox.ErrorCode.Canceled) {
        this.set('isCanceled', true);
        this.set('toasted', true);
      }
      this.set('state', 'failed');
    };

    this.set('startTime', DateTime.now());
    this.peekProgressMeasure();
    const promise = tBoxClient.fileUpload.wait(upload.id);
    promise.onprogress = onProgress;
    promise.then(onSuccess).catch(onError);
  },
});
