// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr } from '@ember-data/model';

// User agent status.
export default class extends Model {
  // True while the user agent is uploading files to the server.
  // @type boolean
  @attr hasLocalChanges;
  
  // True if the user agent has failed to connect with the Cryptobox server. See UserAgent's CheckConnectivity().
  // @type boolean
  @attr isOffline;
}

