const NOTIFICATION_ACTION = {
  INVITE: 'invite',
  ROLE_CHANGE: 'role_change',
  UNINVITE: 'uninvite',
  CHOOSE_DEPOSITARY: 'choose_depositary',
  ADMIN_USER_VALIDATION: 'admin_user_validation',
  INVITE_REGISTERED: 'invitee_registered',
  DOWNLOAD: 'download',
  ADD: 'add',
  MODIFY: 'modify',
  RENAME: 'rename',
  DELETE: 'delete',
  DELETE_WS: 'workspace_owner_delete',
  DELETE_ADMIN_WS: 'workspace_admin_delete',
  DELETE_USER: 'user_admin_delete',
  SHARING_DOWNLOAD: 'sharing_download',
  DEPOSIT_BOX: 'deposit_box_populated',
  DEPOSIT_BOX_MAX_CODE_ATTEMPTS_REACHED:
    'deposit_box_max_code_attempts_reached',
  SHARE_MAX_CODE_ATTEMPTS_REACHED: 'share_max_code_attempts_reached',
  UNKNOWN: 'unknown',
};

const NOTIFICATION_ACTION_FILE_LIST = [
  NOTIFICATION_ACTION.ADD,
  NOTIFICATION_ACTION.RENAME,
  NOTIFICATION_ACTION.DELETE,
];

const CONTENT_TYPE = {
  FILE: 'file',
  DIRECTORY: 'directory',
};

const NOTIFICATION_ACTION_ICON_MAP = {
  [NOTIFICATION_ACTION.INVITE]: 'add-member',
  [NOTIFICATION_ACTION.ROLE_CHANGE]: 'change-member-right',
  [NOTIFICATION_ACTION.UNINVITE]: 'remove-member',
  [NOTIFICATION_ACTION.CHOOSE_DEPOSITARY]: 'depositary',
  [NOTIFICATION_ACTION.ADMIN_USER_VALIDATION]: 'admin-validation-pending',
  [NOTIFICATION_ACTION.INVITE_REGISTERED]: 'img_invite',
  [NOTIFICATION_ACTION.DOWNLOAD]: 'notification-ic-file',
  [NOTIFICATION_ACTION.ADD]: {
    [CONTENT_TYPE.FILE]: 'add-file',
    [CONTENT_TYPE.DIRECTORY]: 'add-folder',
  },
  [NOTIFICATION_ACTION.RENAME]: {
    [CONTENT_TYPE.FILE]: 'update-file',
    [CONTENT_TYPE.DIRECTORY]: 'rename-folder',
  },
  [NOTIFICATION_ACTION.DELETE]: {
    [CONTENT_TYPE.FILE]: 'delete-file',
    [CONTENT_TYPE.DIRECTORY]: 'delete-folder',
  },
  [NOTIFICATION_ACTION.MODIFY]: 'update-file',
  [NOTIFICATION_ACTION.DELETE_WS]: 'ws-deleted',
  [NOTIFICATION_ACTION.DELETE_ADMIN_WS]: 'ws-deleted',
  [NOTIFICATION_ACTION.DELETE_USER]: 'remove-member',
  [NOTIFICATION_ACTION.SHARING_DOWNLOAD]: 'downloaded_file',
  [NOTIFICATION_ACTION.DEPOSIT_BOX]: 'deposit-box',
  [NOTIFICATION_ACTION.UNKNOWN]: 'warning_round',
  [NOTIFICATION_ACTION.UNKNOWN]: 'warning_round',
  [NOTIFICATION_ACTION.DEPOSIT_BOX_MAX_CODE_ATTEMPTS_REACHED]:
    'deposit-box_blocked',
  [NOTIFICATION_ACTION.SHARE_MAX_CODE_ATTEMPTS_REACHED]:
    'share_blocked',
};

const DEFAULT_ICON = 'info';

export default function getIconFromNotificationAction(action, contentType) {
  if (NOTIFICATION_ACTION_FILE_LIST.includes(action)) {
    return NOTIFICATION_ACTION_ICON_MAP[action][contentType];
  }
  return NOTIFICATION_ACTION_ICON_MAP[action]
    ? NOTIFICATION_ACTION_ICON_MAP[action]
    : DEFAULT_ICON;
}
