import EmberObject from '@ember/object';
import {Info} from "luxon";

export default {
  name: 'setup-pikaday-i18n',
  initialize: function(App) {
    let locale = navigator.language || navigator.userLanguage || 'en-US';
    Info.defaultLocale = locale;
    let i18n = EmberObject.extend({
      months: null,
      weekdays: null,
      weekdaysShort: null,

      init() {
        this._super(...arguments);
        this.months = Info.months('long', { locale });
        const weekdays = Info.weekdays('long', { locale });
        this.weekdays = [weekdays[6], ...weekdays.slice(0, 6)];
        const weekdaysShort = Info.weekdays('short', { locale });
        this.weekdaysShort = [weekdaysShort[6], ...weekdaysShort.slice(0, 6)];
      },
    });

    App.register('pikaday-i18n:main', i18n, { singleton: true });
    App.inject('component:pikaday-input', 'i18n', 'pikaday-i18n:main');
  }
};
