import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { USER_ROLES } from '../../../../utils/enums';
import { tracked } from '@glimmer/tracking';
/* global libcryptobox */

export default class ListedMember extends Component {
  @tracked changingRights = false;
  @tracked disableSelectize = false;
  @tracked newMemberRole = null;
  @tracked memberRole = null;
  @tracked deleteMember = false;
  @tracked destroyingRecord = false;

  @service intl;
  @service account;
  @service modalManager;
  @service notify;
  @service store;

  @computed('args.member.email', 'account.userEmail')
  get isCurrentUser() {
    return this.args.member.email === this.account.userEmail;
  }

  get isEditable() {
    return this.args.space.get('isAdmin') && !this.args.space.get('isDeleted');
  }

  @computed('args.accountTypes.[]', 'args.member.{email,maximumLevel,role}')
  get rightsChoices() {
    const choices = [
      USER_ROLES[libcryptobox.Role.Viewer],
      USER_ROLES[libcryptobox.Role.Auditor],
      USER_ROLES[libcryptobox.Role.Member],
    ];

    const email = this.args.member.email;
    const maximumLevel = this.args.member.maximumLevel;
    if (
      (this.args.accountTypes[email] &&
        this.args.accountTypes[email].licensePool ===
          libcryptobox.LicensePool.Invitee) ||
      maximumLevel === 'invitee'
    ) {
      return choices;
    }

    choices.push(USER_ROLES[libcryptobox.Role.Owner]);

    return choices;
  }

  @computed('args.member.role')
  get memberRole() {
    const memberRole = this.args.member.get('role');
    return USER_ROLES[memberRole];
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.disableSelectize = true;
    this.changingRights = false;
  }

  _revokeMember() {
    this.args.member.destroyRecord().then(() => {
      let notify = this.notify;
      notify.success(
        this.intl.t('toasts.removeWorkspaceUser.message', {
          user: this.args.member.get('email'),
        }),
        {
          title: this.intl.t('toasts.removeWorkspaceUser.title'),
          icon: 'group',
        },
      );

      if (this.isCurrentUser) {
        this.args.goToMyWorkspaces();
      }
    });
    this.modalManager.close('revoke-confirm');
  }

  @action
  openViewerInfo() {
    this.modalManager.open('viewer-devices');
  }

  @action
  updateMemberRight(newRights) {
    if (!this.destroyingRecord) {
      this.newMemberRole = newRights;
      this.args.member.set('role', newRights.value);
      if (this.isCurrentUser && newRights.value !== libcryptobox.Role.Owner) {
        if (this.args.owners.length < 1) {
          this.modalManager.open('update-last-owner');
          this.cancelUpdateMemberRight();
          return;
        }
        this.modalManager.open('abdication-confirm');
      } else {
        void this.confirmUpdateMemberRight();
      }
    }
  }

  @action
  async confirmUpdateMemberRight() {
    try {
      this.changingRights = true;
      const memberRecord = this.args.member;
      const newMemberRole = this.newMemberRole.value;
      memberRecord.set('role', newMemberRole);
      await memberRecord.save();
    } catch (error) {
      console.error('Error updating member rights:', error);
    } finally {
      this.changingRights = false;
      this.modalManager.close('abdication-confirm');
    }
  }

  @action
  cancelUpdateMemberRight() {
    if (this.changingRights) {
      return false;
    }
    let ownerRole = this.rightsChoices[3];
    const memberRecord = this.args.member;
    this.memberRole = ownerRole;
    memberRecord.set('role', ownerRole.value);

    this.modalManager.close('abdication-confirm');
  }

  @action
  confirmRevokeUser() {
    this._revokeMember();
  }

  @action
  revokeUser() {
    if (!this.destroyingRecord) {
      if (this.isCurrentUser) {
        if (this.args.owners.length === 1) {
          this.modalManager.open('revoke-last-owner');
          return;
        }
        this.modalManager.open('revoke-confirm');
      } else {
        this._revokeMember();
      }
    }
  }
}
