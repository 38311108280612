import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { runTask } from 'ember-lifeline';

export default class PdfViewerContentComponent extends Component {
  @tracked findText = '';
  @tracked isViewerFocused = false;

  @service('pdfjs-service') pdfjsService;

  constructor() {
    super(...arguments);
  }

  get pdfViewer() {
    return this.pdfjsService.pdfViewer;
  }

  get findController() {
    return this.pdfjsService.findController;
  }

  get eventBus() {
    return this.pdfjsService.eventBus;
  }

  get alignToolbarItemPosition() {
    return this.pdfjsService.alignToolbarItemPosition;
  }

  updateScaleDisplay() {
    const currentScale = this.pdfViewer.currentScale;
    const currentScaleValue = this.pdfViewer.currentScaleValue;

    const scaleSelect = document.getElementById('scaleSelect');
    const customOption = document.getElementById('customScaleOption');

    if (
      currentScaleValue === 'auto' ||
      currentScaleValue === 'page-fit' ||
      currentScaleValue === 'page-width' ||
      currentScaleValue === 'page-actual'
    ) {
      scaleSelect.value = currentScaleValue;
      customOption.style.display = 'none';
      return;
    }
    const percentage = Math.round(currentScale * 100);
    const predefinedValues = ['0.5', '0.75', '1', '1.25', '1.5', '2', '3', '4'];
    if (predefinedValues.includes(currentScale.toString())) {
      scaleSelect.value = currentScale.toString();
      customOption.style.display = 'none';
    } else {
      customOption.style.display = 'block';
      customOption.textContent = `${percentage}%`;
      customOption.value = 'custom';
      scaleSelect.value = 'custom';
    }
  }

  @action
  zoomOut() {
    this.pdfViewer.currentScale /= 1.1;
    this.updateScaleDisplay();
  }

  @action
  zoomIn() {
    this.pdfViewer.currentScale *= 1.1;
    this.updateScaleDisplay();
  }

  @action
  setInitialFocus() {
    const viewerContainer = document.getElementById('viewerContainer');
    if (viewerContainer) {
      viewerContainer.focus();
      this.isViewerFocused = true;
    }
  }
  @action
  handleDocumentClick(event) {
    const secondaryToolbar = document.getElementById('secondaryToolbar');
    const secondaryToolbarToggle = document.getElementById(
      'secondaryToolbarToggle',
    );

    if (
      secondaryToolbar &&
      !secondaryToolbar.classList.contains('hidden') &&
      !secondaryToolbar.contains(event.target) &&
      event.target !== secondaryToolbarToggle) {
      secondaryToolbar.classList.add('hidden');
    }
  }

  @action
  async initializePdfViewer() {
    this.pdfjsService.initializeBase();

    document.addEventListener('keydown', this.handleKeyDown);

    const viewerContainer = document.getElementById('viewerContainer');
    viewerContainer.addEventListener('focus', this.handleViewerFocus);
    viewerContainer.addEventListener('blur', this.handleViewerBlur);

    this.eventBus.on('pagesloaded', () => {
      runTask(this, this.setInitialFocus, 100);
    });
    document.addEventListener('click', this.handleDocumentClick, true);

    document.getElementById('previous').addEventListener('click', () => {
      if (this.pdfViewer.currentPageNumber > 1) {
        this.pdfViewer.previousPage();
      }
    });

    document.getElementById('next').addEventListener('click', () => {
      if (this.pdfViewer.currentPageNumber < this.pdfViewer.pagesCount) {
        this.pdfViewer.nextPage();
      }
    });
    document.getElementById('viewFindButton').addEventListener('click', () => {
      this.alignToolbarItemPosition();

      const findBar = document.getElementById('findbar');
      findBar?.classList?.toggle('hidden');
    });

    document
      .getElementById('secondaryToolbarToggle')
      .addEventListener('click', () => {
        this.alignToolbarItemPosition();

        const secondaryToolbar = document.getElementById('secondaryToolbar');
        secondaryToolbar?.classList?.toggle('hidden');
      });

    document.getElementById('scaleSelect').addEventListener('change', (e) => {
      const scaleValue = e.target.value;
      if (scaleValue === 'auto') {
        this.pdfViewer.currentScaleValue = 'auto';
      } else if (scaleValue === 'page-fit') {
        this.pdfViewer.currentScaleValue = 'page-fit';
      } else if (scaleValue === 'page-width') {
        this.pdfViewer.currentScaleValue = 'page-width';
      } else if (scaleValue === 'page-actual') {
        this.pdfViewer.currentScaleValue = 1.0;
      } else {
        const numericScale = parseFloat(scaleValue);
        if (!isNaN(numericScale)) {
          this.pdfViewer.currentScaleValue = numericScale;
        } else {
          console.error('Invalid scale value:', scaleValue);
        }
      }
    });

    document.getElementById('pageNumber').addEventListener('change', (e) => {
      this.pdfViewer.currentPageNumber = Number(e.target.value);
    });

    document.addEventListener('keydown', (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'p') {
        event.preventDefault();
      }
    });

    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        const findBar = document.getElementById('findbar');
        if (!findBar?.classList?.contains('hidden')) {
          findBar?.classList.add('hidden');
        }
      }
    });
    document.getElementById('findInput').addEventListener('input', (e) => {
      this.eventBus.dispatch('find', {
        query: e.target.value,
        phraseSearch: true,
        caseSensitive: document.getElementById('findMatchCase').checked,
        entireWord: document.getElementById('findEntireWord').checked,
      });
    });

    this.eventBus.on('pagesloaded', (evt) => {
      const pageNumber = evt.pagesCount;
      if (pageNumber <= 1) {
        document.getElementById('previous').setAttribute('disabled', true);
        document.getElementById('next').setAttribute('disabled', true);
      }
      document.getElementById('pageNumber').setAttribute('max', pageNumber);
    });

    this.eventBus.on('pagechanging', (evt) => {
      const page = evt.pageNumber;
      document.getElementById('previous').disabled = page <= 1;
      document.getElementById('next').disabled =
        page >= this.pdfViewer.pagesCount;

      document.getElementById('pageNumber').value = page;
    });

    this.eventBus.on('scalechanging', () => this.updateScaleDisplay());

    document
      .getElementById('presentationMode')
      .addEventListener('click', () => {
        this.pdfjsService.togglePresentationMode();
      });

    document.getElementById('firstPage').addEventListener('click', () => {
      this.pdfjsService.pdfViewer.currentPageNumber = 1;
      this.pdfjsService.closeSecondaryToolbar();
    });

    document.getElementById('lastPage').addEventListener('click', () => {
      this.pdfjsService.pdfViewer.currentPageNumber = this.pdfViewer.pagesCount;
      this.pdfjsService.closeSecondaryToolbar();
    });

    document
      .getElementById('cursorSelectTool')
      .addEventListener('click', () => {
        this.pdfjsService.selectTool();
      });

    document.getElementById('cursorHandTool').addEventListener('click', () => {
      this.pdfjsService.handTool();
    });

    document.getElementById('pageRotateCw').addEventListener('click', () => {
      this.pdfjsService.pdfViewer.pagesRotation =
        (this.pdfjsService.pdfViewer.pagesRotation + 90) % 360;
    });
    document.getElementById('pageRotateCcw').addEventListener('click', () => {
      this.pdfjsService.pdfViewer.pagesRotation =
        (this.pdfjsService.pdfViewer.pagesRotation - 90) % 360;
    });
    ['None', 'Odd', 'Even'].forEach((mode) => {
      document
        .getElementById(`spread${mode}`)
        ?.addEventListener('click', () => {
          this.pdfjsService.setSpreadMode(mode.toLowerCase());
        });
    });
    document.getElementById('scrollWrapped').addEventListener('click', () => {
      this.pdfjsService.pdfViewer.scrollMode = 0;
    });
    ['Page', 'Vertical', 'Horizontal', 'Wrapped'].forEach((mode) => {
      document
        .getElementById(`scroll${mode}`)
        ?.addEventListener('click', () => {
          this.pdfjsService.setScrollMode(mode.toLowerCase());
        });
    });
    document
      .getElementById('documentProperties')
      ?.addEventListener('click', () => {
        this.pdfjsService.showDocumentProperties();
      });

    document
      .getElementById('documentPropertiesClose')
      ?.addEventListener('click', () => {
        document.getElementById('documentPropertiesDialog').close();
      });

    document.getElementById('passwordCancel').addEventListener('click', () => {
      document.getElementById('password').value = '';
      document.getElementById('passwordDialog').close();
      this.eventBus.dispatch('passwordcancel');
    });

    document.getElementById('passwordSubmit').addEventListener('click', () => {
      const password = document.getElementById('password').value;
      document.getElementById('passwordDialog').close();
      document.getElementById('password').value = '';

      this.pdfjsService.loadPdf(this.pdfjsService.pdfUrl, password);
    });

    this.pdfjsService.eventBus.on('cursortoolchanged', (evt) => {
      const { tool } = evt;
      this.pdfjsService.setCursorTool(tool);
    });
  }

  @action
  findTextChange(event) {
    this.findText = event.target.value;
    if (this.findText) {
      this.executeFindOperation();
    }
  }

  @action
  async findKeyDown(event) {
    if (event.key === 'Enter') {
      if (event.shiftKey) {
        this.findPrevious();
      } else {
        this.findNext();
      }
    }
  }

  @action
  executeFindOperation(findPrevious = false, type = 'find') {
    if (!this.findText) {
      return;
    }
    const findMatchCase =
      document.getElementById('findMatchCase')?.checked || false;
    const findEntireWord =
      document.getElementById('findEntireWord')?.checked || false;
    const findHighlightAll =
      document.getElementById('findHighlightAll')?.checked || false;
    const findMatchDiacritics =
      document.getElementById('findMatchDiacritics')?.checked || false;

    this.pdfjsService.executeFindOperation({
      query: this.findText,
      phraseSearch: true,
      caseSensitive: findMatchCase,
      entireWord: findEntireWord,
      highlightAll: findHighlightAll,
      findPrevious: findPrevious,
      matchDiacritics: findMatchDiacritics,
      type,
    });
  }

  @action
  findPrevious() {
    this.executeFindOperation(true, 'again');
  }

  @action
  findNext() {
    this.executeFindOperation(false, 'again');
  }

  @action
  handleFind() {
    this.executeFindOperation(false, 'find');
  }

  @action
  handleKeyDown(event) {
    if (!this.isViewerFocused && !event.target.closest('#viewerContainer')) {
      return;
    }

    const SCROLL_AMOUNT = 40;
    const viewerContainer = document.getElementById('viewerContainer');

    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        viewerContainer.scrollTop += SCROLL_AMOUNT;
        break;
      case 'ArrowUp':
        event.preventDefault();
        viewerContainer.scrollTop -= SCROLL_AMOUNT;
        break;
      case 'ArrowRight':
        event.preventDefault();
        viewerContainer.scrollLeft += SCROLL_AMOUNT;
        break;
      case 'ArrowLeft':
        event.preventDefault();
        viewerContainer.scrollLeft -= SCROLL_AMOUNT;
        break;
      case 'PageDown':
        event.preventDefault();
        viewerContainer.scrollTop += viewerContainer.clientHeight;
        break;
      case 'PageUp':
        event.preventDefault();
        viewerContainer.scrollTop -= viewerContainer.clientHeight;
        break;
      case 'Home':
        event.preventDefault();
        viewerContainer.scrollTop = 0;
        break;
      case 'End':
        event.preventDefault();
        viewerContainer.scrollTop = viewerContainer.scrollHeight;
        break;
      case ' ': // Espace
        event.preventDefault();
        if (event.shiftKey) {
          viewerContainer.scrollTop -= viewerContainer.clientHeight;
        } else {
          viewerContainer.scrollTop += viewerContainer.clientHeight;
        }
        break;
    }
  }

  @action
  handleViewerFocus() {
    this.isViewerFocused = true;
  }

  @action
  handleViewerBlur() {
    this.isViewerFocused = false;
  }

  willDestroy() {
    super.willDestroy();

    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('click', this.handleDocumentClick, true);

    const viewerContainer = document.getElementById('viewerContainer');
    if (viewerContainer) {
      viewerContainer.removeEventListener('focus', this.handleViewerFocus);
      viewerContainer.removeEventListener('blur', this.handleViewerBlur);
    }
  }
}
