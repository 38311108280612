// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr } from '@ember-data/model';

// File cache configuration.
// Caching is enabled when the path is set and the size non-null.
export default class extends Model {
  // Location.
  // @type string
  @attr path;
  
  // Maxmimum size in bytes.
  // @type number
  @attr size;
  
  // Actual size in bytes.
  // @type number
  @attr used;
  
  // Whether caching is enabled. Derived from FileCacheRecord's Path and FileCacheRecord's Size.
  // @type boolean
  @attr enabled;
}

