import { helper } from "@ember/component/helper";

function lessThan(args) {
  const [n1, n2] = args;
  if (n1 == null) return false;
  if (n2 == null) return false;
  return n1 < n2;
}

export default helper(lessThan);
