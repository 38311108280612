import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class NotificationService extends Service {
  @tracked notifications = [];

  constructor() {
    super(...arguments);
  }

  get unreadCount() {
    let count = 0;
    this.notifications.forEach((notification) => {
      if (notification.state === 'unread') {
        count++;
      }
    });
    return count;
  }
}
