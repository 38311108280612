import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { tBoxClient } from 'client/initializers/init-toolbox';
import DS from 'ember-data';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';

export default class CreateFolder extends Component {
  @tracked color = '';
  @tracked isValid = true;
  @tracked errorMessage = '';
  @tracked folderName = '';

  @service store;
  @service modalManager;
  @service intl;

  @computed('args.path', 'folderName')
  get newPath() {
    return this.args.path.endsWith('/')
      ? this.args.path + this.folderName
      : this.args.path + '/' + this.folderName;
  }

  get inConflict() {
    return DS.PromiseObject.create({
      promise: this.checkForConflict(),
    });
  }

  async checkForConflict() {
    try {
      await this.store.queryRecord('file', {
        spaceId: this.args.spaceId,
        revision: 0,
        path: this.newPath,
      });
      return true;
    } catch {
      return false;
    }
  }

  @action
  setFocus(element) {
    // use setTimeout to ensure that the element is rendered before focusing
    setTimeout(() => element.focus(), 0);
  }

  async checkName() {
    this.folderName = this.folderName.trim();
    if (this.folderName === '') {
      this.isValid = false;
      this.errorMessage = this.intl.t('errors.empty');
      return;
    }
    const isNameValid = await tBoxClient.validation.isNameValid(
      this.folderName,
    );
    if (!isNameValid) {
      this.isValid = false;
      this.errorMessage =
        this.folderName === '.' || this.folderName === '..'
          ? this.intl.t('errors.forbiddenName')
          : this.intl.t('errors.slash');
      return;
    }
    const conflictExists = await this.inConflict;
    if (conflictExists) {
      this.isValid = false;
      this.errorMessage = this.intl.t('errors.newFolderConflict', {
        folderName: this.folderName,
      });
      return;
    }
    this.isValid = true;
  }

  @action
  async createFolder() {
    this.isValid = true;

    if (!this.args.spaceId) {
      return;
    }

    this.checkName()
      .then(async () => {
        if (!this.isValid || this.inProcess) {
          return;
        }
        this.inProcess = true;

        const newFolder = this.store.createRecord('file', {
          space: this.store.peekRecord({
            type: 'space',
            id: this.args.spaceId,
          }),
          path: this.newPath,
          isDirectory: true,
        });
        await newFolder.save();
        this.resetState();
        this.modalManager.close('create-folder');
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        this.inProcess = false;
      });
  }

  @action
  resetState() {
    this.folderName = '';
    this.isValid = true;
    this.inProcess = false;
  }

  @action
  resetError() {
    this.isValid = true;
    this.errorMessage = '';
  }
}
