import Controller from '@ember/controller';
import { isBlank } from '@ember/utils';
import { inject as service } from '@ember/service';
import { action, computed } from '@ember/object';
import { sort } from '@ember/object/computed';
import diacritic from 'diacritic';
import { tracked } from '@glimmer/tracking';

/* global libcryptobox */

export default class Create extends Controller {
  @tracked searchTerm = '';
  @tracked inCreation = false;
  @tracked newConversation = null;
  queryParams = ['kind'];
  sortProperties = Object.freeze(['firstName', 'lastname']);

  @service store;
  @service router;
  @service messaging;
  @service modalManager;

  @computed('model.users.{}', 'searchTerm')
  get displayedContacts() {
    const searchTerm = diacritic.clean(this.searchTerm.toLowerCase());
    const contactList = this.model.users;
    const filtredContactsList = [];

    if (searchTerm === '') {
      return contactList;
    }
    contactList.forEach((contact) => {
      const ctName = diacritic.clean(contact.get('fullName').toLowerCase());
      const ctEmail = diacritic.clean(contact.get('email').toLowerCase());

      if (ctName.indexOf(searchTerm) !== -1) {
        filtredContactsList.push(contact);
        return;
      } else if (ctEmail.indexOf(searchTerm) !== -1) {
        filtredContactsList.push(contact);
        return;
      }
    });

    return filtredContactsList;
  }

  _resetDatas() {
    this.newConversation = null;
    this.displayedContacts.forEach((contact) =>
      contact.set('isSelected', false),
    );
  }

  async _createDirectConversation(user) {
    const store = this.store;
    const spaces = await store.findAll('space');
    const existingConversation = spaces.findBy('peer.email', user.get('email'));

    if (existingConversation) {
      return this.router.transitionTo(
        'messages.index.conversation',
        existingConversation.get('id'),
      );
    } else {
      const peer = await store.createRecord('user', {
        email: user.get('email'),
        givenName: user.get('givenName'),
        surname: user.get('surname'),
      });
      store
        .createRecord('space', { kind: this.kind, peer })
        .save()
        .then((newSpace) => {
          this.router.transitionTo(
            'messages.index.conversation',
            newSpace.get('id'),
          );
        })
        .catch(() => this.set('inCreation', false));
    }
  }

  @computed('searchTerm', 'model.spaces.[]')
  get displayedWorkspaces() {
    const searchTerm = diacritic.clean(this.searchTerm.toLowerCase());
    const worskpaces = this.model.spaces.filter(
      (space) => space.status === 'active',
    );
    const filtredWorskpacesList = [];

    if (searchTerm === '') {
      return worskpaces;
    }
    worskpaces.forEach((space) => {
      const spaceName = diacritic.clean(space.get('name').toLowerCase());
      if (spaceName.indexOf(searchTerm) !== -1) {
        filtredWorskpacesList.push(space);
        return;
      }
    });
    return filtredWorskpacesList;
  }

  @sort('displayedContacts', 'sortProperties') sortedContacts;

  @computed('newConversation', 'newConversation.members.[]')
  get formIsValid() {
    const conversation = this.newConversation;
    return conversation && conversation.get('members.content.length') > 0;
  }

  _addUserGroupConversation(user) {
    const store = this.store;
    let conversation = this.newConversation;

    if (isBlank(conversation)) {
      conversation = this.store.createRecord('space', {
        name: null,
        kind: this.kind,
      });
      this.newConversation = conversation;
    }

    const member = conversation
      .get('members')
      .findBy('email', user.get('email'));
    if (isBlank(member)) {
      const newMember = store.createRecord('space-member', {
        email: user.get('email'),
        givenName: user.get('givenName'),
        surname: user.get('surname'),
      });
      conversation.get('members').pushObject(newMember);
    }
  }

  @action
  async addUserToConversation(user) {
    const kind = this.kind;
    if (kind === libcryptobox.SpaceKind.DirectConversation) {
      this.set('inCreation', true);
      return await this._createDirectConversation(user);
    } else if (kind === libcryptobox.SpaceKind.GroupConversation) {
      user.set('isSelected', true);
      return this._addUserGroupConversation(user);
    }
  }

  @action
  addNameToGroup() {
    if (this.formIsValid) {
      this.modalManager.open('group-name');
    }
  }

  @action
  async createGroupConversation() {
    const modal = this.modalManager.isModalOpen('group-name');
    if (modal) {
      this.modalManager.close('group-name');
    }
    const conversation = this.newConversation;
    const name = this.groupName.trim() || '';
    this.set('inCreation', true);
    try {
      conversation.set('name', name);
      await conversation.save();

      setTimeout(async () => {
        this.store.unloadAll('space');
        const spaces = await this.store.findAll('space', {
          adapterOptions: { subscribe: this },
        });
        this.router.transitionTo('messages.index.conversation', spaces.find(space => (space.name === name)).id);
        this._resetDatas();
      }, 10);
    } catch (error) {
      console.warn(error);
      this.set('inCreation', false);
    }
  }

  @action
  removeUserFromConversation(user) {
    this.model.users
      .findBy('email', user.get('email'))
      .set('isSelected', false);
    const members = this.newConversation.members;
    const member = members.findBy('email', user.get('email'));
    members.removeObject(member);
  }
}
