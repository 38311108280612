import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isEqual } from '@ember/utils';
import { guidFor } from "@ember/object/internals";

export default class RadioButtonInputComponent extends Component {

 get inputId() {
    return this.args.inputId ?? `radio-${guidFor(this)}`;
  }
  get joinedClassNames() {
    return this.args.classNames?.join(' ') ?? '';
  }

  get checkedClass() {
    return this.args.checkedClass ?? 'checked';
  }

  get checked() {
    return isEqual(this.args.groupValue, this.args.value);
  }

  @action
  changed(newValue) {
    this.args.changed?.(newValue);
  }
}
