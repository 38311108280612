import { tracked } from '@glimmer/tracking';

export const FILE_TYPE_IMAGE = 'image';
export const FILE_TYPE_PDF = 'pdf';
export const FILE_TYPE_UNKNOWN = 'unknown';

export class FileInfoPreview {
  @tracked url = '';
  type = '';
  mimeType = '';
  spacePath = '';
  contentSize = 0;
  fileId = '';
  attachmentId = '';
  @tracked errorMessage = null;
  modifiedInfo = '';
  iconName = '';

  constructor(
    type,
    mimeType,
    spacePath,
    modifiedInfo,
    iconName,
    fileId,
    attachmentId,
    contentSize,
  ) {
    this.type = type;
    this.mimeType = mimeType;
    this.spacePath = spacePath;
    this.contentSize = contentSize;
    this.fileId = fileId;
    this.modifiedInfo = modifiedInfo;
    this.iconName = iconName;
    this.attachmentId = attachmentId;
  }

  get size() {
    return this.bytesToSize(this.contentSize);
  }

  get name() {
    return this.attachmentId
      ? this.spacePath
      : this.spacePath.substring(this.spacePath.lastIndexOf('/') + 1);
  }

  bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1000)));
    return Math.round(bytes / Math.pow(1000, i), 2) + ' ' + sizes[i];
  }
}
