import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class PageBoxComponent extends Component {
  get isCurrentPageIndex() {
    return this.args.pageNumber === this.args.selectedPage;
  }

  get complementClass() {
    const page = parseInt(this.args.pageNumber);
    const selected = parseInt(this.args.selectedPage);
    const maxPage = parseInt(this.args.maxPage);

    if (maxPage === 0) {
      return 'double-margin-left double-margin-right';
    }

    if (page === 1 && page === selected) {
      return 'double-margin-left';
    }

    if (page === 1 && selected === 2) {
      return 'simple-margin-left';
    }

    if (page === maxPage && selected === maxPage) {
      return 'double-margin-right';
    }

    if (page === maxPage && selected === maxPage - 1) {
      return 'simple-margin-right';
    }

    return '';
  }

  @action
  changePage(number) {
    return this.args.changePage(number);
  }
}
