import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class Routes extends Route {
  @service account;
  @service session;
  @service router;

  async beforeModel(transition) {
    await super.beforeModel(transition);
    document.title = 'Cryptobox';
    const requestedHash = this.paramsFor('index').requestedHash;
    if (this.account.isLoggedIn) {
      if (requestedHash) {
        window.location.hash = requestedHash;
      } else {
        const messagesRoute = transition.router.oldState.params.messages;

        if (messagesRoute) {
          this.router.transitionTo('messages.index');
        } else {
          this.router.transitionTo('my-groups');
        }
      }
    } else {
      this.router.transitionTo('login', {
        queryParams: { requestedHash, register: false },
      });
    }
  }
}
