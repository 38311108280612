import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class ListItem extends Component {
  @service intl;
  @service router;
  @service('transfers') transfersManager;
  @service modalManager;

  @tracked selectedFiles = [];
  @tracked selectedAll = false;

  get depositBox() {
    return this.args.depositBox;
  }

  get files() {
    return this.args.depositBox.files;
  }

  get isAllSelected() {
    return (
      this.files.length > 0 &&
      this.files.every((file) => this.selectedFiles.includes(file))
    );
  }
  get isMultipleSelectedFiles() {
    return this.selectedFiles.length > 1;
  }

  get isDisabled() {
    return this.selectedFiles.length === 0;
  }

  @action
  toggleSelection(file) {
    if (this.selectedFiles.includes(file)) {
      this.selectedFiles.removeObject(file);
    } else {
      this.selectedFiles.addObject(file);
    }
    this.selectedAll = this.isAllSelected;
  }

  @action
  isChecked(file) {
    return this.selectedFiles.includes(file);
  }

  @action
  toggleSelectAll() {
    if (this.isAllSelected) {
      this.selectedFiles = [];
      this.selectedAll = false;
    } else {
      this.selectedFiles = this.files.slice();
      this.selectedAll = true;
    }
  }

  @action
  handleCopyInWorkspace() {
    return this.modalManager.open('copy-move-modal');
  }

  @action
  async handleDownloadDepositBox() {
    await this.transfersManager.downloadDepositBox(
      this.depositBox,
      this.selectedFiles,
    );
  }

}
