import { tBoxClient } from 'client/initializers/init-toolbox';
import BaseAdapter from 'client/adapters/application';
/* global libcryptobox */

export default BaseAdapter.extend({
  updateRecord(_store, _type, snapshot) {
    return tBoxClient.device.setState(snapshot.id, snapshot.attr('state')).catch(() => {
      snapshot.record.rollbackAttributes();
    });
  },
  
  deleteRecord(_store, _type, snapshot) {
    return tBoxClient.device.setState(snapshot.id, libcryptobox.DeviceState.Revoked);
  },
});
