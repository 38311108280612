import EmberRouter from '@ember/routing/router';
import config from './config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  if (config.APP.devtool) {
    this.route('design-system');
  }
  this.route('index', { path: '/' });
  this.route('my-groups', { path: '/my-workspaces' }, function () {
    this.route('index', { path: '/' });
    this.route('login');
    this.route('create-group');
    this.route('my-favorites');
    this.route('deleted-spaces');
    this.route('group', { path: '/workspace/:space_id' }, function () {
      this.route('overview', { path: '/' });
      this.route('documents', function () {
        this.route('history', function () {
          this.route('revision', { path: '/revision/:revision' });
        }); // display panel of dates to choose
      });
      this.route('conversation');
      this.route('members');
      this.route('shares');
      this.route('edit');
    });
    this.route(
      'file-history',
      { path: '/workspace/:space_id/documents/file-history' },
      function () {
        this.route('index', { path: '/' });
      },
    );
  });
  this.route('validate');
  this.route('login');
  this.route('deposit-boxes', function () {
    this.route('index', { path: '/' });
    this.route('deposit-box', { path: '/deposit-box/:box_id' });
  });
  this.route('browser-error');
  this.route('my-transfers');
  this.route('download-share', { path: '/public/:space_id/file/:content_id' });
  this.route(
    'account-recovery',
    { path: '/account-recovery/:id' },
    function () {
      this.route('depositary-choice');
      this.route('rendezvous', function () {
        this.route('claimant', { path: '/:rdv_user_id/claimant' });
        this.route('depositary', { path: '/:rdv_user_id/depositary' });
      });
      this.route('new-password', { path: '/:rdv_user_id/new-password' });
    },
  );
  this.route('invitations');
  this.route('my-notifications');
  this.route('invite');
  this.route('deposit-box-form', { path: '/deposit-box-form/:deposit_box_id' });
  this.route('my-account');
  this.route('fatal-error');
  this.route('tutorial');
  this.route('second-auth-success');
  this.route('second-auth-failed');
  this.route('messages', function () {
    this.route('index', { path: '/' }, function () {
      this.route('conversation', { path: '/:space_id' });
    });
    this.route('create');
  });
  this.route('validate-preadd', { path: '/validate-preadd/:invitee_email' });
  this.route('change-email', { path: '/change-email/:token' });
});
