import Component from '@glimmer/component';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { task, timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class RestoreWorkspace extends Component {
  @tracked workspaceRevision = null;
  modalName = 'confirmRestoration';

  @service intl;
  @service notify;
  @service commonService;
  @service modalManager;
  @service router;

  get fileRevision() {
    return this.args.fileRevision;
  }

  get spaceId() {
    return this.args.spaceId;
  }

  @task
  *restoreWs(spaceId, wsRevision) {
    yield timeout(1000);
    const queryParams = { queryParams: { fileRevision: 0, spaceId: spaceId } };

    return tBoxClient.fileRevision
      .restore(spaceId, wsRevision)
      .then(() => {
        const successTitle = this.intl.t('notifications.restore.success.title');
        const successBody = this.intl.t(
          'notifications.restore.success.content',
        );
        this.closePopup();
        this.notify.success(successBody, {
          title: successTitle,
          closeAfter: 3000,
        });
        this.router.transitionTo('my-groups.group.documents', queryParams);
      })
      .catch(() => {
        const failTitle = this.intl.t('notifications.restore.fail.title');
        const failBody = this.intl.t('notifications.restore.fail.content');
        this.notify.error(failBody, { title: failTitle, closeAfter: 3000 });
        this.router.transitionTo('my-groups.group.documents', queryParams);
      });
  }

  @action
  openPopupToConfirm() {
    this.modalManager.open(this.modalName);
  }

  @action
  closePopup() {
    this.modalManager.close(this.modalName);
  }

  /**
   * set back the current workspace's files to a certain revision number
   */
  @action
  restoreToThisRevision() {
    let spaceId = this.spaceId;
    let wsRevision = this.commonService.workspaceRevision;
    if (spaceId && wsRevision) {
      this.restoreWs.perform(spaceId, wsRevision);
    } else {
      console.error(
        'missing spaceId or workspaceRevision to restore this workspace',
        spaceId,
        wsRevision,
      );
    }
  }
}
