// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr } from '@ember-data/model';

// DepositBox is the detailed information on the deposit box.
export default class extends Model {
  // Name.
  // @type string
  @attr name;
  
  // Description.
  // @type string
  @attr description;
  
  // Private note. Only visible by the owner.
  // @type string
  @attr note;
  
  // State.
  // @type DepositBoxState
  @attr state;
  
  // Creation date and time, ISO 8601.
  // @type string
  @attr creationDate;
  
  // Upload date and time, ISO 8601. Empty until files are uploaded.
  // @type string
  @attr uploadDate;
  
  // Number of uploaded files.
  // @type number
  @attr fileCount;
  
  // Uploaded files.
  // @type Array<DepositBoxFile>
  @attr files;
  
  // Uploader comment.
  // @type string
  @attr comment;
  
  // Access link for unauthenticated users.
  // @type DepositBoxLink
  @attr link;
  
  // The recipient entered too many wrong codes. The deposit box is blocked.
  // @type boolean
  @attr maxCodeAttemptsReached;
}

