import Component from "@glimmer/component";
import { USER_ROLES } from "../../utils/enums";

/* global libcryptobox */

export default class UserRoleSelectionComponent extends Component {
  userRolesOptions = [
    USER_ROLES[libcryptobox.Role.Viewer],
    USER_ROLES[libcryptobox.Role.Auditor],
    USER_ROLES[libcryptobox.Role.Member],
  ];

  get options() {
    const { user, noOwnerOption } = this.args;

    if(noOwnerOption) {
      return this.userRolesOptions;
    }

    if (
      user.maximumLevel === libcryptobox.LicensePool.Invitee ||
      //user.type === libcryptobox.LicensePool.Invitee ||
      user.status === libcryptobox.PreaddedStatus.Invited
    ) {
      return this.userRolesOptions;
    }

    return this.userRolesOptions.concat(USER_ROLES[libcryptobox.Role.Owner]);
  }
}
