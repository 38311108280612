import BaseAdapter from 'client/adapters/application';
import { tBoxClient } from 'client/initializers/init-toolbox';

export default class Message extends BaseAdapter{
  createRecord(store, type, snapshot) {
    return tBoxClient.message.create(snapshot.belongsTo('space').id, snapshot.attr('text'))
      .then(payload => this.normalizeRecord(store, type, payload));
  }

  deleteRecord(_store, _type, snapshot) {
    return tBoxClient.message.delete(snapshot.id);
  }
};
