import { helper } from '@ember/component/helper';

export function firstLetter(params) {
	if (!params[0]) {
		return "";
	}
	return Array.from(params[0].toUpperCase())[0];
}

export default helper(firstLetter);
