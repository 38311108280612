import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class Depositary extends Route {
  @service router;
  @service userActivity;
  @service authService;

  beforeModel() {
    this.authService.loginIfNotAuthenticated();
  }

  model(params) {
    const recoveryId = this.paramsFor('account-recovery').id,
      rdvUserId = params.rdv_user_id;
    return {
      recoveryId,
      rdvUserId,
    };
  }
  constructor() {
    super(...arguments);
    this.router.on('routeWillChange', (transition) => {
      const isDepositaryPage =
        transition.to.name === 'account-recovery.rendezvous.depositary';
      this.userActivity.setIsDepositaryPage(isDepositaryPage);
    });
  }

  setupController(controller, model) {
    controller.set('model', model);
    controller.set('isDepositaryConnected', false);
    controller.set('sas', '');
    controller.set('connectionError', false);
  }

  afterModel() {
    document.title = 'Cryptobox - Password Recovery';
  }
}
