import Service, { inject as service } from "@ember/service";
import { computed } from '@ember/object';
import { URL_CRYPTOBOX } from "../utils/enums";

const ATTRIBUTE_NAME_SERVER_SETTINGS_CGU = "eulaUrl";
const ATTRIBUTE_NAME_SERVER_SETTINGS_PRIVACY_POLICY = "privacyPolicyUrl";

export default class CryptoboxURLsService extends Service {
  @service intl;
  @service account;

  get langAttributeName() {
    return this.intl.locale[0] === 'fr-fr' ? 'FR' : 'EN';
  }

  getUrl(serverSettingsAttrStr) {
    const serverSettings = this.account.serverSettings;

    if (!serverSettings) return;

    const url = serverSettings[serverSettingsAttrStr];

    if (url) {
      return url[this.langAttributeName.toLocaleLowerCase()];
    }
  }

  get cguURL() {
    let eulaUrl = this.getUrl(ATTRIBUTE_NAME_SERVER_SETTINGS_CGU);
    if (!eulaUrl) {
      eulaUrl = URL_CRYPTOBOX[this.langAttributeName].CGU;
    }

    return eulaUrl;
  }

  get privacyPolicyURL() {
    let privacyPolicyUrl = this.getUrl(
      ATTRIBUTE_NAME_SERVER_SETTINGS_PRIVACY_POLICY
    );
    if (!privacyPolicyUrl) {
      privacyPolicyUrl = URL_CRYPTOBOX[this.langAttributeName].PRIVACY_POLICY;
    }

    return privacyPolicyUrl;
  }
}
