import { htmlSafe } from '@ember/template';
import { computed } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class PanelBody extends Component {
  @tracked elementIsInserted = true;

  get isOpen() {
    return this.args.isOpen;
  }

  @computed('contentHeight', 'isOpen')
  get style() {
    if (this.isOpen) {
      let panelHeight = this.contentHeight;
      return htmlSafe('height:' + panelHeight + 'px;');
    }
  }

  @computed('elementId', 'elementIsInserted')
  get contentHeight() {
    const element = document.querySelector(
      '#' + this.elementId + ' .panel-body-content',
    );

    if (element) {
      return element.height;
    }

    return null;
  }
}
