import Component from "@glimmer/component";
import { SECURITY_LEVEL_COLOR } from "../../../utils/enums";

const CHIP_COLOR_BINDING = {
  [SECURITY_LEVEL_COLOR.RED]: "red",
  [SECURITY_LEVEL_COLOR.ORANGE]: "orange",
  [SECURITY_LEVEL_COLOR.GREEN]: "green",
};

export default class ChipComponent extends Component {
  get color() {
    const { color } = this.args;

    return (
      CHIP_COLOR_BINDING[color] || CHIP_COLOR_BINDING[SECURITY_LEVEL_COLOR.RED]
    );
  }
}
