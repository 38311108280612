import BaseModel from 'client/toolbox/generated/models/category';

export default class Category extends BaseModel {
  toggleSpace(space) {
    this.spaces.then((categorySpaces) => {
      if (categorySpaces.indexOf(space) === -1) {
        this.spaces.pushObject(space);
        this.save({ adapterOptions: { addedSpaceId: space.get('id') } });
      } else {
        this.spaces.removeObject(space);
        this.save({ adapterOptions: { removedSpaceId: space.get('id') } });
      }
    });
  }
}
