import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { alias } from '@ember/object/computed';
import { task, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

/* global libcryptobox */

export default class ConversationMessage extends Component {
  @tracked displayDetails = true;
  @tracked unstableState = false;
  @tracked hidden = false;
  @tracked isLastRead = false;
  @service account;
  @service messaging;
  @service commonService;
  @service transfers;
  @service modalManager;

  @alias('message.isSending') isSending;
  @alias('message.isRejected') sentHasFailed;

  get message() {
    return this.args.message;
  }

  get showAcknowledgement() {
    return this.args.showAcknowledgement;
  }

  get spaceId() {
    return this.message.get('spaceId');
  }

  @task({ drop: true })
  *_resendMessage(message) {
    yield timeout(1000);
    message.post();
  }

  @action
  onInsertElement(element) {

    if (
      this.message.timestamp !== 0 &&
      this.args.lastRead === this.message.timestamp &&
      this.args.lastRead !== this.args.latest
    ) {
      this.isLastRead = true;
    }

    if (this.message.timestamp > this.args.lastRead) {
      const options = {
        root: document.querySelector('.container-conversationStream'),
        rootMargin: '0px',
        threshold: 1.0,
      };
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (this.message.isDestroyed) return;
          this.message.set('elementId', this.elementId);

          if (entry.isIntersecting) {
            this.message.set('isRead', true);
            this.message.get('space.cursor').then((c) => c.advance());
          }
        });
      }, options);
      observer.observe(element);
    }
  }

  @computed('args.isDirect', 'message.author.isMySelf')
  get authorNameDisplay() {
    return !this.args.isDirect && !this.message.get('author.isMySelf');
  }

  @computed('message.author.isMySelf', 'sentHasFailed', 'displayDetails')
  get showMore() {
    return (
      this.message.get('author.isMySelf') &&
      !this.sentHasFailed &&
      this.displayDetails
    );
  }

  @computed('message.groupMessageStatus', 'displayDetails')
  get groupMessageStatus() {
    return this.displayDetails
      ? this.message.get('groupMessageStatus')
      : { content: 'details' };
  }

  get isSelected() {
    return this.args.selectedMessage === this.message;
  }

  get filteredAttachments() {
    const attachments = this.message.attachments;
    return attachments.filter(node => !node.isNew);
  }

  calculatePosition(trigger, content) {
    const { top, left, height, width } = trigger.getBoundingClientRect();
    const { height: contentHeight } = content.getBoundingClientRect();

    const style = {
      left: left + width + 8,
      top: top + height / 2 - contentHeight / 2,
    };

    return { style };
  }

  @action
  resendMessage(message) {
    this._resendMessage.perform(message);
  }

  @action
  showMessageRecipients(dropdown) {
    this.args.setShowAcknowledgement(true);
    this.args.setShowUsers(false);
    this.args.setSelectedMessage(this.message);
    dropdown.actions.close();
  }

  @action
  deleteMessage(message, dropdown) {
    this.hidden = true;

    if (message.get('space.isWorkspace') && this.filteredAttachments.length) {
      this.args.setSelectedMessage(message);
      return this.modalManager.open('warningDeletion');
    }

    dropdown.actions.close();
    this.unstableState = false;
    message.deleteMessage().then(() => {
      this.unstableState = true;
    });
  }

  @action
  toggleHidden() {
    this.hidden = !this.hidden;
  }
}
