import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class extends Component {
  @service intl;
  @service('transfers') transfersManager;

  get transfer() {
    return this.args.transfer;
  }

  get luxonOutputFormat() {
    return this.intl.t('myTransfers.startTimeFormat');
  }

  @alias('transfer.progressPercent') buffer;

  @computed('transfer.state')
  get isQueued() {
    return this.transfer.get('state') === 'queued';
  }

  @computed('transfer.state')
  get isCompleted() {
    return this.transfer.get('state') === 'completed';
  }

  @computed('transfer.state')
  get isActive() {
    return (
      this.transfer.get('state') === 'active' ||
      this.transfer.get('state') === 'retry'
    );
  }

  @computed('transfer.direction')
  get isUpload() {
    return this.transfer.get('direction') === 'upload';
  }

  @computed('transfer.state')
  get isFailed() {
    return this.transfer.get('state') === 'failed';
  }

  @computed('isCompleted', 'isFailed')
  get isEnded() {
    return this.isCompleted || this.isFailed;
  }

  @action
  handleDoubleClick() {
    const direction = this.transfer.get('direction');
    const state = this.transfer.get('state');
    if (direction === 'download' && state === 'completed') {
      const transfer = this.transfer;
      transfer.saveAs();
    }
    if (direction === 'upload') {
      const spaceId = this.transfer.get('spaceId');
      const path = this.transfer.get('parentFolderPath');
      this.args.goToUploadPath(spaceId, path);
    }
  }

  @action
  restart() {
    const transfersManager = this.transfersManager;
    const transfer = this.transfer;

    if (transfer.direction === 'upload') {
      transfersManager.retryUpload(transfer);
    } else {
      this.removeEntry();
      if (transfer.restart) {
        transfer.restart();
      } else if (transfer.get('kind') === 'zip') {
        transfersManager.downloadZip(
          transfer.get('spaceId'),
          transfer.get('sources'),
          transfer.get('workspaceRevision'),
        );
      } else {
        const fileId = `${transfer.get('spaceId')}::-1::${transfer.get(
          'path',
        )}`;
        transfersManager.downloadFile(
          fileId,
          transfer.get('workspaceRevision'),
        );
      }
    }
  }

  @action
  removeEntry() {
    const transfersManager = this.transfersManager;
    const queuedArray = transfersManager.get('queued');
    const completedArray = transfersManager.get('completed');
    const failedArray = transfersManager.get('failed');
    const activeArray = transfersManager.get('active');
    let entry = this.transfer;
    if (entry.state === 'queued') {
      queuedArray.removeObject(entry);
    }
    if (entry.state === 'completed') {
      completedArray.removeObject(entry);
    }
    if (entry.state === 'failed') {
      activeArray.removeObject(entry);
      failedArray.removeObject(entry);
    }
    if (entry.state === 'active' || entry.state === 'retry') {
      entry.cancelTransfer();
      entry.set('toasted', true);
      transfersManager.startNextTransfer();
    }
  }
}
