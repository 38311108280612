import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class CheckboxInputComponent extends Component {
  popperOptions = {
    modifiers: {
      preventOverflow: {
        escapeWithReference: false,
      },
    },
  };

  @action
  onClick(event) {
    if (!this.args.onClick) {
      return;
    }
    this.args.onClick(event);
  }
}
