import { debounce } from '@ember/runloop';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { guidFor } from '@ember/object/internals';

export default class TextArea extends Component {

  @service intl;

  @tracked isRequired = false;
  @tracked noSlash = false;
  @tracked _isNotValid = false;
  @tracked emptyError = false;
  @tracked slashError = false;
  @tracked _value = '';

  get id() {
    return  this.args.id || `text-area-${ guidFor(this) }`;
  }

  get label() {
    return this.args.label;
  }

  get register() {
    return this.args.register || true;
  }

  get maxLength() {
    return this.args.maxLength;
  }

  get inputType() {
    return this.args.inputType || 'text';
  }

  get isNotValid() {
    return this.args.isNotValid || this._isNotValid;
  }
  set isNotValid(value) {
    this._isNotValid = value;
  }

  get value() {
    return this.args.value || this._value;
  }

  set value(value) {
    if (this.args.setValue && typeof this.args.setValue === 'function') {
      this.args.setValue(value);
    }
    this._value = value;

  }

  get isEmptyMessage() {
    return this.args.isEmptyMessage || this.intl.t('errors.empty');
  }

  @action
  input() {
    this.resetInputState();
    debounce(this, this.validateInput, 500);
  }

  @action
  focusOut() {
    this.resetInputState();
    debounce(this, this.validateInput, 1);
  }

  @action
  resetInputState() {
    this.isNotValid = false;
    this.emptyError = false;
    this.slashError = false;
  }

  @action
  resetInput() {
    this.value = '';
    this.resetInputState();
  }

  @action
  validateInput(isSubmission) {
    return new window.Promise((resolve) => {
      // TODO: create helper for update args in child component
      let inputValue = this.value || '';
      if (isSubmission) {
        inputValue = inputValue.trim();
        this.value = inputValue;
      }
      if (this.isRequired && !inputValue) {
        this.emptyError = true;
        this.isNotValid = true;
        resolve({ isValid: false });
        return;
      }
      resolve({ isValid: true });
    });
  }

  @action
  onInsertElement() {
    this.args.registerInput(this);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    if (this.register) {
      this.args.unregisterInput(this);
    }
  }
}
