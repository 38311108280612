import { tBoxClient } from 'client/initializers/init-toolbox';
import BaseModel from 'client/toolbox/generated/models/attachment';
import BlobFileWriter from 'client/toolbox/blob-file-writer';
import { attr } from '@ember-data/model';
import { getFileDownloadTarget } from 'client/toolbox/file-download-target';
import mime from 'mime';

export default class Attachment extends BaseModel {
  @attr fileData;

  async downloadAttachment() {
    const target = await getFileDownloadTarget(this.fileName, this.fileSize);
    if (!target) {
      return;
    }
    const promise = target.downloadAttachment(this.id);
    this.downloadHasFailed = false;
    this.isDownloading = true;
    this.cancelDownload = promise.cancel;
    return promise
      .then(() => (this.isDownloading = false))
      .catch((error) => {
        console.error(error);
        this.isDownloading = false;
        if (error.code !== window.libcryptobox.ErrorCode.Canceled) {
          this.downloadHasFailed = true;
        }
      });
  }

  createUrl() {
    this.downloadHasFailed = false;
    let writer = new BlobFileWriter();
    this.isDownloading = true;
    let promise = tBoxClient.attachment.download(this.id, writer);
    this.cancelDownload = promise.cancel;

    return promise
      .then(() => {
        this.isDownloading = false;
        let fileName = this.fileName;
        let blob = new Blob(writer.chunkBlobs, {
          type: mime.getType(fileName) || 'application/octet-stream',
        });
        return URL.createObjectURL(blob);
      })
      .catch((error) => {
        console.error(error);
        this.isDownloading = false;
        if (error.code !== window.libcryptobox.ErrorCode.Canceled) {
          this.downloadHasFailed = true;
        }
      });
  }

  save() {
    return super.save().catch((error) => {
      console.warn('error save', error);
      if (!this.isValid) {
        this.unloadRecord();
      }
    });
  }
}
