import Component from "@glimmer/component";
import { action } from "@ember/object";
import { inject as service } from "@ember/service";

export default class StarFavoriteComponent extends Component {
  @service account;
  @service store;

  @action
  onClick() {
    const { space } = this.args;
    const favCatId = this.account.favoritesCategoryId;

    this.store.findRecord("category", favCatId).then((favCat) => {
      favCat.toggleSpace(space);
    });
  }
}
