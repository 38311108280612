function truncate(element, name) {
  const visibleWidth = element.clientWidth - 18
  const canvas = document.createElement("canvas")
  const context = canvas.getContext("2d");
  context.font = 'normal 12px roboto';
  const metrics = context.measureText(name);

  if (metrics.width > visibleWidth) {
    let i = 1
    const end = '...' + name.substring(name.length - 10, name.length)

    while (context.measureText(name).width > visibleWidth) {
      const start = name.substring(0, name.length - (10 + i * 3))
      name = start + end
      i++
    }
  }

  return name
}


export { truncate };
