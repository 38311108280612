import Route from '@ember/routing/route';

export default class NewPassword extends Route {
  model(params) {
    let recoveryId = this.paramsFor('account-recovery').id;
    let rdvUserId = params.rdv_user_id;
    return {
      recoveryId,
      rdvUserId,
    };
  }

  afterModel() {
    document.title = 'Cryptobox - Password Recovery';
  }
}
