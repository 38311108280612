import { action, computed } from '@ember/object';
import ArrayProxy from '@ember/array/proxy';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { isEmpty } from '@ember/utils';
import DS from 'ember-data';
import { RENAME_NODE_MODAL_NAME } from '../../../../utils/enums';
import { tracked } from '@glimmer/tracking';

/* global libcryptobox */
export default class TreeItemComponent extends Component {
  @tracked mouseX = 0;
  @tracked mouseY = 0;
  @tracked isContextMenuOpened = false;

  @service account;
  @service store;
  @service notify;
  @service intl;
  @service commonService;
  @service modalManager;
  @service router;

  deleteFileModalName = 'delete-file';
  @tracked isLoading = false;
  @tracked isExpanded = this.args.isExpanded || false;
  @tracked folder = this.args.folder;
  @tracked selectedSpace = this.space;
  @tracked dropInProgress = this.args.dropInProgress || false;

  constructor() {
    super(...arguments);
    this.commonService.treeItemsDocuments.push(this);
  }

  @computed('args.model.space', 'args.space', 'folder.space.id')
  get space() {
    return this.args?.model?.space || this.args?.space;
  }

  @computed('args.model.folder.subDirectoryCount')
  get subDirectoryCount() {
    return this.args?.model?.folder?.subDirectoryCount || 0;
  }

  @computed(
    'commonService.treeItemsDocuments',
    'folder.path',
    'folder.space.id',
    'isExpanded',
    'subDirectoryCount',
    'workspaceRevision',
  )
  get files() {
    if (this.subDirectoryCount === 0) {
      this.commonService.treeItemsDocuments.removeObject(this);
    }
    if (!this.isExpanded || !this.folder.get('space.id')) {
      return [];
    }
    return DS.PromiseArray.create({
      promise: this.store.query('file', {
        spaceId: this.folder.get('space.id'),
        revision: this.workspaceRevision,
        path: this.folder.get('path'),
        adapterOptions: { subscribe: this },
      }),
    });
  }

  @computed(
    'args.{selectedPath,selectedSpace,space}',
    'folder',
    'selectedSpace.id',
    'space.id',
  )
  get isSelectedItem() {
    return (
      this.space?.get('id') === this.selectedSpace?.get('id') &&
      this.folder?.get('path') === this.args.selectedPath
    );
  }

  @computed('folder.path', 'space.name')
  get isRootFolder() {
    return !this.folder || this.folder.get('path') === '/';
  }

  @computed('args.space', 'folder.path', 'space.name')
  get name() {
    if (
      !this.folder ||
      !this.folder.get('path') ||
      this.folder.get('path') === '/'
    ) {
      return this.space.get('name');
    }
    return this.folder.path.split('/').pop();
  }

  get selectedNodes() {
    return ArrayProxy.create({ content: [this.folder] });
  }

  get workspaceRevision() {
    return this.commonService.workspaceRevision || 0;
  }

  @computed('files.[]', 'folder.subDirectoryCount')
  get children() {
    return this.files.filter((file) => file.isFolder).sortBy('name');
  }

  @action
  handleRightClick(e) {
    e.preventDefault();
    this.mouseX = e.pageX;
    this.mouseY = e.pageY;
    const search = document.querySelector('input[name=\'search\']');

    if (search) {
      const searchBounds = search.getBoundingClientRect();
      const searchX = searchBounds.left;
      const searchY = searchBounds.top;
      const xValid =
        e.pageX < searchX || e.pageX > searchX + searchBounds.width + 50;
      const yValid =
        e.pageY < searchY || e.pageY > searchY + searchBounds.height + 30;

      if (xValid || yValid) {
        this.isContextMenuOpened = true;
        return false;
      }
      return false;
    } else {
      this.isContextMenuOpened = true;
    }
    return false;
  }

  @computed('args.hideContextMenu', 'hideContextMenu', 'isContextMenuOpened')
  get showContextMenu() {
    return !this.args.hideContextMenu && this.isContextMenuOpened;
  }

  @action
  async handleDidInsert() {
    this.isExpanded = false;

    await this.loadingFolder();

    const path = this.args.trackingIdPath
      ? this.args.trackingIdPath
      : this.args.selectedPath;
    if (path) {
      if (
        this.selectedSpace?.get('name') === this.name ||
        path.split('/').includes(this.name)
      ) {
        this.isExpanded = !!this.args.defaultExpanded;
        if (this.args.isCopyMove) {
          this.isExpanded = this.args.initialExpand;
        }
      }
    }
  }

  @computed('args.selectedPath', 'folder.path')
  get selectedPathChanged() {
    if (this.args.selectedPath === this.folder.get('path')) {
      console.info('selectedPathChanged', this.args.selectedPath);
    }
  }

  @action
  handleOnClickRenameNode() {
    this.isContextMenuOpened = false;
    this.modalManager.open(RENAME_NODE_MODAL_NAME, {
      node: this.args.currentSelectedNode,
    });
  }

  @action
  handleOnClickDelete() {
    this.isContextMenuOpened = false;
    this.modalManager.open(this.deleteFileModalName);
  }

  @action
  handleContextMenuTreeItemFolder(node) {
    if (this.args.onContextMenu) {
      this.args.onContextMenu(node);
    }
  }

  @action
  handleWillDestroy() {
    const treeItems = this.commonService.treeItemsDocuments;
    treeItems.removeObject(this);
    this.store.adapterFor('file').unsubscribe(this);
  }

  @action
  async loadingFolder() {
    if (!this.folder || this.folder.get('space.id') !== this.space.get('id')) {
      this.isLoading = true;
      try {
        this.folder = await DS.PromiseObject.create({
          promise: this.store.queryRecord('file', {
            spaceId: this.space.get('id'),
            revision: this.workspaceRevision,
            path: '/',
            adapterOptions: { subscribe: this },
          }),
        });
      } catch (error) {
        console.error(
          `Une erreur s'est produite lors du chargement du dossier : ${ error }`,
        );
      } finally {
        this.isLoading = false;
      }
    }
  }

  @action
  async toggle() {
    // await this.loadingFolder();
    this.isExpanded = !this.isExpanded;
  }

  @action
  async select() {
    if (this.isSelectedItem) {
      this.isExpanded = !this.isExpanded;
      // this.args.setSelectedSpace(this.space);
    } else {
      if (this.args.trackingIdPath) {
        this.router.transitionTo('my-groups.group.documents', {
          queryParams: {
            path: this.folder.get('path'),
            trackingId: '',
          },
        });
      } else {
        this.args.setSelectedPath(this.folder.get('path'));
        this.commonService.destinationNode = this.folder.get('path');
      }

      this.isExpanded = true;
    }
  }

  @action
  moveObject(element, event) {
    let source;
    if (this.args.weAreInPast) {
      return;
    }

    this.args.showDragCount(false);
    const member = this.space.members.findBy('email', this.account.userEmail);

    if (member.get('role') === libcryptobox.Role.Auditor) {
      this.args.dropInProgress = false;
      const message = this.intl.t('toasts.permissionError.message');
      const title = this.intl.t('toasts.permissionError.title');
      return this.notify.error(message, {
        title: title,
        icon: 'warning',
      });
    }

    const nodes = this.args.nodeToDrag;
    if (!isEmpty(nodes)) {
      source = nodes.map(
        (file) => file.get('space.id') + ':' + file.get('path'),
      );
    } else {
      const path = event.dataTransfer.getData('customDragDataPath');
      source = [this.selectedSpace.get('id') + ':' + path];
    }

    const target =
      this.selectedSpace.get('id') + ':' + event.customDropData.get('path');
    const promise = tBoxClient.file.move(source, target);

    this.dropInProgress = true;
    return promise
      .then(() => {
        this.dropInProgress = false;
        const countNodes = source.length;
        const message = this.intl.t('documents.actions.dragFile.success', {
          count: countNodes,
        });
        this.notify.info(message, {
          title: this.intl.t('documents.actions.dragFile.title'),
          icon: 'file',
        });
      })
      .catch((error) => {
        this.dropInProgress = false;
        this.notify.error(error.message, {
          title: this.intl.t('errors.occur'),
          icon: 'warning',
        });
        this.loadingModifyFile = false;
        if (this.args.notifyAfterCall) {
          this.args.notifyAfterCall('error', 'move');
        }
      });
  }
}
