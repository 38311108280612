import Component from "@glimmer/component";
import { action } from "@ember/object";
import { inject as service } from "@ember/service";
import { LICENCES_VALIDATION_MODAL_NAME } from "../../../utils/enums";

export default class LicencesValidationModalComponent extends Component {
  name = LICENCES_VALIDATION_MODAL_NAME;

  @service modalManager;

  close() {
    if (this.modalManager.isModalOpen(LICENCES_VALIDATION_MODAL_NAME)) {
      this.modalManager.close(LICENCES_VALIDATION_MODAL_NAME);
    }
  }

  @action
  onReject() {
    const { onReject } = this.args;

    if (onReject) {
      onReject();
    }

    this.close();
  }

  @action
  onValidate() {
    const { onValidate } = this.args;

    this.close();
    onValidate();
  }
}
