// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr, hasMany } from '@ember-data/model';

// The file synchronization settings.
// See SyncSettingsCollection.
export default class extends Model {
  // The synchronization mode. Default to SyncModeOff.
  // @type SyncMode
  @attr mode;
  
  // The location of the Cryptobox/ synchronization folder. Default to the current working directory. If set, must exist and be a directory.
  // @type string
  @attr path;
  
  // Whether the user agent should watch and synchronize automatically the changes in SyncSettings's Path.
  // @type boolean
  @attr watch;
  
  // The spaces to synchronize when SyncSettings's Mode is SyncModeCustom.
  // @type Array<string>
  @attr spaceIds;
  
  @hasMany('space', { async: true, inverse: null }) spaces;
}

