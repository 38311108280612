// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr, belongsTo } from '@ember-data/model';

// Preadded space member.
export default class extends Model {
  // Request status.
  // @type PreaddedStatus
  @attr status;
  
  // Requested space.
  // @type string
  @attr spaceId;
  
  // User email.
  // @type string
  @attr email;
  
  // Requested role if the status is PreaddedStatusInvited or PreaddedStatusRegistered. Actual role if the status is PreaddedStatusAdded.
  // @type Role
  @attr role;
  
  // Related invite.
  // @type string
  @attr inviteId;
  
  @belongsTo('space', { async: true, inverse: null }) space;
  @belongsTo('invite', { async: true, inverse: null }) invite;
}

