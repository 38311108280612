import { tBoxClient } from 'client/initializers/init-toolbox';
import BaseAdapter from 'client/adapters/application';

export default class Notification extends BaseAdapter {
  updateRecord(_store, _type, snapshot) {
    return tBoxClient.notification.setState(snapshot.id, snapshot.attr('state'))
      .catch(() => snapshot.record.rollbackAttributes());
  }

  deleteRecord(_store, _type, snapshot) {
    return tBoxClient.notification.delete(snapshot.id);
  }
}
