import Component from "@glimmer/component";
import { inject as service } from '@ember/service';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { tracked } from "@glimmer/tracking";
import { action, computed } from "@ember/object";


export default class FileListComponent extends Component {

    @service('transfers') transferManager;

    @service intl;

    @tracked highlight;


    get fileAlreadyExistErrorCode() {
      return libcryptobox.ErrorCode.InvalidParameter;
    }

    @computed('transferManager.active.length', 'transferManager.completed.length')
    get fileNumberMessage() {
      const active = this.transferManager.active;
      if (active.length > 0) {
        return this.intl.t('depositBoxForm.addFileUploadingMessage', {value:active.length});
      } else {
        return this.intl.t('depositBoxForm.fileUploaded', {value:this.transferManager.completed.length});
      }
    }

    get allTransferSize() {
      const allActiveSize = this.transferManager.active.map(item => item.size).reduce((previous, current) => {
        return previous + current;
      }, 0);
      const allCompletedSize = this.transferManager.completed.map(item => item.size).reduce((previous, current) => {
        return previous + current;
      }, 0);

      const allTransferSize = allActiveSize + allCompletedSize
      return allTransferSize ? " (" + this.bytesToSize(allTransferSize) + ")" : "";
    }

    @computed('transferManager.active.length', 'transferManager.completed.length')
    get showRemainingTime() {
      return this.transferManager.active.length > 0;
    }

    @action
    async onClickDeleteTransfer(transfer) {
      try {
        await transfer.cancelTransfer();
      } finally {
        const active = this.transferManager.active;
        const failed = this.transferManager.failed;
        const completed = this.transferManager.completed;
        active.removeObject(transfer);
        failed.removeObject(transfer);
        completed.removeObject(transfer);
        const failedTransferToRemove = failed.filter((item) => item.error.code === libcryptobox.ErrorCode.InvalidParameter);
        failedTransferToRemove.forEach(element => failed.removeObject(element));
        const allTransferLenght = active.length + failed.length + completed.length;
        if (allTransferLenght === 0) {
          this.args.onAllTransferDeleted();
        }
      }
    }

    @action
    bytesToSize(bytes) {
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 B';
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1000)));
        return Math.round(bytes / Math.pow(1000, i), 2) + ' ' + sizes[i];
    }

    @action
    onClickAddFile() {
      this.highlight = false;
      this.args.onClickAddFile();
    }

    @action
    onMouseOver() {
      this.highlight = true;
    }

    @action
    onMouseOut() {
      this.highlight = false;
    }
}

