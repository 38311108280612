import Service from '@ember/service';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { tracked } from '@glimmer/tracking';

export default class DepositBox extends Service {
  @tracked canAccessDepositBox = false;

  @service store;
  @service account;


  get depositBoxSettings() {
    return this.account.serverSettings.depositBox || {
      codePolicy: 'none',
    };
  }
  @action
  async checkCanAccessDepositBox() {
    return (this.canAccessDepositBox = await tBoxClient.depositBox.canAccess());
  }

  @action
  async getDepositBoxId(boxId) {
    return this.store.queryRecord('deposit-box', { id: boxId });
  }

  @action
  async createDepositBox(box) {
    const { name, description, note, code } = box;
    const newRecord = this.store.createRecord('deposit-box', {
      name,
      description,
      note,
    });
    return newRecord.save({
      adapterOptions: { code },
    });
  }

  @action
  updateDepositBox(oldBox, newBox) {
    const { name, description, note } = newBox;
    oldBox.setProperties({
      name,
      description,
      note,
    });
    return oldBox.save();
  }

  @action
  getAllDepositBoxes() {
    this.store.findAll('deposit-box-list-item', {
      reload: true,
      adapterOptions: { subscribe: this },
    });
    return this.store.peekAll('deposit-box-list-item');
  }

  @action
  async deleteDepositBox(id) {
    return this.store
      .adapterFor('deposit-box')
      .deleteRecord(this.store, 'deposit-box', { id });
  }

  @action
  changeStateDepositBox(id, state) {
    this.store
      .adapterFor('deposit-box')
      .updateState(this.store, 'deposit-box', {
        id,
        state,
      });
  }
}
