import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";

const CHECKBOX_LIST = [
  { name: "saule", label: "Saule", checked: false },
  { name: "peuplier", label: "Peuplier", checked: true },
  { name: "erable", label: "Érable", checked: false },
];

export default class DesignSystemCheckboxComponent extends Component {
  @tracked areYouIn = false;
  @tracked list = CHECKBOX_LIST;

  @action
  onChange(event) {
    const { name, checked } = event;
    this[name] = checked;
  }

  @action
  onChangeGroup(newList) {
    this.list = newList;
  }
}
