// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr, hasMany } from '@ember-data/model';

// A user device.
// See DeviceCollection.
export default class extends Model {
  // Name.
  // @type string
  @attr name;
  
  // Model.
  // @type string
  @attr model;
  
  // System with version.
  // @type string
  @attr system;
  
  // Application version.
  // @type string
  @attr appVersion;
  
  // Application name. "Cryptobox" if empty.
  // @type string
  @attr appName;
  
  // State.
  // @type DeviceState
  @attr state;
  
  // Last user agent.
  // @type string
  @attr lastUserAgent;
  
  // Last access date.
  // @type string
  @attr lastAccessDate;
  
  // Device certificate.
  // @type * - Call `tBoxClient.decodeUint8Array()` to read the value. See Uint8Array in the getting started guide.
  @attr certificate;
  
  // Date of creation. ISO 8601.
  // @type string
  @attr date;
  
  @hasMany('security-level', { async: false, inverse: null }) securityLevels;
}

