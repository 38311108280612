// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr } from '@ember-data/model';

// The login status.
// See LoginStatusCollection.
export default class extends Model {
  // Whether the user agent is logged in.
  // @type boolean
  @attr isLoggedIn;
  
  // Whether the user agent has been disconnected by the server when LoginStatusRecord's IsLoggedIn is false.
  // @type boolean
  @attr disconnectedByServer;
  
  // The logout reason when LoginStatusRecord's DisconnectedByServer is true. Error's Code is always ErrorCodeLoggedOut.
  // @type Error
  @attr disconnectionError;
}

