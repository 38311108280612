import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class Overview extends Route {
  @service account;
  @service intl;
  @service authService;
  @service store;
  @service router;

  beforeModel() {
    this.authService.loginIfNotAuthenticated();
  }

  model() {
    const space = this.modelFor('my-groups.group');
    const spaceId = space.id;

    if (this.account.isInPersonalSpace) {
      this.router.transitionTo('my-groups.group.documents', spaceId);
    }

    this.store.queryRecord('file-download-subscription', {
      spaceId,
      adapterOptions: { subscribe: this },
    });
    return this.store.findRecord('space', spaceId, {
      include: 'notifications',
      adapterOptions: { subscribe: this },
    });
  }

  afterModel(resolvedModel) {
    let spaceName = resolvedModel.get('name');
    document.title = `Cryptobox - ${spaceName} - ${this.intl.t('group.navigation.overview')}`;
  }

  deactivate() {
    this.store.adapterFor('space').unsubscribe(this);
    this.store.adapterFor('file-download-subscription').unsubscribe(this);
  }
}
