import { tBoxClient } from 'client/initializers/init-toolbox';
import { saveAs } from 'file-saver';

class PrivateFileWriter {
  static async initialize() {
    // Keep a directory handle and a list of files ready to remove in onbeforeupload.
    this.directory = navigator.storage && navigator.storage.getDirectory && await navigator.storage.getDirectory();
    this.entries = [];
  }

  static async removeAll() {
    // Remove the known entries immediately.
    // We may be in 'unload' and cannot await.
    for (let name of this.entries) {
      this.directory.removeEntry(name).catch((error) => console.error(error));
    }

    // Remove the remaining files.
    if (!this.directory) {
      return;
    }
    for await (let [_, file] of this.directory.entries()) {
      try {
        await this.directory.removeEntry(file.name);
      } catch (error) {
        console.error(error);
      }
    }
  }

  constructor(name) {
    this.name = name;
    this.file = PrivateFileWriter.directory.getFileHandle(Math.random().toString(), { create: true });
    this.stream = this.file.then((file) => {
      return file.createWritable({ keepExistingData: false });
    });
    this.file.then((file) => PrivateFileWriter.entries.push(file.name));
  }

  async truncate(size) {
    let stream = await this.stream;
    await stream.truncate(size);
  }

  write(chunk) {
    // To prevent ESET and other antiviruses to unzip the file each time it is written we are zeroing the first chunk of data (where the "magic number" lies).
    // NB.
    // - The actual content is written in close().
    // - For simplicity sake, this is applied indiscriminately (the file may not be a zip archive).
    // See ECB-979.
    if (!this.firstChunk) {
      this.firstChunk = tBoxClient.decodeUint8Array(chunk);
      return this.stream.then((stream) => stream.write(new Uint8Array(this.firstChunk.byteLength)));
    }
    return this.stream.then((stream) => stream.write(tBoxClient.decodeUint8Array(chunk)));
  }

  async close(error) {
    if (this.closed) {
      return;
    }
    let stream = await this.stream;
    if (this.firstChunk && !error) {
      await stream.write({ type: 'write', position: 0, data: this.firstChunk });
    }
    await stream.close();
    if (error) {
      let file = await this.file;
      await PrivateFileWriter.directory.removeEntry(file.name);
    } else {
      let file = await this.file;
      setTimeout(() => PrivateFileWriter.directory.removeEntry(file.name), 4E4); // 40s
      let blob = await file.getFile();
      saveAs(blob, this.name);
    }
    this.closed = true;
  }
}

PrivateFileWriter.initialize();

export default PrivateFileWriter;
