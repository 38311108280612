// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr } from '@ember-data/model';

// The messaging connection status.
// See MessagingStatusCollection.
export default class extends Model {
  // Is the web socket connected?
  // @type boolean
  @attr isConnected;
}

