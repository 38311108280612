import _ from 'lodash';
import { DateTime } from 'luxon';

/**
 * groupDataByYearAndByDates
 * Groups items from an array by year and by dates.
 *
 * @param {Array<Object>} data - The array of file activities..
 * @returns {Array<Object>} An array of file activities grouped by year and by dates.
 */
export function groupDataByYearAndByDates(data) {
  const years = data.map((item) => new Date(item.date).getFullYear());
  const minYear = Math.min(...years);
  const maxYear = Math.max(...years);

  const allYears = Array.from(
    { length: maxYear - minYear + 1 },
    (_, index) => minYear + index,
  );

  const groupedData = _.chain(data)
    .groupBy((item) => item.date.substring(0, 4))
    .mapValues((yearData) =>
      _.groupBy(yearData, (item) =>
        DateTime.fromISO(item.date).toISODate(),
      ),
    )
    .map((value, key) => ({ year: key, days: value }))
    .value();

  allYears.forEach((year) => {
    const foundYear = groupedData.find((item) => item.year === String(year));
    if (!foundYear) {
      groupedData.push({ year: String(year), days: {} });
    }
  });

  return groupedData.sortBy('year').reverse();
}
