import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import config from './config/environment';

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
  ready = () => {
    setTimeout(() => {
      const splash = document.querySelector('#splash_screen');
      splash.classList.add('fade-out');
      splash.addEventListener(
        'transitionend',
        () => {
          splash.style.display = 'none';
        },
        false,
      );
    }, 1000);
  };
}

loadInitializers(App, config.modulePrefix);
