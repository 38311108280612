import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { DateTime } from 'luxon';

export default class BrowserError extends Controller {
  @computed('')
  get backgroundImage() {
    return `background-image:url("/static/customizations/images/bg1.jpg?${ DateTime.now().toFormat('MMDDHHSS') }")`;
  }
}
