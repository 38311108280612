import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';

export default Helper.extend({
  intl: service(),
  compute(state) {
    switch (state[0]){
      case 'email_validation_pending':
        return this.intl.t('admin.users.state.mailPending');
      case 'blocked':
        return this.intl.t('admin.users.state.blocked');
      case 'deleted':
        return this.intl.t('admin.users.state.deleted');
      case 'active':
        return this.intl.t('admin.users.state.active');
      case 'admin_validation_pending':
        return this.intl.t('admin.users.state.adminPending');
    }
  },
});
