import Component from "@glimmer/component";
import { inject as service } from "@ember/service";
import { action } from "@ember/object";
import getIconFromNotificationAction from "../../utils/getIconFromNotificationAction";
/* global libcryptobox */

export default class NotificationComponent extends Component {
  @service intl;
  @service router;

  get icon() {
    const { notification } = this.args;
    const { action, contentType } = notification;

    return getIconFromNotificationAction(action, contentType);
  }

  get notificationContent() {
    const { notification } = this.args;

    if (notification.action === "invitee_registered") {
      return this.intl.t("preaddModal.validate.notification", {
        fullname: notification.user.fullName,
      });
    }
    return notification.text;
  }

  get isUnread() {
    const { notification } = this.args;

    return notification.state === libcryptobox.NotificationState.Unread;
  }

  @action
  onDelete() {
    this.args.notification.destroyRecord();
  }

  @action
  onClickValidateLink(event) {
    event.stopPropagation();
    event.preventDefault();

    this.router.transitionTo(
      "validate-preadd",
      this.args.notification.user.email
    );
  }
}
