import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
/* global libcryptobox */

export default class DeviceItemComponent extends Component {
  @tracked showFingerPrint = false;

  @service modalManager;
  @service connection;

  get userSecurityLevels() {
    return this.args.device.securityLevels.toArray().map((sl) => sl);
  }

  get isCurrentDevice() {
    return this.currentDevice?.id === this.args.device.id;
  }

  get isUnlocked() {
    return this.args.device.state === libcryptobox.DeviceState.Unlocked;
  }

  get isLocked() {
    return this.args.device.state === libcryptobox.DeviceState.Locked;
  }

  get isRevoked() {
    return this.args.device.state === libcryptobox.DeviceState.Revoked;
  }

  get revokeModalName() {
    return `revokeModal-${this.args.device.id}`;
  }

  get deviceIcon() {
    const deviceSystem = this.args.device.get('system').toLowerCase();

    if (deviceSystem.indexOf('win') !== -1) {
      return 'logo-windows';
    }
    if (deviceSystem.indexOf('android') !== -1) {
      return 'logo-android';
    }
    if (deviceSystem.indexOf('ios') !== -1) {
      return 'logo-apple';
    }

    return 'device';
  }

  @action
  blockDevice() {
    this.args.device.set('state', libcryptobox.DeviceState.Locked);
    this.args.device.save();
  }

  @action
  unblockDevice() {
    this.args.device.set('state', libcryptobox.DeviceState.Unlocked);
    this.args.device.save();
  }

  @action
  revokeDevice() {
    this.modalManager.open(this.revokeModalName);
  }

  @action
  confirmRevoke() {
    this.args.device.destroyRecord();
    this.modalManager.close(this.revokeModalName);
  }

  @action
  toggleFingerPrint() {
    this.showFingerPrint = true;
  }
}
