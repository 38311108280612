// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr, belongsTo } from '@ember-data/model';

// An entry in a file access log.
// See FileAccessLogCollection.
export default class extends Model {
  // Date.
  // @type string
  @attr date;
  
  // Server.
  // @type string
  @attr cssName;
  
  // Space's identifier.
  // @type string
  @attr spaceId;
  
  // File's content identifier.
  // @type string
  @attr fileId;
  
  // User's email.
  // @type string
  @attr userEmail;
  
  // User's certificate.
  // @type * - Call `tBoxClient.decodeUint8Array()` to read the value. See Uint8Array in the getting started guide.
  @attr userCertificateHash;
  
  @belongsTo('space', { async: true, inverse: null }) space;
}

