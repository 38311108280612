import Helper from '@ember/component/helper';
import { DateTime } from 'luxon';
import { inject as service } from '@ember/service';

export default class FormatDateHelper extends Helper {
  @service intl;

  compute(
    [date],
    {
      format = false,
      locale = navigator.language || 'en-US',
      useMediumFormat = false,
      includeToday = false,
    },
  ) {
    const _locale = Array.isArray(locale) ? locale[0] : locale;
    const _date = DateTime.fromISO(date).setLocale(_locale);

    const today = DateTime.now().setLocale(_locale).startOf('day');

    if (includeToday && _date.hasSame(today, 'day')) {
      return this.intl.t('today');
    }

    if (useMediumFormat) {
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23',
      };
      if (_locale.startsWith('fr')) {
        return _date.toLocaleString(options, { locale: 'fr' });
      } else {
        return _date.toLocaleString(options, { locale: 'en' });
      }
    }
    if (format) {
      return _date.toFormat(format);
    } else {
      const dateFormat = this.intl.t('luxonFormat');
      return _date.toFormat(dateFormat);
    }
  }
}
