import Component from "@glimmer/component";
import { tracked } from '@glimmer/tracking';
import {DateTime, Duration} from "luxon";
export default class RetentionMessage extends Component {

  @tracked deletionDates;
  @tracked dataReady = false;

  constructor() {
    super(...arguments);
    this.getDeletionDates();
  }

  getDeletionDates() {
    if (!this.args.space) return;

    this.args.space.deletionDates.then(dates => {
      this.deletionDates = dates;
      this.dataReady = true;
    });
  }

  get remaining() {
    return this.durationInDays >= 1;
  }

  get imminent() {
    return this.durationInDays < 1 && Math.round(this.durationInDays) >= 0 && this.durationInDays != null;
  }

  get noDuration() {
    return this.durationInDays == null;
  }

  get durationInDays() {
    if (!this.deletionDates) return null;

    const { terminalDeletionDate } = this.deletionDates;

    if (!terminalDeletionDate) return null;

    const start = DateTime.utc().valueOf();
    const end = DateTime.fromISO(terminalDeletionDate).toMillis();
    const duration = Duration.fromMillis(end - start);

    return duration.as('days');
  }
}

