import { action } from '@ember/object';
import Controller from '@ember/controller';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class NewPasswordController extends Controller {
  @tracked pwdUpdated = false;
  @tracked inProcess = false;

  @tracked newPwdIsvalid = true;
  @tracked confirmNewPwdIsValid = true;
  @tracked newPwdIsStrongEnough = true;

  @tracked otpError = null;
  @tracked otpCode = null;

  @tracked errors = 0;

  @tracked isButtonDisabled = true;

  is2faDone = 0;

  @service secondFactorAuthenticator;
  @service connection;
  @service notify;
  @service intl;
  @service router;

  close2fa() {
    this.is2faDone = this.is2faDone + 1;
  }

  @action
  handleChangePassword() {
    if (this.inProcess || this.isButtonDisabled) {
      return false;
    }
    if (this.errors) {
      return;
    }
    const server = this.connection.serverAddress;
    const rdvUserId = this.model.rdvUserId;
    const newPassword = this.newPassword;
    this.inProcess = true;

    tBoxClient.recovery
      .setNewPassword(server, rdvUserId, newPassword)
      .then(() => {
        this.close2fa();
        this.pwdUpdated = true;
        this.inProcess = false;
        setTimeout(() => {
          this.router.transitionTo('login');
          this.newPassword = '';
        }, 5000);
      })
      .catch(() => {
        this.close2fa();
        this.inProcess = false;
      });
  }

  @action
  checkIfValidForm(value) {
    this.isButtonDisabled = value;
  }

  @action
  cancel() {
    this.close2fa();
    this.newPassword = '';
    this.router.transitionTo('index');
  }

  @action
  updateInput(value) {
    this.newPassword = value;
  }

  @action
  cancelOtp() {
    this.secondFactorAuthenticator.cancelOtp();
    this.otpCode = null;
    this.otpError = null;
  }

  @action
  async resendOtp() {
    try {
      await this.secondFactorAuthenticator.resendOtp();
      this.notify.success(this.intl.t('otp.newCodeSent'));
    } catch (error) {
      this.otpError = error.message;
    }
  }

  @action
  async validateOtp() {
    this.otpError = null;
    try {
      await this.secondFactorAuthenticator.setOtp(this.otpCode);
      this.otpCode = null;
    } catch (error) {
      this.otpError = error.message;
    }
  }
}
