import { tracked } from '@glimmer/tracking';
import Service from '@ember/service';
import { action } from '@ember/object';

export default class CommonService extends Service {
  @tracked selectedSpace = null;
  @tracked workspaceRevisionObject = null;
  @tracked workspaceRevision = 0;
  @tracked popoverRegistry = null;
  @tracked destinationNode = null;
  @tracked creationGroupRegistry = false;
  @tracked treeItemsDocuments = [];
  @tracked modalIsOpen;
  @tracked isLoading;
  @tracked closeContextMenu;
  @tracked isOpenSideNav = false;

  closePopover() {
    this.set('popoverRegistry.isOpen', false);
    this.set('popoverRegistry', null);
  }

  @action
  handleOpenSideNav() {
    this.isOpenSideNav = true;
  }

  @action
  toggleSideNav() {
    this.isOpenSideNav = !this.isOpenSideNav;
  }

  @action
  handleCloseSideNav() {
    this.isOpenSideNav = false;
  }

}
