import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
export default class PanelContainer extends Component {
  @tracked accordion = true;
  @tracked openedPanel = null;

  @action
  setOpenPanel(value) {
    return (this.openedPanel = value);
  }
}
