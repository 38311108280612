import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class BreadCrumb extends Component {
  @tracked currentFolderName = '';

  @service account;
  @service intl;

  get ancestors() {
    const path = this.args.trackingIdPath || this.args.path;

    if (path === '/') {
      this.currentFolderName = this.getCurrentFolderName();
      return [];
    }
    let ancestors = [];

    const splittedPath = path.split('/');
    const spaceName = this.args.spaceName;
    let currentParsedPath = '';

    splittedPath.forEach((folder, index) => {
      if (folder) {
        currentParsedPath += `/${folder}`;
        ancestors = [
          ...ancestors,
          ...this.buildAncestor(
            folder,
            currentParsedPath,
            index,
            splittedPath.length,
          ),
        ];
      } else {
        ancestors = [
          ...ancestors,
          ...this.handleEmptyFolderName(index, spaceName, splittedPath),
        ];
      }
    });

    return ancestors;
  }

  getCurrentFolderName() {
    if (this.account.isInPersonalSpace) {
      return this.intl.t('my.documents');
    } else {
      return this.args.spaceName;
    }
  }

  buildAncestor(folderName, path, index, totalLength) {
    let ancestors = [];
    if (index < totalLength - 1) {
      if (index > 1 && index + 1 === totalLength - 1) {
        ancestors.push({
          name: '...',
          path,
        });
      }
      if (index + 1 >= totalLength - 1) {
        ancestors.push({
          name: folderName,
          path,
        });
      }
    } else {
      this.currentFolderName = folderName;
    }
    return ancestors;
  }

  handleEmptyFolderName(index, spaceName, path) {
    if (index + 1 === path.length) {
      this.currentFolderName = this.getCurrentFolderName();
      return [];
    } else {
      return [
        {
          name: index === 0 ? spaceName : this.intl.t('my.documents'),
          path: '/',
        },
      ];
    }
  }
}
