import Component from "@glimmer/component";
import { action } from '@ember/object';
import { inject as service } from "@ember/service";
import { tracked } from "@glimmer/tracking";


export default class OtpValidationModalComponent extends Component {

  @service secondFactorAuthenticator;
  @service intl;
  @service modalManager;
  @service notify;

  @tracked otpCode;
  @tracked otpError;
  @tracked isOtpValidateInProgress = false;


  constructor() {
    super(...arguments);
  }

  @action
  cancelOtp() {
    this.secondFactorAuthenticator.cancelOtp();
    this.otpCode  = null;
    this.otpError = null;
    this.isOtpValidateInProgress = false;
    this.modalManager.close('otp');
  }

  @action
  async validateOtp() {
    this.otpError = null;
    this.isOtpValidateInProgress = true;
    try {
      await this.secondFactorAuthenticator.setOtp(this.otpCode);
      this.otpCode = null;
      this.modalManager.close('otp');
    } catch (error) {
      this.otpError = error.message;
    } finally {
      this.isOtpValidateInProgress = false;
    }
  }

  @action
  async resendOtp() {
    try {
      await this.secondFactorAuthenticator.resendOtp();
      this.notify.success(this.intl.t("otp.newCodeSent"));
    } catch (error) {
      this.otpError = error.message;
    }
  }

}
