import Service from '@ember/service';

export default class ManageStorageService extends Service {
  getSessionStorageItem(item, listType = '') {
    const key = listType ? `${item}-${listType}` : item;
    return JSON.parse(sessionStorage.getItem(key));
  }

  setSessionStorageItem(item, value, listType = '') {
    const key = listType ? `${item}-${listType}` : item;
    sessionStorage.setItem(key, JSON.stringify(value));
  }
}
