import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class={{@class}}>\n{{inline-svg \"info\"}}\n  <div>\n    <p class=\"sub-header-text {{@classSubHeader1}}\">{{t @subheader1 appname=(t \"appname\") htmlSafe=true}}</p>\n    {{#if @subheader2}}\n      <p class=\"sub-header-text {{@classSubHeader2}}\">{{t @subheader2 appname=(t \"appname\") htmlSafe=true}}</p>\n    {{/if}}\n  </div>\n</div>\n", {"contents":"<div class={{@class}}>\n{{inline-svg \"info\"}}\n  <div>\n    <p class=\"sub-header-text {{@classSubHeader1}}\">{{t @subheader1 appname=(t \"appname\") htmlSafe=true}}</p>\n    {{#if @subheader2}}\n      <p class=\"sub-header-text {{@classSubHeader2}}\">{{t @subheader2 appname=(t \"appname\") htmlSafe=true}}</p>\n    {{/if}}\n  </div>\n</div>\n","moduleName":"client/components/info-tips/info-tips.hbs","parseOptions":{"srcName":"client/components/info-tips/info-tips.hbs"}});
import Component from '@glimmer/component';

export default class InfoTipsInfoTipsComponent extends Component {

  constructor() {
    super(...arguments);
  }
}
