import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
import getVariables from '../utils/getVariables';
import { action } from '@ember/object';

const COLOR_BADGE_DEFAULT = '#4A4B50';

class PlatformCustomization {
  @tracked url;
  @tracked isEnabled = false;
  @tracked _badgeColor;
  @tracked _badgeLabel;
  @tracked _description;
  @tracked language;

  constructor(url, data, language) {
    this.url = url;
    this.setData(data, language);
  }

  setData(data, language) {
    if (data) {
      this.isEnabled = true;
      this._badgeColor = data.badgeColor;
      this._badgeLabel = data.badgeLabel;
      this._description = data.description;
      this.language = language;
    } else {
      this.isEnabled = false;
      this._badgeColor = null;
      this._badgeLabel = null;
      this._description = null;
      this.language = null;
    }

    return this;
  }

  get badgeLabel() {
    if (!this.isEnabled) return null;
    return decodeURIComponent(this._badgeLabel[this.language]);
  }

  get description() {
    if (!this.isEnabled) return null;
    return decodeURIComponent(this._description[this.language]);
  }

  get badgeColor() {
    if (!this.isEnabled) return null;
    const isValidColor = CSS.supports('color', this._badgeColor);
    return isValidColor ? this._badgeColor : COLOR_BADGE_DEFAULT;
  }
}

export default class PlatformCustomizationService extends Service {
  @service connection;
  @service intl;
  @service store;
  @service account;

  @tracked configs = A([]);
  @tracked serverSettings = {};

  getConfigFromUrl(serverUrl, callback) {
    if (!serverUrl && !this.account.isLoggedIn) {
      callback(null);
      return;
    }

    const options = serverUrl ? { url: serverUrl } : {};
    return this.store
      .queryRecord('server-settings', options)
      .then((result) => {
        let config;
        if (result && result.platformCustomization) {
          config = this.setConfig(serverUrl, result.platformCustomization);
        } else {
          config = this.setConfig(serverUrl, null);
        }
        callback(config);
      })
      .catch(() => {
        const config = this.setConfig(serverUrl, null);
        callback(config);
      });
  }

  get language() {
    return this.intl.locale[0] === 'fr-fr' ? 'fr' : 'en';
  }

  setConfig(url, data) {
    const isKnownUrl = this.configs.find((conf) => conf.url === url);
    let config;
    if (isKnownUrl) {
      config = isKnownUrl.setData(data, this.language);
    } else {
      config = new PlatformCustomization(url, data, this.language);
      this.configs.push(config);
    }
    return config;
  }

  getConfig(url) {
    return this.configs.find((conf) => conf.url === url);
  }

  @action
  async getPlatformSettings() {
    this.serverSettings = await getVariables();
  }

  async getHideAppStoreLinks(callBack) {
    const { hide_app_store_links = false } = this.serverSettings;
    callBack(hide_app_store_links);
  }
}
