/* global ipc */
import { Base64 } from "js-base64";

export default class ElectronIPCTransport {
  connect() {
    (async () => {
      if (!this.id) {
        this.id = await ipc.rpc.new(this);
      }
      ipc.rpc.connect(this.id);
    })();
  }

  close() {
    ipc.rpc.close(this.id);
    this.id = null;
  }

  send(message) {
    ipc.rpc.send(this.id, message);
  }

  encodeUint8Array(value) {
    return Base64.fromUint8Array(value);
  }

  decodeUint8Array(value) {
    return Base64.toUint8Array(value);
  }
}
