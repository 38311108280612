import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
/* global libcryptobox */

export default class MyGroups extends Route {
  @tracked isInPersonalSpace = null;

  @service account;
  @service modalManager;
  @service store;
  @service intl;
  @service router;
  @service authService;

  beforeModel() {
    this.authService.loginIfNotAuthenticated();
  }
  model(params) {
    const account = this.account,
      personalSpaceId = account.get('personalSpaceId'),
      isInPersonalSpace = params.space_id === personalSpaceId,
      userHavePersonalSpace = account.get('status.personalSpace');

    if (isInPersonalSpace && !userHavePersonalSpace) {
      this.router.transitionTo('my-groups');
      return false;
    }

    return this.store.findRecord('space', params.space_id, {
      reload: true,
      include: 'fileSubscription,members',
      adapterOptions: { subscribe: this },
    });
  }

  @action
  error(reason) {
    this.router.transitionTo('my-groups');
    if (
      reason.code === libcryptobox.ErrorCode.NotFound ||
      reason.code === libcryptobox.ErrorCode.Forbidden
    ) {
      this.modalManager.open('space-not-found');
    }
  }

  deactivate() {
    const account = this.account;
    account.set('isInPersonalSpace', false);
    this.store.adapterFor('space').unsubscribe(this);
  }
}
