import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

export default class extends Helper {

  @service intl;

  compute([time, useFullFormat]) {
    time = Math.round(time / 1000); //convert ms to s
    if(window.isNaN(time) || !isFinite(time)){
      return '';
    }
    let timeRemainingSec = parseInt(time, 10);
    let hoursRemaining   = Math.floor(timeRemainingSec / 3600);
    let minutesRemaining = Math.floor((timeRemainingSec - (hoursRemaining * 3600)) / 60);
    let secondsRemaining = timeRemainingSec - (hoursRemaining * 3600) - (minutesRemaining * 60);
    let formatTimeRemaining='';
    if(hoursRemaining>0){
      formatTimeRemaining+=hoursRemaining+'h ';
    }
    if(minutesRemaining>0){
      if(formatTimeRemaining!=='' && minutesRemaining<10){
        minutesRemaining = '0'+minutesRemaining;
      }
      formatTimeRemaining+=minutesRemaining+'m ';
    }
    if(formatTimeRemaining!=='' && secondsRemaining<10){
      secondsRemaining= '0'+secondsRemaining;
    }
    const formatSeconds = useFullFormat ? ' ' + this.intl.t('time.fullSecond', { value: secondsRemaining }) : secondsRemaining+'s'
    formatTimeRemaining+=formatSeconds;
    return formatTimeRemaining;
  }
}
