import { observes } from '@ember-decorators/object';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { tBoxClient } from 'client/initializers/init-toolbox';

export default class Invite extends Route {
  @service account;
  @service intl;
  @service connection;
  @service browser;
  @service router;

  beforeModel() {
    if (!this.browser.isSupportedBrowser) {
      return this.router.transitionTo('browser-error');
    }

    if (this.account.isLoggedIn) {
      return this.router.transitionTo('my-groups');
    }
  }

  @observes('account.isLoggedIn')
  onLogin() {
    if (this.account.isLoggedIn) {
      if (this.requestedHash) {
        window.location.hash = this.requestedHash;
        this.set('requestedHash', '');
      } else {
        this.router.transitionTo('my-groups');
      }
    }
  }

  model() {
    let token = this.paramsFor('invite').invite_token;
    const server = this.connection.serverAddress;
    return tBoxClient.invite
      .getInfo(server, token)
      .then(function (value) {
        value.token = token;
        return value;
      })
      .catch((error) => {
        let key = 'inviteNotOpen';
        key = /invite_not_found*/g.test(error.details) ? 'inviteNotFound' : key;

        return {
          error: error,
          message: this.intl.t(`inviteUser.errors.${key}`),
        };
      });
  }

  setupController(controller, model) {
    controller.set('model', model);
    controller.set('showForm', true);
    controller.set('largeDisplay', true);
  }

  afterModel() {
    document.title = 'Cryptobox';
    let requestedRoute = this.paramsFor('invite').requestedHash;
    this.set('requestedHash', requestedRoute);
  }
}
