import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import getIconFromNotificationAction from '../../utils/getIconFromNotificationAction';

// CNUAGE-12954
// A notification may have a relationship to a space of which the user is no longer a member.
// Loading such a relationship, even to retrieve the space ID, will raise an error (not_a_space_member).
// To circumvent the issue, we use the attribute "spaceId" instead.

const REQUIRED_ACCESS_RIGHTS = [
  libcryptobox.SpaceAccessRight.Share,
  libcryptobox.SpaceAccessRight.AllSharings,
  libcryptobox.SpaceAccessRight.OnlyMySharings,
];


/* global libcryptobox */
export default class UiNotification extends Component {
  @service account;
  @service router;
  @service store;
  @service modalManager;

  get notification() {
    return this.args.notification;
  }

  get spaces() {
    return this.args.spaces;
  }

  @computed('notification.spaceId', 'spaces.[]')
  get isWorkspaceNotDeleted() {
    const spaceId = this.notification.get('spaceId');

    if (!spaceId) return null;

    return !!this.store.peekRecord({ type: 'space', id: spaceId });
  }

  @computed('notification.state')
  get isUnread() {
    return this.notification.state === 'unread';
  }

  @computed('notification.{action,depositBoxId,isUnknown,message,contentType}')
  get notifIcon() {
    const { notification } = this;
    const action = notification.action;
    const contentType = notification.contentType;

    if (this.notification.isUnknown) {
      return getIconFromNotificationAction('unknown');
    }
    if (action === 'max_code_attempts_reached') {
      const iconType = notification.depositBoxId
        ? 'deposit_box_max_code_attempts_reached'
        : 'share_max_code_attempts_reached';

      return getIconFromNotificationAction(iconType);
    }

    return getIconFromNotificationAction(action, contentType);
  }

  @computed('notification.{action,spaceId}', 'spaces.[]')
  get isClickable() {
    const spaceId = this.notification.get('spaceId');

    const hasSpaceId =
      spaceId && this.store.peekRecord({ type: 'space', id: spaceId });
    const hasClickableAction = [
      'invitee_registered',
      'deposit_box_populated',
    ].includes(this.notification.get('action'));

    return hasSpaceId || hasClickableAction;
  }

  @action
  click(event) {
    event.preventDefault();
    event.stopPropagation();

    const notification = this.notification;
    if (notification.isUnknown) {
      this.modalManager.open('about-modal');
    } else {
      const action = notification.action;

      switch (action) {
        case 'invitee_registered':
          this.goToValidatePread();
          break;
        case 'workspace_owner_delete':
          this.goToWorkspacesDeleted();
          break;
        case 'deposit_box_populated':
          this.goToDepositBox();
          break;
        case 'sharing_download':
          void this.checkRulesAndGoToWorkspaceDocument();
          break;
        case 'uninvite':
          break;
        case 'max_code_attempts_reached':
          if (notification.contentType === 'deposit_box') {
            this.goToDepositBox();
          } else {
            void this.checkRulesAndGoToWorkspaceDocument();
          }
          break;
        default:
          void this.goToWorkspaceDocument();
      }
    }

    this.notification.markAsRead();
  }

  @action
  removeNotif() {
    void this.notification.destroyRecord();
  }

  @action
  goToValidatePread() {
    this.router.transitionTo('validate-preadd', this.notification.user.email);
  }

  @action
  goToWorkspacesDeleted() {
    this.router.transitionTo('my-groups.deleted-spaces');
  }

  @action
  goToDepositBox() {
    this.router.transitionTo(
      'deposit-boxes.deposit-box',
      this.notification.get('depositBoxId'),
    );
  }

  @action
  goToDocument(spaceId, destPath) {
    return this.router.transitionTo('my-groups.group.documents', spaceId, {
      queryParams: {
        fileRevision: 0,
        path: destPath,
      },
    });
  }

  getDestinationPath(path) {
    const lastSlashIndex = path.lastIndexOf('/');
    return lastSlashIndex !== -1 ? path.substring(0, lastSlashIndex) : '/';
  }

  @action
  async checkRulesAndGoToWorkspaceDocument() {
    const accessRights = await this.notification.get('space.accessRights');

    if (
      !accessRights?.some((right) => REQUIRED_ACCESS_RIGHTS.includes(right))
    ) {
      return;
    }
    const spaceId = this.notification.spaceId;
    if (!spaceId) return;
    const space = this.store.peekRecord('space', spaceId);

    if (!space) return;

    const path = this.notification.path;

    if (!path && this.notification.contentType === 'sharing') {
      return this.router.transitionTo('my-groups.group.shares', spaceId);
    }
    const destPath = path ? this.getDestinationPath(path) : '/';

    this.goToDocument(spaceId, destPath);
  }

  @action
  async goToWorkspaceDocument() {
    const spaceId = this.notification.spaceId;
    if (!spaceId) return;
    const space = this.store.peekRecord('space', spaceId);

    if (!space) return;

    const path = this.notification.path;
    const destPath = path ? this.getDestinationPath(path) : '/';
    return this.goToDocument(spaceId, destPath);
  }
}
