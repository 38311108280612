import { tBoxClient } from 'client/initializers/init-toolbox';
import { saveAs } from 'file-saver';

class BlobFileWriter {
  constructor(filename, blobOptions) {
    this.filename = filename;
    this.blobOptions = blobOptions;
    this.chunkBlobs = [];
  }

  async write(chunk) {
    let blob = new Blob([tBoxClient.decodeUint8Array(chunk)], { type: 'application/octet-stream' });
    // CNUAGE-12274 Read back the first byte of the blob to assert that it was properly written.
    // This should fail on Chrome in private mode after 2Go worth of blobs have been created.
    await blob.slice(1).arrayBuffer();
    this.chunkBlobs.push(blob);
  }

  close(error) {
    if (error) {
      return;
    }
    saveAs(new Blob(this.chunkBlobs, this.blobOptions), this.filename);
  }
}

export default BlobFileWriter;
