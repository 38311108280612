import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { notEmpty } from '@ember/object/computed';
import { action } from '@ember/object';
import { keyResponder, onKey } from 'ember-keyboard';

@keyResponder
export default class AutocompleteInputComponent extends Component {
  @tracked lastTerm = '';
  @tracked highlightedResultIndex = this.args.highlightedResultIndex || -1;
  @notEmpty('args.results') hasResults;

  constructor() {
    super(...arguments);
    if (!this.args.results) {
      this.args.results = [];
    }
    this.lastTerm = this.args.term;
  }

  get highlightedResult() {
    if (this.highlightedResultIndex < 0) {
      return null;
    }
    return this.args.results[this.highlightedResultIndex];
  }

  get resultName() {
    return this.args.resultName || '';
  }

  get resultValue() {
    return this.args.resultValue || '';
  }
  @onKey('Enter')
  onEnterPress() {
    let result = this.args.results[this.highlightedResultIndex];

    if (result) {
      this.args.selectResult(result);
    }
  }

  @onKey('Escape')
  onEscPress() {
    this.clearSearch();
  }

  @onKey('ArrowDown')
  onDownPress() {
    let index = 0;

    if (this.highlightedResultIndex >= 0) {
      index = this.highlightedResultIndex + 1;
    }

    if (index > this.args.results.length - 1) {
      index = 0;
    }

    this.highlightedResultIndex = index;
  }

  @onKey('ArrowUp')
  onUpPress() {
    let lastItem = this.args.results.length - 1;
    let index = lastItem;

    if (this.highlightedResultIndex >= 0) {
      index = this.highlightedResultIndex - 1;
    }

    if (index < 0) {
      index = lastItem;
    }

    this.highlightedResultIndex = index;
  }


  @action
  termDidChange() {
    if (this.args.term !== this.lastTerm) {
      this.lastTerm = this.args.term;
      this.args.updateTerm(this.args.term);
    }
  }

  @action
  handleChangeTerm(event) {
    this.args.updateTerm(event.target.value);
  }

  @action
  selectResult(result) {
    this.args.selectResult(result);
  }

  @action
  clearSearch() {
    this.args.clearSearch();
  }
}
