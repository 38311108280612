import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';

export default class WorkSpaceTree extends Component {
  @service commonService;

  get space() {
    return this.args.space;
  }

  @computed('commonService.selectedSpace.id', 'space.id')
  get isCurrentWorkspace() {
    return this.space.get('id') === this.commonService.get('selectedSpace.id');
  }
}
