import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class Messages extends Route {
  @service intl;
  @service store;
  @service authService;

  beforeModel() {
    this.authService.loginIfNotAuthenticated();
  }
  async model() {
    let spaces = await this.store.findAll('space', { adapterOptions: { subscribe: this } });
    const dirtySpaces = spaces.filter(space => space.isNew );
    const dirtyMembers = [];
    dirtySpaces.forEach((space) => {
      space.get('spaceMembers').forEach(member => dirtyMembers.push(member));
      space.unloadRecord();
    });
    dirtyMembers.forEach((member) => member.unloadRecord());
    return spaces;
  }

  afterModel() {
    document.title = 'Cryptobox - ' + this.intl.t('navigation.my.messages');
  }

  @action
  didTransition() {
    this.controller.set('isNew', false);
  }
}
