import Mixin from '@ember/object/mixin';

export default Mixin.create({
  mouseX: 0,
  mouseY: 0,

  contextMenu: function(e) {
    this.set('mouseX',e.pageX);
    this.set('mouseY',e.pageY);
    let search = document.querySelector("input[name='search']");

    if (search) {
      const searchBounds = search.getBoundingClientRect()
      let searchX = searchBounds.left;
      let searchY = searchBounds.top;
      let xValid = e.pageX < searchX || e.pageX > (searchX + searchBounds.width + 50);
      let yValid = e.pageY < searchY || e.pageY > (searchY + searchBounds.height + 30);

      if (xValid || yValid) {
        e.preventDefault();
        this.set('isContextMenuOpened', true);
        return false
      }
      return
    } else {
      e.preventDefault();
      this.set('isContextMenuOpened', true);
    }
    return false
  },
});
