export default function getSortingDepositBoxChoicesFilters(intl) {
  return [
    {
      value: 0,
      label: intl.t('depositBox.sortingFilters.nameAsc'),
      property: 'name',
      order: true,
    },
    {
      value: 1,
      label: intl.t('depositBox.sortingFilters.nameDesc'),
      property: 'name',
      order: false,
    },
    {
      value: 2,
      label: intl.t('depositBox.sortingFilters.creationDateDesc'),
      property: 'creationDate',
      order: false,
    },
    {
      value: 3,
      label: intl.t('depositBox.sortingFilters.creationDateAsc'),
      property: 'creationDate',
      order: true,
    },
    {
      value: 4,
      label: intl.t('depositBox.sortingFilters.depositDateDesc'),
      property: 'uploadDate',
      order: false,
    },
    {
      value: 5,
      label: intl.t('depositBox.sortingFilters.depositDateAsc'),
      property: 'uploadDate',
      order: true,
    },
  ];
}
