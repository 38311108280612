import { action, computed, set } from '@ember/object';
import Component from '@glimmer/component';
import { DateTime, Info } from 'luxon';
import { capitalize } from '@ember/string';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { getFilterFileHistory } from '../../../utils/getFilterFileHistory';

export default class HeatMap extends Component {
  @service('file-history') fileHistoryService;
  @service intl;
  @service account;

  locale = navigator.language.startsWith('fr') ? 'fr' : 'en';

  @tracked subDomainDimensions = {
    type: 'day',
    width: 12,
    height: 12,
    gutter: 4,
    padding: [5, 5, 5, 5],
  };

  @tracked containerClass = '';
  @tracked lockButtonChangeYear = false;
  @tracked calHeatMapInstance = null;
  filterChoices = getFilterFileHistory(this.intl);

  @computed(
    'fileHistoryService.firstFileHistoryItem.date',
    'fileHistoryService.{model,year}',
  )
  get currentYearSelected() {
    if (this.fileHistoryService.model) {

      this.updateHeatMap().then(async () => {
        await this.fileHistoryService.model;
        const elementId = `group-year-${ this.fileHistoryService.year }`;
        const anchorElement = document.getElementById(elementId);

        if (!anchorElement) {
          const lastYearRevision = new Date(
            this.fileHistoryService.firstFileHistoryItem?.date,
          ).getFullYear();

          if (this.fileHistoryService.year <= lastYearRevision) {
            await this.fileHistoryService.loadMoreFileHistoryData();
          }
          const newElement = document.getElementById(elementId);
          if (newElement) {
            newElement.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            });
          }
          return;
        }

        anchorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      });
    }
    this.lockButtonChangeYear = false;
    return this.fileHistoryService.year;
  }

  set currentYearSelected(value) {
    set(this, 'fileHistoryService.year', value);
  }

  get disableDecrement() {
    return (
      this.fileHistoryService.firstDateRevision === null ||
      this.fileHistoryService.firstDateRevision.getFullYear() ===
        this.currentYearSelected || this.lockButtonChangeYear
    );
  }

  get disableIncrement() {
    return (
      this.fileHistoryService.year === new Date().getFullYear() ||
       this.lockButtonChangeYear
    );
  }

  get heatmapData() {
    return this.fileHistoryService.heatmapData;
  }

  get model() {
    return this.args.model;
  }

  async setupLocale() {
    let localeSetting = this.locale;
    if (navigator.language.startsWith('fr')) {
      const { default: localeFr } = await import('dayjs/locale/fr');
      localeSetting = localeFr;
    } else {
      const { default: localeEn } = await import('dayjs/locale/en');
      localeSetting = localeEn;
    }
    return localeSetting;
  }

  handleClickCallHeatMap(event, timestamp, value) {
    event.stopPropagation();
    event.preventDefault();
    const targetElement = event.target;
    const selectedClass = 'subDomainSelected';

    if (!value) {
      return;
    }

    document.querySelectorAll(`.${selectedClass}`).forEach((element) => {
      if (
        element !== targetElement &&
        element.classList.contains(selectedClass)
      ) {
        element.classList.remove(selectedClass);
      }
    });
    const currDate = DateTime.fromJSDate(new Date(timestamp)).toISODate();

    if (this.fileHistoryService.selectedDate !== currDate) {
      event.target.classList.add(selectedClass);
      this.fileHistoryService.oldSelectedDate =
        this.fileHistoryService.selectedDate;
      this.fileHistoryService.selectedDateNumberActivities = value;
      this.fileHistoryService.selectedDate = DateTime.fromJSDate(
        new Date(timestamp),
      ).toISODate();
    } else {
      this.fileHistoryService.oldSelectedDate =
        this.fileHistoryService.selectedDate;
      this.fileHistoryService.selectedDate = null;
      this.fileHistoryService.selectedDateNumberActivities = null;
      event.target.classList.remove(selectedClass);
      this.fileHistoryService.year = new Date().getFullYear();
    }
  }

  async setupPlugins() {
    const { default: CalendarLabel } = await import(
      'cal-heatmap/plugins/CalendarLabel'
    );
    const { default: Tooltip } = await import('cal-heatmap/plugins/Tooltip');
    return [
      [
        Tooltip,
        {
          enable: true,
          text: (timestamp, value) => {
            if (value === 0 || !value) {
              return `<span class='bold'> ${this.intl.t('fileHistory.tooltip.noActivity')}</span> - ${DateTime.fromJSDate(new Date(timestamp)).setLocale(this.locale).toFormat('DDD')}`;
            }
            return `<span class='bold'>  ${this.intl.t('fileHistory.tooltip.activities', { count: value })} </span> - ${DateTime.fromJSDate(new Date(timestamp)).setLocale(this.locale).toFormat('DDD')}`;
          },
          placement: 'top',
          modifiers: [
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['top', 'right'],
              },
            },
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
          ],
        },
      ],
      [
        CalendarLabel,
        {
          position: 'left',
          key: 'left',
          text: () =>
            Info.weekdays('short', { locale: this.locale }).map((day, index) =>
              index % 2 === 0 ? '' : capitalize(day),
            ),
          textAlign: 'start',
          padding: [8, 15, 0, 0],
        },
      ],
    ];
  }

  async renderHeatMap() {
    if (this.calHeatMapInstance) {
      this.calHeatMapInstance.destroy();
    }
    const { default: CalHeatMap } = await import('cal-heatmap');
    this.calHeatMapInstance = new CalHeatMap();
    const localeSetting = await this.setupLocale();

    const plugins = await this.setupPlugins();

    this.calHeatMapInstance.on('click', (event, timestamp, value) =>
      this.handleClickCallHeatMap(event, timestamp, value),
    );
    await this.calHeatMapInstance.destroy();

    await this.calHeatMapInstance.paint(
      {
        itemSelector: '.cal-heatmap',
        verticalOrientation: false,
        animationDuration: 0,
        data: {
          source: this.heatmapData,
          x: 'date',
          y: 'value',
        },
        date: {
          start: this.fileHistoryService.startHeatMapDate,
          locale: { ...localeSetting, weekStart: 1 },
        },
        scale: {
          color: {
            domain: [0, 1],
            range: ['#ededed', '#2C496D'],
            type: 'linear',
          },
        },
        domain: {
          type: 'month',
          padding: [10, 6, 0, 6],
          gutter: 5,
          dynamicDimension: true,
          label: {
            position: 'bottom',
            text: (timestamp) => {
              return `${capitalize(DateTime.fromJSDate(new Date(timestamp)).setLocale(this.locale).toFormat('MMM'))}`;
            },
            textAlign: 'middle',
          },
        },
        subDomain: this.subDomainDimensions,
      },
      plugins,
    );
  }

  @action
  async didInsert() {
    if (!this.account.isLoggedIn) return;
    await this.model;
    await this.fileHistoryService.fetchHeatMapData();
  }

  @action
  async handleUpdateFilter(value) {
    if (this.fileHistoryService.selectedDate) {
      this.fileHistoryService.selectedDate = null;
    }
    if (this.fileHistoryService.filter.value !== value) {
      this.fileHistoryService.filter = value;

      await this.updateHeatMap();
    }
  }

  @action
  async handleChangeYear(type, event) {
    event.preventDefault();
    if (this.lockButtonChangeYear) {
      return;
    }
    this.handleUnselectDate();

    this.fileHistoryService.automaticScroll = true;
    if (
      (type === 'increment' && !this.disableIncrement) ||
      (type === 'decrement' && !this.disableDecrement)
    ) {
      this.currentYearSelected += type === 'increment' ? 1 : -1;
    }
    setTimeout(() => {
      this.fileHistoryService.automaticScroll = false;
      this.lockButtonChangeYear = false;
    }, 1000);
  }

  @action
  async updateHeatMap() {
    try {
      if (!this.account.isLoggedIn) return;
      await this.fileHistoryService.fetchHeatMapData();
      if (this.calHeatMapInstance) {
        this.calHeatMapInstance.destroy();
      }
      await this.renderHeatMap();
    } catch (error) {
      console.error('Error updating HeatMap:', error);
    }
  }

  @action
  async componentDidInsert() {
    if (!this.account.isLoggedIn) return;
    setTimeout(async () => {
      if (this.fileHistoryService.model) {
        await this.updateHeatMap();
      }
    }, 0);
  }

  @action
  destroyComponent() {
    if (this.calHeatMapInstance) {
      this.calHeatMapInstance.destroy();
    }
    this.fileHistoryService.heatmapData = [];
    this.fileHistoryService.filter = {
      value: 'none',
      label: this.intl.t('fileHistory.filterBy.all'),
    };
    this.currentYearSelected = new Date().getFullYear();
    this.handleUnselectDate();
  }

  @action
  handleUnselectDate() {
    this.fileHistoryService.selectedDate = null;
    this.fileHistoryService.oldSelectedDate = null;
    this.fileHistoryService.selectedDateNumberActivities = null;

  }
}
