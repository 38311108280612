import { tBoxClient } from 'client/initializers/init-toolbox';
import BaseAdapter from 'client/adapters/application';

export default BaseAdapter.extend({
  createRecord(store, type, snapshot) {
    return tBoxClient.category.create(snapshot.attr('name')).then(payload => {
      return this.normalizeRecord(store, type, payload);
    });
  },

  updateRecord(store, type, snapshot) {
    if(snapshot.adapterOptions.addedSpaceId) {
      return tBoxClient.category.addSpace(snapshot.id, snapshot.adapterOptions.addedSpaceId);
    }
    else if (snapshot.adapterOptions.removedSpaceId) {
      return tBoxClient.category.removeSpace(snapshot.id, snapshot.adapterOptions.removedSpaceId);
    }

    return Promise.resolve();
  },

  deleteRecord(_store, _type, snapshot) {
    return tBoxClient.category.delete(snapshot.id);
  },
});
