import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { computed, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
/* global libcryptobox */

export default class DepositaryController extends Controller {
  @service intl;
  @service modalManager;
  @service router;

  @tracked sas;
  @tracked connected = false;
  @tracked countRetry = 3;
  @tracked invalidCode = false;
  @tracked disableValidate = false;

  @computed('model.{rdvUserId,recoveryId}')
  get initRdv() {
    this.connected = false;
    tBoxClient.recovery
      .depositClaimantConnect(this.model.recoveryId, this.model.rdvUserId)
      .then(() => (this.connected = true))
      .catch((error) => {
        if (error.code === libcryptobox.ErrorCode.RecoveryBadID) {
          this.modalManager.open('no-recovery-session');
        }
      });
    return true;
  }

  @action
  async confirmSas(event) {
    // prevent form submit to reload the page
    event.preventDefault();
    if (this.disableValidate) return;
    try {
      await tBoxClient.recovery.confirm(this.model.recoveryId, this.sas);
      this.sas = '';
      this.router.transitionTo('my-groups');
    } catch (e) {
      document.querySelector('.sas').classList.add('error');
      const retryCount = await tBoxClient.recovery.retryCount();

      this.countRetry = retryCount;
      this.invalidCode = true;

      if (retryCount === 1) {
        document.querySelector('.retry-info').classList.add('error');
      }

      if (retryCount === 0) {
        const error1 = this.intl.t('recovery.rdv.trustee.errors.code1');
        const error2 = this.intl.t('recovery.rdv.trustee.errors.code2');
        this.disableValidate = true;
        document.querySelector('.invalid-code').innerHTML =
          error1 + '<br/>' + error2;
      }
    }
  }

  @action
  cancel() {
    this.disableValidate = false;
    this.sas = '';
    this.invalidCode = false;
    this.router.transitionTo('my-groups');
  }

  @action
  refresh() {
    window.location.reload();
  }

  @action
  redirectToLogin() {
    if (this.modalManager.isModalOpen('no-recovery-session')) {
      this.router.transitionTo('login');
    }
  }
}
