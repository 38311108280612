import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import { action, computed } from '@ember/object';
import { SECURITY_LEVELS_CONFIRM_REMOVE_MODAL_NAME } from '../../../utils/enums';
import { tBoxClient } from 'client/initializers/init-toolbox';
/*global libcryptobox*/
export default class EditController extends Controller {
  @service store;
  @tracked addingTag;
  @tracked isProcessing;
  @tracked modalIsOpen = false;
  @tracked confirmDisable = false;
  @tracked currentDeviceSecurityLevels;

  @service intl;
  @service modalManager;
  @service account;
  @service notify;
  @service connection;
  @service router;

  @tracked _wsImage;

  @action onWsImage(img) {
    this._wsImage = img;
  }

  @task({ drop: true })
  *createTagTask() {
    yield timeout(1000);
    this.notify.success(this.intl.t('toasts.editSpace.message'), {
      title: this.intl.t('toasts.editSpace.title'),
      icon: 'group',
    });
  }

  @computed('currentDeviceSecurityLevels')
  get securityLevels() {
    return this.currentDeviceSecurityLevels;
  }

  set securityLevels(newSL) {
    this.currentDeviceSecurityLevels = newSL;
  }

  get isOwner() {
    if (!this.model.isAdmin) {
      this.router.transitionTo('my-groups.group.overview', this.model.id);
    }

    return true;
  }

  get thumbnailCapable() {
    const thumbnail = libcryptobox.ServerCapability.Thumbnail;
    return this.account.serverSettings.capabilities.includes(thumbnail);
  }

  async saveWorkspace() {
    const currentSecurityLevels = await this.model.securityLevels;
    this.updateSecurityLevelsRecord(currentSecurityLevels);

    try {
      if (this.thumbnailCapable) {
        if (this._wsImage) {
          await tBoxClient.space.setThumbnail(this.model.id, this._wsImage);
        } else if (this._wsImage === null) {
          await tBoxClient.space.clearThumbnail(this.model.id);
        }
      }

      await this.model.save();
      const spaceId = this.model.id;
      this.createTagTask.perform(spaceId);
      this._wsImage = undefined;
      this.router.transitionTo('my-groups.group.overview', spaceId);
    } catch (err) {
      console.error(err);
      this.notify.error(err.message);
    }
  }

  updateSecurityLevelsRecord(securityLevels) {
    this.securityLevels.forEach((sl) => {
      const slRecord = this.store.peekRecord({
        type: 'securityLevel',
        id: sl.id,
      });
      if (sl.checked) {
        securityLevels.pushObject(slRecord);
      } else {
        securityLevels.removeObject(slRecord);
      }
    });
  }

  @action
  async onEdit() {
    if (this.addingTag) {
      return;
    }

    const currentSecurityLevels = await this.model.securityLevels;
    const removedSecurityLevels = this.securityLevels
      .filter((sl) => !sl.checked)
      .filter((sl) => currentSecurityLevels.findBy('id', sl.id));

    if (removedSecurityLevels.length) {
      this.modalManager.open(SECURITY_LEVELS_CONFIRM_REMOVE_MODAL_NAME);
      return;
    }

    await this.saveWorkspace();
    this.model.removeSecurityLevelMessage = '';
  }

  @action
  async onConfirmRemoveSecurityLevel(message) {
    this.model.removeSecurityLevelMessage = message;

    await this.saveWorkspace();
    this.model.removeSecurityLevelMessage = '';
  }

  @action
  async editGroup() {
    if (this.addingTag) {
      return;
    }

    const securityLevels = await this.model.securityLevels;
    this.securityLevels.forEach((sl) => {
      const slRecord = this.store.peekRecord({ type: 'securityLevel', id: sl.id });
      if (sl.checked) {
        securityLevels.pushObject(slRecord);
      } else {
        securityLevels.removeObject(slRecord);
      }
    });

    this.model
      .save()
      .then(() => {
        const spaceId = this.model.id;
        this.createTagTask.perform(spaceId);
        this.router.transitionTo('my-groups.group.overview', spaceId);
      })
      .catch((err) => {
        console.error(err);
        this.notify.error(err.message);
      });
  }

  @action
  async createTag(term, event) {
    let tags = this.model.tags;
    if (event.keyCode === 13 && term.searchText === '') {
      await this.editGroup();
    } else if (event.keyCode === 13 && tags.indexOf(term.searchText) === -1) {
      tags.pushObject(term.searchText);
    }
  }

  @action
  cancelEdition() {
    window.history.back();
  }

  @action
  showWarningModal() {
    if (!this.model.messagingEmailNotification) {
      this.modalManager.open('messagingEmailNotificationEditWarning');
    }
  }

  @action
  onChangeSecurityLevelSelector(slList) {
    this.securityLevels = slList;
  }

  @action
  onThumbnailLoading(loading) {
    this.confirmDisable = loading;
  }
}
