import Component from "@glimmer/component";
import { inject as service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { SECURITY_LEVELS_CONFIRM_REMOVE_MODAL_NAME } from "../../../utils/enums";

export default class ConfirmRemoveModalComponent extends Component {
  modalName = SECURITY_LEVELS_CONFIRM_REMOVE_MODAL_NAME;
  inputName = SECURITY_LEVELS_CONFIRM_REMOVE_MODAL_NAME + "input";
  textareaElement = null;

  @tracked value = "";

  @service modalManager;

  get error() {
    return false;
  }

  @action
  onChange(event) {
    const { value } = event.target;
    this.value = value;
  }

  @action
  onConfirm() {
    const { onConfirm } = this.args;
    onConfirm(this.value);
  }

  @action
  onCancel() {
    this.value = "";
    this.modalManager.close(this.modalName);
  }

  @action
  didInsert(element) {
    this.textareaElement = element;
  }

  @action
  onOpen() {
    this.textareaElement.focus();
  }
}
