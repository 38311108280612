import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';

export default Helper.extend({
  intl: service(),
  compute(fileName) {
    let fileExt = fileName[0].split('.').pop().toLowerCase() || '';
    if(fileExt === '' || fileName[0].indexOf('.') == -1){
      return '';
    }
    let fileType = this.intl.t('fileType.' + fileExt);
    if(!fileType){
      return '';
    }
    return fileType;
  },
});
