import Component from "@glimmer/component";
import { action } from "@ember/object";

export default class TextBaseComponent extends Component {
  get disabled() {
    const { disabled } = this.args;

    return !!disabled;
  }

  @action
  didInsert(element) {
    const { autoFocus, didInsert } = this.args;

    if (autoFocus) {
      element.focus();
    }

    if (didInsert) {
      didInsert(element);
    }
  }

  @action
  onChange(event) {
    const { onChange } = this.args;

    event.preventDefault();
    event.stopPropagation();

    if (onChange) {
      onChange(event);
    }
  }

  @action
  onKeydown(event) {
    const { onKeyDown, onPressEnter } = this.args;
    if (event.key === "Enter" && onPressEnter) {
      onPressEnter();
    }
    if (onKeyDown) {
      onKeyDown(event);
    }
  }
}
