import { action } from "@ember/object";
import { inject as service } from "@ember/service";
import Component from "@glimmer/component";
import { tBoxClient } from "client/initializers/init-toolbox";

export default class ListedInviteeComponent extends Component {
  @service intl;
  @service notify;

  @action
  revokeUser() {
    tBoxClient.preaddedSpaceMember.delete(this.args.invitee.id).then(() => {
      this.notify.success(
        this.intl.t("toasts.removeWorkspaceUser.message", {
          user: this.args.invitee.email,
        }),
        {
          title: this.intl.t("toasts.removeWorkspaceUser.title"),
          icon: "group",
        }
      );
    });
  }

  @action
  updateInviteeRight(newRights) {
    this.args.invitee.role = newRights.value;

    tBoxClient.preaddedSpaceMember.setRole(
      this.args.invitee.id,
      newRights.value
    );
  }
}
