function snakeToCamel(s) {
  return s.replace(/(_\w)/g, function(m) {
    return m[1].toUpperCase();
  });
}

function normalize(val) {
	if (val !== null && typeof val === "object") {
		for (let key in val) {
			if (val.hasOwnProperty(key)) {
				val[snakeToCamel(key)] = normalize(val[key]);
				if (key !== snakeToCamel(key)) {
					delete val[key];
				}
			}
		}
	}
  return val;
}

export {snakeToCamel, normalize};
