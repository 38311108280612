import Route from '@ember/routing/route';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { inject as service } from '@ember/service';

export default class Validate extends Route {
  @service connection;

  async model(params) {
    const server = this.connection.serverAddress;
    try {
      await tBoxClient.account.validateEmail(server, params.token);
      return this.handleValidationSuccess();
    } catch (error) {
      return this.handleValidationError(error);
    }
  }

  handleValidationSuccess() {
    this.setControllerProperties(true, false);
    this.scheduleTransitionToLogin();
    return {};
  }

  handleValidationError(error) {
    this.setControllerProperties(false, true, error);
    this.scheduleTransitionToLogin();
    return { error };
  }

  setControllerProperties(isValid, isValidationFail, error = null) {
    const controller = this.controllerFor('validate');
    controller.setProperties({
      isValid,
      isValidating: false,
      isValidationFail,
      model: isValidationFail ? { error } : null,
    });
  }

  scheduleTransitionToLogin() {
    setTimeout(() => this.transitionTo('login'), 5000);
  }
}
