// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr, belongsTo } from '@ember-data/model';

// An attachment to an instant message.
// See AttachmentCollection.
export default class extends Model {
  // File name. It may not be safe on Windows.
  // @type string
  @attr fileName;
  
  // Size in bytes.
  // @type number
  @attr fileSize;
  
  // Message's identifier.
  // @type string
  @attr messageId;
  
  @belongsTo('preview', { async: false, inverse: null }) preview;
  @belongsTo('message', { async: true, inverse: null }) message;
}

