import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { openExternal } from 'client/ipc';

export default class DepositBoxShowLink extends Component {
  @service modalManager;
  @service intl;
  @service notify;
  @service router;
  @service store;
  @service connection;

  @tracked modalName = 'depositBox-show-link';
  @tracked showToast = false;
  @tracked toastStatus = 'toasts.depositBox-copy.link.success';
  @tracked mailtoLink = '';

  get depositBox() {
    return this.modalManager.getOptions('depositBox-show-link');
  }

  get isOpen() {
    return this.modalManager.isModalOpen(this.modalName);
  }

  @action
  onOpen() {
    const subject = this.intl.t('depositBox.showLink.mail.subject', {
      name: this.depositBox.name,
    });
    const body = this.intl.t('depositBox.showLink.mail.body', {
      name: this.depositBox.name,
      link: this.depositBox.link,
    });
    this.mailtoLink = `mailto:?subject=${ encodeURIComponent(
      subject,
    ) }&body=${ encodeURIComponent(body) }`;
  }

  @action
  onClose() {
    this.mailtoLink = '';
  }

  @action
  closeModalAfterDelay() {
    if (this.isOpen) {
      setTimeout(() => {
        this.close();
      }, 3000);
    }
  }

  @action
  close() {
    this.modalManager.close(this.modalName);
  }

  @action
  async handleElectron(e) {
    if (this.connection.isDesktopApp) {
      e.preventDefault();
      await openExternal(this.mailtoLink);
    }
  }

  @action
  goToDepositBox() {
    this.router
      .transitionTo('deposit-boxes.deposit-box', this.depositBox.id)
      .then(() => {
        this.close();
      });
  }

  @action
  copyInClipboard(key, value) {
    if (!value) {
      return;
    }

    navigator.clipboard.writeText(value).then(() => {
      this.toastStatus = `toasts.depositBox-copy.${ key }.success`;
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 3000);
    });
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.showToast = false;
  }
}
