import Controller from "@ember/controller";
import { computed, action } from "@ember/object";
import { inject as service } from "@ember/service";
import { tBoxClient } from "client/initializers/init-toolbox";

export default class MyAccount extends Controller {
  showFingerPrint = false;

  @service account;
  @service intl;
  @service connection;

  @computed(
    "account.status.{personalSpace,createSpace,canInvite,setInviteRights,canWrite}"
  )
  get haveRights() {
    let accountStatus = this.account.status;

    if (accountStatus) {
      return (
        accountStatus.personalSpace ||
        accountStatus.createSpace ||
        accountStatus.canInvite ||
        accountStatus.setInviteRights ||
        accountStatus.canWrite
      );
    }
    return false;
  }

  @action
  toggleFileChangeEmailNotification(event) {
    event.preventDefault();
    const { checked } = event.target;
    tBoxClient.accountSettings.setFileChangeEmailNotification(checked);
  }
}
