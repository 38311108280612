import { hash } from 'rsvp';
import Route from '@ember/routing/route';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { inject as service } from '@ember/service';
/* global libcryptobox */

export default class MyAccount extends Route {

  @service intl;
  @service store;
  @service authService;

  beforeModel() {
    this.authService.loginIfNotAuthenticated();
  }

  model() {
    return hash({
      accountSettings: this.store.queryRecord('account-settings', {}),
      SmCertificate: tBoxClient.security.getSmCertificate(),
      devices: this.store.findAll('device', {
        adapterOptions: { subscribe: this },
      }),
      currentDevice: this.loadCurrentDevice(),
    });
  }

  async loadCurrentDevice() {
    try {
      return await this.store.queryRecord('device', {});
    } catch (e) {
      if (libcryptobox.ErrorCode.NotFound) {
        return null;
      }
    }
  }

  afterModel() {
    document.title = 'Cryptobox - ' + this.intl.t('my.account.title');
  }

  deactivate() {
    this.store.adapterFor('device').unsubscribe(this);
  }
}
