import Component from "@glimmer/component";

export default class SecurityLevelGroupedComponent extends Component {
  get onlyOneExtended() {
    const { list, onlyOneExtended } = this.args;
    return list.length === 1 && onlyOneExtended;
  }

  get list() {
    const { list } = this.args;
    if (!list) {
      return [];
    }
    return list;
  }

  get listSlice() {
    const { max } = this.args;
    return this.list.slice(0, max);
  }

  get hasMore() {
    const { max } = this.args;
    return this.list.length > max;
  }

  get hiddenItemsNumber() {
    const { max } = this.args;
    return this.list.length - max;
  }
}
