import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class Login extends Route {
  @service account;
  @service modalManager;
  @service session;
  @service browser;
  @service connection;
  @service secondFactorAuthenticator;
  @service router;
  @service store;

  queryParams = {
    register: {
      refreshModel: true,
    },
    email: {
      refreshModel: false,
    },
    first_name: {
      refreshModel: false,
    },
    last_name: {
      refreshModel: false,
    },
  };

  async beforeModel(transition) {
    if (!this.browser.isSupportedBrowser) {
      return this.router.transitionTo('browser-error');
    }

    await super.beforeModel(transition);

    // Clean up second factor and incomplete login flag(eg. otp)
    this.secondFactorAuthenticator.reset();
    this.account.cancelLogin();

    document.title = 'Cryptobox';
    const hash = this.paramsFor('login').requestedHash;
    this.set('requestedHash', hash);
    this.set('account.requestedHash', hash);

    if (this.account.isLoggedIn) {
      if (this.requestedHash) {
        window.location.hash = this.requestedHash;
        this.set('requestedHash', '');
      } else {
        this.router.transitionTo('my-groups');
      }
    } else {
      this.store.unloadAll();
      this.store.peekAll('user').forEach((item) => {
        this.store.unloadRecord(item);
      });
      this.store.peekAll('space').forEach((item) => {
        this.store.unloadRecord(item);
      });
      this.session
        .getSession()
        .then((sessionObject) => {
          this.account.loadSession(sessionObject);
        })
        .catch((e) => console.warn(e));
    }
  }

  afterModel() {
    const isModalOpened = this.modalManager.isModalOpen('trustee-warning');
    this.account.trusteeWarningModalIsOpen = false;
    if (isModalOpened) {
      this.modalManager.close('trustee-warning');
    }
  }

  async model() {
    if (this.connection.isDesktopApp) {
      return this.store.findAll('account', {
        adapterOptions: { subscribe: this },
      });
    }

    return null;
  }

  async setupController(controller) {
    const model = await this.model();
    const params = this.paramsFor('login');

    const { email, first_name, last_name } = params;
    if (email && email.length > 0) {
      controller.setProperties({
        userEmail: email,
        disabledEmail: true,
      });
    }

    if (first_name) {
      controller.setProperties({
        firstName: first_name,
        disabledFirstName: true,
      });
    }

    if (last_name) {
      controller.setProperties({
        lastName: last_name,
        disabledLastName: true,
      });
    }

    controller.setProperties({
      mailValidation: false,
      logInProgress: false,
      loginPassword: null,
      model,
    });
  }

  deactivate() {
    let loginController = this.controllerFor('login');
    loginController.set('SingUpInProcess', false);
    loginController.set('signupIsDisplayed', false);
    loginController.set('validationError', false);
    loginController.set('credentialError', false);
    loginController.set('disabledEmail', false);
    loginController.set('disabledFirstName', false);
    loginController.set('disabledLastName', false);
  }
}
