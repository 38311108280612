import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { tBoxClient } from 'client/initializers/init-toolbox';
/* global libcryptobox */

export default class WorkspaceRoleComponent extends Component {
  @tracked workspaceName;
  @tracked newRight = null;

  @service store;
  @service intl;
  @service notify;

  get inviteeRight() {
    return this.newRight || this.args.invitee.role;
  }

  get alreadyAdded() {
    return this.args.invitee.status === libcryptobox.PreaddedStatus.Added;
  }

  @action
  revokeUser() {
    tBoxClient.preaddedSpaceMember.delete(this.args.invitee.id).then(() => {
      this.args.allInvites.removeObject(this.args.invitee);
      void this.args.invitee.destroyRecord();
      if (this.args.allInvites.length === 0 && this.args.closeOrNext) {
        this.args.closeOrNext();
      }
      this.notify.success(
        this.intl.t('toasts.removeWorkspaceUser.message', {
          user: this.args.invitee.email,
        }),
        {
          title: this.intl.t('toasts.removeWorkspaceUser.title'),
          icon: 'group',
        },
      );
    });
  }

  @action
  updateInviteeRight(newRight) {
    const { invitee, updateRoleOnRow } = this.args;

    this.newRight = newRight.value;
    if (updateRoleOnRow) {
      tBoxClient.preaddedSpaceMember.setRole(invitee.id, newRight.value);
    }
    invitee.role = newRight.value;
  }
}
