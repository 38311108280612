import Component from "@glimmer/component";
import { inject as service } from "@ember/service";
import { action } from "@ember/object";

/*
 * Warn : If "href" is passed as an HTML attribute and not an Ember argument,
 * the desktop app won't be able to performe the security check
 * */
export default class LinkComponent extends Component {
  @service connection;

  @action
  onClick(event) {
    const { onClick } = this.args;

    if (onClick) {
      onClick(event);
    }
  }
}
