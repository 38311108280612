import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class SecondFactorAuthenticator extends Component {
  @tracked secondFactorWindow = null;

  @service modalManager;
  @service secondFactorAuthenticator;

  constructor() {
    super(...arguments);
    if (this.done) {
      this.close();
    }
  }

  get done() {
    return this.args.done;
  }

  willDestroy() {
    super.willDestroy(...arguments);
    if (this.secondFactorWindow) {
      this.secondFactorWindow.close();
    }
    this.secondFactorAuthenticator.reset();
  }

  @action
  close() {
    if (this.modalManager.isModalOpen('2fa')) {
      this.modalManager.close('2fa');
    }
    if (this.secondFactorWindow) {
      this.secondFactorWindow.close();
    }
  }

  @action
  cancel() {
    this.close();
    this.args.onCancel?.();
  }

  @action
  proceed() {
    this.secondFactorWindow = window.open(
      this.secondFactorAuthenticator.secondFactorUrl,
    );
  }
}
