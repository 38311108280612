import BaseModel from 'client/toolbox/generated/models/notification';
/* global libcryptobox */

export default class Notification extends BaseModel {
  markAsRead() {
    let state = this.state;

    if (state !== libcryptobox.NotificationState.Read) {
      this.state = libcryptobox.NotificationState.Read;
      this.save();
    }
  }
}
