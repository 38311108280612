import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class DepositBoxRoute extends Route {
  @service intl;
  @service store;
  @service authService;
  @service router;
  @service('deposit-box') depositBoxService;

  model(params) {
    return this.depositBoxService
      .getDepositBoxId(params.box_id)
      .catch(() => this.router.transitionTo('deposit-boxes.index'));
  }

  beforeModel() {
    this.authService.loginIfNotAuthenticated();
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    controller.set('model', model);
  }

  async afterModel(model) {
    document.title = 'Cryptobox - ' + this.intl.t('depositBox.title');
    if (!this.depositBoxService.canAccessDepositBox) {
      this.router.transitionTo('index');
    }

    if (model.state === 'populatedUnread') {
      this.depositBoxService.changeStateDepositBox(model.id, 'populatedRead');
    }
  }

  deactivate() {
    this.store.adapterFor('deposit-box').unsubscribe(this);
  }

  @action
  error(error) {
    if (error.status === 404) {
      this.router.transitionTo('deposit-boxes.index');
    }
  }
}
