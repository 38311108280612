import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class MyTransfers extends Route {
  @service notify;
  @service intl;
  @service authService;

  beforeModel() {
    this.authService.loginIfNotAuthenticated();
  }

  afterModel() {
    document.title = 'Cryptobox - ' + this.intl.t('navigation.my.transfers');
  }

}
