import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { observes } from '@ember-decorators/object';

export default class AuthServiceService extends Service {
  @service account;
  @service intl;
  @service userActivity;
  @service router;

  get isUserLoggedIn() {
    return this.account.isLoggedIn;
  }

  loginIfNotAuthenticated() {
    if (!this.isUserLoggedIn) {
      let requestedHash = window.location.hash;
      this.router.transitionTo('index', { queryParams: { requestedHash: requestedHash } });
    }
  }

  @observes('account.isLoggedIn')
  onLogout() {
    const account = this.account;
    account.getLogoutSource();
    if (!account.isLoggedIn) {
      if (account.isUserLogOutSource()) {
        window.location.hash = '';
        this.router.transitionTo('login', { queryParams: { requestedHash: '', register: false } });
      } else {
        if (account.isTimeoutActivityLogOutSource()) {
          this.userActivity.openToastSessionExpired();
        }
        let requestedHash = window.location.hash;
        this.router.transitionTo('login', { queryParams: { requestedHash: requestedHash } });
      }
    }
  }

}
