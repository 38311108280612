import { run } from '@ember/runloop';
import { Promise as EmberPromise } from 'rsvp';
import Service, { inject as service } from '@ember/service';

export default class NotificationApi extends Service {
  @service notify;
  @service router;
  @service connection;

  checkIfNotificationAreGranted() {
    return new EmberPromise((resolve, reject) => {
      if (!('Notification' in window)) {
        run(null, reject);
      } else if (Notification.permission === 'granted') {
        run(null, resolve);
      } else if (Notification.permission !== 'denied') {
        void Notification.requestPermission(function (permission) {
          if (!('permission' in Notification)) {
            Notification.permission = permission;
          }
          if (permission === 'granted') {
            run(null, resolve);
          } else {
            run(null, reject);
          }
        });
      } else {
        run(null, reject);
      }
    });
  }

  sendNotification(title, options) {
    // Attr follow the API notification browser implementation => https://developer.mozilla.org/fr/docs/Web/API/notification
    const userInIm = this.router.currentRouteName.includes('messages.index');
    if (
      localStorage.getItem('notificationTabId') !== window.tabId ||
      (userInIm && !document.hidden)
    ) {
      return false;
    }
    let defaultOptions = {
      icon: `/static/img/logo.png`,
      fallbackIcon: null,
      disableFallback: false,
    };
    let definitiveOptions = Object.assign(defaultOptions, options);

    if (document.hidden) {
      const icon = document.querySelector('link[rel="icon"]');
      icon.href = `/static/img/logo_notified.png`;
    }

    if (definitiveOptions.body.length > 60) {
      const truncatedBody = definitiveOptions.body.substring(0, 59);
      definitiveOptions.body = `${truncatedBody}...`;
    }
    this.checkIfNotificationAreGranted()
      .then(() => {
        return new Notification(title, definitiveOptions);
      })
      .then((notification) => {
        notification.onclick = () => {
          window.focus();
          this.router.transitionTo('messages.index');
        };
      })
      .catch(() => {
        if (!definitiveOptions.disableFallback) {
          this.notify.info(definitiveOptions.body, {
            title: title,
            icon: definitiveOptions.fallbackIcon,
          });
        }
      });
  }
}
