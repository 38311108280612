import BaseModel from 'client/toolbox/generated/models/file-sharing';
// import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default class FileSharing extends BaseModel {
  @service intl;

  // TODO : duplication in models/file.js

  get fileType() {
    let name = this.fileName;
    let fileExt = name.split('.').pop().toLowerCase() || '';
    if (fileExt === '' || name.indexOf('.') === -1) {
      return '';
    }
    let fileType = this.intl.t('fileType.' + fileExt);
    if (!fileType) {
      return '';
    }
    return fileType;
  }

  save() {
    return super.save().catch((error) => {
      console.warn('error save', error);
      if (!this.isValid) {
        this.unloadRecord();
      }
    });
  }
}
