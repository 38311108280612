import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class DeleteProcessedBox extends Component {
  @service store;
  @service intl;
  @service notify;
  @service('deposit-box') depositBoxService;
  @service modalManager;

  get model() {
    return this.args.model;
  }

  get hasPopulatedProcessedBoxes() {
    return this.model.some(
      (depositBox) => depositBox.state === 'populatedProcessed',
    );
  }

  @action
  openDeleteProcessedBoxesModal() {
    this.modalManager.open('depositBox-delete-processed-modal');
  }

  @action
  async handleDeleteProcessedBoxes() {
    try {
      const boxesToDeleteIds = this.model
        .filter(({ state }) => state === 'populatedProcessed')
        .map(({ id }) => id);
      const deleteBox = async (id) =>
        await this.depositBoxService.deleteDepositBox(id);

      await Promise.all(boxesToDeleteIds.map(deleteBox));

      this.args.loadDepositBoxes();
      this.modalManager.close('depositBox-delete-processed-modal');
      this.notify.success(
        this.intl.t('depositBox.tooltip.delete-processed-boxes-success'),
      );
    } catch (error) {
      console.error(error);
    }
  }
}
