/* global libcryptobox */
import fileDownload from 'client/custom-objects/file-download';

export default fileDownload.extend({
  sharingUrl: '',
  key: '',
  transfers: null,

  startDownload() {
    let sharingUrl = this.sharingUrl;
    let key = this.key;
    this.set('startTime', Date.now());
    this.peekProgressMeasure();
    let promise = this.target.downloadSharing(sharingUrl, key);
    promise.onprogress = (timestamp, loaded, total) => {
      this.set('loaded', loaded);
      this.set('size', total);
    };
    promise
      .then(() => {
        this.set('state', 'completed');
      })
      .catch((error) => {
        if (error.code === libcryptobox.ErrorCode.Canceled) {
          // Reset the download.
          this.set('transfers.shareDownload', null);
        } else {
          this.set('error', error);
          this.set('state', 'onError');
        }
      });
  },
});
