import Route from '@ember/routing/route';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { inject as service } from '@ember/service';
export default class MyFavorites extends Route {

  controllerName = 'my-groups/index';
  templateName = 'my-groups/index';

  @service intl;
  @service store;
  @service authService;

  beforeModel() {
    this.authService.loginIfNotAuthenticated();
  }
  async model() {
    const options = { adapterOptions: { subscribe: this } };
    return this.store
      .findRecord(
        'category',
        await tBoxClient.category.getFavoritesCategoryId(),
        options
      )
      .then((rec) => rec.get('spaces'));
  }

  setupController(controller, model) {
    controller.set('model', model);
    controller.set('isFav', true);
    controller.set('deletedWorkspaces', false);
    controller.set('categoryFilters', []);
  }

  afterModel() {
    document.title = 'Cryptobox - ' + this.intl.t('navigation.my.groups.fav');
  }

  deactivate() {
    this.store.adapterFor('space').unsubscribe(this);
    this.store.adapterFor('category').unsubscribe(this);
  }
}
