import Component from "@glimmer/component";
import { action } from "@ember/object";
import { tracked } from '@glimmer/tracking';
import { tBoxClient } from 'client/initializers/init-toolbox';
/*global libcryptobox*/

export default class ThumbnailComponent extends Component {
  @tracked thumbnail = null;

  constructor() {
    super(...arguments);
    this.getThumbnail();
  }

  get thumbnailHash() {
    return this.args.thumbnailHash;
  }

  get spaceId() {
    return this.args.spaceId;
  }

  getThumbnail() {
    if (this.thumbnailHash) {
      tBoxClient.space.getThumbnail(this.spaceId).then((thumbnail) => {
        const img = new Blob([tBoxClient.decodeUint8Array(thumbnail)]);
        if (img) {
          this.thumbnail = URL.createObjectURL(img);
        }
      });
    } else {
      this.thumbnail = null;
    }
  }

  @action
  updateThumbnail() {
    this.getThumbnail();
  }
}
