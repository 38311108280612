import { debounce } from '@ember/runloop';
import EmberObject, { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { A } from '@ember/array';
import diacritic from 'diacritic';
import { tracked } from '@glimmer/tracking';

/* global libcryptobox */

export default class AddUsersComponent extends Component {
  @tracked searchUser = null
  @tracked spaceUsers = null
  @tracked highlightedResultIndex = -1
  @tracked alreadyInSpace = false
  @tracked invalidEmails = false
  @tracked remainingInvites
  @tracked searchUserResults = A()
  @tracked errorInviteRight = false
  @tracked CBUsers;


  @service store
  @service account
  @service notify
  @service intl

  calculatePosition(trigger, content) {
    let { top, left } = trigger.getBoundingClientRect();
    let { height: contentHeight } = content.getBoundingClientRect();

    let style = {
      left: left + 20,
      top: top - contentHeight / 2 + 5
    };

    return { style };
  }

  enterKey(component, e) {
    e.stopPropagation();

    if (e.keyCode === 13){
      e.preventDefault();
      if (component.highlightedResultIndex === -1 ) {
        void component.addUserToList()
      }
    }
  }

  async searchUsers(searchTerm) {
    const term = diacritic.clean(searchTerm.toLowerCase())
    this.searchUserResults = A()

    await this.CBUsers.forEach((user) => {
      if (user.email !== this.account.userEmail) {
        const userName = diacritic.clean(user.fullName.toLowerCase())
        const userMail = diacritic.clean(user.email.toLowerCase())

        if (userName.indexOf(term) !== -1 || userMail.indexOf(term) !== -1) {
          user.fullNameEmail = `${user.fullName} (${user.email.toLowerCase()})`;
          this.searchUserResults.pushObject(user);
        }
      }
    })

    if(this.searchUserResults.length === 0 ){
      this.highlightedResultIndex = -1
    }

    this.searchUserResults = this.searchUserResults.sortBy('fullNameEmail')
  }

  get canInviteUser() {
    if (!this.account.status.canInvite) this.args.usersToInvite.clear();
    return this.account.status.canInvite
  }

  get hasError() {
    return this.invalidEmails || this.alreadyInSpace || this.errorInviteRight
	}

@action
initialize() {
  this.CBUsers = this.store.query('user', {})
}

  @action
  resetComponent(resetErrors) {
    if (resetErrors) {
      this.clearComponent()
    }
  }

  @action
  clearComponent() {
    this.args.addedMembers.clear()
    this.invalidEmails = false
    this.alreadyInSpace = false
    this.searchUser = null
    this.errorInviteRight = false
  }

  @action
  async addUserToList(userEmail) {
    this.remainingInvites = await tBoxClient.invite.getLicenseUsage().then((data) => {
      if (data.personalInvitesPool) {
        if (data.personalInvitesPool.available < 0) return 0
        return data.personalInvitesPool.available
      }
      return data.invitees.available
    })

    if (this.isSearching) return

    let searchEmail
    if (this.searchUser) searchEmail = this.searchUser.trim()
    if (typeof(userEmail) == 'string' && userEmail) searchEmail = userEmail
    if (!searchEmail) return false

    this.isSearching = true

    tBoxClient.user.match(searchEmail).then((result) => {
      tBoxClient.invite.getUsersType(result.map(result => result.email)).then(invitationsType => {
        const userToAdd = A();

        result.forEach((user) => {
          user = EmberObject.create(user)
          user.type = invitationsType[user.email.toLowerCase()].licensePool
          user.consumeInvite = invitationsType[user.email.toLowerCase()].willTakeInvites

          if (user.isRegistered) {
            userToAdd.pushObject(user)
          } else {
            if (user.isEmailValid) {
              if (!this.canInviteUser) {
                this.errorInviteRight = true
                return
              }
              const emailList = this.args.usersToInvite.map(user => user.email)

              if (!emailList.includes(user.email)) {
                user.role = 'auditor'
                this.args.usersToInvite.pushObject(user);
              }
            } else {
              this.invalidEmails = true
            }
          }
        })
        this.isSearching = false

        userToAdd.forEach((user) => {
          const isCurrentUser = user.email === this.account.userEmail
          const isAlreadyInList = this.args.addedMembers.isAny('email', user.email)
          let isAlreadyInSpace

          if (this.args.spaceMembers) {
            isAlreadyInSpace = this.args.spaceMembers.isAny('email', user.email);
            if (isAlreadyInSpace) this.alreadyInSpace = true
          }

          if (isCurrentUser || isAlreadyInList) this.alreadyInSpace = true

          if(!isCurrentUser && !isAlreadyInList && !isAlreadyInSpace) {
            this.args.addedMembers.pushObject(EmberObject.create({
              id: user.userId,
              email: user.email,
              firstName: user.givenName,
              lastName: user.surname,
              certificate: user.certificate,
              maximumLevel: user.type,
              fingerprint: user.fingerprint,
              role: libcryptobox.Role.Auditor,
            }));
          }
        })
      })
    })
    .catch(() => {
      this.isSearching = false
    });

    this.clearInputSearch()
  }

  @action
  changeUserRight(user, newRole) {
    user.set('role', newRole.value);
  }

  @action
  removeUser(user) {
    this.args.addedMembers.removeObject(user);
    this.args.usersToInvite.removeObject(user);
  }

  @action
  selectResult(user) {
    this.addUserToList(user.email)
  }

  @action
  updateTerm(term) {
    this.invalidEmails = false
    this.alreadyInSpace = false
    this.errorInviteRight = false
    this.searchUser = term;
    if (term && term.length > 2) {
      this.searchUsers(term);
    } else {
      this.searchUserResults.clear()
    }
  }

  @action
  clearInputSearch() {
    this.isSearching = false
    this.searchUser = ''
    this.searchUserResults.clear()
    this.highlightedResultIndex = -1
    this.errorInviteRight = false
  }
}
