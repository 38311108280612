import Component from "@glimmer/component";
import { action } from "@ember/object";

export default class DropDownComponent extends Component {
  @action
  onChange(option, dd) {
    this.args.onChange(option);
    dd.actions.close();
  }
}
