import { tBoxClient } from 'client/initializers/init-toolbox';

const SUPPORT_EMAIL_KEY = 'support_email';
const SUPPORT_EMAIL = 'support@cryptobox.com';

async function getSupportEmail(isDesktopApp, serverSettings) {
  try {
    if (isDesktopApp) {
      return await tBoxClient.serverSettings.getSupportEmail();
    }

    if (serverSettings && SUPPORT_EMAIL_KEY in serverSettings) {
      return serverSettings[SUPPORT_EMAIL_KEY];
    }

    return SUPPORT_EMAIL;
  } catch {
    return SUPPORT_EMAIL;
  }
}

export default getSupportEmail;
