import { helper } from '@ember/component/helper';

export function getPrevFileItem(positional, { fileHistoryData, index }) {
  if (index > 0 && index < fileHistoryData.length) {
    return fileHistoryData[index - 1];
  } else {
    return null;
  }
}

export default helper(getPrevFileItem);
