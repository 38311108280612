import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import ENV from '../config/environment';

export default class ConnectionService extends Service {
  @tracked isDesktopApp = ENV.APP.isDesktopApp;
  @tracked isElectronApp = ENV.APP.isElectronApp;
  @tracked isTauriApp = ENV.APP.isTauriApp;
  @tracked tmpServerAddress;

  @service account;

  get serverAddress() {
    if (!this.isDesktopApp) {
      return window.location.origin;
    }

    if (this.account.isLoggedIn && this.account.serverSettings) {
      return this.account.serverSettings.serverUrl;
    }

    return this.tmpServerAddress;
  }
}
