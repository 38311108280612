import Component from '@glimmer/component';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { tracked } from '@glimmer/tracking';

const EXTRA_SMALL = 'xs';
const SMALL = 's';
const MEDIUM = 'm';
const LARGE = 'l';
const sizes = [EXTRA_SMALL, SMALL, MEDIUM, LARGE];
const AUTO = 'auto';

function getIconClassSizeModifier(size) {
  if (size === AUTO) {
    return '';
  }
  if (typeof size === 'number') {
    return `atom-icon-element--size-${ size }`;
  }

  if (!sizes.includes(size)) {
    console.error('Atom:Icon size ', size, ' doesn\'t exist');
    return 'atom-icon-element--s';
  }

  return 'atom-icon-element--' + size;
}

export default class IconComponent extends Component {
  @tracked iconId = `icon-id-${ guidFor(this) }`;

  get class() {
    const { svgClass, size } = this.args;
    return `atom-icon-element ${ getIconClassSizeModifier(size) } ${
      svgClass || ''
    } ${ this.iconId }`;
  }

  @action
  updateSvgColor(element) {
    requestAnimationFrame(() => {
      if (this.args.color) {
        const svgPaths = element
          .getElementsByClassName(this.iconId)[0]
          .getElementsByTagName('path');
        for (let path of svgPaths) {
          path.style.fill = this.args.color;
        }
      }
    });
  }

  @action
  onClick(event) {
    const { onClick, disabled } = this.args;

    if (disabled) {
      return;
    }

    if (onClick) {
      event.stopPropagation();
      onClick();
    }
  }
}
