import { Base64 } from 'js-base64';
import dispatchEvent from '../../dispatch-event';
import RpcError from '../error';

// Named pipe transport.
export default class NamedPipeTransport {
    constructor(pipeName) {
        this._pipeName = pipeName;

        this._pipe = null;
        this._rcv = '';

        this.onready = null;
        this.onmessage = null;
        this.onerror = null;
        this.onclose = null;
    }

    connect() {
        if (!this._pipe) {
            this._pipe = true; // guard against concurrent calls to connect().
            (async () => {
                let retry = 30;
                let interval = 1_000;
                while (true) {
                    try {
                        await this._connect();                        
                        return;
                    } catch (error) {
                        if (retry > 0) {
                            console.error('rcp: named-pipe:', error.message);
                            retry--
                            await new Promise(resolve => setTimeout(resolve, interval));
                        } else {
                            dispatchEvent(this, 'error', new RpcError(`named-pipe: ${error.message}`, error));
                            return;
                        }
                    }
                }
            })();
        }
    }

    async _connect() {
        let connecting = true;
        return new Promise((resolve, reject) => {
            this._pipe = window.requireNode('net').connect(this._pipeName);
            this._pipe.setEncoding('utf8');
            this._pipe.setKeepAlive(true);
            this._pipe.setTimeout(9999999);
            this._pipe.on('ready', () => {
                if (connecting) {
                    connecting = false;
                    resolve();    
                }
                dispatchEvent(this, 'ready');
            });
            this._pipe.on('data', (data) => {
                this._rcv += data;
                while (this._rcv.indexOf('\n') > -1) {
                    let pos = this._rcv.indexOf('\n');
                    let message = this._rcv.substring(0, pos).trim()
                    this._rcv = this._rcv.substring(pos + 1);
                    //NB. There are two '\n' after a message and I don't know why.
                    if (message !== '') {
                        dispatchEvent(this, 'message', JSON.parse(message));
                    }
                }
            });
            this._pipe.on('error', (error) => {
                if (connecting) {
                    connecting = false;
                    reject(error);
                } else {
                    dispatchEvent(this, 'error', new RpcError(`named-pipe: ${error.message}`, error));
                }
            });
            this._pipe.on('timeout', () => {
                console.log('rpc: timeout');
            });
            this._pipe.on('close', () => {
                dispatchEvent(this, 'close');
            });
        });
    }

    close() {
        if (this._pipe) {
            this._pipe.destroy();
            this._pipe = null;
        }
    }

    send(message) {
        if (this._pipe) {
            this._pipe.write(JSON.stringify(message) + '\n');
        }
    }

    encodeUint8Array(value) {
        return Base64.fromUint8Array(value);
    }

    decodeUint8Array(value) {
        return Base64.toUint8Array(value);
    }
}

