export default function getDepositBoxStateColor() {
  return [
    {
      value: { background: 'var(--grey-0, #F2F3F8)', text: 'inherit' },
      label: 'empty',
    },
    {
      value: { background: '#FFF4D6', text: '#8D6B07' },
      label: 'populatedRead',
    },
    {
      value: { background: 'var(--green-light, #E5FBF0)', text: 'inherit' },
      label: 'populatedProcessed',
    },
  ];
}
