// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr, hasMany } from '@ember-data/model';

// File content info.
export default class extends Model {
  // Size in bytes.
  // @type number
  @attr size;
  
  @hasMany('chunk', { async: false, inverse: null }) chunks;
}

