import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';

export default Helper.extend({
  intl: service(),
  compute(size) {
    let formatSize;
    if (size < 1e3) {
      formatSize= size + ' ';
    } else if (size >= 1e3 && size < 1e6) {
      formatSize = (size / 1e3).toFixed(2) + ' k';
    } else if (size >= 1e6 && size < 1e9) {
      formatSize = (size / 1e6).toFixed(2) + ' M';
    } else if (size >= 1e9 && size < 1e12) {
      formatSize = (size / 1e9).toFixed(2) + ' G';
    } else {
      formatSize = (size / 1e12).toFixed(2) + ' T';
    }
    formatSize += this.intl.t('sizeUnit');
    return formatSize;
  },
});
