import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { inject as service } from "@ember/service";

export default class ElectronConnectionComponent extends Component {
  @tracked name = "Secret Défense";

  @service modalManager;

  @action
  onResendEmail() {
    console.debug("ElectronConnectionComponent:onResendEmail");
  }

  @action
  onCancel() {
    console.debug("ElectronConnectionComponent:onCancel");
  }
}
