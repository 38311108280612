import Component from "@glimmer/component";
import { action } from "@ember/object";
import { inject as service } from "@ember/service";
import { DELETE_WORKSPACE_MODAL_NAME } from "../../../utils/enums";

export default class DeleteComponent extends Component {
  @service modalManager;
  @service account;

  deleteModalName = DELETE_WORKSPACE_MODAL_NAME;

  @action
  onClick() {
    this.modalManager.open(this.deleteModalName);
  }

  @action
  onCancel() {
    this.modalManager.close(this.deleteModalName);
  }

  @action
  onConfirm() {
    if (this.args.onConfirm) {
      this.args.onConfirm();
    }
    this.onCancel();
  }
}
