import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { debounce, schedule } from '@ember/runloop';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { guidFor } from '@ember/object/internals';
import { tracked } from '@glimmer/tracking';

/* global libcryptobox */

export default class MessageInput extends Component {
  @tracked resetFileInput = false;
  @tracked newMessage = null;
  @tracked sendingMessage = null;
  elementId = guidFor(this);

  @service store;
  @service account;
  @service messaging;

  get conversation() {
    return this.args.conversation;
  }
  @computed('elementId')
  get fileInputId() {
    return 'file-input-' + this.elementId;
  }

  _resetTextarea() {
    this.newMessage = null;
    const textArea = document.querySelector('.container-messageInput textarea');
    if (textArea) {
      textArea.style.height = '30px';
    }
  }

  constructor() {
    super(...arguments);
    schedule('afterRender', this, this._resetTextarea);
  }

  @action
  onInsertElement() {
    this._resetTextarea();
  }

  @action
  input() {
    const textArea = document.querySelector('.container-messageInput textarea');
    textArea.style.height = '30px';
    let textAreaHeight = textArea.scrollHeight;

    if (textAreaHeight > 100) {
      textAreaHeight = 100;
    }

    textArea.style.height = `${ textAreaHeight }px`;
  }

  @action
  keyPress(e) {
    if (!e.shiftKey && e.keyCode === 13) {
      e.preventDefault();
      debounce(this, this.submit, e, 200);
    }
  }

  @action
  submit(e) {
    e.preventDefault();
    let textMessage = this.newMessage;

    if (!textMessage) {
      return false;
    }

    this.newMessage = textMessage.trim();

    this.sendingMessage = this.newMessage;

    const conversation = this.conversation;
    if (!conversation.get('isNew')) {
      void this.sendMessage(conversation);
    } else {
      this.createConversation(conversation);
    }
    this._resetTextarea();
  }

  @action
  async change(e) {
    e.stopPropagation();
    e.preventDefault();

    if (!e.target.files) {
      return false;
    }
    let files = [].slice.call(e.target.files);
    if (files && !files.length) {
      return false;
    }
    this.messaging.uploadAttachments(files, this.conversation);
    this.resetFileInput = true;
    setTimeout(() => {
      this.resetFileInput = false;
    }, 50);
  }

  async sendMessage(conversation) {
    if (await tBoxClient.im.isConnected()) {
      await this.store
        .createRecord('message', {
          space: conversation,
          text: this.sendingMessage,
          status: libcryptobox.MessageStatus.Pending,
          sentByMySelf: true,
        })
        .save()
        .then((message) => {
          message.post();
          message.set('isRead', true);
          setTimeout(() => {
            document.querySelector('.container-conversationStream').scrollTop =
              document.querySelector('.conversation-stream').offsetHeight;
          }, 100);
        });
    }
  }

  createConversation(conversation) {
    const store = this.store;
    store.createRecord('message', {
      space: conversation,
      text: this.sendingMessage,
      status: libcryptobox.MessageStatus.Pending,
      sentByMySelf: true,
    });
    if (conversation.get('isSaving')) {
      return;
    }
    conversation.save().then(() => {
      conversation.get('messages').forEach((message) => {
        message.save().then(() => message.post());
      });
    });
  }
}
