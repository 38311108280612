import { Base64 } from 'js-base64';
import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';
import dispatchEvent from '../../dispatch-event';
import RpcError from '../error';

const EventSource = NativeEventSource || EventSourcePolyfill;

// HTTP transport.
// NB. Not tested.
export default class HttpTransport {
    constructor() {
        this._session = null;
        this._eventSource = null;

        this.onready = null;
        this.onmessage = null;
        this.onerror = null;
        this.onclose = null;
    }

    async connect() {
        if (!this._session) {
            const info = await this._fetch('GET', 'http://localhost:8080/rpc');
            if (info.minVersion > 1 /*|| info.maxVersion < 1*/) {
                const error = new RpcError(`http: connect: unsupported version (minVersion=${info.minVersion}, maxVersion=${info.maxVersion})`, info, true, false);
                dispatchEvent(this, 'error', error);
                return;
            }
            this._session = await this._fetch('POST', info.openUrl);
            this._eventSource = new EventSource(this._session.readUrl);
            this._eventSource.addEventListener('close', () => {
                this._eventSource.close();
                this._eventSource = null;
                this._session = null;
                dispatchEvent(this, 'close');
            });
            this._eventSource.addEventListener('error', (error) => dispatchEvent(this, 'error', new RpcError(`http: ${error.message}`, error)));
            this._eventSource.addEventListener('message', (message) => dispatchEvent(this, 'message', JSON.parse(message.data)));
            this._eventSource.addEventListener('open', () => dispatchEvent(this, 'ready'));
        }
    }

    close() {
        if (this._eventSource) {
            this._eventSource.close();
            this._eventSource = null;
        }
        if (this._session) {
            this._fetch('POST', this._session.closeUrl);
            this._session = null;
            this.dispatchEvent(this, 'close');
        }
    }

    send(message) {
        return this._fetch('POST', this._session.writeUrl, JSON.stringify(message));
    }

    _fetch(method, url, body) {
        return new Promise((resolve, reject) => {
            const req = new XMLHttpRequest();
            req.onload = () => {
                resolve(req.response && JSON.parse(req.response));
            };
            req.onerror = (error) => {
                dispatchEvent(this, 'error', new RpcError(`http: ${method} ${url}: ${error.message}`, error));
            };
            try {
                req.open(method, url);
                req.responseType = 'text';
                req.send(body);
            } catch (error) {
                dispatchEvent(this, 'error', new RpcError(`http: ${method} ${url}: ${error.message}`, error));
                reject(error);
            }
        });
    }

    encodeUint8Array(value) {
        return Base64.fromUint8Array(value);
    }

    decodeUint8Array(value) {
        return Base64.toUint8Array(value);
    }
}
