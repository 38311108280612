import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class SpaceContextMenu extends Component {
  @tracked mouseX = 0;
  @tracked mouseY = 0;
  @tracked isContextMenuOpened = false;
  willDestroy() {
    super.willDestroy(...arguments);
    this.isContextMenuOpened = false;
    this.mouseX = 0;
    this.mouseY = 0;
  }

  @action
  handleRightClick(e) {
    e.stopPropagation();
    if (this.isContextMenuOpened) {
      this.isContextMenuOpened = false;
    }
    this.mouseX = e.pageX;
    this.mouseY = e.pageY;
    const search = document.querySelector("input[name='search']");

    if (search) {
      const searchBounds = search.getBoundingClientRect();
      const searchX = searchBounds.left;
      const searchY = searchBounds.top;
      const xValid =
        e.pageX < searchX || e.pageX > searchX + searchBounds.width + 50;
      const yValid =
        e.pageY < searchY || e.pageY > searchY + searchBounds.height + 30;

      if (xValid || yValid) {
        e.preventDefault();
        this.isContextMenuOpened = true;
      }
    } else {
      e.preventDefault();
      this.isContextMenuOpened = true;
    }
  }

  @action
  handleClick() {
    if (!this.space.id) {
      return false;
    }
  }
}
