import Component from '@glimmer/component';
import { DateTime } from 'luxon';
import { debounce } from '@ember/runloop';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { tracked } from '@glimmer/tracking';

/* global libcryptobox */
const { Cancelled, Accepted, Expired } = libcryptobox.InviteEventType;

export default class InvitationsRowComponent extends Component {
  @tracked showHistory = false;

  @service intl;
  @service store;
  @service modalManager;
  @service notify;

  get workspaceModalName() {
    return `checkWorkspaces-${this.args.invitation.id}`;
  }

  get tInvitationKind() {
    const key = this.args.invitation.shallowInvite ? 'internal' : 'external';
    return this.intl.t(`inviteUser.kind.${key}`);
  }

  get dateFormat() {
    return this.intl.t('dateFormat');
  }

  get oldEvents() {
    const last = this.args.invitation.eventHistory.lastObject;
    const all = this.args.invitation.eventHistory;
    return all.filter((item) => item.date !== last.date).reverse();
  }

  get lastEvent() {
    const date = DateTime.fromISO(this.args.invitation.updatedAt);
    return {
      status: this.intl.t('inviteUser.status.' + this.args.invitation.status),
      updatedOn: date.toFormat(this.dateFormat),
      time: date.toFormat('HH:mm'),
    };
  }

  get preaddSpaceMember() {
    return this.args.preAddedMembers.filter(
      (member) => member.email === this.args.invitation.guestEmail,
    );
  }

  get firstPreAddSpaceMember() {
    return this.preaddSpaceMember?.at?.(0);
  }
  get invitationIsCancellable() {
    const { invitation } = this.args;
    const { eventHistory, shallowInvite } = invitation;
    const type = eventHistory.lastObject.type;

    return (
      type !== Cancelled &&
      type !== Accepted &&
      type !== Expired &&
      !shallowInvite
    );
  }

  @computed(
    'args.invitation.eventHistory.lastObject.type',
    'invitation.eventHistory.lastObject',
  )
  get invitationResendable() {
    return this.args.invitation.eventHistory.lastObject.type !== 'accepted';
  }

  _reinvite(invitation) {
    tBoxClient.invite.resend(invitation.id);
    this.notify.success(
      this.intl.t('inviteUser.modal.resendConfirmedText', {
        user: invitation.guestEmail,
      }),
      {
        title: this.intl.t('inviteUser.modal.resendConfirmedTitle'),
        icon: 'warning',
      },
    );
  }

  @action
  closeModal(modalName) {
    this.modalManager.close(modalName);
  }

  @action
  resend(invitation) {
    debounce(this, this._reinvite, invitation, 500);
  }

  @action
  cancel(modalName) {
    this.modalManager.open(modalName);
  }

  @action
  confirmCancel(invitation) {
    tBoxClient.invite.delete(invitation.id).then(() => {
      this.notify.success(
        this.intl.t('inviteUser.modal.cancelConfirmedText', {
          user: invitation.guestEmail,
        }),
        {
          title: this.intl.t('inviteUser.modal.cancelConfirmedTitle'),
          icon: 'warning',
        },
      );
    });
    this.closeModal(`confirmCancel-${invitation.id}`);
  }

  @action
  showHistorical() {
    const otherRow = this.args.rowExpanded;
    if (otherRow && otherRow !== this) {
      otherRow.showHistory = false;
    }
    this.showHistory = !this.showHistory;
  }

  @action
  openWorkspacePreadd() {
    this.modalManager.open(this.workspaceModalName);
  }

  @action
  closeWorkspaceModal() {
    this.closeModal(this.workspaceModalName);
  }

  @action
  updateAllRights() {
    this.preaddSpaceMember.forEach((preaddSpace) => {
      tBoxClient.preaddedSpaceMember.setRole(preaddSpace.id, preaddSpace.role);
    });

    this.closeModal(this.workspaceModalName);
  }
}
