/* global ipc */
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const UPDATE_STATE_IDLE = 'idle';
const UPDATE_STATE_AVAILABLE = 'available';
const UPDATE_STATE_VERIFYING = 'verifying';
const UPDATE_STATE_DOWNLOADING = 'downloading';
const UPDATE_STATE_UPTODATE = 'uptodate';
const UPDATE_STATE_DISABLED = 'disabled';

export default class AutoUpdateService extends Service {
  @tracked state;
  @tracked newVersion = null;
  @tracked showUpdateNotification = false;
  @service notify;
  @service modalManager;
  @service intl;
  @service electronCommunication;
  @service connection;

  updateAvailableCallback;

  constructor() {
    super(...arguments);
    if (this.connection.isElectronApp) {
      this.setupIpc();
    }
  }

  get isUpdateAvailable() {
    return this.state === UPDATE_STATE_AVAILABLE;
  }

  get isUpdateVerifying() {
    return this.state === UPDATE_STATE_VERIFYING;
  }

  get isUpdateDownloading() {
    return this.state === UPDATE_STATE_DOWNLOADING;
  }

  get isUpToDate() {
    return this.state === UPDATE_STATE_UPTODATE;
  }

  get isUpdateEnabled() {
    return this.state !== UPDATE_STATE_DISABLED;
  }

  get isUpdateDisabled() {
    return this.state === UPDATE_STATE_DISABLED;
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.state = null;
    this.newVersion = null;
  }

  setupIpc() {
    // initialise the auto update
    ipc.autoUpdate.init();
    ipc.autoUpdate.on('disabled', () => {
      this.state = UPDATE_STATE_DISABLED;
      this.newVersion = null;
    });
    ipc.autoUpdate.on('enabled', () => {
      this.state = UPDATE_STATE_IDLE;
      this.newVersion = null;
    });

    ipc.autoUpdate.on('downloading', (data) => {
      this.state = UPDATE_STATE_DOWNLOADING;
      this.newVersion = data.version;
    });

    ipc.autoUpdate.on('verifying', () => {
      this.newVersion = null;
      this.state = UPDATE_STATE_VERIFYING;
    });

    ipc.autoUpdate.on('available', (data) => {
      this.state = UPDATE_STATE_AVAILABLE;
      this.newVersion = data.version;
      if (this.updateAvailableCallback) {
        this.updateAvailableCallback();
      }
    });

    ipc.autoUpdate.on('up-to-date', () => {
      this.newVersion = null;
      this.state = UPDATE_STATE_UPTODATE;
    });

    ipc.autoUpdate.on('error', (data) => {
      this.newVersion = null;
      this.state = null;
      if (data) {
        const message = this._getErrorMessage(data);

        console.info(
          `${new Date().toISOString()} - AUTO UPDATE:  ${message} ${
            data?.serverUrl ? `- - server  ${data.serverUrl} ` : ''
          } }`,
        );
        if (data.manual) {
          this._notifyError(data, message);
        }
      }
    });
  }

  check() {
    ipc.autoUpdate.check();
  }

  restart() {
    this.newVersion = null;
    ipc.autoUpdate.restart();
  }

  registerUpdateAvailableCallback(callback) {
    this.updateAvailableCallback = callback;
  }

  _notifyError(error, message) {
    const title = this.intl.t('about.error.title');
    this.notify.error(message, { title: title });
  }

  _getErrorMessage(error) {
    switch (error.error) {
      case 'update-execute-failed':
        return this.intl.t('about.error.updateExecute');
      case 'update-download-version-failed':
        return this.intl.t('about.error.updateDownloadVersion');
      case 'update-check-version-failed':
        return this.intl.t('about.error.updateCheckVersion');
      case 'update-download-failed':
        return this.intl.t('about.error.updateDownload');
      case 'update-invalid-signature':
        return this.intl.t('about.error.updateInvalidSignature');
      default:
        return this.intl.t('about.error.default');
    }
  }
}
