import { A } from '@ember/array';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import _ from 'lodash';

export default class InviteUser extends Component {
  @tracked searchEmail = null;
  @tracked lastSearchEmailValue = null;
  @tracked commentRequest = '';
  @tracked invalidEmails = A();
  @tracked existingUsers = A();
  @tracked whiteListedUsers = A();
  @tracked usersToInvite = A();
  @tracked entryRegistry = A();
  @tracked errorRequest;
  @tracked remainingInvites = 0;
  @tracked isSearching = false;
  @tracked invalidEmail = false;
  @tracked isButtonDisabled = true;

  @tracked inviteRequest = 1;
  userInviteModalName = 'user-invite-modal';

  @service account;
  @service modalManager;
  @service notify;
  @service intl;

  @action
  handleKeyPress(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      void this.addEmailToList();
    }
  }

  @action
  updateValue(newValue) {
    this.inviteRequest = newValue;
  }

  @action
  onInsertElement() {
    void this.addEmailToList();
  }

  @computed('usersToInvite.[]', 'remainingInvites', 'whiteListedUsers.[]')
  get notEnoughInvites() {
    if (this.usersToInvite.length === 0 && this.whiteListedUsers.length > 0) {
      return false;
    }

    return this.usersToInvite.length > this.remainingInvites;
  }

  @computed('commentRequest.length')
  get remainingCharacters() {
    const length = parseInt(this.commentRequest.length) || 0;
    return 200 - length;
  }

  @computed(
    'usersToInvite.length',
    'whiteListedUsers.length',
    'notEnoughInvites'
  )
  get buttonIsDisabled() {
    return (
      parseInt(this.usersToInvite.length + this.whiteListedUsers.length) ===
        0 || this.notEnoughInvites
    );
  }

  @computed('commentRequest')
  get disableRequest() {
    return this.commentRequest ? this.commentRequest.trim().length === 0 : true;
  }

  @action
  async click() {
    this.modalManager.open(this.userInviteModalName);
  }

  @task({ drop: true })
  *_inviteUser() {
    yield timeout(1000);
    if (this.buttonIsDisabled) return;
    const usersToInvite = this.usersToInvite;
    const whiteListedUsers = this.whiteListedUsers;
    const merged = _.cloneDeep(usersToInvite, true);

    merged.pushObjects(whiteListedUsers);
    const mergedLength = merged.length;
    merged.forEach((invitedUser, index) => {
      tBoxClient.invite.create(invitedUser.email).then(() => {
        if (index + 1 === mergedLength) {
          const notify = this.notify;
          notify.success(
            this.intl.t('toasts.inviteUser.message', {
              count: mergedLength,
              appname: this.intl.t('appname'),
            }),
            {
              title: this.intl.t('toasts.inviteUser.title', {
                count: mergedLength,
              }),
              icon: 'group',
            }
          );
        }
      });
    });
    this.closeModal();
  }

  @action
  reOpenInvite() {
    if (this.modalManager.isModalOpen('invitation-request')) {
      this.modalManager.close('invitation-request');
      this.modalManager.open(this.userInviteModalName);
    }
  }

  @action
  validateRequest() {
    this.errorRequest = null;
    const message = this.commentRequest.trim();
    const count = parseInt(this.inviteRequest);

    if (!message) {
      this.errorRequest = this.intl.t(
        'inviteUser.invitationRequest.errorComment'
      );
      return;
    }

    tBoxClient.invite.requestExtension(message, count).then(() => {
      this.reOpenInvite();
      this.notify.info(this.intl.t('inviteUser.invitationRequest.success'));
    });
  }

  @action
  async addEmailToList() {
    this.remainingInvites = await tBoxClient.invite
      .getLicenseUsage()
      .then((data) => {
        if (data.personalInvitesPool) {
          if (data.personalInvitesPool.available < 0) return 0;
          return data.personalInvitesPool.available;
        }
        return data.invitees.available;
      });
    if (this.isSearching) {
      return;
    }

    let searchEmail = this.searchEmail;

    if (searchEmail) {
      searchEmail = searchEmail.trim();
    }

    this.searchEmail = searchEmail;

    if (!searchEmail) {
      return false;
    }

    this.isSearching = true;
    tBoxClient.user.match(searchEmail).then((result) => {
      tBoxClient.invite
        .getUsersType(result.map(result => result.email))
        .then((invitationsType) => {
          result.forEach((user) => {
            user.type = invitationsType[user.email.toLowerCase()].licensePool;
            user.consumeInvite =
              invitationsType[user.email.toLowerCase()].willTakeInvites;

            if (user.isRegistered) {
              this.existingUsers.pushObject(user);
            } else {
              if (user.isEmailValid) {
                if (user.type === 'invitee') {
                  const toInvite = this.usersToInvite;
                  const emailList = toInvite.map((user) => user.email);

                  if (!emailList.includes(user.email))
                    this.usersToInvite.pushObject(user);
                } else {
                  const whiteListed = this.whiteListedUsers;
                  const emailList = whiteListed.map((user) => user.email);

                  if (!emailList.includes(user.email))
                    this.whiteListedUsers.pushObject(user);
                }
              } else {
                this.invalidEmails.pushObject(user);
              }
            }
          });

          this.searchEmail = null;
          this.isSearching = false;
          if (this.invalidEmails.length) {
            this.invalidEmail = true;
          }
        });
    });
  }

  @action
  removeUser(user) {
    this.existingUsers.removeObject(user);
  }

  @action
  openRequest() {
    this.inviteRequest = 1;
    this.commentRequest = '';
    this.errorRequest = null;
    this.modalManager.close(this.userInviteModalName);
    this.modalManager.open('invitation-request');
  }

  @action
  closeModal() {
    this.searchEmail = null;
    this.modalManager.close(this.userInviteModalName);
    this.clearInput();
  }

  @action
  inviteUser() {
    this._inviteUser.perform();
  }

  @action
  resetError() {
    if (this.invalidEmails) {
      this.invalidEmail = false;
    }
  }

  @action
  clearInput() {
    this.invalidEmails.clear();
    this.existingUsers.clear();
    this.whiteListedUsers.clear();
    this.usersToInvite.clear();
    this.invalidEmail = false;
  }
}
