import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
import { observes } from '@ember-decorators/object';

export default class DepositBoxFormController extends Controller {
  queryParams = ['code'];

  depositBox = null;

  @tracked hasDepositBoxFetchError = false;

  @tracked fileList = A([]);

  @tracked depositBoxCode = '';
  @tracked hideCode = true;
  @tracked depositBoxCodeErrorMessage = '';
  @tracked depositBoxUploadErrorMessage = '';
  @tracked inputFileValue = null;
  @tracked loading = false;

  @tracked isDraggingFile;

  lastNotifiedTransferErrorId = null;

  stepEnterCode = 'enter_code';
  stepAddInitialFile = 'add_initial_file';
  stepAddFile = 'add_file';
  stepValidated = 'validated';

  @tracked currentStep = this.stepEnterCode;

  comment = '';

  get showValidate() {
    return this.currentStep === this.stepAddFile;
  }

  get validateDisabled() {
    return this.depositBoxCode.trim() === '';
  }

  @service modalManager;

  @service('transfers') transferManager;

  @service account;

  @service observerFactory;

  @service intl;

  @observes(
    'transferManager.active.length',
    'transferManager.failed.length',
    'transferManager.completed.length',
  )
  fileListUpdate() {
    const failedTransfer = this.transferManager.failed[0] || null;
    if (failedTransfer) {
      if (this.lastNotifiedTransferErrorId !== failedTransfer.id) {
        const error = failedTransfer.error;
        if (error.code === libcryptobox.ErrorCode.InvalidParameter) {
          this.depositBoxUploadErrorMessage = this.intl.t(
            'depositBoxForm.fileUploadErrorAlreadyExist',
          );
        } else if (
          error.code === libcryptobox.ErrorCode.ServerError ||
          error.code === libcryptobox.ErrorCode.NotFound
        ) {
          this.hasDepositBoxFetchError = true;
          this.currentStep = this.stepEnterCode;
        } else {
          this.depositBoxUploadErrorMessage = this.intl.t(
            'depositBoxForm.fileUploadError',
          );
        }
      }

      this.lastNotifiedTransferErrorId = failedTransfer.id;
    } else {
      if (this.transferManager.failed.length === 0) {
        this.depositBoxUploadErrorMessage = '';
      }
    }

    this.fileList.length = 0;
    this.fileList.pushObjects(this.transferManager.active);
    this.fileList.pushObjects(this.transferManager.failed);
    this.fileList.pushObjects(this.transferManager.completed);
  }

  @action
  onElementInserted() {
    if (this.depositBox) {
      this.currentStep = this.stepAddInitialFile;
    }
    this.observerFactory.hideErrorNotification = true;
  }

  @action
  toggleHideCode() {
    this.hideCode = !this.hideCode;
  }

  @action
  toggleHideCodePress(event) {
    event.preventDefault();
  }

  @action
  toggleHideCodeReleasePress(event) {
    event.preventDefault();
  }

  @action
  validateDepositBoxCode(event) {
    this.depositBoxCode = event.target.value.trim();
    event.target.value = this.depositBoxCode;
  }

  @action
  openFilePicker() {
    document.getElementById('file-input').click();
  }

  @action
  onFileSelectedForUpload(e) {
    this.uploadFile(e.target.files);
    this.inputFileValue = null;
  }

  @action
  onFileDropped(fileList) {
    this.isDraggingFile = false;
    this.uploadFile(fileList);
  }

  @action
  onDragEnter() {
    this.isDraggingFile = true;
  }

  @action
  onDragExit() {
    this.isDraggingFile = false;
  }

  @action
  willDestroy() {
    this.account.hasLocalChanges = false;
    this.observerFactory.hideErrorNotification = false;
    this.depositBox = null;
  }

  @action
  onAllTransferDeleted() {
    this.currentStep = this.stepAddInitialFile;
    this.depositBoxUploadErrorMessage = '';
    this.account.hasLocalChanges = false;
  }

  @action
  async cancelAll() {
    try {
      await tBoxClient.depositBoxForm.removeAll(this.depositBox.id);
      this.account.hasLocalChanges = false;
    } finally {
      this.currentStep = this.stepAddInitialFile;
      this.transferManager.active.length = 0;
      this.transferManager.failed.length = 0;
      this.transferManager.completed.length = 0;
      this.depositBoxUploadErrorMessage = '';
    }
  }

  @action
  validate(comment) {
    this.comment = comment;
    if (!this.modalManager.isModalOpen('deposit-box-confirm')) {
      this.modalManager.open('deposit-box-confirm');
    }
  }

  @action
  cancelValidateDepositBox() {
    this.modalManager.close('deposit-box-confirm');
  }

  @action
  async validateDepositBox() {
    this.depositBoxUploadErrorMessage = '';
    this.loading = true;
    try {
      await tBoxClient.depositBoxForm.submit(this.depositBox.id, {
        comment: this.comment,
      });
      this.currentStep = this.stepValidated;
      this.account.hasLocalChanges = false;
      this.observerFactory.hideErrorNotification = false;
    } catch (error) {
      if (
        error.code === libcryptobox.ErrorCode.ServerError ||
        error.code === libcryptobox.ErrorCode.NotFound
      ) {
        this.hasDepositBoxFetchError = true;
        this.currentStep = this.stepEnterCode;
      } else {
        this.depositBoxUploadErrorMessage = this.intl.t(
          'depositBoxForm.validateError',
        );
      }
    } finally {
      this.modalManager.closeAll();
      this.loading = false;
    }
  }

  @action
  async submitCode() {
    this.loading = true;
    try {
      this.depositBox = await tBoxClient.depositBoxForm.get(
        window.location.href,
        { code: this.depositBoxCode },
      );
      this.currentStep = this.stepAddInitialFile;
    } catch (error) {
      if (
        error.code === libcryptobox.ErrorCode.ServerError ||
        error.code === libcryptobox.ErrorCode.NotFound
      ) {
        this.hasDepositBoxFetchError = true;
        this.currentStep = this.stepEnterCode;
      } else if (error.code === libcryptobox.ErrorCode.MaxCodeAttemptsReached) {
        this.depositBoxCodeErrorMessage = error.message;
        this.hasDepositBoxFetchError = true;
      } else {
        this.depositBoxCodeErrorMessage = error.message;
      }
    } finally {
      this.loading = false;
    }
  }

  uploadFile(fileList) {
    const failedTransferToRemove = this.transferManager.failed.filter(
      (item) => item.error.code === libcryptobox.ErrorCode.InvalidParameter,
    );
    failedTransferToRemove.forEach((element) =>
      this.transferManager.failed.removeObject(element),
    );
    if (this.transferManager.failed.length === 0) {
      this.depositBoxUploadErrorMessage = '';
    }
    this.account.hasLocalChanges = true;
    if (this.currentStep === this.stepAddInitialFile) {
      this.currentStep = this.stepAddFile;
    }
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      this.transferManager.uploadFileToDepositBoxUnAuthenticated(
        this.depositBox.id,
        file,
      );
    }
  }

}
