import { action, computed, set } from '@ember/object';
import ArrayProxy from '@ember/array/proxy';
import { debounce } from '@ember/runloop';
import { or, sort } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import { observes } from '@ember-decorators/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { DOWNLOAD_TYPE } from '../../../utils/enums';
import {
  FILE_TYPE_IMAGE,
  FILE_TYPE_PDF,
  FILE_TYPE_UNKNOWN,
  FileInfoPreview,
} from '../../../components/file-viewer/fileInfoPreview';
import mime from 'mime';
import getNodeIconName from '../../../utils/getNodeIconName';
import { DateTime } from 'luxon';

/* global libcryptobox */
export default class DocumentsController extends Controller {
  queryParams = ['path', 'trackingId', 'fileRevision'];
  @tracked path = '/';
  @tracked fileRevision = 0;
  @tracked trackingId = '';
  @tracked filePreviewPath = '';
  @tracked dragCount = 0;
  @tracked displayHistory = false;
  @tracked showSearched = '';
  @tracked selectedNodes = ArrayProxy.create({ content: [] });
  @tracked sortingProperty = 'name';
  @tracked sortingOrder = 'asc';
  @tracked displaySearched = true;
  @tracked isInitShareOpened = false;
  @tracked currentFileTrackingId = '';
  @tracked currentTrackingId = '';
  @tracked currentSelectedNode = '';

  @tracked showDragCount = false;
  @tracked dropInProgress = false;
  @tracked searchTerm = '';
  @tracked detailsIsVisible = false;
  @tracked displayOnlySearchedNodes = false;
  @tracked searchedNodes = null;
  @tracked allNodesAreLoaded = false;
  @tracked searchFlag = false;
  @tracked isFolderUpCapable = false;
  @tracked showPathInFiles = false;
  @tracked isSearchResults = false;
  @tracked searchPreviewLength = 5;
  @tracked lastClickedNode = null;
  @tracked detailedNode = null;
  selectedPage = 1;
  @tracked displaySearchedNodesPopup = false;
  @tracked selectedFoundResultsNode = null;
  @tracked selectedFoundResultsListOfNode = null;

  @service account;
  @service browser;
  @service store;
  @service commonService;
  @service notify;
  @service modalManager;
  @service intl;
  @service('transfers') transfersManager;
  @service router;

  copyMoveModalName = 'copy-move-modal copy-move-modal2';

  /**
   * disable interactions if we are in a deleted workspace, or if the user is not allowed to make changes
   */
  @computed('model.space.isDeleted', 'account.status.canWrite')
  get disabledActions() {
    return this.model.space.isDeleted || !this.account.status.canWrite;
  }

  @computed('account.status.canWrite', 'model.space.{isReader,isViewer}')
  get canEdit() {
    return (
      !this.model.space.isReader &&
      !this.model.space.isViewer &&
      this.account.status.canWrite
    );
  }

  @computed('model.space.isDeleted')
  get isSpaceDeleted() {
    return this.model.space.isDeleted;
  }

  @observes('model.space.accessRights')
  accessRightsObserver() {
    const isSharingAllowed =
      this.model.space.accessRights.includes(
        libcryptobox.SpaceAccessRight.AllSharings,
      ) ||
      this.model.space.accessRights.includes(
        libcryptobox.SpaceAccessRight.OnlyMySharings,
      );

    if (!isSharingAllowed && this.isInitShareOpened) {
      this.modalManager.close('init-share');
    }
  }

  @action
  isOpened(opened) {
    this.isInitShareOpened = opened;
  }

  get displayCancelSearch() {
    return this.searchTerm || this.searchedNodes?.length || this.searchFlag;
  }

  @computed('commonService.workspaceRevision')
  get workspaceRevision() {
    return this.commonService.workspaceRevision;
  }

  @computed('selectedNodes.length')
  get hasSeveralNodes() {
    return this.selectedNodes.length > 1;
  }

  @computed('commonService.workspaceRevision')
  get weAreInPast() {
    return 0 < this.commonService.workspaceRevision;
  }

  @computed('workspaceRevision')
  get showRevisions() {
    return this.workspaceRevision;
  }

  @computed(
    'displayHistory',
    'detailsIsVisible',
    'account.status.canWrite',
    'model.space.isReader',
  )
  get displayHistoryPanel() {
    const isReader = this.model.space.isReader;
    return this.displayHistory && this.account.status.canWrite && !isReader;
  }

  @or('detailsIsVisible', 'displayHistoryPanel') showSidePanel;

  @computed(
    'showSearched.{}',
    'model.files.@each.name',
    'displayOnlySearchedNodes',
  )
  get displayedNodes() {
    if (this.displayOnlySearchedNodes) return this.showSearched;

    return this.model.files;
  }

  @sort('displayedNodes', 'sorting')
  sortedNodes;

  @computed('sortedNodes')
  get fileViewerInfoPreviewList() {
    return this.sortedNodes
      .filter((file) => !file.isDirectory)
      .map((file) => {
        const filePath = file.path.startsWith('/')
          ? file.path.substring(1, file.path.length)
          : file.path;
        const mimeType = mime.getType(filePath);
        let fileType = '';
        if (mimeType && mimeType.startsWith('image')) {
          fileType = FILE_TYPE_IMAGE;
        } else if (mimeType && mimeType === 'application/pdf') {
          fileType = FILE_TYPE_PDF;
        } else {
          fileType = FILE_TYPE_UNKNOWN;
        }

        let author = '';

        if (file.author) {
          author =
            file.author.givenName +
            ' ' +
            file.author.surname +
            ' (' +
            file.author.email +
            ')';
        }

        let modifiedInfo;
        if (author) {
          modifiedInfo = this.intl.t('documents.fileViewer.modified', {
            date: DateTime.fromISO(file.lastModified).toLocaleString(
              DateTime.DATETIME_SHORT,
            ),
            user: author,
          });
        } else {
          modifiedInfo = this.intl.t('documents.fileViewer.modifiedNoAuthor', {
            date: DateTime.fromISO(file.lastModified).toLocaleString(
              DateTime.DATETIME_SHORT,
            ),
          });
        }

        const iconName = getNodeIconName(false, filePath.split('.').pop());
        return new FileInfoPreview(
          fileType,
          mimeType,
          filePath,
          modifiedInfo,
          iconName,
          file.id,
          null,
          file.contentSize,
        );
      });
  }

  @computed('filePreviewPath.length', 'fileViewerInfoPreviewList')
  get filePreviewIndex() {
    let previewPath = this.filePreviewPath;
    if (previewPath.startsWith('/')) {
      previewPath = previewPath.substring(1, this.filePreviewPath.length);
    }
    return this.fileViewerInfoPreviewList.findIndex((file) => {
      return previewPath === file.spacePath;
    });
  }

  constructor() {
    super(...arguments);
    const browser = this.browser.info;
    this.isFolderUpCapable =
      browser.name === 'Chrome' ||
      browser.name === 'Opera' ||
      (browser.name === 'Firefox' && browser.version >= 50);
    this.allNodesAreLoaded = true;
  }

  @computed('sortingProperty', 'sortingOrder')
  get sorting() {
    return ['isFolder:desc', `${this.sortingProperty}:${this.sortingOrder}`];
  }

  @computed('sortingOrder')
  get sortedDesc() {
    return this.sortingOrder === 'desc';
  }

  @computed('sortingProperty')
  get sortedByName() {
    return this.sortingProperty === 'name';
  }

  @computed('sortingProperty')
  get sortedByLastModification() {
    return this.sortingProperty === 'lastModified';
  }

  @computed('sortingProperty')
  get sortedBySize() {
    return this.sortingProperty === 'contentSize';
  }

  @computed('isSearchResults', 'allNodesAreLoaded')
  get searchLoading() {
    return this.isSearchResults && !this.allNodesAreLoaded;
  }

  @computed('isSearchResults', 'displayedNodes.[]')
  get noSearchResults() {
    return (
      this.isSearchResults &&
      (!this.displayedNodes || !this.displayedNodes.length)
    );
  }

  get seeMoreResults() {
    if (this.searchedNodes) {
      return this.searchedNodes?.length > this.searchPreviewLength;
    }

    return false;
  }

  get firstSearchedNodes() {
    if (this.searchedNodes?.length) {
      return this.searchedNodes.slice(0, this.searchPreviewLength);
    }
    return this.searchedNodes;
  }

  @observes('model.files.[]')
  cleanSelectedNodesOnNotif() {
    const files = this.model.files;
    this.selectedNodes.setObjects(
      this.selectedNodes.filter((file) => files.includes(file)),
    );
  }

  @computed('model.members.role')
  get hasViewer() {
    const members = this.model.members;

    return members.some((member) => {
      return member.role === libcryptobox.Role.Viewer;
    });
  }

  @computed('model.members.role', 'model.space.isViewer')
  get textViewerBanner() {
    if (this.model.space.isViewer) {
      return this.intl.t('documents.viewerBanner.iamViewer');
    }
    return this.intl.t('documents.viewerBanner.hasViewer');
  }

  _performAutocomplete(event) {
    this.allNodesAreLoaded = false;
    this.searchFlag = true;
    this.model.isLoading = true;
    const term = event.target.value;
    return this.store
      .query('file', {
        search: {
          spaceId: this.model.space.id,
          revision: -1,
          searchTerm: term,
        },
        adapterOptions: { subscribe: this },
      })
      .then((files) => {
        this.searchedNodes = files;
        this.displaySearchedNodesPopup = true;
        this.isSearchResults = true;
        this.allNodesAreLoaded = true;
        this.model.isLoading = false;
      })
      .then(() => {
        if (event.key === 'Enter') {
          this.displaySearchedTermResultsOnly();
        }
      })
      .catch((error) => {
        console.error(error);
        this.notify.error(error.message + ' ' + error.details);
        this.allNodesAreLoaded = true;
        this.model.isLoading = false;
      });
  }

  /*
   * TODO Handle an event from ListedNode component that bubbles current selected node
   * See : handleOnContextMenuTreeItem action
   * */
  @observes('selectedNodes.length')
  selectedNodeObserver() {
    if (Array.isArray(this.selectedNodes) && this.selectedNodes.length > 0) {
      this.currentSelectedNode =  this.selectedNodes?.at?.(0);
    }
  }

  /*
   * Currently used only with TreeItem component
   * TODO Handle selected node from ListedNode component
   * */
  @action
  handleOnContextMenuTreeItem(node) {
    this.currentSelectedNode = node;
  }

  @action
  setPropertyValue(propertyName, value) {
    this[propertyName] = value;
  }

  @action
  setSelectedPath(path) {
    this.path = path;
  }

  @action
  downloadZip(fileRevision) {
    const sources = this.selectedNodes.map( node => node.path);
    const spaceId = this.model.space.id;
    this.transfersManager.downloadZip(spaceId, sources, fileRevision);
  }

  @action
  downloadFile(dlType, nodeId, fileRevision = 0) {
    if (dlType === DOWNLOAD_TYPE.ZIP) {
      return this.downloadZip(fileRevision);
    } else {
      return this.transfersManager.downloadFile(nodeId, fileRevision);
    }
  }

  @action
  async download(dlType) {
    const fileRevision = this.model.fileRevision;

    if (dlType === DOWNLOAD_TYPE.FILE) {
      this.selectedNodes.forEach((node) => {
        this.downloadFile(dlType, node.get('id'), fileRevision);
      });
    } else if (dlType === DOWNLOAD_TYPE.ZIP) {
      this.downloadZip(fileRevision);
    } else {
      console.error(
        'my-groups.group.documents controller : Unknown action type',
        dlType,
      );
    }
  }

  @action
  autocomplete(event) {
    debounce(this, this._performAutocomplete, event, 500);
  }

  @action
  moveObject(element, event) {
    if (event.dataTransfer.files.length) {
      return;
    }
    this.showDragCount = false;
    const node = event.customDropData;
    if (!node.isFolder) {
      const message = this.intl.t('documents.actions.dragFile.notFolder');
      return this.notify.info(message, {
        title: this.intl.t('errors.occur'),
        icon: 'warning',
      });
    }

    const member = this.model.members.findBy('email', this.account.userEmail);
    if (member.role === libcryptobox.Role.Auditor) {
      const message = this.intl.t('toasts.permissionError.message');
      const title = this.intl.t('toasts.permissionError.title');
      return this.notify.error(message, {
        title: title,
        icon: 'warning',
      });
    }

    this.dropInProgress = true;
    const spaceId = this.model.space.get('id');
    let source;
    const nodes = this.selectedNodes;
    if (!isEmpty(nodes)) {
      source = nodes.map(
        (file) => file.get('space.id') + ':' + file.get('path'),
      );
    } else {
      const path = event.dataTransfer.getData('customDragDataPath');

      if (!path) {
        return;
      }

      source = [spaceId + ':' + path];
    }

    const target = spaceId + ':' + node.path;
    return tBoxClient.file
      .move(source, target)
      .then(() => {
        this.dropInProgress = false;
        const countNodes = source.length;
        const message = this.intl.t('documents.actions.dragFile.success', {
          count: countNodes,
        });
        this.notify.info(message, {
          title: this.intl.t('documents.actions.dragFile.title'),
          icon: 'file',
        });
      })
      .catch((error) => {
        this.dropInProgress = false;
        if (error.code !== libcryptobox.ErrorCode.InvalidParameter) {
          this.notify.error(error.message, {
            title: this.intl.t('errors.occur'),
            icon: 'warning',
          });
        }

        this.loadingModifyFile = false;
      });
  }

  /**
   * on click on a "show more" button (svg with dots "..."), toggle action panel.
   * @param node
   */
  @action
  showDetails(node) {
    if (node && node.id) {
      this.lastClickedNode = node;
      this.detailedNode = node;
      this.displayHistory = false;
      this.detailsIsVisible = !this.detailsIsVisible;
    } else {
      console.error('no node', node);
    }
  }

  @action
  setLastClickedNode(node) {
    this.lastClickedNode = node;
  }

  @action
  hideDetails() {
    this.displayHistory = false;
    this.detailsIsVisible = false;
  }

  @action
  onFileViewerClose() {
    this.filePreviewPath = '';
  }

  @action
  toggleHistory() {
    this.displayHistory = !this.displayHistory;
  }

  /**
   * display a found result as the only thing to display in the main file list
   * @param node
   */
  @action
  displayOnlyNode(node) {
    this.displaySearchedNodesPopup = false;
    this.showPathInFiles = true;
    this.searchedNodes = [node];
    this.displaySearchedTermResultsOnly();
  }

  /**
   * show all the search results in the main list of files
   */
  @action
  displaySearchedTermResultsOnly() {
    if (this.searchTerm) {
      this.displaySearchedNodesPopup = false;
      this.displayOnlySearchedNodes = true;
      this.selectedNodes = [];
      this.showPathInFiles = true;
      this.showSearched = this.searchedNodes;
    }
  }

  @action
  onViewFileFromDetails(filePath) {
    this.filePreviewPath = filePath;
  }

  /**
   * clean search input, back to normal file list
   */
  @action
  cancelSearch() {
    this.displaySearchedNodesPopup = false;
    this.selectedFoundResultsNode = null;
    this.selectedFoundResultsListOfNode = null;
    this.searchTerm = '';
    this.isSearchResults = false;
    this.searchFlag = false;
    this.showPathInFiles = false;
    this.searchedNodes = null;
    this.displayOnlySearchedNodes = false;
    this.selectedNodes.clear();
  }

  @action
  dragStart(node) {
    const nodeSelectedIsDrag = this.selectedNodes.includes(node);
    const countNodes =
      this.selectedNodes.length === 0 || !nodeSelectedIsDrag
        ? 1
        : this.selectedNodes.length;
    this.showDragCount = true;
    this.dragCount = countNodes;
  }

  @action
  sortNodesBy(propertyToSort) {
    const sortingProperty = this.sortingProperty;
    const sortingOrder = this.sortingOrder;

    if (sortingProperty === propertyToSort) {
      this.sortingOrder = sortingOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortingProperty = propertyToSort;
    }
  }

  @action
  async refreshFiles() {
    await this.refreshRoute();
  }

  @action
  goToCurrentRevision() {
    const spaceId = this.model.space.get('id');
    this.router.transitionTo('my-groups.group.documents', {
      queryParams: { fileRevision: 0, spaceId: spaceId },
    });
  }

  @action
  onOver(node) {
    if (node.isFolder) {
      node.set('overred', true);
    }
  }

  @action
  onOverExit(node) {
    if (node.isFolder) {
      node.set('overred', false);
    }
  }

  @action
  setWorkspace(ws) {
    this.commonService.selectedSpace = ws;
    set(this, 'model.space', ws);
  }

  @action
  openViewerInfo() {
    this.modalManager.open('viewer-devices');
  }
}
