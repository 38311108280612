// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr } from '@ember-data/model';

// The preview of an attachment in an instant message.
// See AttachmentRecord.
export default class extends Model {
  // The original image in the preview.
  // @type Size
  @attr original;
}

