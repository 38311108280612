import { oneWay } from '@ember/object/computed';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class extends Component {
  @tracked isOpen = false;

  @oneWay('elementId') name;

  get group() {
    return this.args.group;
  }

  @action
  toggleIsOpen() {
    const panels = document.querySelectorAll('.panel');
    panels.forEach((panel) => {
      panel.classList.remove('panel-is-open');
    });

    const panelsBody = document.querySelectorAll('.panel-body');
    panelsBody.forEach((panelBody) => {
      panelBody.classList.remove('panel-is-open');
    });

    this.isOpen = !this.isOpen;
    let group = this.group;
    if (group) {
      if (group.accordion) {
        if (this.isOpen) {
          this.args.setOpenPanel(this.name);
        } else {
          this.args.setOpenPanel(null);
        }
      }
    }
  }
}
