import Service, { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { tBoxClient } from 'client/initializers/init-toolbox';

export default class SecondFactorAuthenticatorService extends Service {
  @tracked secondFactorUrl;
  @tracked emailOtp = '';

  @service modalManager;
  @service account;

  constructor() {
    super(...arguments);
    tBoxClient.setSecondFactorAuthenticator({
      openUrl: async (url) => {
        /* To do: group modal name in constant */
        // Close modal and wait for close before showing 2fa modal to make sure onClose() is called on those closed modal(see remodal issue)
        const isModalOpened = this.modalManager.isModalOpen('changePassword') && this.modalManager.isModalOpen('changePassword');
        if (isModalOpened) {
          await this.modalManager.close('changePassword');
        }

        this.secondFactorUrl = url;
        this.modalManager.open('2fa');
      },
      requestOtp: async (email) => {
        /* To do: group modal name in constant */
        // Close modal and wait for close before showing otp modal to make sure onClose() is called on those closed modal(see remodal issue)
        const isModalOpened = this.modalManager.isModalOpen('changePassword') && this.modalManager.isModalOpen('changePassword');
        if (isModalOpened) {
          await this.modalManager.close('changePassword');
        }

        if (!this.modalManager.isModalOpen('otp') && this.account.isLoggedIn) {
          this.modalManager.open('otp');
        }
        this.emailOtp = email;
      },
    });
  }


  @computed('secondFactorUrl')
  get secondFactorDomain() {
    try {
      return new URL(this.secondFactorUrl).hostname;
    } catch (e) {
      return '';
    }
  }

  async setOtp(code) {
    if (this.emailOtp) {
      return await tBoxClient.otp.set(code).then(() => {
        // Clear otp email after code is set
        this.emailOtp = null;
      });
    }
  }

  async cancelOtp() {
    if (this.emailOtp) {
      this.emailOtp = null;
      return await tBoxClient.otp.cancel();
    }
  }

  async resendOtp() {
    if (this.emailOtp) {
      return await tBoxClient.otp.resend();
    }
  }

  reset() {
    this.secondFactorUrl = null;
    this.emailOtp = null;
  }
}
