// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr, belongsTo } from '@ember-data/model';

// The file download subscriptions in a space.
// See FileDownloadSubscriptionCollection.
export default class extends Model {
  // Space's identifier.
  // @type string
  @attr spaceId;
  
  // Subscribed paths.
  // @type Array<string>
  @attr paths;
  
  @belongsTo('space', { async: true, inverse: null }) space;
}

