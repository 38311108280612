import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
/* global libcryptobox */

export default class GroupController extends Controller {
  @service account;
  @service messaging;
  @service router;

  @tracked showHeader = true;

  get isInPersonalSpace() {
    return this.account.personalSpaceId === this.model.id;
  }

  get sharingAllowed() {
    const isAllowed =
      this.model.accessRights.includes(
        libcryptobox.SpaceAccessRight.AllSharings,
      ) ||
      this.model.accessRights.includes(
        libcryptobox.SpaceAccessRight.OnlyMySharings,
      );

    if (!isAllowed && this.router.isActive('my-groups.group.shares')) {
      this.router.transitionTo('my-groups.group.documents');
    }

    return isAllowed;
  }

  @action
  onBackButton() {
    window.history.back();
  }
}
