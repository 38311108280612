import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class Invitations extends Route {
  @service intl;
  @service store;
  @service authService;

  beforeModel() {
    this.authService.loginIfNotAuthenticated();
  }

  model() {
    return this.store.query('invite', {
      pageSize: -1,
      pageId: -1,
      orderBy: '',
      sort: '',
      adapterOptions: { subscribe: this },
    });
  }

  afterModel() {
    document.title = 'Cryptobox - ' + this.intl.t('navigation.my.invitations');
  }

  setupController(controller, model) {
    controller.set('model', model);
    controller.set('searchTerm', null);
    controller.set('selectedStatus', null);
    controller.set('showCancel', true);
    controller.set('selectedPage', 1);
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('showCancel', false);
    }
  }

  deactivate() {
    this.store.adapterFor('invite').unsubscribe(this);
  }
}
