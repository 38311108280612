export default function getNotifyRightChange(currentUserStatus, newUserStatus) {
  let notifyRightChange = false;
  if (currentUserStatus.maximumLevel !== newUserStatus.maximumLevel) {
    notifyRightChange = true;
  }
  if (currentUserStatus.personalSpace !== newUserStatus.hasPersonalSpace) {
    notifyRightChange = true;
  }
  if (currentUserStatus.createSpace !== newUserStatus.canCreateSpace) {
    notifyRightChange = true;
  }
  if (currentUserStatus.canInvite !== newUserStatus.canInviteUsers) {
    notifyRightChange = true;
  }
  if (currentUserStatus.setInviteRights !== newUserStatus.canSetInviteRights) {
    notifyRightChange = true;
  }
  if (currentUserStatus.canWrite !== newUserStatus.canWrite) {
    notifyRightChange = true;
  }

  return notifyRightChange;
}
