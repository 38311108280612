export default function getDepositBoxStateFilters(intl) {
  return [
    {
      value: 'none',
      label: intl.t('depositBox.badge.all'),
    },
    {
      value: 'empty',
      label: intl.t('depositBox.badge.empty'),
    },
    {
      value: 'populatedRead',
      label: intl.t('depositBox.badge.populatedUnreadOrPopulatedRead'),
    },
    {
      value: 'populatedProcessed',
      label: intl.t('depositBox.badge.populatedProcessed'),
    }
  ];
}
