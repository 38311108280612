import { observer } from '@ember/object';
import fileTransfer from 'client/custom-objects/file-transfer';
import {DateTime} from "luxon";

export default fileTransfer.extend({
  direction: 'download',
  revision: 0,
  target: null,

  init() {
    this._super();
    this.initMeasure();

    if (this.state === 'active' && this.kind === 'file') {
      void this.startDownload();
    }

    if (this.state === 'active' && this.kind === 'zip') {
      void this.startDownloadZip();
    }

    if (this.state === 'active' && this.kind === 'history') {
      void this.startDownloadFileHistory();
    }

    if (this.state === 'active' && this.kind === 'downloadDepositBox') {
      void this.startDownloadDepositBox();
    }
  },

  activate: observer('state', function() {
    if (this.state === 'active' && this.kind === 'file') {
       this.startDownload();
    }

    if (this.state === 'active' && this.kind === 'zip') {
       this.startDownloadZip();
    }

    if (this.state === 'active' && this.kind === 'history') {
      this.startDownloadFileHistory();
    }

    if (this.state === 'active' && this.kind === 'downloadDepositBox') {
      this.startDownloadDepositBox();
    }
  }),

  async startDownload() {
    let { spaceId, path, workspaceRevision: revision } = this;
    this.observe(this.target.download(spaceId, path, revision));
  },

  async startDownloadZip() {
    let { spaceId, sources, workspaceRevision: revision } = this;
    this.observe(this.target.downloadZip(spaceId, sources, revision));
  },

  async startDownloadFileHistory() {
    let { spaceId, trackingId } = this;
    this.observe(this.target.downloadFileHistory(spaceId, trackingId));
  },

  async startDownloadDepositBox() {
    let { boxId, fileNames, isZip } = this;
    this.observe(this.target.downloadDepositBox(boxId, fileNames, isZip));
  },

  observe(promise) {
    // TODO : duplication in share-download
    promise.onprogress = (_, loaded, total) => {
      this.set('loaded', loaded);
      this.set('size', total);
    };
    this.set('cancelTransfer', promise.cancel);
    this.set('startTime', DateTime.now());
    this.peekProgressMeasure();
    promise
      .then(() => {
        this.set('state', 'completed');
      })
      .catch((error) => {
        if (this.state === 'completed') {
          return;
        }
        if (error.code === window.libcryptobox.ErrorCode.Canceled) {
          this.set('isCanceled', true);
          this.set('toasted', true);
        }
        this.set('state', 'failed');
      });
  },

  saveAs() {
    if (this.state === 'completed' && this.kind === 'file') {
      this.startDownload();
    }

    if (this.state === 'completed' && this.kind === 'zip') {
       this.startDownloadZip();
    }

    if (this.state === 'completed' && this.kind === 'downloadDepositBox') {
      void this.downloadDepositBox();
    }
  },

});
