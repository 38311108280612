import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class ReplaceFileInput extends Component {

  @tracked value = null;

  @service transfers;
  get currentFolder() {
    return this.args.currentFolder;
  }

  get disabled() {
    return this.args.disabled;
  }

  @action
  handleFileInputCancel() {
    if (this.args.handleChangeContextMenu) {
      this.args.handleChangeContextMenu(false);
    }
  }

  @action
  handleFileInputChange(event) {
    let file = event.target.files[0];
    if (!file) {
      return false;
    }
    file.CBRelativePath = '';

    void this.transfers.replaceFileContent(
      file,
      this.currentFolder,
      this.args.node
    );
    this.value = null;

    if (this.args.handleChangeContextMenu) {
      this.args.handleChangeContextMenu(false);
    }
  }
}
