import Component from "@glimmer/component";
import { action } from "@ember/object";
import { tBoxClient } from "client/initializers/init-toolbox";
import { inject as service } from "@ember/service";

export default class LocalAccountComponent extends Component {
  @service connection;

  get classColor() {
    if (this.args.account.color === 0) {
      return "primary";
    }
    if (this.args.account.color === 1) {
      return "secondary";
    }
    return "grey";
  }

  get accountLetter() {
    let url = this.args.account.serverUrl;
    if (url !== undefined) {
      if (url.startsWith("http://")) {
        return url.charAt(7).toUpperCase();
      } else if (url.startsWith("https://")) {
        return url.charAt(8).toUpperCase();
      }
    }
    return "";
  }

  @action
  loginAccount(account) {
    this.connection.tmpServerAddress = account.serverUrl;
    this.args.onSelectAccount(account.id);
  }

  @action
  dissociateAccount(account) {
    tBoxClient.account.remove(account.id);

    const { model, onRemoveAllLocalAccount } = this.args;
    model.removeObject(account);
    if (!model.length) {
      onRemoveAllLocalAccount();
    }
  }
}
