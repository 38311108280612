import Component from '@glimmer/component';
import { once } from '@ember/runloop';
import { action } from '@ember/object';

export default class CustomInputComponent extends Component {
  get checkedStr() {
    return this.args.checked?.toString() ?? null;
  }

  @action
  change() {
    if (this.args.groupValue !== this.args.value) {
      once(this.args, 'changed', this.args.value);
    }
  }
}
