import { emit, listen } from "@tauri-apps/api/event";
import { Base64 } from "js-base64";
import RpcError from "../error";

export default class TauriIPCTransport {
  ready = false;

  onready;
  onmessage;
  onerror;
  onclose;

  async connect() {
    if (!this.ready) {
      listen("rpc:tauri:rx", (event) => {
        this.onmessage?.(JSON.parse(event.payload));
      });
      listen("rpc:tauri:error", (event) => {
        this.onerror?.(new RpcError(`tauri-ipc: ${event.payload}`));
      });
      listen("rpc:tauri:close", (event) => {
        this.onclose?.();
      });
      this.ready = true;
      this.onready?.();
    }
  }

  close() {}

  async send(message) {
    emit("rpc:tauri:tx", message);
  }

  encodeUint8Array(value) {
    return Base64.fromUint8Array(value);
  }

  decodeUint8Array(value) {
    return Base64.toUint8Array(value);
  }
}
