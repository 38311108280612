/**
 * clearStorage is a function for clearing localStorage or sessionStorage
 * @param {Object} args - The arguments object
 * @param {string[]} args.toKeep - Array of keys to keep
 * @param {string[]} args.toRemove - Array of keys to remove
 * @param {string} [args.storageType='localStorage'] - Type of storage: 'localStorage' or 'sessionStorage'
 */
export const clearStorage = (args = {}) => {
  const {
    toKeep = [],
    toRemove = [],
    storageType = 'localStorage'
  } = args
  const storage = storageType === 'sessionStorage' ? sessionStorage : localStorage;

  Object.keys(storage).forEach(key => {
    if ((toKeep.length === 0 || !toKeep.includes(key)) && toRemove.includes(key)) {
      storage.removeItem(key);
    }
  });

  if (toKeep.length === 0 && toRemove.length === 0) {
    storage.clear();
  }
};
