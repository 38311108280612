import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class Shares extends Route {
  @service store;
  @service intl;
  @service router;

  model() {
    let spaceId = this.paramsFor('my-groups.group').space_id;
    let model;

    return this.store
      .findRecord('space', spaceId)
      .then((space) => {
        model = space;

        return this.store.query('file-sharing', {
          spaceId,
          adapterOptions: { subscribe: this },
        });
      })
      .then((shares) => {
        model.set('shares', shares.sortBy('fileName'));
        return model;
      });
  }

  setupController(controller, model) {
    controller.set('model', model);
    controller.set('selectedShare', null);
    controller.set('detailsIsVisible', false);
    controller.set('refreshRoute', this.refreshRoute.bind(this));
  }

  afterModel(resolvedModel) {
    let spaceName = resolvedModel.get('name');
    document.title =
      'Cryptobox - ' +
      spaceName +
      ' - ' +
      this.intl.t('group.navigation.shares');
  }

  deactivate() {
    this.store.adapterFor('file-sharing').unsubscribe(this);
  }

  @action
  refreshRoute() {
    this.refresh();
  }
}
