/* global libcryptobox */
import ArrayProxy from '@ember/array/proxy';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { DateTime } from 'luxon';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { task, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { addDaysToDate } from '../../../../utils/dateTime';

export default class ShareCreationComponent extends Component {
  @tracked expirationDate = false;
  @tracked suggestedNameSharing = '';
  @tracked noRecipient;
  @tracked recipients = ArrayProxy.create({ content: [] });
  @tracked shareMessage;
  @tracked sharingKey = '';
  @tracked sharingURL;
  @tracked step = 1;
  @tracked kind = null;
  @tracked shared;
  @tracked showToastCopy = false;
  @tracked showMail = false;
  @tracked keyClipboard = 'linkCopied';
  @tracked shareRecordUrl = null;
  @tracked forceDisableMail = false;
  @tracked disableFinish = false;
  @tracked disableCreatingSharingLinkIncludingKey = false;
  @tracked hasExpirationDate = false;
  @tracked isUniqueDownload = false;
  @tracked isMaxMailsError = false;
  @tracked expirationDisabled = false;
  @tracked maxDate = null;
  @tracked minDate = null;
  @tracked defaultDate;
  sharingLinkMaxPeriod;
  sharingLinkDefaultPeriod;
  @tracked isNotify = false;

  @service modalManager;
  @service store;
  @service account;
  @service intl;

  constructor() {
    super(...arguments);
    tBoxClient.serverSettings.get().then((settings) => {
      this.sharingLinkMaxPeriod = settings.sharingLinkMaxPeriod;
      this.sharingLinkDefaultPeriod = settings.sharingLinkDefaultPeriod;

      this.setExpirationDate(
        this.sharingLinkMaxPeriod,
        this.sharingLinkDefaultPeriod,
      );

      this.disableCreatingSharingLinkIncludingKey =
        settings.disableCreatingSharingLinkIncludingKey;
      this.kind = this.disableCreatingSharingLinkIncludingKey
        ? 'separate'
        : null;
    });
  }

  get selectedNodes() {
    return this.args.selectedNodes;
  }

  @action
  updateSuggestedName() {
    if (!this.selectedNodes || this.selectedNodes.length <= 1) {
      this.suggestedNameSharing = '';
    } else {
      tBoxClient.fileSharing
        .getSuggestedName(
          this.selectedNodes.firstObject.space.get('id'),
          this.selectedNodes.map( node => node.path),
        )
        .then((name) => {
          this.suggestedNameSharing = name;
        })
        .catch((error) => {
          console.error('Failed to get suggested name:', error);
          this.suggestedNameSharing = '';
        });
    }
  }

  setExpirationDate(sharingLinkMaxPeriod, sharingLinkDefaultPeriod) {
    const today = new Date();

    this.minDate = today;
    this.maxDate = null;
    if (!sharingLinkMaxPeriod && !sharingLinkDefaultPeriod) {
      this.expirationDisabled = false;
      this.hasExpirationDate = false;
      this.expirationDate = today;
    } else if (sharingLinkMaxPeriod && !sharingLinkDefaultPeriod) {
      this.expirationDisabled = true;
      this.hasExpirationDate = true;
      this.expirationDate = addDaysToDate(today, sharingLinkMaxPeriod);
      this.maxDate = addDaysToDate(today, sharingLinkMaxPeriod);
    } else if (!sharingLinkMaxPeriod && sharingLinkDefaultPeriod) {
      this.expirationDisabled = false;
      this.hasExpirationDate = true;
      this.expirationDate = addDaysToDate(today, sharingLinkDefaultPeriod);
    } else if (sharingLinkMaxPeriod && sharingLinkDefaultPeriod) {
      this.expirationDisabled = true;
      this.hasExpirationDate = true;
      this.maxDate = addDaysToDate(today, sharingLinkMaxPeriod);

      if (sharingLinkDefaultPeriod > sharingLinkMaxPeriod) {
        this.expirationDate = addDaysToDate(today, sharingLinkMaxPeriod);
      } else {
        this.expirationDate = addDaysToDate(today, sharingLinkDefaultPeriod);
      }
    }
    this.defaultDate = this.expirationDate;
  }

  get disableNext() {
    if (this.step !== 1) return false;
    return this.kind == null;
  }

  get recipientButtonIsDisabled() {
    return this.recipients.get('length') === 0 || this.emailInput;
  }

  get formattedExpirationDate() {
    if (this.hasExpirationDate) {
      return DateTime.fromJSDate(this.expirationDate)
        .endOf('day')
        .toUTC()
        .toISO();
    }
    return '';
  }

  get isStep2() {
    return this.step >= 2;
  }

  get isStep3() {
    return this.step >= 3;
  }

  get disableMail() {
    return (
      this.recipients.length === 0 ||
      this.forceDisableMail ||
      (this.isUniqueDownload && this.recipients.length > 1)
    );
  }

  get title() {
    return this.intl.t('documents.actions.share.title', {
      count: this.args.selectedNodes.length,
    });
  }

  get uniqueDownload() {
    return this.isUniqueDownload
      ? libcryptobox.UniqueDownload.Downloadable
      : libcryptobox.UniqueDownload.Disabled;
  }

  get maxEmails() {
    return this.isUniqueDownload ? 1 : -1;
  }

  get isFileSharingAuthorSubscriptionEnabled() {
    const fSAuthorSub =
      libcryptobox.ServerCapability.FileSharingAuthorSubscription;
    return this.account.serverSettings.capabilities.includes(fSAuthorSub);
  }

  @(task(function* () {
    yield timeout(1000);

    const recipients = this.recipients.content;

    if (!recipients.length) {
      this.noRecipient = true;
      return;
    } else {
      this.noRecipient = false;
    }

    return this.createFileSharingRecord(recipients, this.shareMessage)
      .save()
      .then((shareRecord) => {
        this.shareRecordUrl.destroyRecord();
        const sharingKey = shareRecord.get('key');
        this.shared = sharingKey;
        this.step += 1;
        this.forceDisableMail = false;
      })
      .catch(() => {
        this.modalManager.close('share-recipients');
      });
  }).drop())
  _shareByEmail;

  @(task(function* () {
    yield timeout(1000);

    let shareRecord = this.createFileSharingRecord();

    return shareRecord
      .save()
      .then((shareRecord) => {
        this.shared = shareRecord.get('url');
        this.disableFinish = false;
      })
      .catch(() => {
        this.closeShare();
      });
  }).drop())
  _shareByUrl;

  @action
  shareCreationNextStep() {
    if (this.disableNext) return;

    if (this.step === 1 && this.kind === 'included') {
      this.disableFinish = true;
      this.shared = this.intl.t('documents.actions.share.generatingLink');
      this.step = 3;
    } else {
      this.step += 1;
    }

    if (this.step === 2) {
      this.createFileSharingRecord()
        .save()
        .then((shareRecord) => {
          this.shareRecordUrl = shareRecord;
          this.shareRecordUrl.set('id', shareRecord.get('token'));
          this.shared = shareRecord.get('key');
          this.sharingURL = shareRecord.get('url').split('?key=')[0];
        });
    }

    if (this.step === 3 && this.kind === 'included') {
      this._shareByUrl.perform();
    }
  }

  @action
  filterDate(date) {
    this.expirationDate = date;
  }

  @action
  setPropertyValue(property, value) {
    return (this[property] = value);
  }

  @action
  shareByMail() {
    if (this.forceDisableMail || this.disableMail) return;

    this.forceDisableMail = true;
    this._shareByEmail.perform();
  }

  @action
  notifyOpened() {
    this.args.isOpened(true);
    this.updateSuggestedName();
  }

  @action
  clearInputs() {
    this.args.isOpened(false);
    //share not finished, temporary shareRecord has to be removed
    this.modalManager.close('init-share');
    if (this.step !== 3 && this.shareRecordUrl) {
      this.shareRecordUrl.destroyRecord();
      this.shareRecordUrl = null;
    }
    this.sharingURL = '';
    this.sharingKey = '';
    this.kind = this.disableCreatingSharingLinkIncludingKey ? 'separate' : null;
    this.recipients.clear();
    this.shareMessage = '';
    this.step = 1;
    this.forceDisableMail = false;
    this.showMail = false;
    this.isUniqueDownload = false;
    this.setExpirationDate(
      this.sharingLinkMaxPeriod,
      this.sharingLinkDefaultPeriod,
    );
    this.isNotify = false;
    this.suggestedNameSharing = '';
  }

  @action
  closeModal() {
    if (this.disableFinish) return;
    this.showMail = false;
    this.modalManager.close('init-share');
  }

  @action
  previousAction() {
    if (this.forceDisableMail) return;

    if (this.step === 3 && this.kind === 'included') {
      return (this.step = 1);
    }

    if (this.step === 2 && this.shareRecordUrl) {
      this.shareRecordUrl.destroyRecord();
      this.shareRecordUrl = null;
    }

    this.step -= 1;
  }

  @action
  selectUrl() {
    document.querySelector('#share-url-output').select();
    document.execCommand('Copy');
    this.keyClipboard = 'linkCopied';
    this.showToastCopy = true;

    setTimeout(() => (this.showToastCopy = false), 3000);
  }

  @action
  selectKey(inputName) {
    if (this.disableFinish) return;
    document.querySelector('#share-key-output').select();
    document.execCommand('Copy');
    this.keyClipboard = inputName === 'key' ? 'keyCopied' : 'linkCopied';
    this.showToastCopy = true;

    setTimeout(() => (this.showToastCopy = false), 3000);
  }

  @action
  toggleType(type) {
    this.showMail = type === 'mail';
  }

  @action
  openPicker() {
    const picker = document.querySelector('#expirationDate-picker');
    picker.click();
  }

  @action
  onCheckboxChange(event) {
    const { name, checked } = event;
    this[name] = checked;
  }

  createFileSharingRecord(recipients, message) {

    const node = this.args.selectedNodes.content?.at?.(0);

    return this.store.createRecord('file-sharing', {
      name: this.suggestedNameSharing,
      space: node.space,
      pathList: this.args.selectedNodes.map( node => node.path),
      recipients: recipients,
      message,
      notAfter: this.formattedExpirationDate,
      uniqueDownload: this.uniqueDownload,
      authorSubscription: this.isNotify,
    });
  }
}
