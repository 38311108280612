import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class CreateFolder extends Component {
  @service modalManager;
  @service intl;

  @action
  async handleClick() {
    this.modalManager.open('create-folder');
    if (this.args.handleCloseContextMenu) {
      this.args.handleCloseContextMenu();
    }
  }

}
