import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { debounce } from '@ember/runloop';
import { guidFor } from '@ember/object/internals';
/* global libcryptobox */

export default class LeftPanelConversation extends Component {
  @service router;
  @tracked fetchMessage = null;
  @tracked showDot = false;
  elementId = guidFor(this);

  get kind() {
    return this.args.kind;
  }

  @computed('kind')
  get imageName() {
    let result = 'img-direct-message';

    if (this.kind === libcryptobox.SpaceKind.Workspace) {
      result = 'img-workspace-message';
    } else if (this.kind === libcryptobox.SpaceKind.GroupConversation) {
      result = 'img-group-message';
    }

    return result;
  }

  _checkCursors() {
    if (!document.querySelector('#' + this.elementId)) return;

    if (this.router.currentRoute.params.space_id === this.args.cursor.get('id')) {
      this.showDot = false;
      return false;
    } else {
      this.showDot = this.args.cursor.get('unreadCount') > 0;
    }
  }

  @computed('cursor.unreadCount', 'router.currentRoute.params.space_id')
  get checkDot() {
    debounce(this, this._checkCursors, 2000);
    return true;
  }
}
