// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr } from '@ember-data/model';

// A registered user.
// See UserCollection.
export default class extends Model {
  // Email.
  // @type string
  @attr email;
  
  // First name.
  // @type string
  @attr givenName;
  
  // Last name.
  // @type string
  @attr surname;
}

