import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { inject as service } from '@ember/service';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { action } from '@ember/object';
export default class Create extends Route {
  @service account;
  @service router;
  @service authService;
  @service store;

  beforeModel() {
    this.authService.loginIfNotAuthenticated();
  }
  async model(params) {
    const settings = await tBoxClient.serverSettings.get();

    if (
      settings &&
      settings.disableConversations &&
      params.kind !== 'workspace'
    ) {
      this.router.transitionTo('messages.index');
    }

    let spaces = await this.store.findAll('space');
    let users = await this.store.query('user', {});

    users.forEach((user) => user.set('isSelected', false));
    return hash({
      spaces: spaces.filter(space => space.kind === 'workspace'),
      users: users,
    });
  }

  setupController(controller, model) {
    controller.set('model', model);
    controller.set('inCreation', false);
  }

  @action
  didTransition() {
    this.controller.set('searchTerm', '');
    this.controller.set('newConversation', null);
    this.controller.set('groupName', null);
  }
}
