import ENV from '../config/environment';
import UserAgent from '../toolbox/generated/client/user-agent';
import WebWorkerTransport from '../toolbox/generated/client/rpc/transport/web-worker';
import ElectronIPCTransport from '../toolbox/generated/client/rpc/transport/electron-ipc';
import TauriIPCTransport from '../toolbox/generated/client/rpc/transport/tauri-ipc';
import libcryptobox from '../toolbox/generated/client/libcryptobox';
import { getSettings } from '../toolbox/electron-settings';
import { invoke } from '@tauri-apps/api/core';

let tBoxClient;
window.libcryptobox = libcryptobox;

export async function initialize(application) {
  application.deferReadiness();
  let toolBoxClient;

  if (ENV.APP.isElectronApp) {
    toolBoxClient = new UserAgent(
      new ElectronIPCTransport(),
      await getSettings(application),
    );
  } else if (ENV.APP.isTauriApp) {
    const transport = new TauriIPCTransport();
    toolBoxClient = new UserAgent(transport, async () => {
      return {
        cacheDir: await invoke('get_cache_dir'),
        cacheKeyVault: {
          setSecret: async (secret) => {
            const value = transport.decodeUint8Array(secret);
            await invoke('set_cache_key', { value });
          },
          getSecret: async () => {
            const secret = await invoke('get_cache_key');
            // Tauri converts Uint8Array to Vec<u8> (JS to Rust) but Vec<u8> to Array<number> (Rust to JS).
            // https://github.com/tauri-apps/tauri/discussions/6286
            return transport.encodeUint8Array(new Uint8Array(secret));
          },
        },
        deviceInfo: {
          appName: 'Desktop',
          appVersion: ENV.APP.version,
        },
      };
    });
  } else {
    toolBoxClient = new UserAgent(
      new WebWorkerTransport('static/assets/workers/libcryptobox.js'),
      {
        deviceInfo: {
          appName: 'Web',
          appVersion: ENV.APP.version,
        },
      },
    );
  }

  if (ENV.environment === 'development' || ENV.APP.isDesktopApp) {
    toolBoxClient.logging.setSystemLogEnabled(true);
  }

  tBoxClient = toolBoxClient;
  application.advanceReadiness();
}

export default {
  initialize,
  name: 'initToolbox',
};

export { tBoxClient };
