import { inject as service } from '@ember/service';
import { A } from '@ember/array';
import Route from '@ember/routing/route';

export default class CreateGroup extends Route {
  @service account;
  @service store;
  @service authService;

  beforeModel() {
    this.authService.loginIfNotAuthenticated();
  }

  model() {
    if (this.account.status.createSpace) {
      return this.store.createRecord('space', {
        name: '',
        description: '',
        tags: A(),
        thumbnail: null,
      });
    } else {
      this.router.transitionTo('my-groups');
    }
  }

  async setupController(controller, model) {
    controller.set('model', model);
    controller.set('addedMembers', A());
    controller.set('whiteListedUsers', A());
    controller.set('usersToInvite', A());
    controller.set('disableCancel', false);

    // TODO : Duplicated code with group.edit router
    let device;
    let securityLevels = A();

    try {
      device = await this.store.queryRecord('device', {});
      securityLevels = await device.securityLevels;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.debug('Route => error no current device');
    }

    controller.set(
      'currentDeviceSecurityLevels',
      securityLevels.map((sl) => {
        const serializedData = sl.serialize()?.data?.attributes;
        return {
          ...serializedData,
          id: sl.id,
        };
      }),
    );
  }

  deactivate() {
    const spaceRecord = this.controller.get('model');
    if (spaceRecord.get('isNew')) {
      this.store.unloadRecord(spaceRecord);
    }
  }
}
