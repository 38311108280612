import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class FileHistoryRoutes extends Route {
  @service intl;
  @service router;
  @service store;
  @service('file-history') fileHistoryService;

  async model(params, transition) {
    const path = this.fileHistoryService.filePath;
    const spaceId = transition.to.parent.params.space_id;

    const file = await this.store.queryRecord('file', {
      spaceId,
      path,
      revision: -1,
      adapterOptions: { subscribe: this },
    });
    this.fileHistoryService._modelName = file.name;
    this.fileHistoryService.trackingId = params.trackingId;
    if (!params.trackingId) {
      return file;
    }
    return await this.store.queryRecord('file', {
      find: {
        spaceId,
        trackingId: params.trackingId,
      },
    });
  }

  async afterModel(model) {
    document.title = `Cryptobox - ${ model.space.get('name') }  - ${ this.fileHistoryService.modelName }`;
  }

  setupController = (controller, model) => controller.set('model', model);

  @action
  error(reason, transition) {
    if (!reason) {
      return;
    }

    this.router.transitionTo(
      'my-groups.group.documents',
      transition.to.parent.params.space_id,
      { queryParams: { path: '', trackingId: '' } },
    );
    console.error(reason);
    return reason;
  }
}
