import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class ResizableBar extends Component {
  @action
  resize(event) {
    const panel = document.querySelector('.left-pane');
    const content = document.querySelector('#group_content');
    let panelWidth = event.pageX - panel.getBoundingClientRect().left;
    const minWidth = 200;
    if (panelWidth > content.offsetWidth) {
      panelWidth = content.offsetWidth - 10;
    }
    if (panelWidth < minWidth) {
      panelWidth = minWidth;
    }
    panel.style.width = panelWidth + 'px';
  }

  @action
  mouseDown() {
    window.addEventListener('mousemove', this.resize);
    window.addEventListener('mouseup', () =>
      window.removeEventListener('mousemove', this.resize),
    );
  }

  willDestroy() {
    super.willDestroy(...arguments);
    window.removeEventListener('mousemove', this.resize);
  }
}
