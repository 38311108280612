import { action } from '@ember/object';

import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { tBoxClient } from 'client/initializers/init-toolbox';
/* global libcryptobox */

export default class MembersController extends Controller {
  @tracked displayOwners = true;
  @tracked displayMembers = true;
  @tracked displayReaders = true;
  @tracked displayViewers = true;
  @tracked showUsers = true;
  @tracked sortProperties = ['lastName', 'firstName', 'email'];
  @tracked preaddMembers;
  @tracked refreshModel = false;

  observerId = null;

  @service intl;
  @service account;
  @service modalManager;
  @service router;

  get sortedMembers() {
    return this.model.spaceMembers.sortBy(...this.sortProperties);
  }

  get owners() {
    return this.model.spaceMembers.filter( member => member.role === libcryptobox.Role.Owner);
  }

  get canAddUser() {
    return this.model.isAdmin && !this.model.isDeleted;
  }

  get filteredMembers() {
    return this.sortedMembers.filter((member) => {
      const memberRole = member.role;

      if (
        (this.displayOwners && memberRole === libcryptobox.Role.Owner) ||
        (this.displayMembers && memberRole === libcryptobox.Role.Member) ||
        (this.displayReaders && memberRole === libcryptobox.Role.Auditor) ||
        (this.displayViewers && memberRole === libcryptobox.Role.Viewer)
      )
        return member;
    });
  }

  @action
  observePreadd() {
    tBoxClient.preaddedSpaceMember.watchAll({
      onQueryResultUpdate: (id, results) => {
        if (!results) return;
        this.preaddMembers = results.filter(result => result.spaceId === this.model.id);
        this.observerId = id;
      },
      onQueryError: (_, error) => {
        console.warn('onQueryError', 'preadd', error);
      },
    });
  }

  @action
  openRoleInfo() {
    this.modalManager.open('role-modal');
  }
  @action
  goToMyWorkspaces() {
    this.router.transitionTo('my-groups');
  }
  @action
  toggleDisplayProperty(property) {
    this[property] = !this[property];
  }
}
