import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { DateTime } from 'luxon';

export default class AcknowledgementDetails extends Component {
  @service store;

  get showAcknowledgement() {
    return this.args.showAcknowledgement;
  }
  get message() {
    return this.args.message;
  }

  get timer() {
    const date = DateTime.fromISO(this.message?.formatedTimestamp);
    return date.isValid ? date.toRelative() : null;
  }

  get readers() {
    return this.message?.readFlags.filter( readFlag => readFlag.hasRead);
  }

  get nonReaders() {
    return this.message?.readFlags.filter(readFlag => !readFlag.hasRead);
  }
}
