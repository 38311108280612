import { debounce, schedule } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

/* global libcryptobox */

export default class ConversationStream extends Component {
  @service store;
  @service account;
  @service messaging;

  @tracked hasMore = false;
  @tracked autoScroll = true;
  @tracked dragCounter = 0;
  @tracked dragging = 0;

  get conversation() {
    return this.args.conversation;
  }

  get currentMember() {
    return this.args.currentMember;
  }

  get isDragOver() {
    return this.args.isDragOver;
  }

  set isDragOver(value) {
    this.args.setIsDragOver(value);
  }

  _scrollBottom() {
    const scrollHeight = document.querySelector(
      '.conversation-stream',
    ).scrollHeight;
    const container = document.querySelector('.container-conversationStream');
    container.scrollTop = scrollHeight;

    const heightContainerMessage = container.scrollHeight;
    this.autoScroll = scrollHeight < heightContainerMessage;
  }

  async _setConversation() {
    const conversation = this.args.conversation;
    this.hasMore = await tBoxClient.message.hasMore(conversation.id);
    this.lastRead = conversation.get('cursor.lastRead');
    this._scrollBottom();
    this.loadingPrevious = false;
  }

  get messageNumberListener() {
    if (this.autoScroll) {
      debounce(this, this.scrollToNewMessage, 200);
    }
  }

  @action
  onInsertElement() {
    void this._setConversation();
    schedule('afterRender', this, () => {
      debounce(this, this._scrollBottom, 200);
      document
        .querySelector('.container-conversationStream')
        .addEventListener('scroll', () => {
          const lastRead = this.args.conversation.get('cursor.lastRead');
          const messages = this.args.conversation.messages?.filter(
            (message) =>
              message.get('timestamp') > lastRead && !message.get('deletable'),
          );
          const firstUnreadId = messages.get('firstObject.elementId');
          debounce(this, this.scrollHandling(firstUnreadId), 200);
        });
    });
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.store.adapterFor('space').unsubscribe(this);
  }

  get sortedMessages() {
    const messages = this.args.conversation.messages;
    if (messages && messages.length > 0) {
      return messages?.sortBy('isRejected', 'isSending', 'parsedTimestamp');
    }
    return [];
  }

  get isCreating() {
    const conversation = this.args.conversation;
    return (
      conversation.get('kind') === libcryptobox.SpaceKind.GroupConversation &&
      conversation.get('isNew') &&
      conversation.get('isSaving')
    );
  }

  _canUserWrite() {
    return !(
      !this.account.status.canWrite || this.currentMember.get('isReader')
    );
  }

  @action
  dragEnter() {
    if (this._canUserWrite() && !this.args.conversation.get('isNew')) {
      const count = this.dragCounter;
      this.dragCounter = count + 1;
      this.isDragOver = true;
    }
    return false;
  }

  @action
  dragLeave() {
    if (this._canUserWrite() && !this.args.conversation.get('isNew')) {
      const count = this.dragCounter - 1;
      this.dragCounter = count;
      if (count === 0) {
        this.isDragOver = false;
      }
    }
    return false;
  }

  @action
  dropFiles(e) {
    this.preventDefault(e);
    this.isDragOver = false;
    this.dragCounter = 0;

    if (this.args.conversation.get('isDeleted')) {
      return false;
    }

    if (this._canUserWrite() && !this.args.conversation.get('isNew')) {
      this.dragging = 0;
      const files = [].slice.call(e.dataTransfer.files);
      if (!files.length) {
        return false;
      }
      this.messaging.uploadAttachments(files, this.args.conversation);
    }
    return false;
  }

  @action
  scrollHandling(firstUnreadId) {
    return () => {
      const conversation = this.args.conversation;
      const container = document.querySelector('.container-conversationStream');
      const heightContainerMessage = container.scrollHeight;
      const scrollTop = container.scrollTop;
      const offset = container.offsetHeight;

      if (firstUnreadId) {
        const scrollHeight = document.querySelector(
          '#' + firstUnreadId,
        ).offsetTop;
        const arrow = document.querySelector('.new-msg-btn-container svg');
        if (arrow) {
          const top = document.querySelector(
            '.container-conversationStream',
          ).scrollTop;
          if (top < scrollHeight) {
            arrow.style.transform = 'rotate(180deg)';
          } else {
            arrow.style.transform = '';
          }
        }
      }

      //On top position
      if (scrollTop === 0 && this.hasMore && !this.isLoadingHistory) {
        this.isLoadingHistory = true;
        this.loadingPrevious = true;
        tBoxClient.message
          .loadMore(conversation.id, 10)
          .then(() => {
            return tBoxClient.message.hasMore(conversation.id);
          })
          .then((hasMore) => {
            this.hasMore = hasMore;
            this.isLoadingHistory = false;

            if (hasMore) {
              //we scroll to allow another loading of messages
              container.scrollTop = 2;
            }
          });
      }

      //Bottom position
      this.autoScroll = scrollTop > heightContainerMessage - offset - 50;
    };
  }

  @action
  preventDefault(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  @action
  scrollToNewMessage() {
    const container = document.querySelector('.container-conversationStream');
    if (!container) {
      return;
    }
    const scrollHeight = document.querySelector(
      '.conversation-stream',
    ).scrollHeight;
    const heightContainerMessage = container.scrollHeight;
    const scrollContainerMessage = container.scrollTop;

    const isSender = this.sortedMessages.lastObject.sentByMySelf;
    const isAtBottom =
      scrollHeight - 100 < heightContainerMessage + scrollContainerMessage;

    if (isSender || isAtBottom) {
      container.scrollTop = scrollHeight;
    }
  }

  @action
  confirmDelete() {
    const message = this.args.conversation.selectedMessage;
    message
      .deleteMessage()
      .then(() => this.args.conversation.set('selectedMessage', null));
  }
}
