// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr, belongsTo } from '@ember-data/model';

// File upload.
export default class extends Model {
  // Target space.
  // @type string
  @attr spaceId;
  
  // Target path.
  // @type string
  @attr path;
  
  // Uploaded data in bytes.
  // @type number
  @attr processed;
  
  // Total data to upload in bytes.
  // @type number
  @attr total;
  
  // Transfer status.
  // @type FileUploadStatus
  @attr status;
  
  // The transfer error when the status is FileUploadStatusFailed, empty otherwise. The error code is ErrorCodeCanceled after a cancelation.
  // @type Error
  @attr error;
  
  @belongsTo('space', { async: true, inverse: null }) space;
}

