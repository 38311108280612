import { computed, action } from '@ember/object';
import { sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class NotificationDropDown extends Component {
  @tracked isExpanded = false;
  @tracked sortingRules = ['state:desc', 'date:desc'];
  @tracked spaces = [];

  @service uiNotifications;
  @service store;

  constructor() {
    super(...arguments);
    this.store
      .findAll('notification', { adapterOptions: { subscribe: this } })
      .then((notificationsRecords) => {
        this.uiNotifications.set('notifications', notificationsRecords);
      });
    this.spaces = this.store.findAll('space', { adapterOptions: { subscribe: this } })
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.store.adapterFor('notification').unsubscribe(this);
    this.store.adapterFor('space').unsubscribe(this);
    this.isExpanded = false;
  }

  _markRead() {
    // this.removeClickOutsideListener();
    if (this.notifications) {
      this.notifications.forEach((notification) => {
        notification.markAsRead();
      });
    }
  }

  @sort('uiNotifications.notifications', 'sortingRules') sortedNotifications;

  @computed('sortedNotifications.[]')
  get notifications() {
    const notifications = this.sortedNotifications.filter(notification => notification.state === 'unread');

    if (document.hidden && notifications.length > 0) {
      const icon = document.querySelector('link[rel="icon"]');
      icon.href = `/static/img/logo_notified.png`;
    }
    return notifications.slice(0, 4);
  }

  @action
  handleCLick(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.isExpanded) {
      this.isExpanded = false;
    } else {
      this.isExpanded = true;
    }
  }

  @action
  clickOutside() {
    if (this.isExpanded) {
      this.isExpanded = false;
      this._markRead();
    }
  }
}
