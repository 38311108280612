import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class PlatformCustomizationComponent extends Component {
  @service platformCustomization;
  @service connection;

  @tracked platformConfig = null;

  constructor() {
    super(...arguments);
    this.platformCustomization.getConfigFromUrl(this.serverUrl, (config) => {
      this.platformConfig = config;
    });
  }

  get description() {
    if (this.args.description) return this.args.description;
    if (this.platformConfig) return this.platformConfig.description;
    return null;
  }

  get badgeLabel() {
    if (this.args.text) return this.args.text;
    if (this.platformConfig) return this.platformConfig.badgeLabel;
    return null;
  }

  get badgeColor() {
    if (this.args.color) return this.args.color;
    if (this.platformConfig) return this.platformConfig.badgeColor;
    return null;
  }

  get serverUrl() {
    if (this.args.serverUrl) return this.args.serverUrl;
    return null;
  }

  get isEnabled() {
    return this.platformConfig && this.platformConfig.isEnabled;
  }
}
