import Component from '@glimmer/component';
import config from '../../config/environment';

export default class DevtoolComponent extends Component {
  show = config.APP.devtool;

  didInsert(element) {
    if (config.APP.devtool) {
      const devtoolContainer = document.createElement('div');
      devtoolContainer.classList.add('devtool');
      document.body.appendChild(devtoolContainer);

      devtoolContainer.appendChild(element);
    }
  }
}
