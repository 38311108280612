// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr, hasMany } from '@ember-data/model';

// A space category.
// See CategoryCollection.
export default class extends Model {
  // Name.
  // @type string
  @attr name;
  
  // Identifiers of the spaces belonging to the category.
  // @type Array<string>
  @attr spaceIds;
  
  // True if the category holds the favorites.
  // @type boolean
  @attr isFavorite;
  
  @hasMany('space', { async: true, inverse: null }) spaces;
}

