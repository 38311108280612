import Route from "@ember/routing/route";
import { inject as service } from "@ember/service";

export default class BrowserErrorRoute extends Route {
  @service browser;
  @service router;

  beforeModel() {
    if (this.browser.isSupportedBrowser) {
      return this.router.transitionTo("login");
    }
  }
}
