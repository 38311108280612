import Component from "@glimmer/component";
import { action } from "@ember/object";
import { inject as service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { DEVICE_KEY_ASSOCIATION_MODAL_NAME } from "../../../../utils/enums";

const MODAL_REMOVE_PADDING_CLASSNAME = "deviceKeyAssociation-modal--noPadding";

export default class DeviceKeyAssociationModalComponent extends Component {
  name = DEVICE_KEY_ASSOCIATION_MODAL_NAME;

  @tracked showInfo = false;

  @service modalManager;

  close() {
    if (this.modalManager.isModalOpen(DEVICE_KEY_ASSOCIATION_MODAL_NAME)) {
      this.modalManager.close(DEVICE_KEY_ASSOCIATION_MODAL_NAME);
    }
  }

  @action
  onCancel() {
    const { onCancel } = this.args;

    if (onCancel) {
      onCancel();
    }

    this.close();
  }

  @action
  onConfirm() {
    const { onConfirm } = this.args;

    this.close();
    onConfirm();
  }

  @action
  onToggleInfo() {
    this.showInfo = !this.showInfo;

    const modalElements = Array.from(
      document.getElementsByClassName(DEVICE_KEY_ASSOCIATION_MODAL_NAME)
    );

    modalElements.forEach((el) => {
      if (this.showInfo) {
        el.classList.add(MODAL_REMOVE_PADDING_CLASSNAME);
      } else {
        el.classList.remove(MODAL_REMOVE_PADDING_CLASSNAME);
      }
    });
  }
}
