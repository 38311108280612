import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { inject as service } from "@ember/service";
import {
  SECURITY_LEVELS_CONFIRM_REMOVE_MODAL_NAME,
  SECURITY_LEVEL_COLOR,
} from "../../../utils/enums";

const SECURITY_LEVEL = {
  name: "Secret Défense",
  acronym: "SD",
  color: "red",
  shape: "lockedLock",
  deleted: false,
  checked: false,
};

export default class DesignSystemSecurityLevelComponent extends Component {
  @tracked mock = SECURITY_LEVEL;
  @tracked mockGrouped = [
    SECURITY_LEVEL,
    SECURITY_LEVEL,
    SECURITY_LEVEL,
    SECURITY_LEVEL,
  ].map((item, i) => ({
    ...item,
    name: item.name + i,
    acronym: item.acronym + i,
  }));
  @tracked locked = true;
  @tracked extended = false;
  @tracked name = "Secret Défense";
  @tracked slColors = Object.values(SECURITY_LEVEL_COLOR);

  @service modalManager;

  @action
  onChangeProp(event) {
    const { name, checked } = event;
    this[name] = checked;
  }

  @action
  onChangeSLGroup(data) {
    this.mockGrouped = data;
  }

  @action
  onClickTriggerConfirmRemoveModal() {
    this.modalManager.open(SECURITY_LEVELS_CONFIRM_REMOVE_MODAL_NAME);
  }
}
