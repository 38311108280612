import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { tracked } from '@glimmer/tracking';

export default class TrayMenuComponent extends Component {
  @service account;
  @service authService;
  @service intl;

  @tracked userType;

  @action
  async mouseEnter() {
    const userEmail = this.account.userEmail;

    if (userEmail) {
      const type = (await tBoxClient.invite.getUsersType([userEmail]))[
        userEmail
      ];
      if (!type) {
        return;
      }
      if (type?.licensePool) {
        this.userType = this.intl.t(`my.account.type.${type.licensePool}`);
      }
    }
  }

  @action
  logout() {
    this.account.logout('user');
  }
}
