import BlobFileWriter from 'client/toolbox/blob-file-writer';
import mime from 'mime';
import PrivateFileWriter from 'client/toolbox/private-file-writer';

// FileWriter selects a writing strategy on the first chunk received:
// - PrivateFileWriter if the API is available and the whole file can be allocated.
// - BlobFileWriter otherwise.
class FileWriter {
    constructor(name, size) {
        this.name = name;
        this.size = size;
    }

    async write(chunk) {
        if (!this.writer) {
            try {
                let writer = new PrivateFileWriter(this.name);
                await writer.truncate(this.size);
                this.writer = writer;
            } catch (_) {
                this.writer = new BlobFileWriter(this.name, { type: mime.getType(this.name) || 'application/octet-stream' });
            }
        }
        await this.writer.write(chunk);
    }

    close(error) {
        // CNUAGE-12016 If the file is empty, write() was never called and this.writer never created.
        if (!this.writer) {
            this.writer = new BlobFileWriter(this.name, { type: mime.getType(this.name) || 'application/octet-stream' });
        }
        return this.writer.close(error);
    }
}

export default FileWriter;
