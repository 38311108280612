import Component from '@glimmer/component';
import { computed, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class CategoryFilterComponent extends Component {
  @tracked isActive = false;

  get category() {
    return this.args.category;
  }

  constructor() {
    super(...arguments);
    this.registerCategory();
  }

  @computed('category.name')
  get catfilterId() {
    return this.args.category.name.replace(' ', '') + '-filter';
  }

  @action
  toggleCategory(event) {
    event.preventDefault();
    this.isActive = !this.isActive;
    this.args.onChange();
  }

  @action
  registerCategory() {
    this.args.categoryFilters.push(this);

    if (this.args.categoryFilters.length === this.args.categoriesNumber) {
      this.args.categoriesRegistered();
    }
  }
}
