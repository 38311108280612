import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';
import { action } from '@ember/object';

/*
 * For the indeterminate pseudo-classe
 * https://developer.mozilla.org/fr/docs/Web/CSS/:indeterminate
 * */
export default class Checkbox extends Component {
  inputId = `checkbox-${ guidFor(this) }`;

  didInsertInputOrUpdate(e, [indeterminate]) {
    e.indeterminate = indeterminate;
  }

  @action
  triggerOnChange(event) {
    if (this.args.disabled) return;

    event.stopPropagation();

    const id = this.args.id || this.inputId;

    const checkboxElement = document.getElementById(id);

    if (!checkboxElement) return;

    this?.args?.onChange?.({
      id,
      name: this.args.name,
      checked: checkboxElement.checked,
    });
  }
}
