const MAX_LENGTH_MAILTO = 2000;

function encodeURIMaxLength(text, maxLength) {
  if((encodeURIComponent(text)).length <= maxLength)
    return encodeURIComponent(text);

  let encodedURI = "";
  let lengthLeft = maxLength;

  [...text].every(char => {
    const encodedChar = encodeURIComponent(char);

    if ((lengthLeft - encodedChar.length) >= 0) {
      encodedURI += encodedChar;
      lengthLeft -= encodedChar.length;
      return true;
    }

    return false;
  })

  return encodedURI;
}

function getMailToWithoutBody(email = '', subject) {
  return `mailto:${email}?subject=${encodeURIComponent(subject)}&body=`;
}

function getMailTo({ email, subject, body }) {
  const mailTo = getMailToWithoutBody(email, subject);
  return mailTo + encodeURIMaxLength(body, MAX_LENGTH_MAILTO - mailTo.length);
}

export default getMailTo;
