import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { LICENCES_VALIDATION_MODAL_NAME } from '../utils/enums';
import { observes } from '@ember-decorators/object';

/* global libcryptobox */

/*
 *  TODO Refacto with login
 *  There should be only one component handling login/register
 *  Create that component and remove duplicated code
 * */
export default class Invite extends Controller {
  queryParams = ['invite_token'];

  @service account;
  @service notify;
  @service modalManager;
  @service intl;
  @service connection;

  @tracked largeDisplay = true;
  @tracked showForm = true;
  @tracked is2faDone = 0;
  @tracked EmailNotAvailable;

  constructor() {
    super(...arguments);
  }

  @computed('intl.locale')
  get locale() {
    return this.intl.locale[0];
  }

  close2fa() {
    // incrementProperty() ensures didReceiveAttrs is invoked on the component.
    this.is2faDone = this.is2faDone + 1;
  }

  @observes('account.isLoggedIn')
  onLogin() {
    if (this.account.isLoggedIn) {
      this.showForm = true;
      this.largeDisplay = true;
      this.close2fa();
      return true;
    }
    return false;
  }

  @action
  signUp() {
    this.modalManager.open(LICENCES_VALIDATION_MODAL_NAME);
  }

  @action
  confirmSignUp() {
    if (this.SingUpInProcess) {
      return false;
    }

    if (!this.EmailNotAvailable) {
      this.SingUpInProcess = true;
      this.showForm = false;
      this.largeDisplay = false;

      const userToken = this.model.token;
      const server = this.connection.serverAddress;
      let promise = tBoxClient.invite.register(
        server,
        userToken,
        this.singUpPassword,
        this.firstName,
        this.lastName,
      );
      this._cancelRegister = promise.cancel;

      promise
        .then(() => {
          this.firstName = '';
          this.lastName = '';
          this.singUpPassword = '';
          this.confirmPassword = '';
          this.SingUpInProcess = false;
          this.close2fa();
        })
        .then(() => this.account.loadUserStatus(true))
        .catch((error) => {
          if (error.code === libcryptobox.ErrorCode.AlreadyRegistered) {
            this.EmailNotAvailable = true;
          } else if (error.code !== libcryptobox.ErrorCode.Canceled) {
            let notify = this.notify;
            notify.error(error.message, {
              title: error.title,
              closeAfter: null,
              error: error,
            });
          }

          this.showForm = true;
          this.largeDisplay = true;
          this.SingUpInProcess = false;
          this.close2fa();
        });
    }
  }

  @action
  cancel() {
    if (this._cancelRegister) {
      this._cancelRegister();
      this._cancelRegister = null;
    }
  }
  @action
  updateInput(value) {
    this.singUpPassword = value;
  }
}
