// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr } from '@ember-data/model';

// DepositBoxForm is the deposit box form.
export default class extends Model {
  // Name.
  // @type string
  @attr name;
  
  // Description.
  // @type string
  @attr description;
  
  // Author.
  // @type DepositBoxAuthor
  @attr author;
  
  // Creation date and time, ISO 8601.
  // @type string
  @attr creationDate;
}

