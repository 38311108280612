import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';

export default class MyTransfersController extends Controller {
  @service intl;
  @service transfers;
  @service notify;
  @service router;
  @service store;

  get luxonOutputFormat() {
    return this.intl.t('myTransfers.startTimeFormat');
  }

  @action
  clearCompletedList() {
    this.transfers.failed.clear();
    this.transfers.completed.clear();
    this.transfers.active.filter( activeTransfers => activeTransfers.state === 'failed').forEach(failed => {
      this.transfers.active.removeObject(failed);
    });
  }

  @action
  async goToUploadPath(spaceId, path) {
    const spaces = await this.store.findAll('space');
    const space = spaces.findBy('id', spaceId);

    if (space) {
      this.router.transitionTo('my-groups.group.documents', spaceId, { queryParams: { path: path } });
    } else {
      const message = this.intl.t('errors.notMember');
      this.notify.error(message, {
        title: this.intl.t('errors.occur'),
        icon: 'warning',
      });
    }
  }
}
