export default function getRoleChoicesFilters(intl) {
  return [
    {
      value: 'none',
      label: intl.t('my.groups.roleplaceholder'),
    },
    {
      value: 'isViewer',
      label: intl.t('members.member.role.viewer'),
    },
    {
      value: 'isReader',
      label: intl.t('members.member.role.auditor'),
    },
    {
      value: 'isMember',
      label: intl.t('members.member.role.member'),
    },
    {
      value: 'isAdmin',
      label: intl.t('members.member.role.owner'),
    },
  ];
}
