/* global libcryptobox */

export default function shouldRenderWarningNoTrusteeModal(
  nbTrustees,
  status,
  checkTrustee,
  remodalModals,
  modalManager,
) {
  const { enforcedTrusteeStatus, inviteeTrusteeStatus } = status;

  return (
    nbTrustees === 0 &&
    enforcedTrusteeStatus === libcryptobox.EnforcedTrusteeStatus.OK &&
    inviteeTrusteeStatus === libcryptobox.InviteeTrusteeStatus.OK &&
    checkTrustee
    && !remodalModals.map((m) => modalManager.isModalOpen(m)).filter((b) => b).length
  );
}
