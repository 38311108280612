import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { tBoxClient } from 'client/initializers/init-toolbox';

export default class WindowsWarning extends Component {
  @tracked nameSafeForWindow = true;

  @action
  async checkNameSafety() {
    return (this.nameSafeForWindow =
      await tBoxClient.validation.isNameSafeOnWindows(this.args.entry || ''));
  }
}
