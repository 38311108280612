import { computed } from '@ember/object';
import { sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class Overview extends Controller {
  @service modalManager;
  @service intl;
  @service account;
  @service notify;
  @service router;

  @tracked modalIsOpen = false;
  @tracked isProcessing = false;
  @tracked modalName = 'restoreWarning';
  @tracked sortingRules;

  constructor() {
    super(...arguments);
    this.sortingRules = ['state:desc', 'date:desc'];
  }

  @sort('model.notifications', 'sortingRules') sortedNotifications;

  @computed('sortedNotifications.[]')
  get displayedNotifications() {
    return this.sortedNotifications.slice(0, 4);
  }

  @action
  toggleFav(space) {
    return this.store
      .findRecord('category', this.account.favoritesCategoryId)
      .then((favCat) => favCat.toggleSpace(space));
  }

  @action
  displayRestoreWarning() {
    this.modalManager.open(this.modalName);
    this.modalIsOpen = true;
  }

  @action
  closeRestoreWarning() {
    this.modalManager.close(this.modalName);
    this.modalIsOpen = false;
  }

  @action
  restoreWorkspace() {
    this.modalManager.close(this.modalName);
    this.modalIsOpen = false;
    this.model.status = 'active';

    this.model
      .save()
      .then((space) => {
        let notify = this.notify;

        notify.success(this.intl.t('toasts.restoreSpace.message'), {
          title: this.intl.t('toasts.restoreSpace.title'),
          linkProperties: {
            route: 'my-groups',
          },
          icon: 'restore',
        });

        return space.reload();
      })
      .then(() => {
        this.router.transitionTo('my-groups.deleted-spaces');
      });
  }

  @action
  onClickNotification(notification) {
    notification.markAsRead();
  }

  @action
  deleteWorkspace() {
    this.model.rollbackAttributes();
    this.model.status = 'deleted';
    this.modalIsOpen = false;
    this.isProcessing = true;

    this.model
      .save()
      .then((space) => {
        let notify = this.notify;

        notify.success(this.intl.t('toasts.deleteSpace.message'), {
          title: this.intl.t('toasts.deleteSpace.title'),
          linkProperties: {
            route: 'my-groups',
          },
          icon: 'delete',
        });

        return space.reload();
      })
      .then(() => {
        this.router.transitionTo('my-groups');
      })
      .catch((err) => {
        this.notify.error(err.message);
      });
  }
}
