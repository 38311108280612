import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class InformationTab extends Component {

  @service('transfers') transfersManager;

  get cumulatedSize() {
    let sum = 0;
    this.args.selectedNodes.model.forEach((elem) => {
      sum += parseInt(elem.contentSize);
    });
    return sum;
  }

}
