import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

class StickyElement {
  @tracked elementClientRect = null;

  constructor(element) {
    this.element = element;
    this.updateClientRect();
  }

  setTop(top) {
    this.element.style.top = `${ top }px`;
  }

  getHeight() {
    return this.elementClientRect ? this.elementClientRect.height : 0;
  }

  updateClientRect() {
    this.elementClientRect = this.element.getBoundingClientRect();
  }
}

export default class StickyContainerComponent extends Component {
  @tracked containers = [];

  @action
  initSticky(element) {
    const stickyTitles = element.querySelectorAll('.sticky-content');
    stickyTitles.forEach((title) => {
      this.registerElement(title);
    });
  }

  @action
  registerElement(element) {
    const containerElement = element.closest('.sticky-container');

    if (!containerElement) {
      throw new Error('StickyService: missed container element');
    }

    const stickyElement = new StickyElement(element);

    let container = this.containers.find(
      (container) => container.containerElement === containerElement,
    );

    if (!container) {
      container = {
        containerElement,
        stickyElements: [],
      };

      this.containers.push(container);
    }

    container.stickyElements.push(stickyElement);

    this.recalculateStickyElementsTop(container);
  }

  recalculateStickyElementsTop(container) {
    let top = 0;

    container.stickyElements.forEach((stickyElement) => {
      stickyElement.setTop(top);

      top += stickyElement.getHeight();
    });
  }
}
