// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr, belongsTo } from '@ember-data/model';

// A file revision in a space.
// See FileRevisionCollection.
export default class extends Model {
  // Space's identifier.
  // @type string
  @attr spaceId;
  
  // Revision's number.
  // @type number
  @attr number;
  
  // Number of file.
  // @type number
  @attr fileCount;
  
  // Total size of files in the revision, in bytes. This includes the encryption header (12 bytes per file chunk). Requires ServerCapabilityFileRevisionRawSize.
  // @type number
  @attr rawSize;
  
  // Creation date.
  // @type string
  @attr date;
  
  @belongsTo('space', { async: true, inverse: null }) space;
  @belongsTo('user', { async: false, inverse: null }) author;
}

