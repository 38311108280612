import Inflector from "ember-inflector";

export function initialize(/* application */) {
  const inflector = Inflector.inflector;

  // Tell the inflector that the plural of "account-settings" is "account-settings"
  inflector.uncountable("account-settings");
}

export default {
  name: "custom-inflector-rules",
  initialize,
};
