// import DS from 'ember-data';
import BaseModel from 'client/toolbox/generated/models/user';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default class User extends BaseModel {
  constructor() {
    super(...arguments);
  }

  @service account;

  @alias('givenName') firstName;
  @alias('surname') lastName;


  get isMySelf() {
    return this.email === this.account.userEmail;
  }


  get fullName() {
    return `${ this.firstName } ${ this.lastName }`;
  }

  save() {
    return super.save().catch((error) => {
      console.warn('error save', error);
      if (!this.isValid) {
        this.unloadRecord();
      }
    });
  }
}
