import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/template';
let entityMap = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#39;',
  '/': '&#x2F;',
  '`': '&#x60;',
  '=': '&#x3D;',
  '.': '&#46;'
};
let escapeHtml = function (string) {
  return String(string).replace(/[&<>"'`=/.]/g, function fromEntityMap (s) {
    return entityMap[s];
  });
};
export function urlify(params) {
    let string = escapeHtml(params[0]);
    const urlRegex = /(\b(((https?|ftp|file):&#x2F;&#x2F;)|(www&#46;))[-A-Z0-9+&amp;@#/%?&#x3D;~_|!:,.;]*[-A-Z0-9+&amp;@#/%&#x3D;~_|])/ig;
    let urlifiedString = string.replace(urlRegex, function(url, _b, c) {
        const url2 = (c == 'www&#46;') ?  'http://' + url : url;
        return '<a target="_blank" href="' + url2 + '">' + url + '</a>';
    })
  return htmlSafe(urlifiedString);
}

export default helper(urlify);
