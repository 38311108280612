import BaseAdapter from 'client/adapters/application';
import { tBoxClient } from 'client/initializers/init-toolbox';

export default BaseAdapter.extend({  
  createRecord(store, type, snapshot) {
    if (!snapshot.attr('isDirectory')) {
      return Promise.reject(`cannot create a 'file' record with isDirectory !== true, use tBoxClient.file.upload() directly.`);
    }
    return tBoxClient.file.createDirectory(snapshot.belongsTo('space').id, snapshot.attr('path')).then(payload => {
      return this.normalizeRecord(store, type, payload);
    });
  },

  deleteRecord(_store, _type, snapshot) {
    return tBoxClient.file.remove(snapshot.belongsTo('space').id, snapshot.attr('path'));
  },
});
