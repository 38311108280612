import {inject as service} from '@ember/service';
import {action} from "@ember/object";
import Component from "@glimmer/component";
import {tracked} from '@glimmer/tracking';

export default class FolderInputComponent extends Component {
  @tracked value = null;
  @tracked currentFolder = this.args.currentFolder;
  @tracked targetSpace = this.args.targetSpace;
  @tracked disabled = this.args.disabled;

  @service transfers;

  @action
  change(e) {
    e.stopPropagation();
    e.preventDefault();
    let files = [].slice.call(e.target.files);
    if (!files[0].webkitRelativePath || (files.length === 0 && !e.target.webkitEntries)) {
      // La liste de fichiers ou le navigateur n'est pas compatible, éviter l'action de téléchargement du dossier.
      this.value = null;
      return false;
    }
    let fileListArray = [];
    files.forEach((file) => {
      file.CBRelativePath = file.webkitRelativePath;
      fileListArray.push(file);
    });

    this.transfers.uploadFolderFilesList(fileListArray, this.currentFolder, this.targetSpace);
    this.value = null;
  }
}
