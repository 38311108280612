import Component from '@glimmer/component';

export default class extends Component {
  get middle() {
    return this.args.middle || 60;
  }
  get max() {
    return this.args.max || 64;
  }
  get messageLength() {
    if (!this.args.text) return 0;
    return this.args.text.length;
  }

  get isShort() {
    return this.messageLength <= this.middle;
  }

  get isMedium() {
    return this.messageLength >= this.middle && this.messageLength <= this.max;
  }

  get isLong() {
    return this.messageLength > this.max;
  }
}
