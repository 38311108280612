import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { AUTOMATIC_LOGOUT_COUNTDOWN_SEC } from '../../utils/constants';

export default class AutomaticLogoutModalComponent extends Component {
  @service userActivity;
  @service modalManager;

  @tracked counter;
  @tracked noToast = false;
  @tracked shouldLogOut = false;

  interval;

  get counterValue() {
    const timeout = this.userActivity.timeoutLeftSeconds;
    return (timeout != null) ? timeout : AUTOMATIC_LOGOUT_COUNTDOWN_SEC;
  }

  startInterval() {
    this.stopInterval();
    this.interval = setInterval(this.intervalEvent.bind(this), 1000);
  }

  stopInterval() {
    if (this.interval) clearInterval(this.interval);
  }

  intervalEvent() {
    this.counter = this.counterValue;

    if (this.counter <= 0) {
      this.timeoutEvent();
    }
  }

  timeoutEvent() {
    this.stopInterval();
  }

  @action
  onOpen() {
    this.shouldLogOut = false;
    this.noToast = false;

    this.counter = this.counterValue;

    if (this.counter) {
      this.startInterval();
    }
  }

  @action
  onConfirm() {
    this.shouldLogOut = false;
    this.userActivity.start();
    this.modalManager.close('automatic-logout-modal')
  }

  @action
  onCancel() {
    this.counter = null;
    this.shouldLogOut = true;
    this.noToast = true;
    this.stopInterval();
    this.userActivity.confirmLogOut(this.shouldLogOut, this.noToast);
    this.modalManager.close('automatic-logout-modal')
  }

}
