import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class Item extends Component {
  @service intl;
  @service router;

  get file() {
    return this.args.file;
  }

  get isChecked() {
    return this.args.selectedFiles.includes(this.file);
  }

  set isChecked(value) {
    //TODO  find best solution for checked
  }
}
