// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr } from '@ember-data/model';

// Chunk info.
export default class extends Model {
  // Name.
  // @type string
  @attr name;
  
  // Download URL.
  // @type string
  @attr url;
  
  // AES-GCM key.
  // @type * - Call `tBoxClient.decodeUint8Array()` to read the value. See Uint8Array in the getting started guide.
  @attr key;
}

