import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { oneWay } from '@ember/object/computed';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class UserEntry extends Component {
  @tracked isSelected = false;

  @service account;

  @oneWay('elementId') componentId;

  @computed('componentId')
  get canWriteInputId() {
    return 'invitee-write-' + this.componentId;
  }

  @computed('componentId')
  get havePersonalInputId() {
    return 'invitee-personal-' + this.componentId;
  }

  @computed('componentId')
  get canCreateInputId() {
    return 'invitee-create-' + this.componentId;
  }

  @computed('componentId')
  get canInviteInputId() {
    return 'invitee-invite-' + this.componentId;
  }

  @computed('componentId')
  get canSetRightsInputId() {
    return 'invitee-rights-' + this.componentId;
  }

  @action
  handleClick() {
    if (this.args.entryRegistry.indexOf(this) === -1) {
      this.args.entryRegistry.pushObject(this);
    }

    this.args.entryRegistry.forEach((element) => {
      element.isSelected = false;
    });
    this.isSelected = true;
  }

  @action
  removeUser(user) {
    this.args.invitedUsers.removeObject(user);
    this.args.entryRegistry.removeObject(this);
  }
}
