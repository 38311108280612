import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { tBoxClient } from "../../../../initializers/init-toolbox";

export default class ViewerModalComponent extends Component {
  constructor(owner, args) {
    super(owner, args);

    tBoxClient.file.getViewableTypes().then((types) => {
      this.viewableTypes = types;
    });
  }

  @tracked viewableTypes = [];
}
