import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";

const RENAME_NODE_INPUT_NAME = "RENAME_NODE_INPUT_NAME";

export default class RenameNodeComponent extends Component {
  renameNodeInputName = RENAME_NODE_INPUT_NAME;
  @tracked errorMessage = "";
  @tracked placeholder = "placeholder message";

  @action
  onChangeRenameNodeInput(event) {
    const { value } = event.target;
    this.args.onChange(value);
  }

  @action
  didInsertInput(e) {
    this.args.didInsertInput(e);
  }
}
