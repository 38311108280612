import BaseModel from 'client/toolbox/generated/models/invite';
import { alias } from '@ember/object/computed';

export default class Invite extends BaseModel {
  constructor() {
    super(...arguments);
  }

  @alias('eventHistory.lastObject.date') updatedAt;
  @alias('eventHistory.lastObject.type') status;

  save() {
    return super.save().catch((error) => {
      console.warn('error save', error);
      if (!this.isValid) {
        this.unloadRecord();
      }
    });
  }
}
