import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';

export default class RenameSpace extends Component {
  @tracked isValid = true;
  @tracked newName = '';
  @tracked errorMessage = '';

  modalName = 'Rename-space';
  inputId = guidFor(this);

  @service modalManager;
  @service store;
  @service intl;

  constructor() {
    super(...arguments);
    this.newName = this.space.get('name');
  }

  get space() {
    return this.args.space;
  }

  @action
  click() {
    const modalName = this.modalName;
    this.modalManager.open(modalName);
    return false;
  }

  @action
  async checkName() {
    const oldName = this.space.get('name');
    const newName = this.newName.trim();
    this.newName = newName;
    if (newName === oldName) {
      return;
    }
    if (this.newName === '') {
      this.isValid = false;
      this.errorMessage = this.intl.t('errors.empty');
      return;
    }
    if (!(await tBoxClient.validation.isNameValid(newName))) {
      this.isValid = false;
      if (newName === '.' || newName === '..') {
        this.errorMessage = this.intl.t('errors.forbiddenName');
      } else {
        this.errorMessage = this.intl.t('errors.slash');
      }
      return;
    }
  }

  @action
  renameSpace() {
    void this.checkName();
    if (!this.isValid) {
      return;
    }
    const modalName = this.modalName,
      newName = this.newName,
      space = this.space,
      oldName = space.get('name');
    if (newName === oldName) {
      this.modalManager.close(modalName);
      return;
    }
    space.set('name', newName);
    space.save();
    this.modalManager.close(modalName);
  }

  @action
  clearInput() {
    this.isValid = true;
    this.newName = this.space.get('name');
  }

  @action
  resetError() {
    this.isValid = true;
    this.errorMessage = '';
  }
}
