import RSVP from 'rsvp';
import { schedule } from '@ember/runloop';
import StoreService from 'ember-data/store';

export default class Store extends StoreService {
  unloadAll() {
    const args = arguments;
    return new RSVP.Promise((resolve) => {
      super.unloadAll(...args);
      schedule('destroy', this, function () {
        resolve();
      });
    });
  }
}
