import BaseAdapter from 'client/adapters/application';
import { tBoxClient } from 'client/initializers/init-toolbox';
/* global libcryptobox */

export default class Space extends BaseAdapter{
  createRecord(store, type, snapshot) {
    const peer = snapshot.belongsTo('peer');
    const members = snapshot.hasMany('members');
    return new Promise((resolve) => {
      switch (snapshot.attr('kind')) {
        case libcryptobox.SpaceKind.DirectConversation: {
          resolve(tBoxClient.space.createDirectConversation(peer.attr('email'), []));
          break;
        }
        case libcryptobox.SpaceKind.GroupConversation: {
          const emails = members.map(member => member.attr('email'));
          snapshot.record.members.toArray().forEach(m => m.deleteRecord())
          resolve(tBoxClient.space.createGroupConversation(snapshot.attr('name'), emails, []))
          break;
        }
        default: {
          resolve(
            tBoxClient.space.createWorkspace(snapshot.attr("name"), {
              description: snapshot.attr("description"),
              tags: snapshot.attr("tags"),
              messagingEmailNotification: snapshot.attr(
                "messagingEmailNotification"
              ),
              securityLevelIds: snapshot
                .hasMany("securityLevels")
                ?.map(sl => sl.id),
            })
          );
          //TODO We should invite the members.
          //     For now, this is not required as the members are stored outside the record.
          //     See controllers/my-groups/create-group.js.
          break;
        }
      }
    }).then(payload => {
      // Clean up the user temporary records
      if (peer) {
        peer.record.unloadRecord();
      }
      if (members) {
        members.forEach(member => member.record.unloadRecord());
      }
      this._unloadAssociation(payload, store, type);
      return this.normalizeRecord(store, type, payload);
    }).catch(error => console.warn(error));
  }

  updateRecord(_store, _type, snapshot) {
    const securityLevelIDs = snapshot.hasMany('securityLevels').map(sl => sl.id)

    return Promise.all([
      tBoxClient.space.setName(snapshot.id, snapshot.attr('name')),
      tBoxClient.space.setDescription(snapshot.id, snapshot.attr('description')),
      tBoxClient.space.setTags(snapshot.id, snapshot.attr('tags')),
      tBoxClient.space.setStatus(snapshot.id, snapshot.attr('status')),
      tBoxClient.space.setMessagingEmailNotification(snapshot.id, snapshot.attr('messagingEmailNotification')),
      tBoxClient.space.setSecurityLevels(snapshot.id, securityLevelIDs, snapshot.attr('removeSecurityLevelMessage'))
    ]).catch(() => snapshot.record.rollbackAttributes()).then(() => undefined);
  }
};
