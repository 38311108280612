import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class DropContainerComponent extends Component {
  @tracked isDragActive = false;

  @action
  handleDragState(isActive, event) {
    this.isDragActive = isActive;
    if (isActive && this.args.onDragEnter) {
      this.args.onDragEnter(event);
    } else if (!isActive && this.args.onDragExit) {
      this.args.onDragExit(event);
    }
    this.stopEvent(event);
  }

  @action
  handleDrop(event) {
    if (this.args.isDroppable) {
      this.processDropData(event);
      this.args.onItemDropped?.(event.target, event);
      this.args.onFileDropped?.(event.dataTransfer.files);
      this.stopEvent(event);
    }
  }

  @action
  initiateDrag(event) {
    if (this.args.customDragData) {
      event.dataTransfer.setData(
        'customDragDataPath',
        this.args.customDragData.path,
      );
    }
  }

  processDropData(event) {
    if (this.args.customDragData) {
      event.customDropData = this.args.customDragData;
    }
  }

  stopEvent(event) {
    event?.preventDefault();
    event?.stopPropagation();
  }

  @action
  onElementInserted() {
    this.args.onElementInserted?.();
  }
}
