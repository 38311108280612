/* global libcryptobox */
export function getFilterFileHistory(intl) {
  return [
    {
      value: 'none',
      label: intl.t('fileHistory.filterBy.all'),
    },
    {
      value: libcryptobox.FileHistoryAction.Add,
      label: intl.t('fileHistory.filterBy.add'),
    },
    {
      value: libcryptobox.FileHistoryAction.Move,
      label: intl.t('fileHistory.filterBy.move'),
    },
    {
      value: libcryptobox.FileHistoryAction.Modify,
      label: intl.t('fileHistory.filterBy.modify'),
    },
    {
      value: libcryptobox.FileHistoryAction.Rename,
      label: intl.t('fileHistory.filterBy.rename'),
    },
    {
      value: libcryptobox.FileHistoryAction.Delete,
      label: intl.t('fileHistory.filterBy.delete'),
    },
  ];
}
