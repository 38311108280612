import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class FileHistoryController extends Controller {
  queryParams = ['trackingId'];

  @service router;
  @service('file-history') fileHistoryService;
  @service intl;

  @tracked model;
  @tracked sideNavIsOpen = false;

  @action
  initializeFileHistory() {
    this.fileHistoryService.model = this.model;
  }

  @action
  onBackButton() {
    this.router.transitionTo('my-groups.group.documents', this.model.spaceId);
    window.history.go(-1);

  }

  @action
  handleExportActivities() {
    return this.fileHistoryService.exportActivities();
  }

  get canDisabledDownloadBtn() {
    return this.fileHistoryService.isExportActivitiesDisabled;
  }
}
