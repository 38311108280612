import Helper from '@ember/component/helper';
import mime from 'mime';

export default class GetMimeType extends Helper {
  compute([filename = '']) {
    const normalizedFilename = filename.trim();
    if (!normalizedFilename) {
      return '*/*';
    }

    const mimeType = mime.getType(normalizedFilename);

    if (!mimeType) {
      return 'application/octet-stream';
    }

    return mimeType;
  }
}
