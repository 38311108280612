import libcryptobox from "../toolbox/generated/client/libcryptobox";
const { AlreadyRegistered, EmailNotAllowed, InvalidEmail } =
  libcryptobox.ErrorCode;

export const USER_ROLES = {
  [libcryptobox.Role.Viewer]: {
    label: "members.member.role.viewer",
    value: libcryptobox.Role.Viewer,
    disabled: false,
  },
  [libcryptobox.Role.Auditor]: {
    label: "members.member.role.auditor",
    value: libcryptobox.Role.Auditor,
    disabled: false,
  },
  [libcryptobox.Role.Member]: {
    label: "members.member.role.member",
    value: libcryptobox.Role.Member,
    disabled: false,
  },
  [libcryptobox.Role.Owner]: {
    label: "members.member.role.owner",
    value: libcryptobox.Role.Owner,
    disabled: false,
  },
};

export const URL_LEGAL_CRYPTOBOX = "https://legal.cryptobox.com";
export const URL_CRYPTOBOX = {
  FR: {
    CGU: `${URL_LEGAL_CRYPTOBOX}/cgu-fr.html`,
    PRIVACY_POLICY: `${URL_LEGAL_CRYPTOBOX}/confidentialite-cryptobox.html`,
  },
  EN: {
    CGU: `${URL_LEGAL_CRYPTOBOX}/cgu-en.html`,
    PRIVACY_POLICY: `${URL_LEGAL_CRYPTOBOX}/privacy-cryptobox.html`,
  },
};

// REGISTER
export const REGISTER_ERRORS = [
  AlreadyRegistered,
  EmailNotAllowed,
  InvalidEmail,
];
export const REGISTER_ERROR_MESSAGE_BINDING = {
  [AlreadyRegistered]: "signup.errors.email.unavailable",
  [EmailNotAllowed]: "signup.errors.email.notAllowed",
  [InvalidEmail]: "signup.errors.email.tooLong",
};

// Modal name
export const LICENCES_VALIDATION_MODAL_NAME = "cluf";
export const DEVICE_KEY_ASSOCIATION_MODAL_NAME =
  "DEVICE_KEY_ASSOCIATION_MODAL_NAME";
export const ENFORCED_TRUSTEES_MODAL_NAME = "enforcedTrustee";

// Security Levels
export const SECURITY_LEVELS_CONFIRM_REMOVE_MODAL_NAME =
  "SECURITY_LEVELS_CONFIRM_REMOVE_MODAL_NAME";

export const SECURITY_LEVEL_COLOR = {
  RED: "red",
  ORANGE: "orange",
  GREEN: "green",
};

// FILE TYPES
export const FILE_TYPE = {
  PDF: "Adobe Portable Document Format",
  IMAGE: "Image File"
};

export const FILE_TYPE_WITH_PREVIEW_LIST = [FILE_TYPE.PDF, FILE_TYPE.IMAGE];

export const RENAME_NODE_MODAL_NAME = "RENAME_NODE_MODAL_NAME";
export const NODE_EXTENSION_CHANGE_MODAL_NAME =
  "NODE_EXTENSION_CHANGE_MODAL_NAME";

export const NODE_NAME_ERROR = {
  NO_CHANGE: "NO_CHANGE",
  EMPTY: "EMPTY",
  FORBIDDEN: "FORBIDDEN",
  TOO_LONG: "TOO_LONG",
  SLASH: "SLASH",
  NODE_CONFLICT: "NODE_CONFLICT",
};

export const DELETE_WORKSPACE_MODAL_NAME = "DELETE_WORKSPACE_MODAL_NAME";

export const DOWNLOAD_TYPE = {
  FILE: "FILE",
  ZIP: "ZIP",
};
