import { action, computed } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { tBoxClient } from 'client/initializers/init-toolbox';
/* global libcryptobox */

export default class ValidateMembersComponent extends Component {
  @tracked index = 0;

  @service account;
  @service preaddMember;
  @service store;
  @service modalManager;
  @service notify;
  @service intl;

  get indexShowed() {
    return this.index + 1;
  }

  @computed('preaddMember.preaddUser', 'preaddMember.preaddedMembers.[]')
  get noPreaddUser() {
    return (
      !!this.preaddMember.preaddUser &&
      !this.preaddMember.preaddedMembers.some(
        (user) => user.email === this.preaddMember.preaddUser,
      )
    );
  }

  get modalTitle() {
    if (this.noPreaddUser) return this.intl.t('preaddModal.validate.title2');

    return this.intl.t('preaddModal.validate.title1', {
      index: this.indexShowed,
      maxIndex: this.preaddMember.maxIndex,
    });
  }

  get notAllAlreadyAdded() {
    if (!this.sortedPreaddMember) return false;
    return this.sortedPreaddMember.invites.some(
      (invite) => invite.status !== libcryptobox.PreaddedStatus.Added,
    );
  }

  @computed(
    'index',
    'preaddMember.preaddedMembers.[]',
    'sortedPreaddMember.email',
  )
  get user() {
    if (!this.sortedPreaddMember) return null;
    return tBoxClient.user.get(this.sortedPreaddMember.email);
  }

  @computed('preaddMember.{preaddUser,preaddedMembers.length}', 'index')
  get sortedPreaddMember() {
    if (!this.preaddMember.preaddedMembers) return null;
    return this.preaddMember.preaddedMembers[this.index];
  }

  @action
  resetIndex() {
    this.index = 0;
  }

  @action
  async cancelInvites() {
    const currentPreadd = this.preaddMember.preaddedMembers[this.index];
    currentPreadd.invites.forEach((invite) => invite.destroyRecord());

    if (this.indexShowed === this.preaddMember.preaddedMembers.length) {
      this.index = 0;
      this.modalManager.close('validatePreadd');
      this.account.onCloseModals('validatePreadd');
      return;
    }

    this.index += 1;
  }

  @action
  async confirmInvites() {
    const currentPreadd = this.preaddMember.preaddedMembers[this.index];
    const invitee = currentPreadd.user;
    currentPreadd.invites.forEach((invite) => {
      if (invite.status === libcryptobox.PreaddedStatus.Registered) {
        const newSpaceMember = this.store.createRecord('spaceMember', {
          email: invitee.email,
          firstName: invitee.firstName,
          lastName: invitee.lastName,
          certificate: invitee.certificate,
          role: invite.role,
          space: invite.space,
          maximumLevel: libcryptobox.LicensePool.User,
        });
        newSpaceMember.save({
          adapterOptions: { fingerprint: invitee.fingerprint },
        });
      } else {
        invite.destroyRecord();
      }
    });

    if (this.indexShowed === this.preaddMember.preaddedMembers.length) {
      this.index = 0;
      this.modalManager.close('validatePreadd');
      this.account.onCloseModals('validatePreadd');
      return;
    }

    this.index += 1;
  }

  @action
  nextPreadd() {
    const currentPreadd = this.preaddMember.preaddedMembers[this.index];

    if (currentPreadd) {
      currentPreadd.invites.forEach((invite) => {
        if (invite.status === libcryptobox.PreaddedStatus.Added) {
          // destroyRecord generate an exception so i use toolbox api directly
          tBoxClient.preaddedSpaceMember.delete(invite.id);
        }
      });
    }

    if (
      this.indexShowed === this.preaddMember.preaddedMembers.length ||
      this.preaddMember.preaddedMembers.length === 0
    ) {
      this.index = 0;
      this.modalManager.close('validatePreadd');
      this.account.onCloseModals('validatePreadd');
      return;
    }

    this.index += 1;
  }

  @action
  closeNoPreadd() {
    if (this.preaddMember.maxIndex === 0) {
      this.modalManager.close('validatePreadd');
      this.account.onCloseModals('validatePreadd');
    }
    setTimeout(() => {
      if (this.preaddMember.preaddUser) {
        this.preaddMember.preaddUser = null;
      }
    }, 1000);
  }
}
