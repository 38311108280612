// import { computed } from '@ember/object';
import Model, { attr } from '@ember-data/model';

export default class LocalAccount extends Model {
  @attr('string') givenName;
  @attr('string') surName;
  @attr('string') serverUrl;
  @attr('string') userEmail;
  @attr color;
  
  
  get deviceId() {
    return `${this.userEmail}_${this.serverUrl}`;
  }
}
