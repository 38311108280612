export default function getSortingChoicesFilters(intl) {
  return [
    {
      value: 0,
      label: intl.t('my.groups.nameAsc'),
      property: 'name',
      order: true,
    },
    {
      value: 1,
      label: intl.t('my.groups.nameDesc'),
      property: 'name',
      order: false,
    },
    {
      value: 2,
      label: intl.t('my.groups.dateDesc'),
      property: 'date',
      order: false,
    },
    {
      value: 3,
      label: intl.t('my.groups.dateAsc'),
      property: 'date',
      order: true,
    },
  ];
}
