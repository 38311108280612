import { later } from '@ember/runloop';
import ArrayProxy from '@ember/array/proxy';
import EmberObject, { computed } from '@ember/object';
import { DateTime } from 'luxon';

//Don't create file Transfer object use fileDownload or fileUpload objects instead
export default EmberObject.extend({
  fileName: '',
  path: '',
  spaceId: '',
  spaceName: '',
  size: 0,
  state: '',
  toasted: false,
  isCanceled: false,
  addTime: null,
  loaded: 0,
  startTime: null,
  progressMeasureArray: null,
  observer: null,
  kind: 'file',
  sources: null,

  initMeasure() {
    this.set('addTime', DateTime.now());
    this.set(
      'progressMeasureArray',
      ArrayProxy.create({
        content: [],
      }),
    );
  },

  progressPercent: computed('loaded', 'size', function () {
    const loaded = this.loaded,
      size = this.size;
      const computed = (loaded / size) * 100;
    return computed ? computed : 0;
  }),

  speed: computed('loaded', 'progressMeasureArray', 'size', function () {
    const progressMeasureArray = this.progressMeasureArray;
    if (!progressMeasureArray) {
      return 0;
    }
    const progressMeasureArrayLength = this.progressMeasureArray.get('length');
    if (!progressMeasureArrayLength) {
      return 0;
    }
    let latestMeasure = progressMeasureArray.objectAt(0);
    if (progressMeasureArrayLength <= 1) {
      return 0;
    }
    let oldestMeasure = progressMeasureArray.objectAt(
      progressMeasureArrayLength - 1,
    );
    let loadedByteMeasured = latestMeasure.loaded - oldestMeasure.loaded;
    let timeMeasured = latestMeasure.timeStamp - oldestMeasure.timeStamp;

    return loadedByteMeasured / timeMeasured; //Return speed in o/ms
  }),

  remain: computed('loaded', 'size', 'speed', function () {
    const loadedSize = this.loaded,
      totalSize = this.size,
      speed = this.speed;
    if (speed === 0) {
      return false;
    }
    let remainSize = totalSize - loadedSize;
    return Math.round(remainSize / speed); //Time on Ms
  }),

  remainSize: computed('loaded', 'size', 'speed', function () {
    return this.size - this.loaded;
  }),

  peekProgressMeasure() {
    later(
      this,
      function () {
        if (this.state === 'active') {
          const progressMeasureArray = this.progressMeasureArray;
          let measure = {
            timeStamp: DateTime.now(),
            loaded: this.loaded,
          };
          progressMeasureArray.unshiftObject(measure);
          if (progressMeasureArray.get('length') > 15) {
            // Change int of condition to modify ponderation of measurement
            progressMeasureArray.popObject();
          }
          this.peekProgressMeasure();
        }
      },
      1000,
    );
  },
});
