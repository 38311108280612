import Component from "@glimmer/component";
import { action } from "@ember/object";

export default class CheckboxGroupComponent extends Component {
  @action
  onChange(event) {
    const { name, checked } = event;
    const { list, onChange } = this.args;

    // TODO : Use item.id instead of item.name
    // We identify an item using the name because the Checkbox component doesn't require an id.
    // When no id is provided, the Checkbox component generate one,
    // so the list in CheckboxGroup component isn't aware of that id

    // Solution: require an id for each items here in the CheckboxGroup component
    onChange(
      list.map((item) => (item.name === name ? { ...item, checked } : item))
    );
  }
}
