import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class ValidatePreadd extends Route {
  @service modalManager;
  @service account;
  @service preaddMember;
  @service router;

  async model(params) {

    if (this.account.isLoggedIn) {
      this.preaddMember.preaddUser = params.invitee_email;
      await this.preaddMember.loadPreaddedMembers();
      this.modalManager.open('validatePreadd');
    }

    return this.router.transitionTo('my-groups');
  }
}
