// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

// An instant message.
// See MessageCollection.
export default class extends Model {
  // The space ID of the message.
  // @type string
  @attr spaceId;
  
  // The timestamp of the message. It is also the identifier of the message once delivered to the server.
  // @type number
  @attr timestamp;
  
  // The text of the message.
  // @type string
  @attr text;
  
  // The timestamp of the message converted to an ISO 8601 date.
  // @type string
  @attr date;
  
  // The dispatch status of the message.
  // @type MessageStatus
  @attr status;
  
  // Whether the message can be deleted.
  // @type boolean
  @attr deletable;
  
  @belongsTo('space', { async: true, inverse: null }) space;
  @belongsTo('user', { async: false, inverse: null }) author;
  @hasMany('attachment', { async: false, inverse: null }) attachments;
  @hasMany('message-recipient', { async: false, inverse: null }) recipients;
}

