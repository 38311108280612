import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { LICENCES_VALIDATION_MODAL_NAME, REGISTER_ERROR_MESSAGE_BINDING, REGISTER_ERRORS } from '../../../utils/enums';
import { getDefaultServerUrl } from 'client/ipc';

const CONNECTION_VIEW = {
  REGISTER: 'REGISTER',
  LOGIN: 'LOGIN',
  LOGIN_BURIED_KEYS: 'LOGIN_BURIED_KEYS',
  PASSWORD_RECOVERY: 'PASSWORD_RECOVERY',
};

const CONNECTION_TITLE_TRADS_KEYS = {
  [CONNECTION_VIEW.REGISTER]: 'signup.titleDesktop',
  [CONNECTION_VIEW.LOGIN]: 'login.login',
  [CONNECTION_VIEW.LOGIN_BURIED_KEYS]: 'desktop.localAccount.login',
  [CONNECTION_VIEW.PASSWORD_RECOVERY]: 'recovery.init.title',
};

export default class ConnectionContainerComponent extends Component {
  @tracked connectionView = CONNECTION_VIEW;
  @tracked connectionTitleTradsKeys = CONNECTION_TITLE_TRADS_KEYS;

  @tracked view = CONNECTION_VIEW.LOGIN;
  @tracked serverUrl = '';
  @tracked password = '';
  @tracked userEmail = '';
  @tracked firstName = '';
  @tracked lastName = '';

  @tracked notValidEmailMessage = '';
  @tracked notValidEmail = false;

  @service account;
  @service connection;
  @service modalManager;
  @service electronCommunication;

  @action
  async initialize() {
    this.serverUrl = await getDefaultServerUrl();
    if (this.args.localAccountList?.length > 0) {
      this.view = CONNECTION_VIEW.LOGIN_BURIED_KEYS;
    } else {
      this.view = CONNECTION_VIEW.LOGIN;
    }
  }

  get showLeftChevron() {
    return (
      this.view === CONNECTION_VIEW.REGISTER ||
      this.view === CONNECTION_VIEW.PASSWORD_RECOVERY
    );
  }

  get currentView() {
    return this.view;
  }

  clear() {
    this.firstName = '';
    this.lastName = '';
    this.password = '';
  }

  @action
  setView(newView) {
    if (newView === CONNECTION_VIEW.REGISTER) {
      this.clear();
    }
    this.view = newView;
  }

  @action
  handleServerUrl(event) {
    this.connection.tmpServerAddress = `https://${ event.target.value }`;
  }

  @action
  onPasswordChange(value) {
    this.password = value;
  }

  @action
  resetError(event) {
    if (event && event.key) {
      this.account.credentialError = false;
    }
  }

  @action
  onValidateLicenseModal() {
    const { onRegister } = this.args;
    const { userEmail, firstName, lastName, password } = this;

    onRegister({ userEmail, firstName, lastName, password })
      .then(() => {
        this.setView(CONNECTION_VIEW.LOGIN);
        this.clear();
      })
      .catch((error) => {
        if (REGISTER_ERRORS.includes(error.code)) {
          this.notValidEmail = true;
          this.notValidEmailMessage =
            REGISTER_ERROR_MESSAGE_BINDING[error.code];
        }
      });
  }

  /* MAIN FORMS ACTIONS */

  @action
  onRegister() {
    this.modalManager.open(LICENCES_VALIDATION_MODAL_NAME);
  }

  @action
  onLogin() {
    const { onLogin } = this.args;
    const { userEmail, password } = this;

    onLogin({ userEmail, password });
  }

  @action
  onPasswordRecovery() {
    const { onPasswordRecovery } = this.args;
    const { userEmail } = this;

    onPasswordRecovery({ userEmail }).then(() => {
      this.setView(CONNECTION_VIEW.LOGIN);
    });
  }
}
