import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";

export default class DesignSystemSecurityLevelComponent extends Component {
  @tracked textAreaValue = "";

  @action
  onChangeTextAreaValue(event) {
    const { value } = event.target;
    this.textAreaValue = value;
  }
}
