import { inject as service } from '@ember/service';

export function initialize(app) {
  const workers = app.__container__.lookup('service:worker');
  workers.set('webWorkersPath', '/static/assets/web-workers/');
}

export default {
  name: 'web-worker',
  worker: service(),
  initialize,
};
