import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class FileSize extends Component {
  @service intl;

  get fileCount() {
    return this.args.fileCount;
  }

  get files() {
    return this.args.files;
  }

  get isMultiplesFiles() {
    return this.files.length > 1;
  }

  get totalFilesSize() {
    return this.files.reduce((acc, file) => acc + file.size, 0);
  }
}
