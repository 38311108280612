import BaseAdapter from 'client/adapters/application';
import { tBoxClient } from 'client/initializers/init-toolbox';

export default BaseAdapter.extend({
  createRecord(store, type, snapshot) {
    return tBoxClient.fileSharing.create(snapshot.belongsTo('space').id, snapshot.record.get('pathList'), { 
      name: snapshot.record.get('name'),
      notAfter: snapshot.attr('notAfter'),
      uniqueDownload: snapshot.attr('uniqueDownload'),
      authorSubscription: snapshot.attr('authorSubscription'),
    }).then(async (payload) => {
      snapshot.record.set('key', await tBoxClient.fileSharing.getKey(payload.url));
      if (snapshot.attr('recipients')) {
        return tBoxClient.fileSharing.sendByEmail(
          snapshot.attr('recipients'),
          snapshot.record.get('message'),
          payload.url
          ).then(() => {
            return this.normalizeRecord(store, type, payload);
        });
      } else {
        return this.normalizeRecord(store, type, payload);
      }
    }).catch((error) => {
      snapshot.record.unloadRecord();
      throw error;
    });
  },

  updateRecord(store, type, snapshot) {
    return tBoxClient.fileSharing.update(snapshot.belongsTo('space').id, snapshot.id, { 
      notAfter: snapshot.attr('notAfter'),
      suspended: snapshot.attr('suspended'),
      uniqueDownload: snapshot.attr('uniqueDownload'),
      authorSubscription: snapshot.attr('authorSubscription'),
    }).catch(() => {
      snapshot.record.toggleProperty('suspended');
    });
  },

  deleteRecord(store, type, snapshot) {
    return tBoxClient.fileSharing.delete(snapshot.belongsTo('space').id, snapshot.id);
  },
});
