import { computed } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { guidFor } from '@ember/object/internals';
import { action } from '@ember/object';

export default class ContextMenu extends Component {
  @service commonService;

  elementId = guidFor(this);
  @tracked displayLeft;

  @action
  didInsert() {
    const element = document.querySelector(`#${this.elementId}`);
    const width = element.offsetWidth;
    const height = element.offsetHeight;
    const wWidth = window.innerWidth;
    const wHeight = window.innerHeight;
    let left;
    let top;
    const parentView = this.args.parentView;

    if (parentView.mouseX + width >= wWidth || this.displayLeft) {
      left = parentView.mouseX - width;
    } else {
      left = parentView.mouseX;
    }
    if (parentView.mouseY + height >= wHeight) {
      top = parentView.mouseY - height;
      if (top < 70) {
        top = 70;
      }
    } else {
      top = parentView.mouseY;
    }

    element.style.top = `${top}px`;
    element.style.left = `${left}px`;
    window.addEventListener('mousedown', this.clickHandler);
    window.addEventListener('resize', this.clickHandler);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    window.removeEventListener('mousedown', this.clickHandler);
    window.removeEventListener('resize', this.clickHandler);
    return super.willDestroy();
  }

  @computed('elsewhereHandler')
  get clickHandler() {
    return this.elsewhereHandler.bind(this);
  }

  @action
  onClickElsewhere() {
    if (this.modalIsOpen || this.commonService.modalIsOpen) {
      return;
    }
    this.args.parentView.isContextMenuOpened = false;
  }

  @action
  elsewhereHandler(event) {
    if (!event.target.closest) return;

    const isThisElement = event.target.closest(`#${this.elementId}`);
    if (!isThisElement) {
      return this.onClickElsewhere(event);
    }
  }

  @action
  handleClick(e) {
    e.stopPropagation();
  }
}
