// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr } from '@ember-data/model';

// Extended user information.
// See UserCollection's Get().
export default class extends Model {
  // Email.
  // @type string
  @attr email;
  
  // First name.
  // @type string
  @attr givenName;
  
  // Last name.
  // @type string
  @attr surname;
  
  // The user certificate.
  // @type * - Call `tBoxClient.decodeUint8Array()` to read the value. See Uint8Array in the getting started guide.
  @attr certificate;
  
  // The security levels, per device.
  // @type Array<Array<string>>
  @attr securityLevelIds;
  
  // Whether the email address is valid.
  // @type boolean
  @attr isEmailValid;
  
  // Whether the email belongs to a registered user on the server.
  // @type boolean
  @attr isRegistered;
}

