import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class extends Component {
  @service commonService;
  @service intl;

  @tracked workspaceRevision = null;
  @tracked hideFirst = false;
  @tracked debug = null;

  get index() {
    return this.args.index;
  }
  get timeNode() {
    return this.args.timeNode;
  }

  get selectedPage() {
    return this.args.selectedPage;
  }

  @computed('index')
  get isFirst() {
    return !this.index;
  }
  get displayOldHistory() {
    return this.args.displayOldHistory;
  }

  @computed('index', 'selectedPage', 'hideFirst')
  get shouldHide() {
    return !this.selectedPage === 1 && this.index === 0 && this.hideFirst;
  }

  @computed(
    'commonService.workspaceRevision',
    'timeNode.number',
    'workspaceRevision',
  )
  get isCurrentRevision() {
    return (
      parseInt(this.timeNode.number) ===
      parseInt(this.commonService.workspaceRevision)
    );
  }

  get tDateFormat() {
    return this.intl.t('dateHourFormat');
  }

  // /**
  //  * show version of folder
  //  * @param timeNode
  //  * @returns {*}
  //  */
  // @action
  // selectRestorableDate(timeNode) {
  //   this.workspaceRevision = timeNode.number;
  //   this.displayOldHistory = true;
  //   return timeNode;
  // }
}
