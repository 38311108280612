import Modifier from 'ember-modifier';

export default class RightClickCounterModifier extends Modifier {
  rightClickCount = 0;

  didInstall() {
    this.element.addEventListener('contextmenu', this.handleRightClick);
  }

  willRemove() {
    this.element.removeEventListener('contextmenu', this.handleRightClick);
  }

  handleRightClick = (event) => {
    event.preventDefault(); // Pour empêcher le menu contextuel par défaut
    this.rightClickCount++;

    if (this.rightClickCount === 2) {
      // Réinitialiser le compteur
      this.rightClickCount = 0;

      // Appeler la fonction passée en argument
      if (typeof this.args.positional[0] === 'function') {
        this.args.positional[0]();
      }
    }
  };
}
