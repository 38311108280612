import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class MyGroups extends Route {
  @service intl;
  @service store;
  @service authService;

  beforeModel() {
    this.authService.loginIfNotAuthenticated();
  }

  model() {
    const options = { adapterOptions: { subscribe: this } };
    this.store.findAll('category', options);
  }

  setupController(controller, model) {
    controller.set('model', model);
    controller.set('isFav', false);
    controller.set('deletedWorkspaces', false);
    controller.set('searchByName', '');
    controller.set('categoryFilters', []);
  }

  afterModel() {
    document.title = 'Cryptobox - ' + this.intl.t('navigation.my.groups');
  }

  deactivate() {
    this.store.adapterFor('space').unsubscribe(this);
    this.store.adapterFor('category').unsubscribe(this);
  }
}
