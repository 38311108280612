import { isPresent } from '@ember/utils';
import { computed } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class ProgressBar extends Component {
  @tracked transforms;

  get accent() {
    return this.args.accent || false;
  }

  get value() {
    return this.args.value || null;
  }

  get bufferValue() {
    return this.args.bufferValue || null;
  }

  constructor() {
    super(...arguments);
    this.transforms = new Array(101)
      .fill(null)
      .map((_, i) => this.makeTransform(i));
  }

  get mode() {
    if (isPresent(this.value)) {
      if (isPresent(this.bufferValue)) {
        return 'buffer';
      } else {
        return 'determinate';
      }
    } else {
      return 'indeterminate';
    }
  }

  get bar1Style() {
    return 'transform' + ': ' + this.transforms[this.clampedBufferValue];
  }

  get bar2Style() {
    if (this.mode === 'query') {
      return '';
    }
    return 'transform' + ': ' + this.transforms[this.clampedValue];
  }

  get clampedValue() {
    const newValue = this.clampValue(this.value)
    return newValue;
  }

  get clampedBufferValue() {
    return this.clampValue(this.bufferValue);
  }

  clampValue(value) {
    if (value > 100) {
      return 100;
    }
    if (value < 0) {
      return 0;
    }
    return Math.ceil(value || 0);
  }

  /**
   *
   *  Integrate component call directly on template
   *
   *  Set up Progresse bar mode
   *  {{progress-bar}} => Indetermiate
   *  {{progress-bar value=xx}}   => Determinate mode
   *  {{progress-bar value=xx bufferValue=xx}}   => Buffer moode
   *
   *  Select Color scheme
   *  {{progress-bar accent=true}}  => Select Theme defaut(blue) / Accent=ture For yellow / Warn=true for red
   *
   */
  makeTransform(value) {
    let scale = value / 100;
    let translateX = (value - 100) / 2;
    return `translateX(${translateX}%) scale(${scale}, 1)`;
  }
}
