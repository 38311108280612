import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import ArrayProxy from '@ember/array/proxy';
import Component from '@glimmer/component';
import getNodeIconName from '../../../../utils/getNodeIconName';
import { DOWNLOAD_TYPE, RENAME_NODE_MODAL_NAME } from '../../../../utils/enums';
import { tracked } from '@glimmer/tracking';
import { guidFor } from '@ember/object/internals';

/* global libcryptobox */

export default class ListedNode extends Component {
  @tracked mouseX = 0;
  @tracked mouseY = 0;
  @tracked isContextMenuOpened = false;
  @tracked fileListArray = [];

  @tracked workspaceRevision = null;
  @tracked downloadType = DOWNLOAD_TYPE;

  @service account;
  @service('transfers') transfersManager;
  @service commonService;
  @service intl;
  @service modalManager;
  @service router;
  @service('file-history') fileHistoryService;

  copyMoveModalName = 'copy-move-modal copy-move-modal2';

  get selectedNodes() {
    return this.args.selectedNodes;
  }

  @computed('')
  get tDateFormat() {
    return this.intl.t('dateHourFormat');
  }

  @computed('args.node.space.{accessRight,accessRights}')
  get isSharable() {
    return this.args.node.space
      .get('accessRights')
      .includes(libcryptobox.SpaceAccessRight.Share);
  }

  @computed('args.workspaceRevision')
  get displayOldFiles() {
    return this.args.workspaceRevision;
  }

  @computed('args.node.isFolder')
  get isFile() {
    return !this.args.node.isFolder;
  }

  @computed('args.node.space.isViewer', 'account.status.canWrite')
  get canCopy() {
    return (
      !this.args.node.space.get('isViewer') && this.account.status.canWrite
    );
  }

  @computed('args.node.space.{isReader,isViewer}', 'account.status.canWrite')
  get canShowFileHistory() {
    return (
      !this.args.node.space.get('isReader') &&
      !this.args.node.space.get('isViewer') &&
      this.account.status.canWrite
    );
  }

  @computed('args.node.space.{isReader,isViewer}', 'account.status.canWrite')
  get canExportFileHistory() {
    return (
      !this.args.node.space.get('isReader') &&
      !this.args.node.space.get('isViewer') &&
      this.account.status.canWrite
    );
  }

  @computed('args.node.space.{isReader,isViewer}', 'account.status.canWrite')
  get canEdit() {
    return (
      !this.args.node.space.get('isReader') &&
      !this.args.node.space.get('isViewer') &&
      this.account.status.canWrite
    );
  }

  @computed('args.node.name')
  get getFileExtension() {
    return (this.args.node.name.match(/\.([0-9a-z]+)$/i) || [])[1];
  }

  @computed('canEdit', 'args.node.space.isDeleted')
  get isEditable() {
    return this.canEdit && !this.args.node.space.get('isDeleted');
  }

  @computed('args.node.id', 'selectedNodes.[]')
  get isSelected() {
    return this.args.node
      ? this.selectedNodes.isAny('id', this.args.node.id)
      : false;
  }

  @computed('selectedNodes.[]')
  get multipleSelectedNodes() {
    return this.selectedNodes.length > 1;
  }

  @computed('selectedNodes.[]')
  get nodeHasFolder() {
    return this.selectedNodes.filter(node => node.isFolder).length > 0;
  }

  get overred() {
    return this.args.overred || false;
  }

  @computed('args.node.space.userRole')
  get canDownload() {
    return [
      libcryptobox.Role.Owner,
      libcryptobox.Role.Member,
      libcryptobox.Role.Auditor,
    ].includes(this.args.node.space.get('userRole'));
  }

  @computed('args.node.isDirectory', 'getFileExtension')
  get nodeIconName() {
    return getNodeIconName(this.args.node.isDirectory, this.getFileExtension);
  }

  @computed('elementId', 'node')
  get deleteFileModalName() {
    return `delete-file-${ guidFor(this) }`;
  }

  get disabledExportFileHistory() {
    return this.args.node.isDirectory === true;
  }

  menuAction = {
    VIEW_FILE: 'view_file',
    FILE_HISTORY: 'file_history',
    SHARE_FILE: 'share_file',
    GET_LINK: 'get_link',
    DOWNLOAD_FILE: 'download_file',
    DOWNLOAD_ZIP: 'download_zip',
    RENAME_FILE: 'rename_file',
    DELETE_FILE: 'delete_file',
    COPY_MOVE: 'copy_move',
    EXPORT_FILE_HISTORY: 'export_file_history',
  };

  //TODO : find solution for not duplicate logic in another component
  readEntries(entriesArray) {
    return new Promise((resolve) => {
      const processEntry = (entry) => {
        if (entry?.isFile) {
          entry.file((fileObject) => {
            fileObject.CBRelativePath = entry.fullPath.slice(1);
            this.fileListArray.pushObject(fileObject);
            nextEntry();
          });
        } else if (entry?.isDirectory) {
          entry.createReader().readEntries((dirEntries) => {
            this.readEntries(dirEntries).then(nextEntry);
          });
        }
      };

      const nextEntry = () => {
        const next = entriesIterator.next();
        if (next.done) {
          resolve();
        } else {
          processEntry(next.value);
        }
      };

      const entriesIterator = (function* (entries) {
        for (const entry of entries) {
          yield entry;
        }
      })(entriesArray);

      nextEntry();
    });
  }

  @action
  async drop(e) {
    const items = e.dataTransfer.items;
    const files = e.dataTransfer.files;

    if (
      !files.length ||
      !this.args.node.isFolder ||
      this.args.model.space.isReader
    ) {
      return;
    }

    const space = await this.args.node.space;

    if (!items) {
      this.transfersManager.uploadFilesList(files, this.args.node, space);
    } else {
      const entriesArray = [];
      [].forEach.call(items, (entry) => {
        entriesArray.push(entry.webkitGetAsEntry());
      });
      this.fileListArray = ArrayProxy.create({ content: [] });
      this.readEntries(entriesArray).then(() => {
        const fileList = [];
        this.fileListArray.get('content').forEach((file) => {
          fileList.push(file);
        });
        this.transfersManager.uploadFolderFilesList(
          fileList,
          this.args.node,
          space,
        );
        this.fileListArray.clear();
      });
    }
    this.args.node.set('overred', false);
  }

  @computed('args.node', 'isContextMenuOpened', 'isSelected', 'selectedNodes')
  get contextMenuTriggered() {
    if (this.isContextMenuOpened) {
      if (!this.isSelected) {
        this.commonService.selectedSpace = this.args.node.get('space');
        this.commonService.destinationNode = this.args.node.get('path');
        this.args.setLastClickedNode(this.args.node);
        this.selectedNodes.clear();
        this.selectedNodes.addObject(this.args.node);
      }
    }
  }

  @action
  click(e) {
    e.stopPropagation();
    const selectedNodesLength = this.selectedNodes.length;
    const node = this.args.node;
    this.transfersManager.refreshLogs(node.get('space.id'), node.get('path'));

    this.commonService.destinationNode = this.args.node.get('path');
    this.commonService.selectedSpace = this.args.node.get('space');

    if (this.isContextMenuOpened) {
      return;
    }

    /**
     * if we click on the action button and the node is already selected
     */
    if (selectedNodesLength === 1 && this.selectedNodes.isAny('id', node.id)) {
      if (node !== this.lastClickedNode) {
        this.args.setLastClickedNode(node);
      }
    } else {
      /**
       * toggle selection for file if we clicked on it with ctrl key
       */
      if (e.ctrlKey || e.metaKey) {
        if (this.selectedNodes.isAny('id', node.id)) {
          this.selectedNodes.removeObject(node);
        } else {
          this.selectedNodes.pushObject(node);
        }

        const folders = this.selectedNodes.filter(node => node.isDirectory);
        let last;

        if (folders.length) {
          last = folders.get('firstObject');
        } else {
          last = this.selectedNodes.get('firstObject');
        }
        this.args.setLastClickedNode(last);
        this.args.setDetailedNode(last);
        return;
      } else if (e.shiftKey) {
        /**
         * select a range of files with shift + click
         */
        const displayedNodes = this.args.displayedNodes;
        const lastClickedNode = this.args.setLastClickedNode;
        const node = this.args.node;
        let startIndex = 0;

        if (lastClickedNode) {
          startIndex = displayedNodes.indexOf(lastClickedNode);
        }
        const nodeIndex = displayedNodes.indexOf(node);
        let nextSelectedNode;
        if (nodeIndex > startIndex) {
          nextSelectedNode = displayedNodes.slice(startIndex, nodeIndex + 1);
        } else {
          nextSelectedNode = displayedNodes.slice(nodeIndex, startIndex + 1);
        }

        const folders = this.selectedNodes.filter(node => node.isDirectory);
        //TODO setSelectedNodes
        this.selectedNodes.set('content', nextSelectedNode);
        let last;

        if (folders.length) {
          last = folders.get('firstObject');
        } else {
          last = this.selectedNodes.get('firstObject');
        }
        this.args.setLastClickedNode(last);
        this.args.setDetailedNode(last);
      } else {
        /**
         * if there is nothing selected or more than one node,
         * we want to select just one
         */

        // when we have several node selected and we click on just one to select only one
        if (!this.isContextMenuOpened && selectedNodesLength >= 1) {
          this.selectedNodes.clear();
        }
        this.selectedNodes.addObject(node);
        if (this.selectedNodes.length === 1) {
          this.args.setLastClickedNode(node);
          this.args.setDetailedNode(node);
        } else if (!this.selectedNodes.length) {
          this.hideDetails();
        }
      }
    }
    this.args.setDetailedNode(node);
    this.args.setLastClickedNode(node);
  }

  @action
  async handleDoubleClick() {
    this.isContextMenuOpened = false;
    if (this.args.node.get('isFolder')) {
      this.commonService.treeItemsDocuments.forEach((treeItemsDocument) => {
        const selected =
          treeItemsDocument.folder &&
          this.args.node
            .get('path')
            .includes(treeItemsDocument.folder.get('path'));
        if (selected) {
          treeItemsDocument.isExpanded = true;
        }
      });

      this.args.setCurrentPath(this.args.node.get('path'));
      this.args.setCurrentTrackingId('');
    } else {
      this.args.setCurrentFilePreviewPath(this.args.node.get('path'));
    }
  }

  @action
  handleChangeContextMenu(value = false) {
    this.isContextMenuOpened = value;
  }

  @action
  handleWillDestroy() {
    super.willDestroy(...arguments);
    this.isContextMenuOpened = false;
  }

  @action
  handleClickOutside() {
    if (this.isContextMenuOpened) {
      this.handleChangeContextMenu(false);
    }
  }

  @action
  handleRightClick(e) {
    e.stopPropagation();
    if (this.isContextMenuOpened) {
      this.handleChangeContextMenu(false);
    }
    this.mouseX = e.pageX;
    this.mouseY = e.pageY;
    const search = document.querySelector('input[name=\'search\']');

    if (search) {
      const searchBounds = search.getBoundingClientRect();
      const searchX = searchBounds.left;
      const searchY = searchBounds.top;
      const xValid =
        e.pageX < searchX || e.pageX > searchX + searchBounds.width + 50;
      const yValid =
        e.pageY < searchY || e.pageY > searchY + searchBounds.height + 30;

      if (xValid || yValid) {
        e.preventDefault();
        this.isContextMenuOpened = true;
      }
    } else {
      e.preventDefault();
      this.isContextMenuOpened = true;
    }
    this.contextMenuTriggered;
  }

  @action
  toggleSelected(event) {
    event.stopPropagation();
    if (this.selectedNodes.isAny('id', this.args.node.id)) {
      this.selectedNodes.removeObject(this.args.node);
    } else {
      this.selectedNodes.pushObject(this.args.node);
    }

    if (this.selectedNodes.length === 1) {
      this.args.setDetailedNode(this.selectedNodes.objectAt(0));
    }
    this.lastClickedNode = this.args.node;
  }

  @action
  async onMenuActionClicked(menuAction) {
    switch ( menuAction ) {
      case this.menuAction.VIEW_FILE:
        this.args.onViewFile(this.args.node.path);
        break;
      case this.menuAction.SHARE_FILE:
        this.modalManager.open('init-share');
        break;
      case this.menuAction.GET_LINK:
        if (this.args.node.length > 1) {
          return;
        }
        this.modalManager.open('get-link-modal');
        break;
      case this.menuAction.DOWNLOAD_FILE:
        if (!this.args.workspaceRevision || this.args.workspaceRevision === 0) {
          this.args.onDownload(this.downloadType.FILE);
        } else {
          this.args.onDownloadFile(
            this.downloadType.FILE,
            this.args.node.id,
            this.args.workspaceRevision,
          );
        }
        break;
      case this.menuAction.DOWNLOAD_ZIP:
        if (!this.args.workspaceRevision || this.args.workspaceRevision === 0) {
          this.args.onDownload(this.downloadType.ZIP);
        } else {
          this.args.onDownloadFile(
            this.downloadType.ZIP,
            this.args.node.id,
            this.args.workspaceRevision,
          );
        }
        break;
      case this.menuAction.RENAME_FILE:
        this.modalManager.open(RENAME_NODE_MODAL_NAME, {
          node: this.args.node,
        });
        break;
      case this.menuAction.COPY_MOVE:
        this.onCopyMoveFile();
        break;
      case this.menuAction.DELETE_FILE:
        this.modalManager.open(this.deleteFileModalName);
        break;
      case this.menuAction.FILE_HISTORY:
        this.fileHistoryService.filePath = this.args.node.path;
        this.router.transitionTo(
          'my-groups.file-history.index',
          this.args.node.spaceId,
          {
            queryParams: {
              trackingId: this.args.node.trackingId,
            },
          },
        );
        break;
      default:
        return;
    }
    // HotFix wait 100ms before closing the context menu
    await new Promise(() =>
      setTimeout(() => this.handleChangeContextMenu(false), 100),
    );
  }

  @action
  onNodeInsert() {
    if (
      this.args.currentFileTrackingId &&
      this.args.currentFileTrackingId === this.args.node.get('trackingId')
    ) {
      this.showDetailsAction(this.args.node, null);
      this.args.setCurrentFileTrackingId('');
    }
  }

  @action
  showDetailsAction(node, fromClick) {
    if (!this.selectedNodes.map((node) => node.id).includes(node.get('id'))) {
      const selectedNodes = this.selectedNodes;
      selectedNodes.clear();
      selectedNodes.pushObject(node);
      // this.detailedNode = node;
      this.lastClickedNode = node;
    }

    this.args.showDetails(node, fromClick); // imported from document.js controller via template
  }

  @action
  onCopyMoveFile() {
    if (!this.modalManager.isModalOpen(this.copyMoveModalName)) {
      this.modalManager.open(this.copyMoveModalName);
    }
  }
}
