/* global libcryptobox */

export default function shouldRenderEnforcedTrusteesModal(status) {
  const { enforcedTrusteeStatus, inviteeTrusteeStatus } = status;

  return (
    inviteeTrusteeStatus !== libcryptobox.InviteeTrusteeStatus.OK ||
    enforcedTrusteeStatus !== libcryptobox.EnforcedTrusteeStatus.OK
  );
}
