import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class PaginationListComponent extends Component {

  get selectedPage() {
    return this.args.selectedPage;
  }


  get minPage() {
    return this.args.minPage;
  }

  set selectedPage(value) {
    this.args.setSelectedPage(value);
  }

  /**
   * Generates a list of integers representing pagination pages.
   * @returns {number[]} List of integers
   */
  get pageList() {
    const maxPage = parseInt(this.maxPage) || 1;
    const pageMargin = 2;
    const selected = parseInt(this.selectedPage);
    const list = [];

    // Validation des entrées
    if (
      typeof selected !== 'number' ||
      typeof maxPage !== 'number' ||
      selected < 1 ||
      maxPage < 1
    ) {
      throw new Error(
        'Les valeurs de selectedPage et maxPage doivent être des nombres positifs.',
      );
    }

    for (let i = selected - pageMargin; i <= selected + pageMargin; i++) {
      if (i > 0 && i <= maxPage) {
        list.push(i);
      }
    }
    return list;
  } // list of integers

  get maxPage() {
    const secondList = this.args.secondList || []
    const listLength = this.args.firstList.length + secondList.length;
    return Math.max(Math.ceil(listLength / this.args.elementsPerPage));
  }

  get nextPage() {
    if (this.maxPage === this.selectedPage) return this.selectedPage;
    return Math.max(this.selectedPage + 1, this.maxPage);
  }

  get nextPageEnabled() {
    return this.selectedPage < this.maxPage;
  }

  get previousPageEnabled() {
    return this.selectedPage > this.minPage;
  }

  @action
  updatePageNumber(pageNumber) {
    this.selectedPage = pageNumber;
    this.args.onPageSelected(pageNumber);
  }
}
