// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr, belongsTo } from '@ember-data/model';

// A file sharing entry.
// See FileSharingCollection.
export default class extends Model {
  // Token.
  // @type string
  @attr token;
  
  // Space's identifier.
  // @type string
  @attr spaceId;
  
  // File name.
  // @type string
  @attr fileName;
  
  // File size in bytes.
  // @type number
  @attr fileSize;
  
  // Date after which the sharing is not longer valid.
  // @type string
  @attr notAfter;
  
  // Suspension status.
  // @type boolean
  @attr suspended;
  
  // Download limit.
  // @type UniqueDownload
  @attr uniqueDownload;
  
  // Creation date, ISO 8601.
  // @type string
  @attr creationDate;
  
  // Download notification (UI+email).
  // @type boolean
  @attr authorSubscription;
  
  // Download notification support. False for sharings created before v4.27.
  // @type boolean
  @attr isAuthorSubscriptionSupported;
  
  // The recipient entered too many wrong codes. The sharing is blocked.
  // @type boolean
  @attr maxCodeAttemptsReached;
  
  // Recipients, if the creator did request the server to send the link.
  // @type Array<string>
  @attr recipients;
  
  // Sharing URL, for newly created record only.
  // @type string
  @attr url;
  
  @belongsTo('space', { async: true, inverse: null }) space;
  @belongsTo('user', { async: false, inverse: null }) author;
}

