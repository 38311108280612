import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class SidenavComponent extends Component {
  id = this.args.id || `sideNav-${ guidFor(this) }`;

  @service('common-service') commonService;

  get isOpen() {
    const isOpen = this.commonService.isOpenSideNav;

    if (isOpen === true && this.args.onOpen) {
      this.args.onOpen();
    }
    return isOpen;
  }

  @action
  handleClose() {
    if (this.isOpen) {
      this.commonService.handleCloseSideNav();
    }
    if (this.args.onClose) {
      this.args.onClose();
    }
  }
}
