import DS from 'ember-data';

// The JSON API responses already abide by the naming conventions expected by EmberData.
// In some case, it is even dangerous to let JSONAPISerializer "guess" the right name; 
// by eg. modelNameFromPayloadKey("server-settings") returns "server-setting" (singular)
// while the model name is actually "server-settings" (plural).
export default DS.JSONAPISerializer.extend({
	modelNameFromPayloadKey(key) {
		return key;
	},

	payloadKeyFromModelName(name) {
		return name;
	},

	keyForAttribute(key) {
		return key;
	},

	keyForRelationship(key) {
		return key;
	}
});
