import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { getCfsbUrls } from 'client/ipc';

export default class ElectronCommunicationService extends Service {
  @service connection;

  async cfsbSetup() {
    if (!this.connection.isDesktopApp) return;
    const { dlpUrl, avUrl } = await getCfsbUrls();
    if (dlpUrl !== null) {
      tBoxClient.security.setDlp(dlpUrl);
    }
    if (avUrl !== null) {
      tBoxClient.security.setAv(avUrl);
    }
  }
}
