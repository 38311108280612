import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
export default class ContextMenu extends Service {
  @tracked mouseX = 0;
  @tracked mouseY = 0;
  isContextMenuOpened = false;

  @action
  handleRightClick(e) {
    this.mouseX = e.pageX;
    this.mouseY = e.pageY;
    let search = document.querySelector("input[name='search']");

    if (search) {
      const searchBounds = search.getBoundingClientRect();
      let searchX = searchBounds.left;
      let searchY = searchBounds.top;
      let xValid =
        e.pageX < searchX || e.pageX > searchX + searchBounds.width + 50;
      let yValid =
        e.pageY < searchY || e.pageY > searchY + searchBounds.height + 30;

      if (xValid || yValid) {
        e.preventDefault();
        this.isContextMenuOpened = true;
        return false;
      }
      return;
    } else {
      e.preventDefault();
      this.isContextMenuOpened = true;
    }
    // return false;
  }
}
