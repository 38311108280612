// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr, belongsTo } from '@ember-data/model';

// The read cursor in a conversation.
// See ReadCursorCollection.
export default class extends Model {
  // The space ID of the conversation.
  // @type string
  @attr spaceId;
  
  // The timestamp of the last read message.
  // @type number
  @attr lastRead;
  
  // The timestamp of the latest message in the conversation.
  // @type number
  @attr latest;
  
  // The number of unread messages.
  // @type number
  @attr unreadCount;
  
  @belongsTo('space', { async: true, inverse: null }) space;
}

