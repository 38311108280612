import { sort } from '@ember/object/computed';
import { action } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class extends Controller {
  @service intl;

  @tracked sortingRules = ['date:desc'];

  @sort('model.notifications', 'sortingRules') sortedNotifications;

  @action
  async markAllAsRead() {
    const notifications = await this.model.notifications;
    notifications.forEach((notification) => notification.markAsRead());
  }

  @action
  async removeAllNotifications() {
    const notifications = await this.model.notifications;
    notifications.forEach((notification) => notification.destroyRecord());
  }
}
