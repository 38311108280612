import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { inject as service } from "@ember/service";
import constants from "../constants";

const EMPTY_PASSWORD = "";

export default class ConfirmPasswordModalComponent extends Component {
  passwordInputElement = null;
  confirmPasswordModalName = constants.CONFIRM_PASSWORD_MODAL_NAME;

  @tracked password = EMPTY_PASSWORD;
  @tracked writing = false;

  @service account;
  @service modalManager;

  get error() {
    const { error } = this.args;
    const inputError = !this.writing && error;

    if (inputError) {
      this.passwordInputElement.focus();
    }

    return inputError;
  }

  @action
  onConfirm() {
    const { onConfirm } = this.args;

    this.writing = false;

    onConfirm(this.password);

    this.password = EMPTY_PASSWORD;
  }

  @action
  onCancel() {
    this.writing = false;
    this.modalManager.close(this.confirmPasswordModalName);
  }

  @action
  onChangePassword(event) {
    const { value } = event.target;
    this.password = value;
    this.writing = true;
  }

  @action
  didInsertInputPassword(element) {
    this.passwordInputElement = element;
  }

  @action
  onOpenModal() {
    this.passwordInputElement.focus();
  }

  @action
  onCloseModal() {
    const { onClose } = this.args;

    this.password = EMPTY_PASSWORD;
    onClose();
  }
}
