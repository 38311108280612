import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class WarningTitle extends Component {


  @service commonService;

  get disabledActions() {
    return !this.args.model.space.isAdmin;
  }
}
