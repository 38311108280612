import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { debounce } from '@ember/runloop';
import { hash } from 'rsvp';
import diacritic from 'diacritic';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { A } from '@ember/array';
import constants from '../../confirm-password/constants';
/* global libcryptobox */

const EMPTY_TRUSTEE = null;
const NO_PASSWORD = '';

async function batchAddTrustees(trustees, password) {
  return hash(
    trustees.reduce(
      (res, trustee) => ({
        ...res,
        [trustee.email]: tBoxClient.trustee.add(trustee.email, password, false),
      }),
      {},
    ),
  );
}

/*
 * TODO
 *   - Focus on open modal "add trustee"
 *   - Validate on press "enter"
 *   - Add trustee modal : remove already added trustees to the input proposition list
 * */
export default class UserTrusteesComponent extends Component {
  addTrusteesModalName = 'addTrusteesModal';
  @tracked trusteeToRemove = EMPTY_TRUSTEE;

  @tracked isNotCBXMember = false;
  @tracked invalidEmails = false;
  @tracked searchUser = null;
  @tracked newTrustees = A([]);
  @tracked searchUserResults = [];
  @tracked highlightedResultIndex = -1;
  @tracked CBUsers;
  @tracked searchUserIsAlreadyTrustee;
  @tracked passwordError = false;
  @tracked is2faDone = 0;
  @tracked isEditingTrusteeInProgress = false;

  @service account;
  @service store;
  @service modalManager;
  @service intl;
  @service connection;

  constructor() {
    super(...arguments);
    this.CBUsers = this.store.query('user', {});
  }

  _resetInputError() {
    if (this.invalidEmails) {
      this.invalidEmails = false;
    }

    if (this.isNotCBXMember) {
      this.isNotCBXMember = false;
    }

    if (this.searchUserIsAlreadyTrustee) {
      this.searchUserIsAlreadyTrustee = false;
    }
  }

  searchUsers(searchTerm) {
    let searchResult = [];

    if (!searchTerm) {
      this.searchUserResults = searchResult;
      return;
    }

    const term = diacritic.clean(searchTerm.toLowerCase());
    this.CBUsers.forEach((user) => {
      if (user.email !== this.account.userEmail) {
        const userName = diacritic.clean(user.fullName.toLowerCase());
        const userMail = diacritic.clean(user.email.toLowerCase());

        if (userName.indexOf(term) !== -1) {
          searchResult.push(user);
        } else if (userMail.indexOf(term) !== -1) {
          searchResult.push(user);
        }
      }
    });

    if (searchResult.length === 0) {
      this.highlightedResultIndex = -1;
    }
    this.searchUserResults = searchResult.sortBy('fullName');
  }

  get buttonIsDisabled() {
    return this.newTrustees.length === 0 || this.searchUser;
  }

  /* tBoxClient actions : ADD or REMOVE trustees */

  async removeTrustee(email, password) {
    return tBoxClient.trustee.remove(email, password);
  }

  async addTrustees(trustees, password) {
    return batchAddTrustees(trustees, password);
  }

  close2fa() {
    // incrementProperty() ensures didReceiveAttrs is invoked on the component.
    this.is2faDone = this.is2faDone + 1;
  }

  /* Actions */
  @action
  openAddTrusteesModal() {
    this.modalManager.open(this.addTrusteesModalName);
  }

  @action
  closeAddTrusteesModal() {
    this.modalManager.close(this.addTrusteesModalName);
    this.clearInput();

    this.account.toggleLockModalsFlow(false);
    this.account.openNextModal();
  }

  @action
  clearInput() {
    this.newTrustees.clear();
    this.searchUser = null;
    this.lastsearchUser = null;
    this.isNotCBXMember = false;
    this.invalidEmails = false;
    this._resetInputError();
  }

  @action
  addTrusteeToList(userEmail) {
    this._resetInputError();

    if (this.isSearchings) {
      return;
    }

    if (userEmail && typeof userEmail == 'string') {
      this.searchUser = userEmail;
    }

    if (this.searchUser) {
      this.searchUser = this.searchUser.trim();
    } else {
      return false;
    }

    this.isSearching = true;
    tBoxClient.user
      .match(this.searchUser)
      .then((result) => {
        this.isSearching = false;

        if (result.length) {
          let userToAdd = [];
          let notMatchedUsers = [];
          let invalidEmails = [];
          result.forEach((user) => {
            if (user.isRegistered) {
              userToAdd.push(user);
            } else {
              if (user.isEmailValid) {
                notMatchedUsers.push(user);
              } else {
                invalidEmails.push(user);
              }
            }
          });
          userToAdd.forEach((user) => {
            const isCurrentUser = user.email === this.account.userEmail;
            const isAlreadyInList = this.newTrustees.isAny('email', user.email);
            const trusteesList = this.account.trustees;
            if (trusteesList.isAny('email', user.email) || isAlreadyInList) {
              this.searchUserIsAlreadyTrustee = true;
              return;
            }

            if (!isCurrentUser && !isAlreadyInList) {
              this.newTrustees.pushObject({
                email: user.email,
                fullName: user.givenName + ' ' + user.surname,
                certificate: user.certificate,
              });
            }
          });

          if (notMatchedUsers.length) {
            this.isNotCBXMember = true;
          }

          this.clearSearch();
          if (invalidEmails.length) {
            this.invalidEmails = true;
          }
        }
      })
      .catch(() => {
        this.isSearching = false;
      });
  }

  @action
  removeTrusteeToList(trustee) {
    this.newTrustees.removeObject(trustee);
  }

  @action
  confirmDelete(index) {
    this.modalManager.open(`confirmRemove-${index}`);
  }

  @action
  selectResult(user) {
    this.addTrusteeToList(user.get('email'));
  }

  @action
  updateTerm(term) {
    this.searchUser = term;
    debounce(this, this.searchUsers, term, 500);
  }

  @action
  clearSearch() {
    this.searchUser = null;
    this.searchUserResults = [];
    this.highlightedResultIndex = -1;
  }

  async toggleTrustees(password) {
    this.isEditingTrusteeInProgress = true;
    try {
      if (this.trusteeToRemove) {
        await this.removeTrustee(this.trusteeToRemove, password);
        this.trusteeToRemove = EMPTY_TRUSTEE;
      } else {
        await this.addTrustees(this.newTrustees, password);
        this.closeAddTrusteesModal();
      }
    } finally {
      this.isEditingTrusteeInProgress = false;
    }
  }

  async shouldOpenConfirmPasswordModal() {
    const currentAccount = await tBoxClient.account.getCurrent();

    return this.connection.isDesktopApp && currentAccount.isOnDevice;
  }

  @action
  async addNewTrustees() {
    if (this.isSearching || this.buttonIsDisabled) {
      return;
    }

    const openModal = await this.shouldOpenConfirmPasswordModal();
    if (openModal) {
      this.modalManager.open(constants.CONFIRM_PASSWORD_MODAL_NAME);
      return;
    }

    await this.toggleTrustees(NO_PASSWORD);
  }
  willDestroy() {
    super.willDestroy();
    this.clearInput();
  }

  @action
  async deleteTrustee(email) {
    this.trusteeToRemove = email;

    const openModal = await this.shouldOpenConfirmPasswordModal();
    if (openModal) {
      this.modalManager.open(constants.CONFIRM_PASSWORD_MODAL_NAME);
      return;
    }

    await this.toggleTrustees(NO_PASSWORD);
  }

  @action
  onCancelAddTrustee() {
    this.closeAddTrusteesModal();
  }

  /*
   * ELECTRON
   * Password confirmation
   * */
  @action
  async onConfirmPassword(password) {
    try {
      await this.toggleTrustees(password);
    } catch (error) {
      if (error.code === libcryptobox.ErrorCode.InvalidCredentials) {
        this.passwordError = true;
      }
      return error;
    }

    this.passwordError = false;

    this.modalManager.close(constants.CONFIRM_PASSWORD_MODAL_NAME);
    this.close2fa();
    return null;
  }

  @action
  onClosePassword() {
    this.passwordError = false;
  }

  @action
  onCancel2FA() {
    this.close2fa();
  }
}
