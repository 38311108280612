import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class CategoriesManagementComponent extends Component {
  @tracked newCategory = '';
  @tracked modalName = 'categories-management-modal';
  @tracked errorMessage = '';
  @tracked isInvalid = false;
  @tracked isOpen = false;

  @service store;
  @service router;
  @service modalManager;
  @service intl;

  get categories() {
    return this.args.categories;
  }

  get sortedCategories() {
    return this.args.sortedCategories;
  }


  @action
  resetError() {
    this.isInvalid = false;
  }

  @action
  async createCategory(event) {
    event.preventDefault();
    this.newCategory.trim();

    if (!this.newCategory) {
      this.isInvalid = true;
      this.errorMessage = this.intl.t('categories.errors.empty');
      return false;
    }

    if (this.categories.isAny('name', this.newCategory)) {
      this.isInvalid = true;
      this.errorMessage = this.intl.t('categories.errors.conflict');
      return false;
    }
    const store = this.store;
    await store.createRecord('category', { name: this.newCategory }).save();
    this.args.reloadCategories();
    this.newCategory = '';
  }

  @action
  async deleteCategory(categoryRecord) {
    this.categories.removeObject(categoryRecord);
    await categoryRecord.destroyRecord();
  }

  @action
  openModal() {
    this.modalManager.open(this.modalName);
  }

  @action
  closeModal() {
    this.modalManager.close(this.modalName);
  }

  @action
  resetInput() {
    this.newCategory = '';
    this.closeModal();
  }
}
