import { tracked } from '@glimmer/tracking';
import Service from '@ember/service';

export default class ModalManager extends Service {
  /**
   * A map of modal names to their open state.
   * @private
   * @type {Map<string, boolean>}
   * @example new Map([['my-modal', true]])
   * @default new Map()
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map
   */
  @tracked _modals = new Map();

  /**
   * Opens a modal specified by its name.
   * @param {string} name - The name of the modal to open.
   * @param options - The options to pass to the modal.
   * @example open('my-modal', { foo: 'bar' })
   * @example open('my-modal')
   */
  open(name, options = {}) {
    this._modals.set(name, { isOpen: true, options })
    this._markModalsAsTracked();
  }

  /**
   * Closes a modal specified by its name.
   * @param {string} name - The name of the modal to close.
   * @example close('my-modal')
   */
  close(name) {
    if (this._modals.has(name)) {
      this._modals.set(name, { ...this._modals.get(name), isOpen: false });
    }
    this._markModalsAsTracked();
  }

  /**
   * Closes all modals.
   * @example closeAll()
   */
  closeAll() {
    this._modals.forEach((value, key) => {
      this._modals.set(key, { ...value, isOpen: false });
    });
    this._markModalsAsTracked();
  }
  /**
   * Checks if a modal specified by its name is open.
   * @param {string} name - The name of the modal to check.
   * @returns {boolean} Returns true if the modal is open, false otherwise.
   * @example isModalOpen('my-modal') // false
   */
  isModalOpen(name) {
    return this._modals.get(name)?.isOpen;
  }

  /**
   * Returns the options of a modal specified by its name.
   * @param {string} name - The name of the modal to get options for.
   * @returns {object} The options of the modal.
   * @example getOptions('my-modal') // { foo: 'bar' }
   */
  getOptions(name) {
    return this._modals.get(name)?.options;
  }

  /**
   * Marks the `_modals` map as tracked to trigger re-rendering.
   * This method is used internally to ensure that changes in the state of modals are reflected in templates.
   * @private
   */
  _markModalsAsTracked() {
    this._modals = new Map(this._modals);
  }
}
