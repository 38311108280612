import Component from '@glimmer/component';
import { debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import BlobFileWriter from 'client/toolbox/blob-file-writer';
import {
  FILE_TYPE_IMAGE,
  FILE_TYPE_PDF,
  FILE_TYPE_UNKNOWN,
  FileInfoPreview,
} from '../../../file-viewer/fileInfoPreview';
import mime from 'mime';
import getNodeIconName from '../../../../utils/getNodeIconName';
/* global libcryptobox */

export default class AttachmentComponent extends Component {
  @tracked isLoading;
  @tracked loadingPrevious;
  @tracked filePreviewIndex = -1;

  @service transfers;
  @service store;
  @service modalManager;
  @service commonService;

  get fileViewerInfoPreviewList() {
    const attachment = this.args.attachment;
    const mimeType = mime.getType(attachment.fileName);
    let fileType = '';
    if (mimeType?.startsWith('image')) {
      fileType = FILE_TYPE_IMAGE;
    } else if (mimeType === 'application/pdf') {
      fileType = FILE_TYPE_PDF;
    } else {
      fileType = FILE_TYPE_UNKNOWN;
    }

    const iconName = getNodeIconName(
      false,
      attachment.fileName.split('.').pop(),
    );
    return [
      new FileInfoPreview(
        fileType,
        mimeType,
        attachment.fileName,
        null,
        iconName,
        null,
        attachment.id,
        attachment.fileSize,
      ),
    ];
  }

  _scrollBottom() {
    const scrollHeight = document.querySelector(
      '.conversation-stream',
    ).scrollHeight;
    const container = document.querySelector('.container-conversationStream');
    container.scrollTop = scrollHeight;
  }

  get isPdf() {
    return this.args.attachment.fileName.indexOf('.pdf') > -1;
  }

  get hasPreview() {
    return this.args.attachment.preview != null;
  }

  get previewImage() {
    const writer = new BlobFileWriter();

    const attachmentId = this.args.attachment.id;
    const item = sessionStorage.getItem(attachmentId);

    if (item) {
      if (!this.loadingPrevious) {
        debounce(this, this._scrollBottom, 400);
      }

      return item;
    }

    return tBoxClient.attachment
      .downloadPreview(attachmentId, -1, writer)
      .then(() => {
        if (!this.loadingPrevious) {
          debounce(this, this._scrollBottom, 400);
        }

        const blob = new Blob(writer.chunkBlobs, { type: 'image/jpeg' });
        const url = URL.createObjectURL(blob);
        sessionStorage.setItem(attachmentId, url);
        return url;
      });
  }

  @(task(function* (attachment) {
    yield timeout(1000);
    let messageAtt;
    attachment.message.then((message) => {
      messageAtt = message;
      this.store
        .createRecord('message', {
          space: message.space,
          text: message.text,
          status: libcryptobox.MessageStatus.Pending,
        })
        .save()
        .then((newMessage) => {
          this.store
            .createRecord('attachment', {
              message: newMessage,
              fileName: attachment.name,
              fileSize: attachment.fileSize,
              fileData: attachment.fileData,
            })
            .save()
            .then(() => messageAtt.destroyRecord())
            .then(() => newMessage.post());
        });
    });
  }).drop())
  _resendAttachment;

  @action
  async resendAttachment(attachment) {
    this._resendAttachment.perform(attachment);
  }

  @action
  cancelAttachmentUpload(message) {
    message.get('cancelUpload')();
  }

  @action
  removeAttachment(message) {
    message.destroyRecord();
  }

  @action
  cancelAttachmentDownload(attachment) {
    attachment.cancelDownload();
  }

  @action
  downloadAttachment(attachment) {
    attachment.downloadAttachment();
  }

  @action
  previewPdf() {
    this.filePreviewIndex = 0;
  }

  @action
  onFileViewerClose() {
    this.filePreviewIndex = -1;
  }
}
