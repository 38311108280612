import { DateTime } from "luxon";

function getReportErrorBody({
  server,
  userEmail,
  title,
  code,
  message,
  details,
}) {
  return (
    "Domain: " +
    server +
    "\nUser Agent: " +
    navigator.userAgent +
    "\nUser: " +
    userEmail +
    "\nDate: " +
    DateTime.utc().toFormat('MM/dd/yyyy, HH:mm:ss') +
    " (UTC)" +
    "\n\n\nError Title: " +
    title +
    "\nCode: " +
    code +
    "\nLocation hash: " +
    window.location.hash +
    "\nMessage: " +
    message +
    "\nCall stack: " +
    details
  );
}

export default getReportErrorBody;
