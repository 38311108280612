import { FILE_SUBSCRIPTION_TYPE } from "../constants";

export default function getFileSubscriptionCollectionName(type) {
  if (type === FILE_SUBSCRIPTION_TYPE.MODIFICATIONS) {
    return "fileSubscription";
  }
  if (type === FILE_SUBSCRIPTION_TYPE.DOWNLOADS) {
    return "fileDownloadSubscription";
  }
  return "";
}
