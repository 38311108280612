import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';

export default class RecipientsListComponent extends Component {
  @tracked emailInput = '';
  @tracked invalidEmails = false;

  get isEmptyList() {
    return this.args.isEmptyList;
  }
  get errorMaxRecipients() {
    return this.args.errorMaxRecipients
  }


  get authorizeCurrent() {
    return this.args.authorizeCurrent || false;
  }

  get recipients() {
    return this.args.recipients;
  }

  get numberRecipientsMax() {
    return this.args.numberRecipientsMax || -1;
  }

  @service account;

  regexp =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  get isMaxRecipientsReached() {
    if (this.numberRecipientsMax === -1 || !this.recipients) return false;
    return this.numberRecipientsMax < this.recipients.length;
  }

  @action
  keyDown(event) {
    if (this.invalidEmails) {
      this.invalidEmails = false;
    }

    if (event.keyCode === 13) {
      event.preventDefault();
      debounce(this, this.addEmailToList, 500);
    }
  }

  @action
  async addEmailToList() {
    if (!this.emailInput) return;

    if (this.isEmptyList) {
      this.args.setIsEmptyList(false);
    }

    let addedEmailList = (await tBoxClient.user.parse(this.emailInput)).map((email) => email.email);
    let invalidEmails = false;
    this.emailInput = '';

    addedEmailList.forEach((currentEmail) => {
      if (!this.regexp.test(currentEmail)) {
        invalidEmails = true;
        return;
      }

      if (!currentEmail) {
        invalidEmails = true;
        return;
      }

      let currentUserEmail = this.account.userEmail === currentEmail;
      let isAlreadyInList = this.recipients.includes(currentEmail);

      if ((!currentUserEmail || this.authorizeCurrent) && !isAlreadyInList) {
        this.recipients.pushObject(currentEmail);
      }
    });

    this.updateErrorMax();

    if (invalidEmails) {
      this.invalidEmails = true;
    }
    document.querySelector('.recipients-list input').value = '';
  }

  @action
  removeRecipient(recipient) {
    this.recipients.removeObject(recipient);
    this.updateErrorMax();
  }

  @action
  updateErrorMax() {
    this.args.setErrorMaxRecipients(this.isMaxRecipientsReached);
  }
}
