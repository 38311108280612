import { tBoxClient } from "client/initializers/init-toolbox";
import { NODE_NAME_ERROR } from "./enums";

async function checkName(currentName, newName) {
  let error = false;

  if (currentName === newName) {
    error = NODE_NAME_ERROR.NO_CHANGE;
  } else if (newName === "") {
    error = NODE_NAME_ERROR.EMPTY;
  } else if (!(await tBoxClient.validation.isNameValid(newName))) {
    if (newName === "." || newName === "..") {
      error = NODE_NAME_ERROR.FORBIDDEN;
    } else if (newName.length > 254) {
      error = NODE_NAME_ERROR.TOO_LONG;
    } else {
      error = NODE_NAME_ERROR.SLASH;
    }
  }

  return error;
}

export default checkName;
