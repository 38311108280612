import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class ChangeEmail extends Route {

	@service account;
	@service connection;
  @service authService;
  @service router;

  beforeModel() {
    this.authService.loginIfNotAuthenticated();
  }
	constructor() {
		super(...arguments);
	}

	async model(params) {
		let account = this.account;
		let token = params.token;
		let route = this;
		let email = await account.getNewEmail(token);
		if (email === null) {
			this.router.transitionTo('my-groups');
			return false;
		}
		return { ...email, currentEmail: account.userEmail };
	}

	@action
	didTransition() {
		this.controller.set('isEmailChangeValidated', false);
	}
}
