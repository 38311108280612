import { action, computed } from '@ember/object';
import Component from '@glimmer/component';

export default class CategoryInput extends Component {
  get category() {
    return this.args.category;
  }

  get space() {
    return this.args.space;
  }

  @computed('category.name')
  get inputId() {
    return this.category.name.replace(' ', '');
  }

  @action
  handleClick() {
    return this.category.toggleSpace(this.space);
  }

  // @computed('category.spaces.[]', 'space.id')
  get categoryActiveForSpace() {
    return !!this.category.spaces.findBy('id', this.space.get('id'));
  }
  set categoryActiveForSpace(value) {
    // HACK: This is a hack to make the computed property settable
  }
}
