import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';

export default Helper.extend({
  intl: service(),
  compute(role) {
    switch(role[0]){
      case 'auditor':
        return this.intl.t('members.member.role.auditor');
      case 'member':
        return this.intl.t('members.member.role.member');
      case 'owner':
        return this.intl.t('members.member.role.owner');
    }
  },
});
