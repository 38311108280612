import { tBoxClient } from 'client/initializers/init-toolbox';
import BaseAdapter from 'client/adapters/application';

export default class DepositBox extends BaseAdapter {
  queryRecord(store, type, query) {
    return tBoxClient.depositBox.get(query.id).then((payload) => {
      return this.normalizeRecord(store, type, payload);
    });
  }

  createRecord(store, type, snapshot) {
    return tBoxClient.depositBox
      .create(snapshot.attr('name'), {
        description: snapshot.attr('description'),
        note: snapshot.attr('note'),
        code: snapshot.adapterOptions.code,
      })
      .then((payload) => this.normalizeRecord(store, type, payload));
  }

  updateRecord(store, type, snapshot) {
    return Promise.resolve(
      tBoxClient.depositBox
        .update(snapshot.id, {
          name: snapshot.attr('name'),
          description: snapshot.attr('description'),
          note: snapshot.attr('note'),
        })
        .catch((err) => {
          snapshot.record.rollbackAttributes();
          return err;
        }),
    );
  }

  updateState(store, type, query) {
    return Promise.resolve(
      tBoxClient.depositBox
        .update(query.id, {
          state: query.state,
        })
        .catch((err) => {
          return err;
        }),
    );
  }

  deleteRecord(store, type, query) {
    return tBoxClient.depositBox.delete(query.id);
  }
}
