import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class HistoryWatch extends Component {
  @service commonService;
  @service intl;

  // flags
  actionIcon = 'ic-history-focus';
  actionIconDisabled = 'ic-history-azure';
  actionToRun = null;
  theme = null;

  @action
  toggleHistory() {
    this.args.toggleHistory();
    // set revision to 0 when we hide the history panel
    if (!this.displayHistory) {
      this.commonService.set('revision', 0);
    }
  }
}
