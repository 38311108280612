import Route from '@ember/routing/route';
import { A } from '@ember/array';
import { inject as service } from '@ember/service';

export default class Edit extends Route {
  @service store;
  @service intl;
  @service account;
  @service router;
  @service authService;

  beforeModel() {
    this.authService.loginIfNotAuthenticated();
  }


  model() {
    const account = this.account;
    let spaceId = this.paramsFor('my-groups.group').space_id;
    if (account.get('isInPersonalSpace')) {
      this.router.transitionTo('my-groups.group.documents', spaceId);
    }
    return this.store.findRecord('space', spaceId);
  }

  async setupController(controller, model) {
    controller.set('model', model);
    controller.set('isProcessing', false);
    this.controllerFor('my-groups.group').set('showHeader', false);

    // TODO : Duplicated code with create-group router
    let device;
    let securityLevels = A();

    try {
      device = await this.store.queryRecord('device', {});
      securityLevels = await device.securityLevels;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.debug('Route => error no current device');
    }

    controller.set(
      'currentDeviceSecurityLevels',
      securityLevels.map((sl) => {
        const serializedData = sl.serialize()?.data?.attributes;
        const checked = !!model.securityLevels.findBy('id', sl.id);
        return {
          ...serializedData,
          id: sl.id,
          checked,
        };
      }),
    );
  }


  afterModel(resolvedModel) {
    let spaceName = resolvedModel.get('name');
    document.title = 'Cryptobox - ' + spaceName + ' - ' + this.intl.t('group.navigation.overview');
  }

  deactivate() {
    let spaceRecord = this.controller.get('model'),
      spaceRecordState = spaceRecord.get('currentState.stateName');
    if (spaceRecordState.indexOf('uncommitted') !== -1) {
      spaceRecord.rollbackAttributes();
    }
  }

  resetController(_controller, isExiting, ) {
    if (isExiting) {
      this.controllerFor('my-groups.group').set('showHeader', true);
    }
  }
}
