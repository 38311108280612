import Component from '@glimmer/component';
import ArrayProxy from '@ember/array/proxy';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class TreeItemsFolder extends Component {
  @tracked fileListArray = [];

  @service('transfers') transfersManager;

  readEntries(entriesArray) {
    return new Promise((resolve) => {
      const processEntry = (entry) => {
        if (!entry) return;
        if (entry?.isFile) {
          entry.file((fileObject) => {
            fileObject.CBRelativePath = entry.fullPath.slice(1);
            this.fileListArray.pushObject(fileObject);
            nextEntry();
          });
        } else if (entry?.isDirectory) {
          entry.createReader().readEntries((dirEntries) => {
            this.readEntries(dirEntries).then(nextEntry);
          });
        }
      };

      const nextEntry = () => {
        const next = entriesIterator.next();
        if (next.done) {
          resolve();
        } else {
          processEntry(next.value);
        }
      };

      const entriesIterator = (function* (entries) {
        for (let entry of entries) {
          yield entry;
        }
      })(entriesArray);

      nextEntry();
    });
  }

  @action
  async drop(e) {
    const items = e.dataTransfer.items;
    const files = e.dataTransfer.files;

    if (!files.length) {
      return;
    }
    const space = await this.args.folder.get('space');

    if (!items) {
      this.transfersManager.uploadFilesList(files, this.args.folder, space);
    } else {
      let entriesArray = [];
      [].forEach.call(items, (entry) => {
        entriesArray.push(entry.webkitGetAsEntry());
      });
      this.fileListArray = ArrayProxy.create({ content: [] });
      this.readEntries(entriesArray).then(() => {
        const fileListArray = this.fileListArray;
        let fileList = [];
        fileListArray.get('content').forEach((file) => {
          fileList.push(file);
        });
        this.transfersManager.uploadFolderFilesList(
          fileList,
          this.args.folder,
          space,
        );
        fileListArray.clear();
      });
    }
  }

  @action
  oncontextmenu() {
    this.args.onContextMenu(this.args.folder);
  }

  @action
  handleClick() {
    this.args.actionClick();
  }
}
