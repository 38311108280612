// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr } from '@ember-data/model';

// The settings of the authenticated user.
// See AccountSettingsCollection.
export default class extends Model {
  // Notify file changes by email.
  // @type boolean
  @attr fileChangeEmailNotification;
}

