// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr } from '@ember-data/model';

// A security level.
// See SecurityLevelCollection.
export default class extends Model {
  // Name.
  // @type string
  @attr name;
  
  // Acronym.
  // @type string
  @attr acronym;
  
  // Color.
  // @type SecurityLevelColor
  @attr color;
  
  // Icon.
  // @type SecurityLevelShape
  @attr shape;
  
  // Always false. Deleted levels are not provided.
  // @type boolean
  @attr deleted;
}

