// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr } from '@ember-data/model';

// A trustee.
// See TrusteeCollection.
export default class extends Model {
  // Email.
  // @type string
  @attr email;
  
  // First name.
  // @type string
  @attr givenName;
  
  // Last name.
  // @type string
  @attr surname;
  
  // User certificate.
  // @type * - Call `tBoxClient.decodeUint8Array()` to read the value. See Uint8Array in the getting started guide.
  @attr certificate;
  
  // Has been enforced.
  // @type boolean
  @attr enforced;
}

