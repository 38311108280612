export default async function getVariables() {
  try {
    const response = await fetch('static/variables.json');
    if (!response.ok) throw new Error('Response not OK');
    return await response.json();
  } catch (error) {
    console.error(error);
    return {};
  }
}
