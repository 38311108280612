import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import getDepositBoxStateFilters from '../../utils/getDepositBoxStateFilters';
import getSortingDepositBoxChoicesFilters from '../../utils/getSortingDepositBoxChoicesFilters';

export default class IndexController extends Controller {
  @service intl;
  @service modalManager;
  @service store;
  @service manageStorage;
  @service('deposit-box') depositBoxService;

  @tracked sorting;
  @tracked state;
  @tracked depositBoxes = [];

  sortingChoices = getSortingDepositBoxChoicesFilters(this.intl);
  stateFilters = getDepositBoxStateFilters(this.intl);

  constructor() {
    super(...arguments);
    this.sorting = this.sortingChoices[0];
    this.state = this.stateFilters[0];
  }


  get sortedModel() {
    const { order = true, property = 'name' } = this.sorting || {};
    let sortedModel = this.depositBoxes.sortBy(property);

    if (this.state.value === 'populatedRead') {
      const populatedRead = sortedModel.filter( model => model.state === 'populatedRead');
      const populatedUnread = sortedModel.filter(model => model.state === 'populatedUnread');
      sortedModel = populatedUnread.concat(populatedRead);
    } else if (this.state.value !== 'none') {
      sortedModel = sortedModel.filter(model => model.state === this.state.value);
    }
    return order ? sortedModel : sortedModel.reverse();
  }

  @action
  loadDepositBoxes() {
    this.depositBoxes = this.depositBoxService.getAllDepositBoxes();
  }

  @action
  updateSorting(sorting) {
    this.sorting = sorting;
    this.manageStorage.setSessionStorageItem('sortingDepositBoxes', sorting);
  }

  @action
  updateState(state) {
    this.state = state;
    this.manageStorage.setSessionStorageItem('stateDepositBoxes', state);
  }

  @action
  openModalCreateBox() {
    this.modalManager.open('depositBox-editor-modal');
  }
}
