import Component from "@glimmer/component";
import { action } from "@ember/object";

export default class ListComponent extends Component {
  @action
  onClickItem(item, index, event) {
    event.stopPropagation();

    const { onClickItem } = this.args;

    if (onClickItem) {
      onClickItem(item, index);
    }
  }
}
