import Component from "@glimmer/component";
import { action } from "@ember/object";
import { inject as service } from "@ember/service";
import { tracked } from '@glimmer/tracking';

export default class BadgeComponent extends Component {
  @service modalManager;
  @tracked badgeStyle = "";

  constructor() {
    super(...arguments);
    this.setColor(this.args.color);
  }

  setColor(color) {
    this.badgeStyle = `color:${color}; border:2px solid ${color};`;
  }

  @action
  onClick() {
    if (this.args.isClickable) {
      this.modalManager.open('platform-customization-modal');
    }
  }
}
