import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import Controller from '@ember/controller';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { MODAL_TYPES } from '../components/enforced-trustee/modal/component';
import getEnforcedTrusteeModalType from '../utils/getEnforcedTrusteeModalType';
import {
  DEVICE_KEY_ASSOCIATION_MODAL_NAME,
  ENFORCED_TRUSTEES_MODAL_NAME,
} from '../utils/enums';
import getSupportEmail from '../utils/getSupportEmail';
import getReportErrorBody from '../utils/getReportErrorBody';
import getMailTo from '../utils/getMailTo';
import { tracked } from '@glimmer/tracking';
import { openExternal } from 'client/ipc';

export default class Application extends Controller {
  @service account;
  @service('transfers') transfersManager;
  @service modalManager;
  @service intl;
  @service messaging;
  @service observerFactory;
  @service store;
  @service router;
  @service connection;
  @service commonService;
  @service platformCustomization;

  @tracked platformConfig = null;

  enforcedTrusteesModalName = ENFORCED_TRUSTEES_MODAL_NAME;
  enforcedTrusteeModalTypes = Object.keys(MODAL_TYPES);

  get isDepositBoxForm() {
    return this.router.currentRouteName === 'deposit-box-form';
  }

  constructor() {
    super(...arguments);
    this.observerFactory; //WorkAround to enable observer on the service

    let hidden, visibilityChange;
    if (typeof document.hidden !== 'undefined') {
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }

    const handleVisibilityChange = () => {
      if (!document[hidden]) {
        const icon = document.querySelector('link[rel="icon"]');
        icon.href = `/static/img/logo.png`;
      }
    };

    document.addEventListener(visibilityChange, handleVisibilityChange, false);

    this.platformCustomization.getConfigFromUrl(
      this.connection.serverAddress,
      (config) => {
        this.platformConfig = config;
      },
    );
    void this.platformCustomization.getPlatformSettings();
  }

  @computed('account.enforcedTrusteesStatus')
  get enforcedTrusteeModalType() {
    return getEnforcedTrusteeModalType(this.account.enforcedTrusteesStatus);
  }

  @task({ drop: true })
  *_forceUpload(files, folder, space) {
    yield timeout(500);

    if (this.transfersManager.isFolder) {
      this.transfersManager.uploadFolderFilesList(files, folder, space, true);
    } else {
      this.transfersManager.uploadFilesList(files, folder, space, true);
    }
  }

  @computed('account.{isLoggedIn,trustees}', 'platformConfig.isEnabled')
  get cryptoboxStatus() {
    if (
      !this.account.get('isLoggedIn') ||
      this.account.get('trustees') === null
    ) {
      return {
        message: null,
        action: null,
        class: null,
        icon: null,
        link: null,
      };
    }

    const hasTrustees = this.account.get('trustees')?.length > 0;
    const isPlatformEnabled = this.platformConfig?.isEnabled;

    if (!hasTrustees) {
      return {
        message: this.intl.t('appState.noTrustee'),
        action: null,
        icon: 'shield-alert',
        class: 'warning',
        link: 'my-account',
      };
    }

    if (!isPlatformEnabled) {
      return {
        message: this.intl.t('appState.ok'),
        icon: 'shield',
        action: null,
        class: null,
        link: null,
      };
    }

    return {
      message: null,
      action: null,
      class: null,
      icon: null,
      link: null,
    };
  }

  @computed(
    'messaging.{isEnabled,isOffline,hideOfflineBanner}',
    'account.isLoggedIn',
  )
  get showBannerOffline() {
    const messaging = this.messaging;
    const logged = this.account.isLoggedIn;
    return (
      messaging.get('isEnabled') &&
      messaging.get('isOffline') &&
      logged &&
      !messaging.get('hideOfflineBanner')
    );
  }

  @computed(
    'transfersManager.conflictObject.fileConflicts.length',
    'transfersManager.conflictObject.folderConflicts.length',
  )
  get conflictMessage() {
    if (this.transfersManager.conflictObject) {
      let filesConflictsLength =
        this.transfersManager.conflictObject.fileConflicts.length;
      let folderConflictsLength =
        this.transfersManager.conflictObject.folderConflicts.length;
      return this.intl.t('conflictModal.message', {
        count: filesConflictsLength + folderConflictsLength,
      });
    }
    return '';
  }

  @computed('transfersManager.conflictObject.fileConflicts.length')
  get conflictFiles() {
    if (this.transfersManager.conflictObject) {
      let filesConflictsLength =
        this.transfersManager.conflictObject.fileConflicts.length;
      return this.intl.t('conflictModal.file', {
        count: filesConflictsLength,
      });
    }
    return '';
  }

  @computed('transfersManager.conflictObject.folderConflicts.length')
  get conflictFolders() {
    if (this.transfersManager.conflictObject) {
      let folderConflictsLength =
        this.transfersManager.conflictObject.folderConflicts.length;
      return this.intl.t('conflictModal.folder', {
        count: folderConflictsLength,
      });
    }
    return '';
  }

  @action
  async reportError(error) {
    const isDesktopApp = this.connection.isDesktopApp;
    const settings = this.platformCustomization.serverSettings;
    const { title, code, message, details } = error;
    const email = await getSupportEmail(isDesktopApp, settings);
    const subject = 'Cryptobox error report: ' + error.title;
    const body = getReportErrorBody({
      server: this.connection.serverAddress,
      userEmail: this.account.get('userEmail'),
      title,
      code,
      message,
      details,
    });

    const mailTo = getMailTo({ email, subject, body });

    if (this.connection.isDesktopApp) {
      await openExternal(mailTo);
    } else {
      window.location = mailTo;
    }
  }

  @action
  overwriteFile() {
    const conflictObject = this.transfersManager.conflictObject;
    conflictObject.overwrite();
  }

  @action
  ignoreConflicts() {
    const conflictObject = this.transfersManager.conflictObject;
    conflictObject.ignoreConflicts();
  }

  @action
  abortUpload() {
    this.modalManager.close('node-conflict-modal');
  }

  @action
  cancelUpload() {
    this.modalManager.close('filesCountWarning');
  }

  @action
  continueUpload() {
    this.modalManager.close('filesCountWarning');
    const files = this.transfersManager.pendingFiles;
    const folder = this.transfersManager.pendingFolder;
    const space = this.transfersManager.pendingSpace;
    this._forceUpload.perform(files, folder, space);
  }

  @action
  closeConflictModal() {
    const conflictObject = this.transfersManager.conflictObject;
    if (conflictObject) {
      conflictObject.abortUpload();
    }
  }

  @action
  async enforceTrustee() {
    await tBoxClient.trustee.resolveStatus();
    this.modalManager.close(ENFORCED_TRUSTEES_MODAL_NAME);
    this.account.onCloseModals(ENFORCED_TRUSTEES_MODAL_NAME);
  }

  @action
  async confirmDeviceAssociation() {
    await tBoxClient.device.add();
    this.modalManager.close(DEVICE_KEY_ASSOCIATION_MODAL_NAME);
    this.account.onCloseModals(DEVICE_KEY_ASSOCIATION_MODAL_NAME);
  }

  @action
  cancelDeviceAssociation() {
    this.modalManager.close(DEVICE_KEY_ASSOCIATION_MODAL_NAME);
    this.account.onCloseModals(DEVICE_KEY_ASSOCIATION_MODAL_NAME);
  }

  @action
  removePlatformCustomizationModal() {
    this.account.onCloseModals('platform-customization-modal');
  }

  // Modal Cancel
  @action
  closeTrusteeWarningModal() {
    this.account.openNextModal();
  }

  // Modal Confirm
  @action
  async goToAddTrusteeForm() {
    this.router.transitionTo('my-account');

    this.account.toggleLockModalsFlow(true);
    this.account.closeModal('trustee-warning');
    await new Promise((resolve) => setTimeout(resolve, 100));
    this.modalManager.open('addTrusteesModal');
    this.account.toggleLockModalsFlow(false);
  }

  // Modal onClose
  @action
  async onCloseTrusteeWarning() {
    this.account.trusteeWarningModalIsOpen = false;

    // Needed because of the close action (click on the cross icon OR outside of the modal
    this.account.closeModal('trustee-warning');
    if (this.account.lockModalsFlow) {
      this.modalManager.open('addTrusteesModal');
    } else {
      this.account.openNextModal();
    }
  }

  @action
  navigateToMyAccount() {
    this.router.transitionTo(this.cryptoboxStatus.link);
    this.modalManager.open('addTrusteesModal');
  }
}
