import { tBoxClient } from 'client/initializers/init-toolbox';
import BaseAdapter from 'client/adapters/application';

export default class SpaceMember extends BaseAdapter {

  createRecord(store, type, snapshot) {
    const spaceId = snapshot.belongsTo('space').id;
    return tBoxClient.spaceMember.add(spaceId, snapshot.attr('email'), snapshot.attr('role')).then(payload => {
      const existingRecord = store.peekRecord({ type: 'space-member', id: payload.id });

      if (existingRecord) {
        existingRecord.unloadRecord();
      }

      return this.normalizeRecord(store, type, payload);
    });
  }

  updateRecord(store, _type, snapshot) {
    const spaceId = snapshot.belongsTo('space').id;
    return tBoxClient.spaceMember.setRole(spaceId, snapshot.id, snapshot.attr('role')).catch(() => {
      snapshot.record.rollbackAttributes();
      store.query('spaceMember', { spaceId });
    });
  }

  deleteRecord(store, _type, snapshot){
    return tBoxClient.spaceMember.remove(snapshot.belongsTo('space').id, snapshot.id);
  }
}
