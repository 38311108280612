import { computed, action } from '@ember/object';
import { sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
/* global libcryptobox */
export default class SharesController extends Controller {
  @tracked selectedShare = null;

  @service account;
  @service intl;
  @service router;

  @sort('model.shares', (a, b) => {
    if (a.creationDate && b.creationDate) {
      return Date.parse(b.creationDate) - Date.parse(a.creationDate);
    } else if (a.creationDate) {
      return -1;
    } else if (b.creationDate) {
      return 1;
    } else {
      return a.fileName.localeCompare(b.fileName);
    }
  })
  sortedShares;

  @computed('account.status.canWrite', 'model.{id,isReader}')
  get userHasRights() {
    let userCanRight = this.account.status.canWrite;

    if (!userCanRight) {
      return this.router.transitionTo('my-groups.group', this.model.id);
    }
    return true;
  }

  @action
  selectShare(share) {
    if (this.selectedShare === share) {
      this.selectedShare = null;
      this.detailsIsVisible = false;
    } else {
      this.selectedShare = share;
    }
  }

  @action
  suspendShare(share, event) {
    event.stopPropagation();
    this.selectedShare = share;
    share.suspended = true;
    share.save();
  }

  @action
  resumeShare(share, event) {
    event.stopPropagation();
    this.selectedShare = share;
    share.suspended = false;
    share.save();
  }

  /* Do not forget to call applyChanges to apply changes */
  @action
  onChangeExpiration(share, newExpirationDate) {
    share.notAfter = newExpirationDate || '';
  }

  /* Do not forget to call applyChanges to apply changes */
  @action
  onChangeUniqueDownload(share, newUniqueDownload) {
    share.uniqueDownload = newUniqueDownload
      ? libcryptobox.UniqueDownload.Downloadable
      : libcryptobox.UniqueDownload.Disabled;
  }

  /* Do not forget to call applyChanges to apply changes */
  @action
  onChangeAuthorSubscription(share, authorSubscription) {
    share.authorSubscription = authorSubscription;
  }

  @action
  applyChanges(share) {
    share.save();
  }

  @action
  async refresh() {
    this.isLoading = true;
    this.refreshRoute();
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  }
}
