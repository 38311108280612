import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";


export default class UploadItemComponent extends Component {

  @tracked showDelete = false;
  @tracked showTooltip = false;

  tooltipTimeoutId;

  sizes = ['B', 'KB', 'MB', 'GB', 'TB'];


  @action
  onMouseEnter() {
    this.showDelete = true;
  }

  @action
  onMouseOut() {
    this.showDelete = false;
  }

  @action
  onMouseEnterFileName() {
    this.tooltipTimeoutId = setTimeout(() => this.showTooltip = true, 500)
    this.showDelete = true;
  }

  @action
  onMouseOutFileName() {
    clearTimeout(this.tooltipTimeoutId);
    this.showTooltip = false;
  }

  @action
  bytesToSize(bytes) {
    if (bytes === 0) return '0 B';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1000)));
    return Math.round(bytes / Math.pow(1000, i), 2) + ' ' + this.sizes[i];
  }

  @action
  roundByteSize(bytesProgress, bytesTotal) {
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];

    const i = parseInt(Math.floor(Math.log(bytesTotal) / Math.log(1000)));
    const progress = bytesProgress / Math.pow(1000, i);
    const total = bytesTotal / Math.pow(1000, i);

    const sizeUnit = sizes[i];
    var unit;
    if (sizeUnit === "B") {
      unit = "byte";
    } else if (sizeUnit === "KB") {
      unit = "kilobyte";
    } else if (sizeUnit === "MB") {
      unit = "megabyte";
    } else if (sizeUnit === "GB") {
      unit = "gigabyte";
    } else {
      unit = "terabyte";
    }

    const splittedTotal = total.toString().split(".");
    var decimalNumberTotal;

    if (splittedTotal.length > 1 && splittedTotal[0].length >= 3) {
      decimalNumberTotal = 0;
    } else if (splittedTotal.length > 1 && splittedTotal[0].length === 2) {
      decimalNumberTotal = 1;
    } else {
      decimalNumberTotal = 2;
    }

    const splittedProgress = progress.toString().split(".");
    let decimalNumberProgress;

    if (splittedProgress.length > 1 && splittedProgress[0].length >= 3) {
      decimalNumberProgress = 0;
    } else if (splittedProgress.length > 1 && splittedProgress[0].length === 2) {
      decimalNumberProgress = 1;
    } else {
      decimalNumberProgress = 2;
    }

    const formatterTotal = new Intl.NumberFormat(navigator.language, {
      style: "unit",
      unit: unit,
      minimumFractionDigits: 0,
      maximumFractionDigits: decimalNumberTotal,
    });
    const formatterProgress = new Intl.NumberFormat(navigator.language, {
      minimumFractionDigits: 0,
      maximumFractionDigits: decimalNumberProgress,
    });
    const formattedProgress = formatterProgress.format(progress);
    const formattedTotal = formatterTotal.format(total);

    return formattedProgress + "/" + formattedTotal;
  }

}

