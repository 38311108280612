import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { tBoxClient } from '../initializers/init-toolbox';

/* global libcryptobox */
export default class DepositBoxForm extends Route {
  @service account;
  @service modalManager;
  @service session;
  @service browser;
  @service connection;
  @service secondFactorAuthenticator;
  @service router;
  @service store;
  @service intl;
  @service notify;

  queryParams = {
    code: {
      refreshModel: true,
    },
  };

  async model(params) {
    document.title = this.intl.t('depositBoxForm.title');
    let hasDepositBoxFetchError = false;
    const depositBoxLink = window.location.href;
    const code = params.code;

    if (!code) {
      try {
        await tBoxClient.depositBoxForm.test(depositBoxLink);
      } catch (error) {
        if (error.code === libcryptobox.ErrorCode.NotFound) {
          hasDepositBoxFetchError = true;
        } else if (
          error.code === libcryptobox.ErrorCode.MaxCodeAttemptsReached
        ) {
          hasDepositBoxFetchError = true;
        } else {
          this.notify.error(error.message);
        }
      }
    }

    let result = null;

    if (code) {
      try {
        result = await tBoxClient.depositBoxForm.get(depositBoxLink, {
          code: code,
        });
      } catch (error) {
        if (error.code === libcryptobox.ErrorCode.NotFound) {
          hasDepositBoxFetchError = true;
        } else if (
          error.code === libcryptobox.ErrorCode.MaxCodeAttemptsReached
        ) {
          hasDepositBoxFetchError = true;
        } else {
          this.notify.error(error.message);
        }
      }
    }

    return {
      depositBoxId: params.deposit_box_code,
      depositBox: result,
      hasDepositBoxFetchError: hasDepositBoxFetchError,
    };
  }

  async setupController(controller, model) {
    controller.set('depositBox', model.depositBox);
    controller.set('hasDepositBoxFetchError', model.hasDepositBoxFetchError);
  }
}
