import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

/* global libcryptobox */

export default class ObserverFactory extends Service {
  @tracked fatalError = null;
  @tracked fatalErrorLocation = null;
  @service notify;
  @service account;
  @service session;
  @service intl;
  @service router;
  @service messaging;

  hideErrorNotification = false;


  constructor() {
    super(...arguments);
    this.initialiseFactory();
  }

  initialiseFactory() {
    this.errorWithReportPage = [
      // libcryptobox.ErrorCode.FatalError, // Removed.
    ];
    this.errorsWithoutToast = [
      libcryptobox.ErrorCode.Canceled,
      libcryptobox.ErrorCode.NotExist,
      libcryptobox.ErrorCode.RecoveryBadTrustee,
      libcryptobox.ErrorCode.NotFound,
      libcryptobox.ErrorCode.InvalidCredentials,
      libcryptobox.ErrorCode.NetworkError,
      libcryptobox.ErrorCode.RecoveryBadID,
      libcryptobox.ErrorCode.RecoveryBadSAS,
      libcryptobox.ErrorCode.RecoverySessionBroken,
      libcryptobox.ErrorCode.UnvalidatedEmail,
      libcryptobox.ErrorCode.BadKey,
      libcryptobox.ErrorCode.BadKeySize,
      libcryptobox.ErrorCode.Forbidden,
      libcryptobox.ErrorCode.InvalidParameter,
      libcryptobox.ErrorCode.InvalidOTP,
      libcryptobox.ErrorCode.TooManyOTPAttempts,
      libcryptobox.ErrorCode.BadDepositBoxCode,
      libcryptobox.ErrorCode.BadDepositBoxCodeSize,
    ];
  }

  _invitationError(detail) {
    return (
      /invite_not_found*/g.test(detail) || /invite_not_open*/g.test(detail)
    );
  }

  _hasRedirection(error) {
    return /temporary_redirection*/g.test(error);
  }

  getErrorManagement() {

    const notify = this.notify;
    const service = this;
    const account = this.account;
    const session = this.session;
    const errorsWithoutToast = this.errorsWithoutToast;
    const errorWithReportPage = this.errorWithReportPage;

    return (error) => {

      if (this.hideErrorNotification) {
        return;
      }

      if (error.code === libcryptobox.ErrorCode.UpgradeRequired) {
        this.notify.error(error.message, {
          id: 'upgrade-required-notif',
          title: error.title,
          closeAfter: 10000,
        });
        return;
      }
      if (error.title === 'Connection error')
        this.messaging.hideOfflineBanner = false;
      if (error.code === libcryptobox.ErrorCode.NotExist) {
        return;
      }
      if (errorWithReportPage.indexOf(error.code) !== -1) {
        if (!service.get('fatalError')) {
          service.set('fatalError', error);
        }
      } else if (
        error.code === libcryptobox.ErrorCode.NotFound &&
        error.server &&
        error.server.code === 'email_change_not_found'
      ) {
        return notify.error(this.intl.t('errors.changeEmailNotFound.title'));
      } else if (
        error.code === libcryptobox.ErrorCode.NotFound &&
        error.server &&
        error.server.code === 'file_sharing_not_found'
      ) {
        notify.error(error.message, {
          title: error.title,
          closeAfter: null,
          error: error,
        });
      } else if (
        error.code === libcryptobox.ErrorCode.ServerError &&
        error.details.startsWith('deposit_box_unknown:')
      ) {
        // skip error deposit box unknow
        return;
      } else if (
        error.code === libcryptobox.ErrorCode.InternalError &&
        error.details.startsWith('cipher:')
      ) {
        //No toast on key download share
        return;
      } else if (
        error.code === libcryptobox.ErrorCode.InternalError &&
        error.details.startsWith('NotReadableError:')
      ) {
        notify.error(this.intl.t('toasts.downloadError.message'), {
          title: this.intl.t('toasts.downloadError.title'),
          closeAfter: null,
          error: error,
        });
      } else if (
        error.code === libcryptobox.ErrorCode.Forbidden &&
        error.details.startsWith('forbidden\nspace: get')
      ) {
        // skip error
        return;
      } else if (error.code === libcryptobox.ErrorCode.Forbidden) {
        notify.error(this.intl.t('toasts.permissionError.message'), {
          title: this.intl.t('toasts.permissionError.title'),
          error: error,
        });
      } else if (
        error.code === libcryptobox.ErrorCode.InvalidCredentials &&
        !account.get('isLoggingIn') &&
        !account.get('inRecovery')
      ) {
        if (
          !session.get('changingPwd') &&
          this.router.currentRouteName !== 'my-account'
        ) {
          sessionStorage.clear();
          window.location.reload();
        }
      } else if (
        error.code === libcryptobox.ErrorCode.MessagingConnectionRefused
      ) {
        return;
      } else if (error.code === libcryptobox.ErrorCode.NetworkError) {
        if (service.get('networkError.visible')) {
          return;
        }
        let networkError = notify.error(this.intl.t('errors.network.message'), {
          title: this.intl.t('errors.network.title'),
          closeAfter: null,
          icon: 'disconnected',
        });
        service.set('networkError', networkError);
      } else if (
        error.code === libcryptobox.ErrorCode.AlreadyRegistered ||
        error.code === libcryptobox.ErrorCode.EmailNotAllowed
      ) {
        return notify.error(error.message, {
          title: error.title,
          error: error,
        });
      } else if (this._hasRedirection(error.details)) {
        const location = error.details.match(/\/#[^\s]+/g);
        this.router.transitionTo(location[0]);
        return window.location.reload();
      } else if (errorsWithoutToast.indexOf(error.code) === -1) {
        const messages = notify.get('target.messages');

        if (this._invitationError(error.details)) {
          return;
        }

        if (
          !error.details?.startsWith('rename:') &&
          messages
            .map((message) => message.get('error.code'))
            .indexOf(error.code) === -1
        ) {
          notify.error(error.message, {
            title: error.title,
            closeAfter: null,
            error: error,
          });
        }
      }
    };
  }
}
