import Service from "@ember/service";
import getBrowserInfo from "../utils/getBrowserInfo";

export default class BrowserService extends Service {
  get info() {
    return getBrowserInfo();
  }

  get isSupportedBrowser() {
    return this.info.name !== 'IE';
  }
}
