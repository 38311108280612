import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';

import getDepositBoxStateColor from '../../utils/getDepositBoxStateColor';

export default class DepositBoxController extends Controller {
  @service intl;
  @service router;
  @service modalManager;
  @service store;
  @service('deposit-box') depositBoxService;
  @service notify;

  @tracked stateChoices = [
    {
      value: 'populatedProcessed',
      label: this.intl.t('depositBox.badge.populatedProcessed'),
    },
    {
      value: 'populatedRead',
      label: this.intl.t('depositBox.badge.populatedUnreadOrPopulatedRead'),
    },
  ];

  @tracked isUpdating = false;
  @tracked isTemplating = false;

  get depositBox() {
    return this.model;
  }

  get state() {
    if (this.depositBox && this.depositBox.state === 'populatedUnread') {
      // fix bug revalidation when state is populatedUnread
      setTimeout(() => this.handleUpdateStatus({ value: 'populatedRead' }), 0);
    }
    return this.depositBox.state;
  }

  get selectedState() {
    return this.stateChoices.find((choice) => choice.value === this.state);
  }
  get isBlocked() {
    return this.depositBox.maxCodeAttemptsReached;
  }

  get stateBackgroundColor() {
    if (this.state === undefined || this.state === 'populatedUnread')
      return null;
    const color = getDepositBoxStateColor().find(
      (color) => color.label === this.state,
    );

    if (!color || !color.value) return null;
    return htmlSafe(
      `background: ${color.value.background}; color: ${color.value.text}`,
    );
  }

  get isPopulated() {
    return (
      this.state === 'populatedRead' || this.state === 'populatedProcessed'
    );
  }

  @action
  handleUpdateStatus(state) {
    this.depositBoxService.changeStateDepositBox(
      this.depositBox.id,
      state.value,
    );
    this.depositBox.state = state.value;
  }

  @action
  goBack() {
    this.router.transitionTo('deposit-boxes.index');
  }

  @action
  handleUpdateBox() {
    this.isUpdating = true;
    this.modalManager.open('depositBox-editor-modal', this.depositBox);
  }

  @action
  handleCreateTemplateBox() {
    this.isTemplating = true;
    this.modalManager.open('depositBox-editor-modal', this.depositBox);
  }

  @action
  openModalSend() {
    this.modalManager.open('depositBox-show-link', {
      name: this.depositBox.name,
      code: this.depositBox.link?.code || '',
      link: this.depositBox.link?.url || '',
      id: this.depositBox.id,
      inBox: true,
    });
  }

  @action
  handleDeleteBoxModal() {
    this.modalManager.open('depositBox-delete-modal');
  }

  @action
  async handleDeleteBox() {
    try {
      await this.depositBox.destroyRecord();
      this.modalManager.close('depositBox-delete-modal');
      this.router.transitionTo('deposit-boxes.index');
      this.notify.success(this.intl.t('documents.actions.delete.succeed'));
    } catch (error) {
      this.notify.error(this.intl.t('documents.actions.delete.failed'));
    }
  }

  @action
  handleCloseModal() {
    this.isUpdating = false;
    this.isTemplating = false;
  }

  @action
  willDestroy() {
    this.state = undefined;
    this.depositBox = undefined;
    this.isUpdating = false;
    this.isTemplating = false;
  }
}
