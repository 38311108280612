import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class FileItemSideNav extends Component {
  @service intl;
  @service('file-history') fileHistoryService;
  @service commonService;

  @tracked numberDownloadBySharing = 0;
  @tracked numberDownloadByUsers = 0;
  @tracked isOpenSidePanelRight = false;
  @tracked openSidePanelRightName = '';

  get selectModifier() {
    if (this.fileItem.action === 'modify') {
      return this.intl.t('fileHistory.actionText.edit.action');
    }
    return this.intl.t(`fileHistory.actionText.${ this.fileItem.action }.action`);
  }

  get version() {
    return this.fileItem.fileVersionNumber;
  }

  get revision() {
    return this.fileItem.fileRevisionNumber;
  }

  get isFromSharing() {
    return this.openSidePanelRightName === 'fromSharing';
  }

  @action
  onOpen() {
    this.numberDownloadBySharing = 0;
    this.numberDownloadByUsers = 0;

    // fileVersionNumber at 0 means that there is no actual version
    // for the selected change
    if (this.fileItem.fileVersionNumber != 0) {
      this.fileHistoryService
        .getNumberDownload({
          fileVersion: this.fileItem.fileVersionNumber,
        })
        .then(({ byUsers, bySharingLink }) => {
          this.numberDownloadBySharing = bySharingLink;
          this.numberDownloadByUsers = byUsers;
        });
    };
  }

  get fileItem() {
    return this.fileHistoryService.selectedFileHistoryInSideNav;
  }

  get iconName() {
    return this.fileHistoryService.iconName(this.fileItem.action);
  }

  @action
  handleClose() {
    this.commonService.isOpenSideNav = false;
    this.isOpenSidePanelRight = false;
    this.fileHistoryService.selectedFileHistoryInSideNav = null;
    this.openSidePanelRightName = '';
    this.fileHistoryService.fileHistoryDownloadList = [];
    this.numberDownloadBySharing = 0;
    this.numberDownloadByUsers = 0;
  }

  @action
  async handleDownload(event) {
    event.preventDefault();
    await this.fileHistoryService.downloadFileHistoryVersion(this.fileItem);
  }

  @action
  async openSidePanelRight(name, event) {
    event.preventDefault();
    this.openSidePanelRightName = name;
    this.isOpenSidePanelRight = true;
    await this.fileHistoryService.getDownloadListFileHistory({
      version: this.version,
      type: name,
    });
  }
}
