import Component from "@glimmer/component";

/*
 * Those values are both
 * - Keys in the object result of the request `tBoxClient.file.getViewableTypes()`
 * - Keys in trads files
 * */
const CLIENTS = ["web", "android", "ios"];

export default class TableViewerAccessComponent extends Component {
  get clients() {
    return CLIENTS;
  }
}
