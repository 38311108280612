import { alias } from '@ember/object/computed';
import { attr, hasMany } from '@ember-data/model';
import BaseModel from 'client/toolbox/generated/models/space';

import { inject as service } from '@ember/service';
import { schedule } from '@ember/runloop';
import { FILE_SUBSCRIPTION_TYPE } from '../utils/constants';
import getFileSubscriptionCollectionName from '../utils/tBoxClient/getFileSubscriptionCollectionName';
import { tBoxClient } from '../initializers/init-toolbox';

/* global libcryptobox */
export default class Space extends BaseModel {
  @alias('fileCount') documentCount;
  @alias('memberCount') userCount;
  @attr folder;
  @hasMany('notification', { async: true, inverse: null }) notifications;
  @alias('members') spaceMembers;
  @alias('fileSharings') shares;
  @alias('readCursor') cursor;
  @alias('peer') user;

  selectedMessage = null;

  @service messaging;
  @service account;
  @alias('readCursor.latest') latestCursor;
  @attr removeSecurityLevelMessage;

  get computedName() {
    if (this.name) {
      return this.name;
    }
    if (this.user.fullName) {
      return this.user.fullName;
    }
    return '';
  }


  get isWorkspace() {
    return this.kind === libcryptobox.SpaceKind.Workspace;
  }


  get isDirect() {
    return this.kind === libcryptobox.SpaceKind.DirectConversation;
  }


  get visibleTags() {
    return this.tags?.slice(0, 2);
  }


  get hiddenTagsNumber() {
    let hiddenTagsNumber = this.tags?.length - 2;
    if (hiddenTagsNumber < 1) {
      return false;
    }
    return hiddenTagsNumber;
  }


  get sortedMembers() {
    const members = this.members;

    if (members.length === 0) return;
    return members.sortBy('fullName');
  }


  get isAdmin() {
    return this.userRole === libcryptobox.Role.Owner;
  }


  get isMember() {
    return this.userRole === libcryptobox.Role.Member;
  }


  get isReader() {
    return this.userRole === libcryptobox.Role.Auditor;
  }


  get isViewer() {
    return this.userRole === libcryptobox.Role.Viewer;
  }


  get unreadNotificationsCount() {
    let unreadCount = 0;
    this.notifications.forEach((notification) => {
      if (notification.get('state') === 'unread') {
        unreadCount++;
      }
    });
    return unreadCount;
  }

  /*
   *  Subscriptions : MODIFICATIONS && DOWNLOADS
   * */

  get isDeleted() {
    return this.status === 'deleted';
  }

  get hasDownloadsSubscribedChild() {
    return this.hasSubscribedChild(
      this.downloadsSubscriptions,
      this.hasSubscribedToDownloads
    );
  }

  hasSubscribedChild(subscriptions, hasSubscribed) {
    return hasSubscribed ? subscriptions.length > 1 : subscriptions.length >= 1;
  }

  // MODIFICATIONS

  get subscriptions() {
    return this.getSubscriptions('fileSubscription');
  }

  get subscribed() {
    return this.hasSubscribed(this.subscriptions);
  }

  get subscribedChild() {
    return this.hasSubscribedChild(this.subscriptions, this.subscribed);
  }

  // DOWNLOADS

  get downloadsSubscriptions() {
    return this.getSubscriptions('fileDownloadSubscription');
  }

  get hasSubscribedToDownloads() {
    return this.hasSubscribed(this.downloadsSubscriptions);
  }

  getSubscriptions(subscriptionsAttributeName) {
    const subscriptions = this[subscriptionsAttributeName];
    if (!subscriptions) return;
    return subscriptions.get('paths') || [];
  }

  hasSubscribed(subscriptions) {
    if (!subscriptions) return;
    return subscriptions.includes('/');
  }

  getSubscriptionData(type) {
    if (type === FILE_SUBSCRIPTION_TYPE.DOWNLOADS) {
      return {
        subscribed: this.hasSubscribedToDownloads,
        subscribedParent: false,
        subscribedChild: this.hasDownloadsSubscribedChild,
      };
    } else {
      return {
        subscribed: this.subscribed,
        subscribedParent: false,
        subscribedChild: this.subscribedChild,
      };
    }
  }

  toggleSubscription(toggle, type) {
    const collectionName = getFileSubscriptionCollectionName(type);
    const action = toggle ? 'add' : 'remove';

    return tBoxClient[collectionName][action](this.id, ['/']);
  }

  get deletionDates() {
    if (this.isDeleted) {
      return tBoxClient.space.getDeletionDates().then((deletionDates) => {
        if (!deletionDates) return;
        return deletionDates.find(({ spaceId }) => this.id === spaceId);
      });
    }
  }

  get isFavorite() {
    return !!this.categories.find(category => category.id === this.account.favoritesCategoryId);
  }

  unloadRecord() {
    // Skip new records, they don't even have an ID.
    if (this.isNew) {
      super.unloadRecord();
      return;
    }

    // Redirect to my-groups if currently in this space.
    if (window.location.hash.indexOf(this.id) !== -1) {
      window.location.hash = '';
    }

    // Unload related records *without* triggering roundtrip with the backend.
    // To prevent EmberData to "crash" on logout (because we also clear the store at this very moment and EmberData tries, and fails, to create a snapshot from a `null` record), we:
    // - keep only references,
    // - defer the operation (so we don't really have to unload the record in case of a logout).
    let refs = [];
    refs.push(
      ...this.hasMany('fileSharings')
        .ids()
        .map((id) => ({ type: 'file-sharing', id }))
    );
    refs.push(
      ...this.hasMany('members')
        .ids()
        .map((id) => ({ type: 'space-member', id }))
    );
    refs.push(
      ...this.hasMany('messages')
        .ids()
        .map((id) => ({ type: 'message', id }))
    );
    schedule('destroy', () => {
      for (let ref of refs) {
        let record = this.store.peekRecord({ type: ref.type, id: ref.id });
        if (record) {
          // console.debug('unloadRecord', ref.type, ref.id);
          record.unloadRecord();
        }
      }
    });

    // Actually unload the record.
    super.unloadRecord();
  }

  unloadSpace() {
    this.unloadRecord();
  }

  async save() {
    return super.save().catch((error) => {
      console.warn('error save', error);
      if (!this.isValid) {
        this.unloadRecord();
      }
    });
  }
}
