import Service, { inject as service } from '@ember/service';
import { A } from '@ember/array';
import { tracked } from '@glimmer/tracking';
import { DateTime } from 'luxon';

export default class HistoryService extends Service {
  @tracked histories = A([]);

  @service store;
  @service commonService;
  @service router;

  async loadHistory({
    space,
    workspaceId,
    workspaceRevision,
    limit = 100,
    offset = 0,
    notAfterDate = '',
  }) {
    this.histories = A([]);
    if (notAfterDate) {
      notAfterDate = DateTime.fromISO(notAfterDate).plus({ days: 1 }).toISO();
    }
    if (!space.isReader && !space.isViewer) {
      this.histories = await this.store.query(
        'file-revision',
        {
          spaceId: workspaceId,
          notAfterDate: notAfterDate,
          notAfterNumber: offset,
          count: limit,
          noCache: true,
        },
        {
          reload: true,
          adapterOptions: { subscribe: true },
        },
      );
      if (workspaceRevision !== 0) {
        this.commonService.workspaceRevisionObject = this.histories.findBy(
          'number',
          workspaceRevision,
        );
      }
    }
    return this.histories;
  }
}
