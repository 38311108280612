export const extensions = {
    program: ["exe", "bat", "com", "msi", "reg", "pkg", "apk", "ipa"], 
    compressed: ["zip", "rar", "7z", "gz", "tar"],
    video: ["avi", "mov", "mp4", "mkv", "flv", "webm"],
    audio: ["mp3", "wave", "wav", "wma", "flac", "alac", "ac3"],
    photo: ["jpeg", "jpg", "png", "gif", "svg", "tiff", "tif", "raw", "bmp", "heif", "heic", "webp"],
    document: ["pdf"],
    text: ["doc", "docx", "txt", "md", "odt", "rtf", "log"],
    presentation: ["ppt", "pptx", "key"],
    sheet: ["xls", "xlsx", "ods"],
    graphic: ["psd", "ai", "ind", "ae", "xd", "eps", "sketch", "fig"],
    programmation: ["html", "htm", "xml", "php", "asp"],
    cd_dvd: ["iso", "dmg"],
};

export function getIconNameFromExtension(extension, extensions) {
    if (extension) {
        const lowercaseExtension = extension.toLowerCase();

        const foundIconExtension = Object.entries(extensions).find(extensionEntry => {
            const [iconName, iconExtensions] = extensionEntry;
            if (iconExtensions.includes(lowercaseExtension)) return iconName;
        });
        
        if (foundIconExtension) return foundIconExtension[0];
    }
}