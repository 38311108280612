import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class DeleteNodeModal extends Component {
  @service notify;
  @service store;
  @service intl;
  @service commonService;
  @service modalManager;

  get modalWording() {
    const selectedNodesLength = this.args.selectedNodes.length;
    if (this.args.currentSelectedNode) {
      return this.intl.t('documents.actions.delete.confirm.file', {
        name: this.args.currentSelectedNode?.name || '',
      });
    }

    if (!selectedNodesLength) {
      return '';
    }

    if (selectedNodesLength > 1) {
      return this.intl.t('documents.actions.delete.confirm.multiple', {
        count: selectedNodesLength,
      });
    }

    const firstSelectedNode = Array.isArray(this.args.selectedNodes.content) && this.args.selectedNodes.content?.at?.(0);

    if (!firstSelectedNode) {
      return '';
    }

    const nodeName = firstSelectedNode.get('name') || ' (no name) ';
    const translationKey = firstSelectedNode.get('isFolder')
      ? 'documents.actions.delete.confirm.folder'
      : 'documents.actions.delete.confirm.file';

    return this.intl.t(translationKey, { name: nodeName });
  }

  get suppressionSuccessTrad() {
    return this.intl.t('documents.actions.delete.succeed');
  }

  get suppressionFailedTrad() {
    return this.intl.t('documents.actions.delete.failed');
  }
  /**
   * remove selected nodes in document
   */
  @action
  async deleteNodes() {
    try {
      if (this.args.currentSelectedNode) {
        await this.deleteNode(this.args.currentSelectedNode);
      } else {
        await Promise.all(
          this.args.selectedNodes.map((node) => this.deleteNode(node))
        );
      }
      this.notify.success(this.suppressionSuccessTrad, '');
    } catch (e) {
      this.notify.error(this.suppressionFailedTrad, e.message);
    } finally {
      this.args.selectedNodes.clear();
      this.commonService.selectedSpace = null;
      this.commonService.destinationNode = null;
      this.modalManager.close(this.args.modalName);
    }
  }

  async deleteNode(node) {
    await node.destroyRecord();
  }
}
