import { extensions, getIconNameFromExtension } from '../utils/icon-constants';

function getNodeIconName(isDirectory, extension) {
  if (isDirectory) {
    return "filetype/folder";
  } else if (extension) {
    const icon = getIconNameFromExtension(extension, extensions);
    if (icon) return `filetype/${icon}`;
  }

  return "filetype/unknown";
}

export default getNodeIconName;
