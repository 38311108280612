import Controller from '@ember/controller';
import { uniqBy } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import diacritic from 'diacritic';
import { computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';
/* global libcryptobox */

export default class IndexController extends Controller {
  @tracked isNew;
  @tracked currentConversations = false;
  @tracked spacesKind = 'all';
  @tracked spaceName;

  @service messaging;
  @service store;
  @service account;

  @computed(
    'model.length',
    'model.@each.{latestCursor,isDeleted}',
    '{spacesKind,spaceName}',
  )
  get filteredModel() {
    let spaces = this.model.filter( model => model.kind !== 'personal_space');
    spaces = spaces.filter(space => !space.isDeleted).sortBy('computedName');
    let name = this.spaceName || '';
    let filteredModel = [];
    let kind = this.spacesKind;

    spaces = spaces.filter((space) => {
      return (
        ((space.get('latestCursor') !== 0 &&
          space.get('latestCursor') !== undefined) ||
          space.get('kind') === libcryptobox.SpaceKind.GroupConversation) &&
        space.get('kind') !== libcryptobox.SpaceKind.PersonalSpace
      );
    });

    if (name === '' && kind === 'all') {
      return spaces;
    }
    spaces.forEach((space) => {
      if (kind !== 'all' && space.get('kind') !== kind) {
        return;
      }
      const cleanName = diacritic.clean(name.toLowerCase());

      if (
        diacritic
          .clean(space.get('computedName'))
          .toLowerCase()
          .indexOf(cleanName) !== -1
      ) {
        filteredModel.push(space);
        return;
      }
    });
    return filteredModel;
  }

  @uniqBy('filteredModel', 'id') uniqModel;

  get showNoMessage() {
    return this.filteredModel.length === 0;
  }
}
