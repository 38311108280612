import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
export default class ChangeEmailController extends Controller {

	@tracked isEmailChangeValidated = false;
	@tracked token;
	@tracked currentEmail;
	@tracked newEmail;

	@service account;

	get initController() {
		this.currentEmail = this.model.currentEmail;
		this.newEmail = this.model.newEmail;
		this.token = this.model.id;
		return this.model;
	}

	@action
	async validateChangeEmail() {
		await this.account.confirmChangeEmail(this.token, this.newEmail).then( response => {
      if (response ) {
        this.isEmailChangeValidated = true;
      }
		});
	}

	@action
	redirectToLogin() {
		return this.account.logout('user');
	}
}
