import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { computed } from '@ember/object';

/* global libcryptobox */
export default class DetailsPanel extends Component {
  @service store;
  @service('transfers') transfersManager;

  get nodeChildren() {
    if (!this.args.node.isFolder) {
      return null;
    }

    return this.store
      .query('file', {
        spaceId: this.args.space.id,
        revision: this.args.model.fileRevision,
        path: this.args.node.path,
      })
      .then((files) => files.length - this.args.node.subDirectoryCount);
  }

  get hasNoNodes() {
    return this.args.selectedNodes.length === 0;
  }

  get nodeHasFolder() {
    const folders = this.args.selectedNodes.filter( node => node.isFolder);
    return folders.length > 0;
  }

  @computed(
    'args.hasSeveralNodes',
    'args.node.{isFolder,version}',
    'args.selectedNodes.[]',
    'hasNoNodes',
  )
  get showFileHistoryVersion() {
    return (
      this.args.selectedNodes.filter(node => node.isFolder).length === 0 &&
      this.hasNoNodes === false &&
      this.args.node.isFolder === false &&
      this.args.node.version >= 1 &&
      this.args.hasSeveralNodes === false);

  }

  @computed(
    'args.node.{isFolder,version}',
    'args.selectedNodes.[]',
  )
  get firstNode() {
    return this.args.selectedNodes.content?.at?.(0);
  }
}
