import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { task, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import pause from '../utils/pause';
import { LICENCES_VALIDATION_MODAL_NAME, REGISTER_ERROR_MESSAGE_BINDING, REGISTER_ERRORS } from '../utils/enums';
/* global libcryptobox */

export default class LoginController extends Controller {

  queryParams = ['requestedHash', 'register', 'email'];

  @tracked initial = null;
  @tracked creatingAccount = false;
  @tracked resendingEmail = false;
  @tracked mailValidation = false;
  @tracked unvalidatedMail = false;
  @tracked passwordRecovery = false;
  @tracked recoveryConfirm = false;
  @tracked serverUrl = '';
  @tracked signInForm;
  @tracked formLogin;
  @tracked singUpPassword;
  @tracked loginPassword;
  @tracked customMessage;
  @tracked notValidEmail;
  @tracked userEmail;
  @tracked firstName;
  @tracked lastName;
  @tracked register = false;
  @tracked browserWarningHidden = false;
  @tracked logInProgress = false;
  @tracked isLoginOtpInProgress = false;
  @tracked is2faDone = 0;
  @tracked disablePasswordReset = false;
  @tracked EmailNotAvailable = false;
  @tracked otpCode;
  @tracked otpError;

  @tracked disabledEmail = false;
  @tracked disabledFirstName = false;
  @tracked disabledLastName = false;

  @service browser;
  @service account;
  @service notify;
  @service modalManager;
  @service intl;
  @service connection;
  @service secondFactorAuthenticator;

  @computed('register', 'mailValidation')
  get showMailValidation() {
    return !this.register && this.mailValidation;
  }

  @computed('register', 'account.validationError', 'passwordRecovery')
  get largeDisplay() {
    return this.register || this.passwordRecovery;
  }

  @computed('intl.locale')
  get locale() {
    return this.intl.locale[0];
  }

  @computed('serverUrl')
  get disableInputs() {
    return !this.serverUrl;
  }

  get isLoginInProgress() {
    return this.logInProgress && !this.secondFactorAuthenticator.emailOtp || this.isLoginOtpInProgress;
  }

  get hasLocalAccount() {
    return this.model && this.model.length > 0;
  }

  @computed('model.[]')
  get localAccountList() {
    return this?.model?.toArray() || [];
  }

  @computed(
    "passwordRecovery",
    "register",
    "mailValidation",
    "recoveryConfirm",
    "resendingEmail",
    "account.validationError"
  )
  get showLogin() {
    const validationError = this.account.validationError;

    return (
      !this.creatingAccount &&
      !this.recoveryConfirm &&
      !this.mailValidation &&
      !this.passwordRecovery &&
      !validationError &&
      !this.register &&
      !this.resendingEmail
    );
  }

  constructor() {
    super(...arguments);
    const browser = this.browser.info;
    const badBrowser =
      (browser.name === 'Firefox' && browser.version >= 43) ||
      (browser.name === 'Chrome' && browser.version >= 43) ||
      (browser.name === 'Opera' && browser.version >= 32);
    this.isBadBrowser = !badBrowser;
  }

  close2fa() {
    // incrementProperty() ensures didReceiveAttrs is invoked on the component.
    this.is2faDone = this.is2faDone + 1;
  }

  _resetSignupForm() {
    this.firstName = '';
    this.lastName = '';
    this.singUpPassword = '';
    this.confirmPassword = '';
    this.loginPassword = '';
  }

  _hasRedirection(error) {
    return /temporary_redirection*/g.test(error);
  }

  _handleListener(component) {
    return () => {
      clearInterval(component.initial);
      component.initial = window.setInterval(
        component._resetPasswordField(component),
        60000,
      );
    };
  }

  _resetPasswordField(component) {
    return () => {
      component.loginPassword = '';
    };
  }

  @(task(function* () {
    yield timeout(2000);

    let controller = this;
    controller.creatingAccount = true;

    if (!this.EmailNotAvailable) {
      controller.register = false;

      let singUpUserEmail = this.userEmail;
      let singUpPassword = this.singUpPassword;
      const server = this.connection.serverAddress;
      let promise = tBoxClient.account.register(
        server,
        singUpUserEmail,
        singUpPassword,
        controller.firstName,
        controller.lastName
      );
      this._cancelRegister = promise.cancel;
      yield promise
        .then(() => {
          controller.creatingAccount = false;
          controller.mailValidation = true;
          controller._resetSignupForm();

          setTimeout(() => {
            controller.register = false;
            controller.mailValidation = false;
          }, 10000);

          this._cancelRegister = null;
          controller.close2fa();
        })
        .catch((error) => {
          if (REGISTER_ERRORS.includes(error.code)) {
            controller.notValidEmail = true;
            controller.customMessage = this.intl.t(
              REGISTER_ERROR_MESSAGE_BINDING[error.code]
            );
          } else if (this._hasRedirection(error.details)) {
            return;
          } else if (error.code === libcryptobox.ErrorCode.PasswordTooWeak) {
            setTimeout(() => {
              controller.creatingAccount = false;
              controller.register = true;
            }, 200);
          } else if (error.code !== libcryptobox.ErrorCode.Canceled) {
            const notify = controller.notify;
            notify.error(error.message, {
              title: error.title,
              closeAfter: null,
              error: error,
            });
          }
          setTimeout(() => {
            controller.creatingAccount = false;
            controller.register = true;
          }, 200);

          this._cancelRegister = null;
          controller.close2fa();

          throw error;
        });
    }
  }).drop())
  _confirmSignup;

  @action
  cancelOtp() {
    this.secondFactorAuthenticator.cancelOtp();
    this.otpCode = null;
    this.otpError = null;
    this.isLoginOtpInProgress = false;
    this.logInProgress = false;
  }

  @action
  async validateOtp() {
    this.otpError = null;
    this.isLoginOtpInProgress = true;
    try {
      await this.secondFactorAuthenticator.setOtp(this.otpCode);
      this.otpCode = null;
    } catch (error) {
      this.otpError = error.message;
    } finally {
      this.isLoginOtpInProgress = false;
    }
  }

  @action
  async resendOtp() {
    try {
      await this.secondFactorAuthenticator.resendOtp();
      this.notify.success(this.intl.t('otp.newCodeSent'));
    } catch (error) {
      this.otpError = error.message;
    }
  }

  @action
  resetError(event) {
    if (event && event.key) {
      this.account.credentialError = false;
    }
  }

  @action
  showRegister() {
    this.register = true;
  }

  @action
  hideRegister() {
    this.register = false;
  }

  @action
  hideBrowserWarning() {
    this.browserWarningHidden = true;
  }

  @action
  /**
   * login only for good browsers
   */
  loginUser() {
    this.logInProgress = true;
    return this.account
      .login(this.userEmail, this.loginPassword)
      .then((logged) => {
        if (logged) {
          this.userEmail = '';
          this.loginPassword = '';

          if (this.signInForm) {
            this.signInForm.reset();
          }
          this.close2fa();
          this.logInProgress = false;
        } else {
          this.logInProgress = false;
          setTimeout(() => {
            this.loginPassword = '';
          }, 60000);
        }
      })
      .catch((e) => console.warn(e));
  }

  @action
  setPropertyValue(property, value) {
    this[property] = value;
  }

  @action
  cancel() {
    this.account.cancelLogin();
    if (this._cancelRegister) {
      this._cancelRegister();
      this._cancelRegister = null;
    }
    this.close2fa();
  }

  @action
  signUp() {
    this.modalManager.open(LICENCES_VALIDATION_MODAL_NAME);
    this.disablePasswordReset = true;
  }

  @action
  confirmSignUp() {
    this.disablePasswordReset = false;
    this._confirmSignup.perform();
  }

  @action
  cancelSignUp() {
    this.disablePasswordReset = false;
  }

  @action
  recoverPassword() {
    if (this.isClaiming) {
      return false;
    }
    this.isClaiming = true;
    let userEmail = this.userEmail;
    let account = this.account;
    account.inRecovery = true;
    const server = this.connection.serverAddress;

    return tBoxClient.recovery
      .claim(server, userEmail)
      .then(async () => {
        this.loginPassword = '';
        this.userEmail = '';
        this.passwordRecovery = false;
        this.recoveryConfirm = true;

        await pause(10000);

        this.recoveryConfirm = false;
        this.isClaiming = false;
      })
      .catch((error) => {
        // TODO The error state of the form should not be handle here
        let fieldsetTarget = 'recovery-mail';
        let message = error.message;

        if (error.code === libcryptobox.ErrorCode.Unavailable) {
          fieldsetTarget = 'server';
          message = error.message;
        } else if (error.code === libcryptobox.ErrorCode.InvalidCredentials) {
          fieldsetTarget = 'recovery-mail';
          message = this.intl.t('errors.notRegister');
        }

        const fieldsetSelector = `.${fieldsetTarget}.form-group`;
        document.querySelector(fieldsetSelector).classList.add('error');
        document.querySelector(`${fieldsetSelector} .error-message`).innerText =
          message;

        account.inRecovery = false;
        this.isClaiming = false;

        // Used to be able to still know if an error happens
        // Exemple: Electron password recovery is waiting for that promise
        throw new Error(error);
      });
  }

  @action
  resendEmail() {
    this.account.validationError = false;
    this.resendingEmail = true;
    const server = this.connection.serverAddress;
    tBoxClient.account
      .resendValidationEmail(server, this.userEmail)
      .then(() => {
        setTimeout(() => {
          this.resendingEmail = false;
          this.mailValidation = true;
        }, 1000);
        setTimeout(() => {
          this.mailValidation = false;
          this.formLogin = true;
        }, 10000);
      })
      .catch(() => {
        this.resendingEmail = false;
        this.set("account.validationError", true);
      });
    this.formLogin = false;
  }

  @action
  hideValidationErrorMessage() {
    this.account.validationError = false;
    this.formLogin = true;
  }

  @action
  displayPassRecovery() {
    if (this.passwordRecovery) {
      return;
    }
    this.formLogin = false;
    this.passwordRecovery = true;
  }

  @action
  hidePassRecovery() {
    if (!this.passwordRecovery) {
      return;
    }
    this.passwordRecovery = false;
    this.formLogin = true;
  }

  @action
  handleServerUrl(event) {
    this.connection.tmpServerAddress = `https://${event.target.value}`;
  }

  @action
  setResetForm() {
    this.initial = window.setInterval(this._resetPasswordField(this), 60000);

    document.addEventListener('mousemove', this._handleListener(this));
    document.addEventListener('keydown', this._handleListener(this));
  }

  @action
  unregisterReset() {
    clearInterval(this.initial);
    document.removeEventListener('mousemove', this._handleListener(this));
    document.removeEventListener('keydown', this._handleListener(this));
  }

  @action
  updatePassword(value) {
    this.singUpPassword = value;
  }

  /* Desktop client connexions actions */

  @action
  onRegister({ userEmail, firstName, lastName, password }) {
    this.userEmail = userEmail;
    this.firstName = firstName;
    this.lastName = lastName;
    this.singUpPassword = password;

    return this._confirmSignup.perform();
  }

  @action
  onLogin({ userEmail, password }) {
    this.userEmail = userEmail;
    this.loginPassword = password;

    return this.loginUser();
  }

  @action
  onPasswordRecovery({ userEmail }) {
    this.userEmail = userEmail;

    return this.recoverPassword();
  }

  @action
  onSelectLocalAccount(accountId) {
    this.logInProgress = true;
    tBoxClient.device.login(accountId).finally(() => {
      this.logInProgress = false;
    });
  }
}
