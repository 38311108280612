import Component from '@glimmer/component';
import { DateTime } from 'luxon';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { htmlSafe } from '@ember/template';
export default class LandingPageContainer extends Component {
  @tracked elementId = 'landing_page';

  @service intl;
  @service connection;
  @service platformCustomization;

  @tracked platformConfig = null;
  @tracked hideAppStoreLinks = false;

  constructor() {
    super(...arguments);
    this.platformCustomization.getConfigFromUrl(
      this.connection.serverAddress,
      (config) => {
        this.platformConfig = config;
      },
    );

    void this.platformCustomization.getHideAppStoreLinks((result) => {
      this.hideAppStoreLinks = result;
    });
  }
  get large() {
    return this.args.large || false;
  }

  get hideMobileAppBar() {
    return this.args.hideMobileAppBar || false;
  }
  get isWarning() {
    return this.args.isWarning || false;
  }

  get isSuccess() {
    return this.args.isWarning || false;
  }

  get appStoreUrl() {
    const url = 'https://itunes.apple.com/fr/app/cryptobox/id972602802?&mt=8';
    this.intl.locale[0] === 'fr-fr';
    return this.intl.locale[0] === 'fr-fr' ? url : `${url}&l=en`;
  }

  get backgroundImage() {
    return htmlSafe(
      `background-image:url("/static/customizations/images/bg1.jpg?${DateTime.now().toFormat(
        'MMDDHHSS',
      )}")`,
    );
  }

  get isPlatformCustomizationEnabled() {
    return this.platformConfig && this.platformConfig.isEnabled;
  }
}
