export default function getBrowserInfo() {
  let ua = navigator.userAgent,
    tem,
    browser = {
      name: "",
      version: 0,
    };
  let M;
  M =
    ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
    [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    browser.name = "IE";
    browser.version = tem[1];
    return browser;
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) {
      browser.name = "Opera";
      browser.version = tem[2];
      return browser;
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  browser.name = M[0];
  browser.version = M[1];
  return browser;
}
