import { MODAL_TYPES } from "../components/enforced-trustee/modal/component";
/* global libcryptobox */

/*
* @param enforcedTrusteesStatus
* */
export default function getEnforcedTrusteeModalType(enforcedTrusteesStatus) {
  if (!enforcedTrusteesStatus) {
    return;
  }

  // see toolbox/doc/docs/Reference/Structs/TrusteeStatus.md
  const { enforcedTrusteeStatus, inviteeTrusteeStatus } =
    enforcedTrusteesStatus;

  const { EnforcedTrusteeStatus, InviteeTrusteeStatus } = libcryptobox;
  const userRequired = [
    EnforcedTrusteeStatus.AdditionRequired,
    EnforcedTrusteeStatus.ReplacementRequired,
  ];
  const inviterRequired = [InviteeTrusteeStatus.AdditionRequired];

  if (
    userRequired.includes(enforcedTrusteeStatus) &&
    inviterRequired.includes(inviteeTrusteeStatus)
  ) {
    return MODAL_TYPES.BOTH;
  } else if (
    userRequired.includes(enforcedTrusteeStatus) &&
    !inviterRequired.includes(inviteeTrusteeStatus)
  ) {
    return MODAL_TYPES.USER_ONLY;
  } else if (
    !userRequired.includes(enforcedTrusteeStatus) &&
    inviterRequired.includes(inviteeTrusteeStatus)
  ) {
    return MODAL_TYPES.INVITER_ONLY;
  } else {
    // IF both status are OK (or "RemovalRequired" or "CleanUpRequired" for user trustee)
    return null;
  }

}
