import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class TooltipComponent extends Component {
  @action
  openTooltip(dropdown) {
    dropdown.actions.open()
  }

  @action
  closeTooltip(dropdown) {
    dropdown.actions.close()
  }
}
