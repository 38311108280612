import {helper} from '@ember/component/helper';

/**
 * @description comparator to include in templates
 *
 * @example will add active class to <li> if selectedMenuIndex === index of the #each loop
 {{#each menuItems as |item index|}}
 <li class="item
 {{active-class index selectedMenuIndex}}"
 {{action "selectMenuItem" index}}
 >
 {{item.text}}
 </li>
 {{/each}}

 * @param params
 * @returns {string}
 */
export function activeClass(params/*, hash*/) {
  const index = params[0];
  const active = params[1];

  return (index === active) ? 'active' : '';
}

export default helper(activeClass);
