import Controller from '@ember/controller';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import diacritic from 'diacritic';

class Sorting {
  @tracked property = 'updatedAt';
  @tracked direction = 'desc';
}

export default class InvitationsController extends Controller {
  @service intl;
  @service account;
  @service store;
  @service router;

  @tracked sorting = new Sorting();
  @tracked selectedPage = 1;
  @tracked elementsPerPage = 10;
  @tracked searchTerm;
  @tracked selectedStatus;

  @tracked showCancel = true;
  @tracked preAddedMembers = [];

  @computed('account.status.canInvite')
  get hasInvitationRights() {
    if (!this.account.status) return false;

    if (!this.account.status.canInvite) {
      this.router.transitionTo('my-groups');
    }

    return true;
  }

  get status() {
    return [
      {
        key: 'created',
        label: this.intl.t('inviteUser.status.created'),
      },
      {
        key: 'resent',
        label: this.intl.t('inviteUser.status.resent'),
      },
      {
        key: 'cancelled',
        label: this.intl.t('inviteUser.status.cancelled'),
      },
      {
        key: 'accepted',
        label: this.intl.t('inviteUser.status.accepted'),
      },
      {
        key: 'expired',
        label: this.intl.t('inviteUser.status.expired'),
      },
    ];
  }

  get filteredModel() {
    let models = this.model;

    if (this.searchTerm) {
      const term = diacritic.clean(this.searchTerm.toLowerCase());
      models = models.filter((model) => {
        const email = diacritic.clean(model.get('guestEmail').toLowerCase());
        return email.includes(term);
      });
    }

    if (this.selectedStatus) {
      models = models.filter((model) => {
        const lastTypeEvent = model.get('eventHistory.lastObject.type');
        return lastTypeEvent === this.selectedStatus.key;
      });
    }

    return models;
  }

  get sortedModels() {
    const page = this.selectedPage;

    if (this.filteredModel) {
      let sorted = this.filteredModel.slice().sort((a, b) => {
        const propA = a[this.sorting.property];
        const propB = b[this.sorting.property];

        if (propA < propB) return -1;
        if (propA > propB) return 1;
        return 0;
      });

      if (this.sorting.direction === 'desc') {
        sorted = sorted.reverse();
      }

      const startIndex = (page - 1)*this.elementsPerPage;
      const endIndex = page*this.elementsPerPage;
      return sorted.slice(startIndex, endIndex);
    }

    return [];
  }

  @action
  async fetchPreAddedMembers() {
    this.preAddedMembers = await this.store
      .findAll('preadded-space-member', {
        reload: true,
        adapterOptions: { subscribe: this },
      })
      .then((members) => {
        return members;
      })
      .catch((error) => {
        console.error('Failed to load preadded-space-member:', error);
        return [];
      });
  }

  @action
  changeSorting(property) {
    const sorting = this.sorting;
    this.selectedPage = 1;

    if (sorting.property === property) {
      const oldDirection = sorting.direction;
      return (sorting.direction = oldDirection === 'asc' ? 'desc' : 'asc');
    }

    sorting.property = property;
    sorting.direction = 'desc';
  }

  @action
  selectStatus(selected) {
    this.selectedStatus = selected;
    this.selectedPage = 1;
  }

  @action
  setSelectedPage(pageNumber) {
    this.selectedPage = pageNumber;
  }

  @action
  onPageSelected(pageNumber) {
    this.selectedPage = pageNumber;
  }
}
