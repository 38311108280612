import Component from '@glimmer/component';
import { computed, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class FormContainer extends Component {
  @tracked isDisabled = false;
  @tracked registeredInputs = [];

  @action
  registerInput(inputComponent) {
    this.registeredInputs = [...this.registeredInputs, inputComponent];
  }

  @action
  unregisterInput(inputComponent) {
    this.registeredInputs = this.registeredInputs.filter(
      (component) => component !== inputComponent,
    );
  }

  willDestroy() {
    super.willDestroy(...arguments);
    if (this.args.autoClean || this.args.resetOnDestruction) {
      this.registeredInputs.map((input) => input?.resetInput());
    }

    this.registeredInputs.clear();
  }

  get hasRequiredField() {
    return this.registeredInputs.some((input) => input.isRequired);
  }

  @action
  submit(event) {
    event.stopPropagation();
    event.preventDefault();
    if (this.isDisabled && this.isDestroying) {
      return;
    }

    let validatingInput = this.registeredInputs.invoke('validateInput', true);
    window.Promise.all(validatingInput).then((validationResults) => {
      if (!validationResults.isAny('isValid', false)) {
        this.args.action?.call();
        if (this.args.autoClean) {
          this.registeredInputs.map((input) => input.resetInput());
        }
      }
    });
  }

  @action
  reset() {
    this.registeredInputs.map((input) => input.resetInput());
  }
}
