import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class Number extends Component {

  get min() {
    return this.args.min || 1;
  }

  get max() {
    return this.args.max || 10;
  }

  @action
  focusOut() {
    let newValue = parseInt(this.args.value) || 0;
    newValue = Math.min(Math.max(newValue, this.min), this.max);
    this.args.updateValue(newValue);
  }

  @action
  changeValue(val) {
    let newValue = (parseInt(this.args.value) || 0) + val;
    newValue = Math.min(Math.max(newValue, this.min), this.max);
    if (this.args.updateValue) {
      this.args.updateValue(newValue);
    }
  }
}
