import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class PanelHeader extends Component{

  get isOpen() {
    return this.args.isOpen;
  }
  @action
  click(e) {
    e.preventDefault();
    this.args.onClick();
  }
}
