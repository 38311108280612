//
// IMPORTANT
//
// This file is copied to desktop-client. The code must work in both projects.
//
// Also, by design, the class should only communicate with the JSON-API API
// in the toolbox and should not contain application-specific code.
//

import $ from 'jquery';
import DS from 'ember-data';
import { tBoxClient } from '../initializers/init-toolbox'; // NB. The relative path works in both projects.
/* global libcryptobox */

export default DS.Adapter.extend({
    host: window.location.origin,
    coalesceFindRequests: true,

    _subscriptions: null,
    _recordArrayResults: null,

    _unloadAssociation(payload, store, type) {
      let record = store.peekRecord(type.modelName, payload.id);
      if (record) {
        store.unloadRecord(record);
      }
    },

    normalizeRecord(store, modelClass, resourceHash) {
        //FIXME Relationships are missing. JSONSerializer is not handling them properly.
        return { data: { type: modelClass.modelName, id: resourceHash.id, attributes: resourceHash } };
    },

    init() {
        this._super(...arguments);
        this._subscriptions = [];
        this._recordArrayResults = new Map();
    },

    createRecord(/*store, type, snapshot*/) {
        return Promise.reject('createRecord() is not implemented by default');
    },

    deleteRecord(/*store, type, snapshot*/) {
        return Promise.reject('deleteRecord() is not implemented by default');
    },

    findAll(store, type, sinceToken, snapshotRecordArray) {
        let { modelName } = type;
        let { include } = snapshotRecordArray;
        let { subscribe } = snapshotRecordArray.adapterOptions || {};
        return this._resolve(
            store,
            subscribe,
            null,
            () => tBoxClient.jsonApi.findAll(modelName, include),
            (obs) => tBoxClient.jsonApi.watchFindAll(modelName, include, obs),
            (payload) => {
                const ids = payload.data.map(res => res.id);
                store.peekAll(modelName).forEach((record) => {
                    if (record.id && !ids.includes(record.id)) {
                        record.unloadRecord();
                    }
                })
            },
        );
    },

    findRecord(store, type, id, snapshot) {
        let { modelName } = type;
        let { include } = snapshot;
        let { subscribe } = snapshot.adapterOptions || {};
        return this._resolve(
            store,
            subscribe,
            null,
            () => tBoxClient.jsonApi.findRecord(modelName, id, include),
            (obs) => tBoxClient.jsonApi.watchFindRecord(modelName, id, include, obs),
        );
    },

    query(store, type, query, recordArray) {
        let { modelName } = type;
        let { subscribe } = query.adapterOptions || {};
        delete query.adapterOptions;
        return this._resolve(
            store,
            subscribe,
            recordArray,
            () => tBoxClient.jsonApi.query(modelName, $.param(query), ''),
            (obs) => tBoxClient.jsonApi.watchQuery(modelName, $.param(query), '', obs),
        );
    },

    queryRecord(store, type, query) {
        let { modelName } = type;
        let { subscribe } = query.adapterOptions || {};
        delete query.adapterOptions;
        return this._resolve(
            store,
            subscribe,
            null,
            () => tBoxClient.jsonApi.queryRecord(modelName, $.param(query), ''),
            (obs) => tBoxClient.jsonApi.watchQueryRecord(modelName, $.param(query), '', obs),
        );
    },

    findHasMany(store, snapshot, url, relationship) {
        return tBoxClient.jsonApi.findHasMany(snapshot.modelName, snapshot.id, relationship.key);
    },

    findBelongsTo(store, snapshot, url, relationship) {
        return tBoxClient.jsonApi.findBelongsTo(snapshot.modelName, snapshot.id, relationship.key);
    },

    findMany(store, type, ids, snapshots) {
        return tBoxClient.jsonApi.findMany(type.modelName, ids);
    },

    updateRecord(/*store, type, snapshot*/) {
        return Promise.reject('updateRecord() is not implemented by default');
    },

    unsubscribe(observer) {
        this._subscriptions.forEach((s) => {
            if (s.observer === observer) {
                tBoxClient.stopWatching(s.queryId);
                this._recordArrayResults.delete(s.recordArray);
            }
        });
        this._subscriptions = this._subscriptions.filter((s) => s.observer !== observer);
    },

    _resolve(store, observer, recordArray, get, watch, onload) {
        // On update, AdapterPopulatedRecordArray calls the query again.
        if (this._recordArrayResults.has(recordArray)) {
            return this._recordArrayResults.get(recordArray);
        }
        if (observer) {
            let resolved = false
            return new Promise(async (resolve, reject) => {
                let queryId = await watch({
                    onQueryResultUpdate: (id, payload) => {
                        let updated = resolved;
                        recordArray && this._recordArrayResults.set(recordArray, payload);
                        if (resolved) {
                            if (recordArray) {
                                recordArray.update();
                            } else {
                                store.push(payload);
                            }
                        } else {
                            resolve(payload);
                            resolved = true;
                        }
                        onload && onload(payload, updated);
                    },
                    onQueryError: (id, error) => {
                        if (error.code !== libcryptobox.ErrorCode.LoggedOut) {
                            console.warn('onQueryError', error);
                        }
                        if (!resolved) {
                            reject(error);
                            resolved = true;
                        }
                    },
                });
                this._subscriptions.push({ observer, queryId, recordArray });
            });
        } else {
            return get().then((payload) => {
                onload && onload(payload, false);
                return payload;
            });
        }
    },
});
