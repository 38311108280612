import Route from '@ember/routing/route';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { inject as service } from '@ember/service';

export default class Conversation extends Route {
  @service messaging;
  @service store;
  @service authService;

  beforeModel() {
    this.authService.loginIfNotAuthenticated();
  }
  waitForOpenConnection() {
    return new Promise((resolve, reject) => {
      const maxNumberOfAttempts = 20;
      const intervalTime = 1000;

      let currentAttempt = 0;
      const interval = setInterval(() => {
        if (currentAttempt > maxNumberOfAttempts - 1) {
          clearInterval(interval);
          reject(new Error('Maximum number of attempts exceeded'));
        } else if (this.messaging.messagingStatus.isConnected) {
          clearInterval(interval);
          resolve();
        }
        currentAttempt++;
      }, intervalTime);
    });
  }

  async model() {
    let space = this.modelFor('my-groups.group');
    space = await this.store.findRecord('space', space.get('id'), {
      include: 'messages',
      adapterOptions: { subscribe: this },
    });
    await this.waitForOpenConnection();
    await tBoxClient.message.load(space.get('id'), 20);
    const members = await this.store.query('space-member', { spaceId: space.get('id') });
    const msg = await this.store.query('message', { spaceId: space.get('id') });
    space.messages.pushObjects(msg);
    space.members.pushObjects(members);
    return space;
  }

  setupController(controller, model) {
    controller.set('model', model);
    controller.set('showAcknowledgement', false);
  }

  afterModel() {
    let spaceName = this.modelFor('my-groups.group').get('name');
    document.title = 'Cryptobox - ' + spaceName + ' - ' + 'Conversation';
  }
}
