// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr, belongsTo } from '@ember-data/model';

// A space member.
// See SpaceMemberCollection.
export default class extends Model {
  // Email.
  // @type string
  @attr email;
  
  // First name.
  // @type string
  @attr givenName;
  
  // Last name.
  // @type string
  @attr surname;
  
  // Space's identifier.
  // @type string
  @attr spaceId;
  
  // Role.
  // @type Role
  @attr role;
  
  // User certificate.
  // @type * - Call `tBoxClient.decodeUint8Array()` to read the value. See Uint8Array in the getting started guide.
  @attr certificate;
  
  @belongsTo('space', { async: true, inverse: null }) space;
}

