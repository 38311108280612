import { debounce } from '@ember/runloop';
import { or } from '@ember/object/computed';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import Ember from 'ember';
import { htmlSafe } from '@ember/template';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { tracked } from '@glimmer/tracking';

/* global libcryptobox */
export default class PasswordInputComponent extends Component {
  @tracked initial = null;
  @tracked confirmPassword;
  @tracked passwordScore = {};
  @tracked passwordComplexity;
  @tracked aboutModal = false;
  @tracked isStrong = false;
  @tracked passwordIsNotValid = false;
  @tracked passwordIsEmpty = false;
  @tracked passwordIsTooWeak = false;
  @tracked confirmPasswordIsNotValid = false;
  @tracked confirmPasswordIsEmpty = false;
  @tracked confirmPasswordIsDifferent = false;
  @tracked value;

  @service modalManager;
  @service connection;
  @service intl;
  @service account;

  @or('passwordIsNotValid', 'confirmPasswordIsNotValid') isNotValid;

  get disablePasswordReset() {
    return this.args.disablePasswordReset || false;
  }

  get strenghtBarStyle() {
    const htmlEscape = Ember.Handlebars.Utils.escapeExpression;

    const colorToWidth = {
      [libcryptobox.PasswordScoreColor.Red]: 33.3,
      [libcryptobox.PasswordScoreColor.Orange]: 66.6,
      [libcryptobox.PasswordScoreColor.Green]: 100,
    };

    const width = colorToWidth[this.passwordScore?.color] || 0;

    return htmlSafe(`width: ${htmlEscape(width)}%`);
  }

  get checkPassword() {
    const password = this.value || '';

    if (password !== '') {
      debounce(this, this.validateInput, 500);
    }

    return true;
  }

  constructor() {
    super(...arguments);
    this._setResetForm();
  }

  async validateInput(isSubmission) {
    return new window.Promise(async (resolve) => {
      const password = this.value || '';

      if (password === '') {
        this.passwordComplexity = '';
        this.passwordScore = {};

        if (this.args.isRequired) {
          this.passwordIsNotValid = true;
          this.passwordIsEmpty = true;
          if (this.args.isNotValid) this.args.isNotValid(true);
          resolve({ isValid: false });
          return;
        }

        resolve({ isValid: true });
        if (this.args.isNotValid) this.args.isNotValid(false);
        return;
      }

      if (!this.validateConfirmPassword(isSubmission)) {
        resolve({ isValid: false });
        if (this.args.isNotValid) this.args.isNotValid(true);
      }

      const server = this.connection.serverAddress;
      const firstname = this.account.firstName || this.args.firstName;
      const lastname = this.account.lastName || this.args.lastName;
      const email = this.account.userEmail || this.args.userEmail;
      tBoxClient.validation
        .getPasswordScore(server, email, password, firstname, lastname)
        .then((passwordScore) => {
          if (this.isDestroyed) {
            return false;
          }

          this.passwordScore = passwordScore;
          const { ok: isOk, label, color } = passwordScore;

          this.isStrong = false;
          this.passwordIsTooWeak = false;
          this.passwordIsNotValid = false;
          this.passwordComplexity = label;

          switch (color) {
            case libcryptobox.PasswordScoreColor.Red:
              this.passwordIsNotValid = true;
              this.passwordIsTooWeak = true;
              break;

            case libcryptobox.PasswordScoreColor.Orange:
              this.passwordIsNotValid = !isOk;
              break;

            case libcryptobox.PasswordScoreColor.Green:
              this.isStrong = true;
              break;

            default:
              console.warn('Unhandled password score color:', color);
          }

          resolve({ isValid: isOk });
          if (this.args.isNotValid) {
            this.args.isNotValid(!isOk);
          }
        });
    });
  }

  resetpasswordInputState() {
    this.passwordIsNotValid = false;
    this.passwordIsEmpty = false;
    this.passwordComplexity = '';
    if (this.args.isNotValid) this.args.isNotValid(false);
  }

  validateConfirmPassword(displayErrorOnEmpty) {
    const password = this.value;

    if (!this.confirmPassword) {
      if (displayErrorOnEmpty && this.args.isRequired) {
        this.confirmPasswordIsNotValid = true;
        this.confirmPasswordIsEmpty = true;
        if (this.args.isNotValid) this.args.isNotValid(true);
        return false;
      }
    } else if (this.confirmPassword !== password) {
      this.confirmPasswordIsNotValid = true;
      this.confirmPasswordIsDifferent = true;
      if (this.args.isNotValid) this.args.isNotValid(true);

      return false;
    }
    if (this.args.isNotValid) this.args.isNotValid(false);
    return true;
  }

  resetConfirmPasswordState() {
    this.confirmPasswordIsNotValid = false;
    this.confirmPasswordIsEmpty = false;
    this.confirmPasswordIsDifferent = false;
  }

  _handleListener(component) {
    return () => {
      clearInterval(component.initial);
      component.initial = window.setInterval(
        component._resetPasswordField(component),
        60000,
      );
    };
  }

  _resetPasswordField(component) {
    return () => {
      if (!component.disablePasswordReset) {
        component.value = null;
        component.confirmPassword = null;
        component.passwordScore = null;
        component.passwordComplexity = null;
      }
    };
  }

  _setResetForm() {
    if (
      this.args.formContainer &&
      Array.isArray(this.args.formContainer.registeredInputs)
    ) {
      this.args.formContainer.registeredInputs.push(this);
    }

    if (this.args.registry && Array.isArray(this.args.registry)) {
      this.args.registry.push(this);
    }

    this.initial = window.setInterval(this._resetPasswordField(this), 60000);

    document.addEventListener('mousemove', this._handleListener(this));
    document.addEventListener('keydown', this._handleListener(this));
  }

  @action
  checkInput() {
    this.resetConfirmPasswordState();
    this.resetpasswordInputState();
    debounce(this, this.validateInput, 500);
    if (this.args.onUpdate) this.args.onUpdate(this.value);
  }

  @action
  checkConfirm() {
    this.resetConfirmPasswordState();
    debounce(this, this.validateConfirmPassword, true, 500);
  }

  @action
  openPasswordModal() {
    this.aboutModal = true;
    setTimeout(() => {
      this.modalManager.open('about-password');
    }, 500);
  }

  @action
  reopenPassword() {
    const modal = this.args.passwordModalName;
    if (modal) {
      this.modalManager.close('about-password');
      this.modalManager.open(modal);
    }
  }

  @action
  onInsertElement() {
    this.args.registerInput(this);
  }

  @action
  unregisterReset() {
    this._resetPasswordField(this);
    this.args.unregisterInput(this);
    if (this.args.registry) this.args.registry.clear();

    clearInterval(this.initial);
    document.removeEventListener('mousemove', this._handleListener(this));
    document.removeEventListener('keydown', this._handleListener(this));
  }
}
