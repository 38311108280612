import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class FileItemDownloadButton extends Component {
  @service('file-history') fileHistoryService;

  get file() {
    return this.args.file;
  }

  get isDownloadable() {
    return this.args.isDownloadable;
  }

  @action
  async downloadFile(e) {
    e.preventDefault();
    e.stopPropagation();
    await this.fileHistoryService.downloadFileHistoryVersion(this.file);
  }
}
