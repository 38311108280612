import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

export default Helper.extend({
  intl: service(),
  compute(speed) {
    let formatSpeed;
    speed = speed * 1000; //convert B/ms to B/s
    if (speed < 1e3) {
      formatSpeed= speed.toFixed(2) + ' ';
    } else if (speed >= 1e3 && speed < 1e6) {
      formatSpeed = (speed / 1e3).toFixed(2) + ' k';
    } else if (speed >= 1e6 && speed < 1e9) {
      formatSpeed = (speed / 1e6).toFixed(2) + ' M';
    } else if (speed >= 1e9 && speed < 1e12) {
      formatSpeed = (speed / 1e9).toFixed(2) + ' G';
    } else {
      formatSpeed = (speed / 1e12).toFixed(2) + ' T';
    }
    formatSpeed += this.intl.t('sizeUnit')+'/s';
    return formatSpeed;
  },
});
