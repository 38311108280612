class FileReader {
  constructor(ua, file, fileName) {
    this.length = file.size;
    this.file = file;
    this.fileName = fileName || file.name;
    this.ua = ua;
    this.chunk = null;
  }

  name() {
    return this.fileName;
  }

  size() {
    return this.length;
  }

  read(offset, count) {
    return new Promise((resolve, reject) => {
      const reader = new window.FileReader();
      reader.onload = () => {
        resolve(this.ua.encodeUint8Array(new Uint8Array(reader.result)));
      };
      reader.onerror = (e) => {
        reject(e.target.error);
      };
      reader.readAsArrayBuffer(this.file.slice(offset, offset + count));
    });
  }
}

export default FileReader;
