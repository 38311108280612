import Component from "@glimmer/component";
import { guidFor } from "@ember/object/internals";
import { action } from "@ember/object";

export default class TextareaComponent extends Component {
  inputId = "input-textarea-" + guidFor(this);

  @action
  didInsert(element) {
    const { autoFocus, didInsert } = this.args;

    if (autoFocus) {
      element.focus();
    }

    if (didInsert) {
      didInsert(element);
    }
  }
}
