import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class DeletedSpaces extends Route {
  controllerName = 'my-groups/index';
  templateName = 'my-groups/index';

  @service intl;
  @service store;
  @service authService;

  beforeModel() {
    this.authService.loginIfNotAuthenticated();
  }
  setupController(controller, model) {
    controller.set('model', model);
    controller.set('isFav', false);
    controller.set('deletedWorkspaces', true);
  }

  afterModel() {
    document.title = 'Cryptobox - ' + this.intl.t('navigation.my.groups.deleted');
  }

  deactivate() {
    this.store.adapterFor('space').unsubscribe(this);
    this.store.adapterFor('category').unsubscribe(this);
  }
}
