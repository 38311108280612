import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import getReportErrorBody from '../utils/getReportErrorBody';
import getMailTo from '../utils/getMailTo';
import getSupportEmail from '../utils/getSupportEmail';
import { action } from '@ember/object';
import { openExternal } from 'client/ipc';

export default class FatalError extends Route {
  @service observerFactory;
  @service account;
  @service connection;
  @service('platform-customization') platformCustomization;
  @service router;

  beforeModel() {
    if (!this.observerFactory.fatalError) {
      this.router.transitionTo('my-groups');
    }
  }

  willTransition(transition) {
    if (this.observerFactory.fatalError) {
      transition.abort();
    }
  }

  @action
  reload() {
    window.sessionStorage.clear();
    window.location.hash = '#';
    window.location.reload();
  }

  @action
  async reportError() {
    const isDesktopApp = this.connection.isDesktopApp;
    const settings = this.platformCustomization.serverSettings;
    const error = this.observerFactory.get('fatalError');
    const { title, code, message, details } = error;
    const email = await getSupportEmail(isDesktopApp, settings);
    const subject = 'Cryptobox error report: ' + title;
    const body = getReportErrorBody({
      server: this.connection.serverAddress,
      userEmail: this.account.get('userEmail'),
      title,
      code,
      message,
      details,
    });

    const mailTo = getMailTo({ email, subject, body });

    if (this.connection.isDesktopApp) {
      await openExternal(mailTo);
    } else {
      window.location = mailTo;
    }

    setTimeout(() => {
      this.reload();
    }, 2500);
  }
}
