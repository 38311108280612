import { helper } from "@ember/component/helper";
/* global libcryptobox */

function isClickableNotification(notification) {
  return notification.state === libcryptobox.NotificationState.Unread;
}

function isClickable([target, testName]) {
  if (testName === "notification") {
    return isClickableNotification(target);
  }

  return false;
}

export default helper(isClickable);
