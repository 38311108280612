import { run } from '@ember/runloop';
import Evented from '@ember/object/evented';
import Mixin from '@ember/object/mixin';
let RESIZE_EVENTS = 'resize orientationchange';

export default Mixin.create(Evented, {
  bindResize() {
    this.resizeHandler = () => this.debouncedResize();
    document.addEventListener(RESIZE_EVENTS, this.resizeHandler)
  },

  unbindResize() {
    document.removeEventListener(RESIZE_EVENTS, this.resizeHandler)
  },

  debouncedResize() {
    window.requestAnimationFrame(() => {
      if (this.isDestroyed) {
        return;
      }
      run(() => {
        this.trigger('onResize');
        this.onResize();
      });
    });
  },
});
