import {action} from "@ember/object";
import Component from "@glimmer/component";
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';

export default class FileInputComponent extends Component {

  @tracked value = null;

  @service transfers

  get targetSpace() {
    return this.args.targetSpace;
  }

  get currentFolder() {
    return this.args.currentFolder;
  }
 get disabled() {
    return this.args.disabled;
  }

  @action
  change(e) {
    e.stopPropagation();
    e.preventDefault();

    let files = [].slice.call(e.target.files);
    if (!files.length) {
      return false;
    }
    let fileListArray = [];
    files.forEach((file) => {
      file.CBRelativePath = '';
      fileListArray.push(file);
    });

    this.transfers.uploadFilesList(fileListArray, this.currentFolder, this.targetSpace);
    this.value = null;
  }
}
