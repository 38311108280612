import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class OfflineBanner extends Component {
  @service messaging;

  @action
  _focusHandler() {
    const banner = document.querySelector('.banner.warning');

    if (banner) {
      banner.querySelector('button').click();
    }
  }
  @action
  reconnectMessaging() {
    this.messaging.retryMessagingConnection();
  }
}
