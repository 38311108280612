import { gt } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import { tBoxClient } from 'client/initializers/init-toolbox';
import libcryptobox from '../toolbox/generated/client/libcryptobox';
import { tracked } from '@glimmer/tracking';

export default class Session extends Service {
  @service account;
  @service connection;

  @tracked session = null;
  @tracked changingPwd;
  @tracked revision = 0; // 0 until ready, limit the propagation of session changes.

  @tracked canLogout = false;
  @tracked canAssociateDevice = false;
  @tracked isLoggedIn = false;
  @tracked isLoginWithDevice = false;
  @tracked localAccountIDToDelete = false;
  @tracked fatalError = false;
  @tracked sigmaSessionId = null;
  @gt('revision', 0) ready; // true when the session is ready (ie. the session is loaded or cleared).

  constructor() {
    super(...arguments);
    void this.initialize();
  }

  async initialize() {
    const crypto = window.crypto || window.msCrypto;
    const isDesktopApp = this.connection.isDesktopApp;

    // nb. on Firefox with "delete cookies on close", the service worker API is available but raises a security error exception when invoked.
    if (navigator.serviceWorker && !isDesktopApp) {
      try {
        await navigator.serviceWorker.register('/service-worker.js');
      } catch (error) {
        console.warn(error);
      }
    }

    // session sharing
    this._id = crypto.getRandomValues(new Uint8Array(4)).toString();
    this._keyPair = await crypto.subtle.generateKey(
      { name: 'ECDH', namedCurve: 'P-256' },
      false,
      ['deriveBits'],
    );
    this._jwk = await crypto.subtle.exportKey('jwk', this._keyPair.publicKey);
    this._aesKeys = {};
    window.addEventListener('storage', async (event) => {
      if (!event.newValue) {
        return;
      }
      switch (event.key) {
        case 'sessionChange': {
          const data = JSON.parse(event.newValue);
          if (data.revision > this.revision) {
            window.localStorage.setItem(
              'getSession',
              JSON.stringify({
                jwk: this._jwk,
                from: this._id,
                revision: this.revision,
              }),
            );
          } else if (this.revision > data.revision) {
            window.localStorage.setItem(
              'sessionChange',
              JSON.stringify({
                revision: this.revision,
                timestamp: Date.now(),
              }),
            );
          }
          break;
        }

        case 'setSession': {
          const data = JSON.parse(event.newValue);
          if (data.to !== this._id || this.revision > data.revision) {
            return;
          }

          const aesKey = await this._deriveAesKey(data.jwk);

          const iv = Uint8Array.from(data.iv);
          const ciphertext = Uint8Array.from(data.ciphertext);
          const cleartext = await crypto.subtle.decrypt(
            { name: 'AES-GCM', iv },
            aesKey,
            ciphertext,
          );
          const session = JSON.parse(new TextDecoder('utf8').decode(cleartext));

          const changed =
            this.ready &&
            JSON.stringify(session) !== JSON.stringify(this.session);

          this.session = session;
          this.revision = data.revision;

          if (changed) {
            if (this.session) {
              this.account.loadSession(this.session);
            } else {
              this.account.confirmLogout();
            }
          }
          break;
        }

        case 'getSession': {
          const data = JSON.parse(event.newValue);
          if (this.revision > data.revision) {
            const aesKey = await this._deriveAesKey(data.jwk);

            const iv = crypto.getRandomValues(new Uint8Array(12));
            const ciphertext = await crypto.subtle.encrypt(
              { name: 'AES-GCM', iv },
              aesKey,
              new TextEncoder('utf8').encode(JSON.stringify(this.session)),
            );

            window.localStorage.setItem(
              'setSession',
              JSON.stringify({
                jwk: this._jwk,
                iv: Array.from(iv),
                ciphertext: Array.from(new Uint8Array(ciphertext)),
                to: data.from,
                revision: this.revision,
              }),
            );
          }
          break;
        }
      }
    });

    // session reload after page refresh
    // nb. the service worker may outlive the session; 'restoreSession' ensures we're in the same session when the page reloads.
    // nb. postMessage is discarded on Safari but the service worker is not stopped while there are client tabs opened, so we don't need to awake it but we need to pass it the session as soon as possible.
    // https://webkit.org/blog/8090/workers-at-your-service/
    window.addEventListener('unload', () => {
      // CNUAGE-10835 Send SIGMA logout beacon.
      this.sendLogoutBeacon();

      if (!this.ready) {
        return;
      }
      if (navigator.serviceWorker && navigator.serviceWorker.controller) {
        window.sessionStorage.setItem('restoreSession', 'true');
        navigator.serviceWorker.controller.postMessage({
          message: 'storeSession',
          session: JSON.stringify(this.session),
        });
      } else {
        // fallback: use the session storage (unsafe)
        window.sessionStorage.setItem('session', JSON.stringify(this.session));
      }
    });

    // automatic session termination
    tBoxClient.serverSettings.watch({
      onQueryResultUpdate: (_, settings) => {
        this.canLogout = settings.capabilities.includes(
          libcryptobox.ServerCapability.Logout,
        );
      },
    });
    tBoxClient.session.watchSigmaSession({
      onQueryResultUpdate: (_, session) => (this.sigmaSessionId = session.id),
    });
    tBoxClient.loginStatus.watch({
      onQueryResultUpdate: (_, status) => {
        if (!status.isLoggedIn) {
          this.sigmaSessionId = null;
        }
      },
    });

    // load/trigger the session loading
    if (window.sessionStorage.getItem('session')) {
      const session = window.sessionStorage.getItem('session');
      window.sessionStorage.removeItem('session');
      await this._setSession(JSON.parse(session));
    } else {
      // ask everybody
      // nb. on Firefox with "delete cookies on close", the service worker API is available but the worker won't start.
      window.localStorage.setItem(
        'getSession',
        JSON.stringify({ jwk: this._jwk, from: this._id, revision: 0 }),
      );

      if (
        navigator.serviceWorker &&
        window.sessionStorage.getItem('restoreSession')
      ) {
        const channel = new MessageChannel();
        channel.port1.onmessage = (event) => {
          if (!this.ready && event.data && event.data.session) {
            this._setSession(JSON.parse(event.data.session));
          }
        };
        try {
          const serviceWorkerRegistration = await navigator.serviceWorker.ready;
          serviceWorkerRegistration.active.postMessage(
            { message: 'restoreSession' },
            [channel.port2],
          );
        } catch (error) {
          console.warn(error);
        }
      }
    }
  }

  sendLogoutBeacon() {
    let canLogout = this.canLogout;
    let sigmaSessionId = this.sigmaSessionId;
    if (canLogout && sigmaSessionId) {
      let data = [{ id: sigmaSessionId, type: 'sigma_session' }];
      let blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
      navigator.sendBeacon('/api/auth/logout', blob);
      this.sigmaSessionId = null;
    }
  }

  async setSession() {
    return await this._setSession(await tBoxClient.session.save());
  }

  async clearSession() {
    return await this._setSession(null);
  }

  getSession() {
    return new Promise((resolve, reject) => {
      if (this.ready) {
        if (this.session) {
          resolve(this.session);
        } else {
          reject('no session');
        }
      } else {
        const handler = () => {
          if (this.session) {
            resolve(this.session);
          } else {
            reject('no session');
          }
          this.removeObserver('ready', handler);
        };
        this.addObserver('ready', handler);
        setTimeout(async () => {
          if (!this.ready) {
            await this._setSession(null);
          }
        }, 1000);
      }
    });
  }

  async _setSession(session) {
    const updated =
      !this.ready || JSON.stringify(session) !== JSON.stringify(this.session);

    this.session = session;
    if (updated) {
      this.revision = this.revision + 1;
    }

    // notify
    if (updated) {
      // 'timestamp' only ensures that the store event is triggered (we may also delete the item on reception by eg.).
      window.localStorage.setItem(
        'sessionChange',
        JSON.stringify({
          revision: this.revision,
          timestamp: Date.now(),
        }),
      );
    }

    // store
    // nb. see "session reload after page refresh" above.
    if (navigator.serviceWorker) {
      try {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;
        serviceWorkerRegistration.active.postMessage({
          message: 'storeSession',
          session: JSON.stringify(this.session),
        });
      } catch (error) {
        console.warn(error);
      }
    }
  }

  async _deriveAesKey(jwk) {
    const peerPublicKey = await crypto.subtle.importKey(
      'jwk',
      jwk,
      { name: 'ECDH', namedCurve: 'P-256' },
      false,
      [],
    );
    const secret = await crypto.subtle.deriveBits(
      { name: 'ECDH', public: peerPublicKey },
      this._keyPair.privateKey,
      256,
    );
    const rawAesKey = await crypto.subtle.digest('SHA-256', secret);
    const aesKey = await crypto.subtle.importKey(
      'raw',
      rawAesKey,
      { name: 'AES-GCM' },
      false,
      ['encrypt', 'decrypt'],
    );
    return aesKey;
  }
}
