import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class ListHeaderComponent extends Component {
  @tracked detailsIsVisible = null

  get allNodeSelected() {
    return this.args.allNodes.length === this.args.selectedNodes.length
  }

  @action
  sortNodesBy(criteria) {
    this.args.sortNodesBy(criteria);
  }

  @action
  toggleAllSelected() {
    if (this.args.allNodes.length === this.args.selectedNodes.length) {
      this.args.selectedNodes.clear()
    } else {
      this.args.allNodes.forEach(element => {
        if (!this.args.selectedNodes.isAny('id', element.id)) {
          this.args.selectedNodes.pushObject(element)
        }
      })
    }
  }
}
