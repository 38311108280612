import BaseAdapter from 'client/adapters/application';
import FileReader from 'client/toolbox/file-reader';
import { tBoxClient } from 'client/initializers/init-toolbox';

export default class Attachment extends BaseAdapter{
  createRecord(store, type, snapshot) {
    return this._getOptions(snapshot.attr('fileData')).then(options => {
      let reader = new FileReader(tBoxClient, snapshot.attr('fileData'), snapshot.attr('fileName'));
      return tBoxClient.attachment.add(snapshot.belongsTo('message').id, reader, options).then(payload => {
        const existingRecord = store.peekRecord({ type:'attachment',id: payload.id });
        if (existingRecord) {
          existingRecord.unloadRecord();
        }
        return this.normalizeRecord(store, type, payload);
      });
    });
  }

  _getOptions(file) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = async () => {
        const previewSize = await tBoxClient.attachment.getDefaultPreviewSize();
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (img.width <= previewSize && img.height <= previewSize) {
          canvas.width = img.width;
          canvas.height = img.height;
        } else if (img.width > img.height) {
          canvas.width = previewSize;
          canvas.height = img.height * previewSize / img.width;
        } else {
          canvas.height = previewSize;
          canvas.width = img.width * previewSize / img.height;
        }
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => {
          // NB. blob.arrayBuffer() is not supported on Safari.
          const reader = new window.FileReader();
          reader.onload = () => {
            resolve({ limitImageSize: false, preview: tBoxClient.encodeUint8Array(new Uint8Array(reader.result)), original: { width: img.width, height: img.height } });
          };
          reader.onerror = reject;
          reader.readAsArrayBuffer(blob);
        }, 'image/jpeg');
      };
      img.onerror = reject;

      const reader = new window.FileReader();
      reader.onload = function () {
        img.src = reader.result;
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    }).catch(() => {
      return { limitImageSize: false };
    });
  }
}
