import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
/* global libcryptobox */
export default class Documents extends Route {
  @service notify;
  @service commonService;
  @service account;
  @service modalManager;
  @service intl;
  @service store;
  @service router;
  @service authService;

  @tracked queryOptions = null;
  @tracked params = null;
  @tracked transition = null;
  @tracked isLoading = null;
  queryParams = {
    path: { refreshModel: true },
    trackingId: { refreshModel: true },
    fileRevision: { refreshModel: true },
  };

  constructor() {
    super(...arguments);
  }

  syncCommonService(queryOptions) {
    this.queryOptions = queryOptions;
    if (this.commonService.workspaceRevision !== queryOptions.fileRevision) {
      this.commonService.workspaceRevision = queryOptions.fileRevision;
    }
  }

  getQueryOptions() {
    const params = this.params;
    const spaceId = this.paramsFor('my-groups.group').space_id;
    const path = params.path;
    const trackingId = params.trackingId;
    const queryOptions = {
      fileRevision: params.fileRevision,
      spaceId: spaceId,
      path: path,
      trackingId: trackingId,
      reload: true,
    };
    this.set('queryOptions', queryOptions);
    return queryOptions;
  }

  refreshModel() {
    console.warn(
      '[routes:my-groups.group.documents] stop calling refreshModel()',
    );
  }

  beforeModel() {
    this.authService.loginIfNotAuthenticated();
    const space = this.modelFor('my-groups.group');
    const params = this.paramsFor('my-groups.group.documents');

    //user try to see old file revision
    if (!this.account.status.canWrite && params.fileRevision !== 0) {
      return this.router.transitionTo('my-groups.group.documents', space.id, {
        queryParams: { fileRevision: 0, path: '/' },
      });
    }

    return this.store
      .queryRecord('breadcrumb', {
        spaceId: space.id,
        revision: params.fileRevision,
        path: params.path,
      })
      .then((breadcrumb) => {
        if (breadcrumb.get('path') !== params.path) {
          this.router.transitionTo('my-groups.group.documents', space.id, {
            queryParams: {
              fileRevision: breadcrumb.get('revision'),
              path: breadcrumb.get('path'),
            },
          });
        }
      });
  }

  async model(params, transition) {
    const documentModel = this.modelFor('my-groups.group.documents');

    if (documentModel) {
      documentModel.isLoading = true;
    }

    this.unsubscribe();
    this.params = params;
    this.transition = transition;
    const queryOptions = this.getQueryOptions();

    this.syncCommonService(queryOptions);

    const adapterOptions = { subscribe: this };
    const space = this.modelFor('my-groups.group');
    const members = space.get('members');
    let path = queryOptions.path;
    let filePreviewPath = '';
    if (queryOptions.trackingId) {
      try {
        const fileTrackingIdInfo = await this.store.queryRecord('file', {
          find: {
            spaceId: queryOptions.spaceId,
            trackingId: queryOptions.trackingId,
          },
        });
        if (!fileTrackingIdInfo.isDirectory) {
          path = fileTrackingIdInfo.path.substring(
            0,
            fileTrackingIdInfo.path.lastIndexOf('/'),
          );
          filePreviewPath = fileTrackingIdInfo.path;
          if (!path) {
            path = '/';
          }
        } else {
          path = fileTrackingIdInfo.path;
        }
      } catch (error) {
        if (error.code === libcryptobox.ErrorCode.NotFound) {
          this.modalManager.open('file-not-found');
        } else if (error.code === libcryptobox.ErrorCode.Forbidden) {
          this.modalManager.open('space-not-found');
        }
      }

      this.set('trackingIdPath', path);
    } else {
      this.set('trackingIdPath', '');
    }

    this.set(
      'filePreviewPath',
      filePreviewPath.substring(1, filePreviewPath.length),
    );

    // TODO : Differences between those 2 queries
    const folder = this.store.queryRecord('file', {
      spaceId: queryOptions.spaceId,
      revision: queryOptions.fileRevision,
      path: path,
      adapterOptions,
    });
    const files = await this.store.query('file', {
      spaceId: queryOptions.spaceId,
      revision: queryOptions.fileRevision,
      path: path,
      adapterOptions,
    });
    const isLoading = false;
    const fileRevision = queryOptions.fileRevision;
    const breadcrumb = this.store.queryRecord('breadcrumb', {
      spaceId: queryOptions.spaceId,
      revision: queryOptions.fileRevision,
      path: path,
      adapterOptions,
    });

    if (queryOptions.trackingId) {
      this.set('folderTrackingId', folder.get('trackingId'));
    } else {
      this.set('folderTrackingId', '');
    }

    const fileDownloadSubscription = this.store.queryRecord(
      'file-download-subscription',
      {
        spaceId: queryOptions.spaceId,
        adapterOptions,
      },
    );

    return RSVP.hash({
      fileDownloadSubscription,
      space,
      members,
      folder,
      files,
      isLoading,
      fileRevision,
      breadcrumb,
    });
  }

  deactivate() {
    this.unsubscribe();
    window.removeEventListener('resize', this.resizeEvent);
  }

  unsubscribe() {
    this.store.adapterFor('file').unsubscribe(this);
    this.store.adapterFor('file-revision').unsubscribe(this);
    this.store.adapterFor('breadcrumb').unsubscribe(this);
    this.store.adapterFor('file-download-subscription').unsubscribe(this);
  }

  afterModel() {
    const space = this.modelFor('my-groups.group');
    document.title =
      'Cryptobox - ' +
      space.get('name') +
      ' - ' +
      this.intl.t('group.navigation.documents');
  }

  setupController(controller, model) {
    controller.set('model', model);
    controller.set('trackingIdPath', this.trackingIdPath);
    controller.set('filePreviewPath', this.filePreviewPath);
    if (this.folderTrackingId) {
      controller.set('trackingId', this.folderTrackingId);
      controller.set('fileTrackingId', this.getQueryOptions().trackingId);
    }
  }

  @action
  didTransition() {
    this.controller.set('searchedNodes', null);
    this.controller.set('searchTerm', '');
    this.controller.set('showPathInFiles', false);
    this.controller.set('displaySearchedNodesPopup', false);
    this.controller.set('displayOnlySearchedNodes', false);
    this.controller.set('searchFlag', false);

    //if there is no current revision
    if (!this.params.fileRevision) {
      this.controller.set('displayHistory', false);
    }
    return true;
  }

  @action
  refreshRoute() {
    this.refresh();
  }
}
