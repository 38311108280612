import Component from "@glimmer/component";
import { inject as service } from "@ember/service";

export const MODAL_TYPES = {
  BOTH: "BOTH",
  USER_ONLY: "USER_ONLY",
  INVITER_ONLY: "INVITER_ONLY",
};

export default class EnforcedTrusteeModalComponent extends Component {
  @service intl;

  get showUserTrustee() {
    return (
      this.args.type === MODAL_TYPES.BOTH ||
      this.args.type === MODAL_TYPES.USER_ONLY
    );
  }

  get showInviterTrustee() {
    return (
      this.args.type === MODAL_TYPES.BOTH ||
      this.args.type === MODAL_TYPES.INVITER_ONLY
    );
  }

  get title() {
    let tradPath;
    if (
      this.args.type === MODAL_TYPES.BOTH ||
      this.args.type === MODAL_TYPES.USER_ONLY
    ) {
      tradPath = this.args.newUser
        ? "trustees.enforced.subtitle"
        : "trustees.enforced.subtitleChange";
    } else if (this.args.type === MODAL_TYPES.INVITER_ONLY) {
      tradPath = "trustees.enforced.subtitleGuest";
    } else {
      tradPath = "trustees.enforced.subtitleChange"
    }

    return this.intl.t(tradPath);
  }

  get label() {
    let tradPath;
    if (this.args.type === MODAL_TYPES.BOTH) {
      tradPath = this.args.newUser
        ? "trustees.enforced.labelTrustees"
        : "trustees.enforced.changeLabelTrustees";
    } else if (this.args.type === MODAL_TYPES.USER_ONLY) {
      tradPath = this.args.newUser
        ? "trustees.enforced.label"
        : "trustees.enforced.changeLabel";
    } else if (this.args.type === MODAL_TYPES.INVITER_ONLY) {
      tradPath = "trustees.enforced.labelGuest";
    } else {
      tradPath = "trustees.enforced.changeLabel"
    }

    return this.intl.t(tradPath);
  }
}
