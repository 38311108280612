import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { DOWNLOAD_TYPE, RENAME_NODE_MODAL_NAME } from '../../../../../utils/enums';
import { tracked } from '@glimmer/tracking';

/* global libcryptobox */

export default class ActionsTab extends Component {
  @tracked downloadType = DOWNLOAD_TYPE;

  copyMoveModalName = 'copy-move-modal copy-move-modal1';

  deleteFileModalName = 'delete-file-action-tab';

  @service('transfers') transfersManager;
  @service modalManager;
  @service store;
  @service account;
  @service router;
  @service('file-history') fileHistoryService;

  get isSharable() {
    return this.args.space
      .get('accessRights')
      .includes(libcryptobox.SpaceAccessRight.Share);
  }

  get canCreateLink() {
    const firstSelectedNode = Array.isArray(this.args.selectedNodes.content) && this.args.selectedNodes.content.at?.(0);
    if (!firstSelectedNode) {
      return false;
    }
    return (
      !this.args.space.get('isReader') &&
      !this.args.space.get('isViewer') &&
      this.account.get('status.canWrite')
    );
  }

  get canCopy() {
    return !this.args.space.get('isViewer') && this.account.status.canWrite;
  }

  get canEdit() {
    return (
      !this.args.space.get('isReader') &&
      !this.args.space.get('isViewer') &&
      this.account.get('status.canWrite')
    );
  }

  get canShowFileHistory() {
    return !this.args.space.get('isReader') && !this.args.space.get('isViewer');
  }

  get disabledReplaceFileContent() {
    return this.args.selectedNodes.length > 1;
  }

  get disabledDownloadZip() {
    return !(this.args.node.isFolder || this.args.selectedNodes.length > 1);
  }

  get isEditable() {
    return this.canEdit && !this.args.space.get('isDeleted');
  }

  get isSubscribable() {
    return this.args.node.get('isLoaded');
  }

  get canDownload() {
    return [
      libcryptobox.Role.Owner,
      libcryptobox.Role.Member,
      libcryptobox.Role.Auditor,
    ].includes(this.args.node.space.get('userRole'));
  }

  @action
  onViewFileAction() {
    this.args.onViewFile(this.args.node.path);
  }

  @action
  onShareFileAction() {
    this.modalManager.open('init-share');
  }

  @action
  onGetLinkAction() {
    this.modalManager.open('get-link-modal');
  }

  @action
  onClickRenameNode() {
    this.modalManager.open(RENAME_NODE_MODAL_NAME, {
      node: this.args.node,
    });
  }

  @action
  onClickCopyMove() {
    this.modalManager.open(this.copyMoveModalName);
  }

  @action
  handleOnClickDelete() {
    this.modalManager.open(this.deleteFileModalName);
  }

  @action
  onDownloadAction(dlType) {
    this.args.onDownload(dlType);
  }

  @action
  goToFileHistory() {
    this.fileHistoryService.filePath = this.args.node.path;
    this.router.transitionTo(
      'my-groups.file-history.index',
      this.args.node.spaceId,
      {
        queryParams: {
          trackingId: this.args.node.trackingId,
        },
      },
    );
  }
}
