// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr, belongsTo } from '@ember-data/model';

// The account information of the authenticated user.
// See AccountCollection.
export default class extends Model {
  // Server URL.
  // @type string
  @attr serverUrl;
  
  // Whether the account is saved on the device.
  // @type boolean
  @attr isOnDevice;
  
  // User certificate.
  // @type * - Call `tBoxClient.decodeUint8Array()` to read the value. See Uint8Array in the getting started guide.
  @attr certificate;
  
  @belongsTo('user', { async: false, inverse: null }) user;
}

