import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { computed } from '@ember/object';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
/* global libcryptobox */

export default class DepositaryChoice extends Controller {
  @tracked emailIsSent = false;
  @tracked stepDepositary = 1;
  @tracked isDefault = true;

  @service intl;
  @service connection;
  @service router;

  @computed('isDefault', 'model.defaultTrustee')
  get tLabel() {
    const label = this.isDefault ? 'labelDefault' : 'labelPersonal';
    return this.intl.t(`recovery.trustee.${label}`);
  }

  @computed('model.defaultTrustee')
  get hasDefaultTrustee() {
    return this.model.defaultTrustee !== '';
  }

  @action
  chooseDepositary() {
    const depositaryEmail = this.depositaryEmail;
    const recoveryId = this.model.recoveryId;
    const server = this.connection.serverAddress;
    tBoxClient.recovery
      .chooseDeposit(server, recoveryId, depositaryEmail)
      .then(() => {
        this.emailIsSent = true;
      })
      .catch((error) => {
        if (error.code === libcryptobox.ErrorCode.RecoveryBadTrustee) {
          let errorMessage = this.intl.t('recovery.trustee.errors.notExist');
          document.querySelector('.depositary-email').classList.add('error');
          document.querySelector('.depositary-email .error-message').innerText =
            errorMessage;
        }
      });
  }

  @action
  cancel() {
    this.depositaryEmail = '';
    this.router.transitionTo('/');
  }

  @action
  nextStep() {
    const isDefault = this.isDefault;
    this.stepDepositary = 2;

    if (isDefault) {
      this.depositaryEmail = this.model.defaultTrustee;
      this.chooseDepositary();
    }
  }
}
