import dispatchEvent from '../../dispatch-event';
import RpcError from '../error';

// WebWorker transport/back-end.
export default class WebWorkerTransport {
    constructor(url) {
        this._worker = null;
        this._url = url;

        this.onready = null;
        this.onmessage = null;
        this.onerror = null;
        this.onclose = null;
    }

    connect() {
        if (!this._worker) {
            this._worker = new Worker(this._url);
            this._worker.onmessage = (event) => {
                if (event.data === 'ready') {
                    dispatchEvent(this, 'ready');
                } else {
                    dispatchEvent(this, 'message', event.data);
                }
            };
            this._worker.onerror = () => {
                dispatchEvent(this, 'error', new RpcError('web-worker: error'));
            };
            this._worker.onmessageerror = (_) => {
                dispatchEvent(this, 'error', new RpcError('web-worker: fail to deserialize message'));
            };
        }
    }

    close() {
        if (this._worker) {
            this._worker.terminate();
            this._worker = null;
        }
    }

    send(message) {
        if (this._worker) {
            this._worker.postMessage(message);
        }
    }

    encodeUint8Array(value) {
        return value;
    }

    decodeUint8Array(value) {
        return value;
    }
}
