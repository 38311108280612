import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class EditorBox extends Component {
  @tracked modalName = 'depositBox-editor-modal';

  @tracked isProcessing = false;

  @tracked name = '';
  @tracked oldName = '';
  @tracked description = '';
  @tracked note = '';
  @tracked protectWithAPassCode = false;

  @service store;
  @service modalManager;
  @service intl;
  @service notify;
  @service('deposit-box') depositBoxService;

  get isUpdating() {
    return this.args.isUpdating;
  }

  get isTemplating() {
    return this.args.isTemplating;
  }

  @computed('depositBoxService.depositBoxSettings.codePolicy')
  get protectWithAPassCodeEnforced() {
    return this.depositBoxService.depositBoxSettings.codePolicy === libcryptobox.DepositBoxCodePolicy.Enforced;
  }

  get modalDepositBoxOptions() {
    return this.modalManager.getOptions(this.modalName);
  }

  get modalTitle() {
    const keyPrefix = `depositBox.${
      this.isUpdating ? 'update' : 'create'
    }.title`;
    return this.intl.t(keyPrefix);
  }

  get isDisableSubmitButton() {
    const isNameValid =
      this.name && this.name.trim().length > 0 && this.name.length <= 64;
    return !isNameValid || this.isProcessing;
  }

  @action
  initializeFormData() {
    const shouldProtectWithAPassCode = [libcryptobox.DepositBoxCodePolicy.EnabledByDefault, libcryptobox.DepositBoxCodePolicy.Enforced].includes(this.depositBoxService.depositBoxSettings.codePolicy);
    if (this.isUpdating || this.isTemplating) {
      const depositBox = this.modalDepositBoxOptions;

      if (!depositBox) return;

      const { name, description, note, link } = depositBox;
      this.name = name;
      this.oldName = name;
      this.description = description;
      this.note = note;
      this.protectWithAPassCode = !!link?.code;
      if (this.isTemplating) {
         if (this.depositBoxService.depositBoxSettings.codePolicy === libcryptobox.DepositBoxCodePolicy.Enforced) {
          this.protectWithAPassCode = true;
        } else {
          this.protectWithAPassCode = !!link?.code;
        }

      }
    } else {
      this.name = '';
      this.description = '';
      this.note = '';
      this.protectWithAPassCode = shouldProtectWithAPassCode
    }
  }

  @action
  async handleSubmitBox() {
    this.isProcessing = true;
    try {
      const savedRecord =
        this.isUpdating && !this.isTemplating
          ? await this.updateDepositBox()
          : await this.createDepositBox();

      this.closeModal();
      if (this.isUpdating) {
        return this.notify.info(this.intl.t('depositBox.update.notify-text'), {
          title: this.intl.t('depositBox.update.notify-title'),
          icon: 'deposit-box',
        });
      }
      this.handleModalOperations(savedRecord);
    } catch (error) {
      console.error(
        `Error:: Deposit-Box::${
          this.isUpdating
            ? 'Update'
            : this.isTemplating
              ? 'Create-Template'
              : 'Create'
        }`,
        error,
      );
    } finally {
      this.isProcessing = false;
    }
  }

  @action
  async checkDepositBoxNameExists() {
    if (this.isUpdating && this.oldName === this.name) {
      return false;
    }

    const depositBoxListItems = this.depositBoxService.getAllDepositBoxes();

    return depositBoxListItems
      .toArray()
      .some(
        (box) =>
          box.get('name').toLowerCase() ===
          this.name.trim().replace(/\s+/g, ' ').toLowerCase(),
      );
  }

  @action
  async createDepositBox() {
    return this.depositBoxService.createDepositBox({
      name: this.name,
      description: this.description,
      note: this.note,
      code: this.protectWithAPassCode,
    });
  }

  @action
  async updateDepositBox() {
    const box = this.modalManager.getOptions(this.modalName);
    return this.depositBoxService.updateDepositBox(box, {
      name: this.name,
      description: this.description,
      note: this.note,
    });
  }

  handleModalOperations(savedRecord) {
    this.modalManager.open('depositBox-editor-loading-modal');
    setTimeout(() => {
      this.modalManager.close('depositBox-editor-loading-modal');
      this.modalManager.open('depositBox-show-link', {
        name: savedRecord.name,
        code: savedRecord.link?.code || '',
        link: savedRecord.link?.url || '',
        id: savedRecord.id,
      });
      if (this.args.loadDepositBoxes) {
        this.args.loadDepositBoxes();
      }
      this.initializeFormData();
    }, 3000);
  }

  @action
  closeModal() {
    this.modalManager.close(this.modalName);
  }

  @action
  toggleProtectWithAPassCode() {
    this.protectWithAPassCode = !this.protectWithAPassCode;
  }

  @action
  handleClosingModal() {
    if (this.args.onClose) {
      this.args.onClose();
    }
    this.initializeFormData();
  }
}
