// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr, belongsTo } from '@ember-data/model';

// A file in a space.
// See FileCollection.
export default class extends Model {
  // Space's identifier.
  // @type string
  @attr spaceId;
  
  // Revision's number.
  // @type number
  @attr revision;
  
  // Path.
  // @type string
  @attr path;
  
  // Is the file a folder?
  // @type boolean
  @attr isDirectory;
  
  // Size in bytes.
  // @type number
  @attr contentSize;
  
  // Modification date.
  // @type string
  @attr lastModified;
  
  // Number of child folders.
  // @type number
  @attr subDirectoryCount;
  
  // Identifier preserved on update, rename and move.
  // @type string
  @attr trackingId;
  
  // File version number.
  // @type string
  @attr version;
  
  @belongsTo('space', { async: true, inverse: null }) space;
  @belongsTo('user', { async: false, inverse: null }) author;
}

