import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class UserEntryComponent extends Component {
  @service intl;
  @tracked user;

  get roleName() {
    const key = `members.member.role.${ this.args.user.role }`;
    return this.intl.t(key);
  }

  get isExternal() {
    return this.args.user.consumeInvite && this.args.user.type === 'invitee';
  }

  @action
  removeUser(user) {
    this.args.entryRegistry.removeObject(user);
  }
}
