import { debounce } from '@ember/runloop';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { guidFor } from '@ember/object/internals';

export default class TextInput extends Component {
  @service intl;
  @tracked noSlash = false;
  @tracked emptyError = false;
  @tracked slashError = false;
  @tracked customError = false;
  @tracked isNotValidMail = false;
  @tracked maxLengthError = false;

  @tracked _isNotValid = false;

  elementId = this.args.id || guidFor(this);

  Regexp = {
    email:
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  };

  get disabled() {
    return this.args.disabled || false;
  }

  get name() {
    return this.args.name || '';
  }

  get placeholder() {
    return this.args.placeholder || '';
  }

  get isNotValid() {
    return this.args.isNotValid || this._isNotValid;
  }

  get isRequired() {
    return this.args.isRequired || false;
  }

  get inputType() {
    return this.args.inputType || 'text';
  }

  get getMaxLength() {
    if (this.allowMaxLengthOverride) {
      return null;
    } else {
      return this.args.maxLength;
    }
  }

  get allowMaxLengthOverride() {
    return this.args.allowMaxLengthOverride || false;
  }

  get maxLengthMessage() {
    return this.args.isMaxLengthMessage || this.intl.t('errors.max');
  }

  get emptyMessage() {
    return this.args.isEmptyMessage || this.intl.t('errors.empty');
  }

  get notValidMessage() {
    return this.args.isNotValidMessage || this.intl.t('errors.invalid');
  }

  get customMessage() {
    return this.args.customMessage || '';
  }

  @action
  onElementInsert() {
    this.args.registerInput(this);

    const autofocus = this.args.autofocus;
    if (autofocus) {
      const selector = document.getElementById(this.elementId);

      setTimeout(() => {
        if (selector) selector.querySelector('input').focus();
      }, 1000);
    }
  }

  @action
  setIsNotValid(value) {
    if (
      this.args.setIsNotValid &&
      typeof this.args.setIsNotValid === 'function'
    ) {
      this.args.setIsNotValid(value);
    }
    this._isNotValid = value;
  }

  @action
  setValue(value) {
    if (this.args.setValue && typeof this.args.setValue === 'function') {
      this.args.setValue(value);
    }
  }

  @action
  input() {
    this.resetInputState();
    debounce(this, this.validateInput, 500);
  }

  @action
  focusOut(event) {
    this.resetInputState();
    if (this.args.focusOut && typeof this.args.focusOut === 'function') {
      this.args.focusOut(event);
    }
    debounce(this, this.validateInput, 1);
  }

  @action
  resetInputState() {
    document.querySelector(`#${this.elementId}`)?.classList.remove('error');
    this.setIsNotValid(false);
    this.emptyError = false;
    this.slashError = false;
    this.customError = false;
    this.isNotValidMail = false;
    this.maxLengthError = false;
  }

  @action
  resetInput() {
    this.setValue('');
    this.resetInputState();
  }

  @action
  validateInput(isSubmission) {
    return new window.Promise(async (resolve) => {
      let inputValue = this.args.value || '';
      if (isSubmission && this.inputType !== 'password') {
        inputValue = inputValue.trim();
        this.setValue(inputValue);
      }

      if (this.args.maxLength && inputValue.length > this.args.maxLength) {
        this.setIsNotValid(true);
        this.maxLengthError = true;
        resolve({ isValid: false });
        return;
      }
      if (this.isRequired && !inputValue) {
        this.emptyError = true;
        this.setIsNotValid(true);
        resolve({ isValid: false });
        return;
      }
      if (this.inputType === 'email') {
        const emailRegexp = this.Regexp.email;
        if (!emailRegexp.test(inputValue)) {
          this.setIsNotValid(true);
          this.isNotValidMail = true;
          resolve({ isValid: false });
          return;
        }
      }
      if (
        this.noSlash &&
        (inputValue.indexOf('/') > -1 || inputValue.indexOf('\\') > -1)
      ) {
        this.setIsNotValid(true);
        this.slashError = true;
        resolve({ isValid: false });
        return;
      }

      if (this.args.onInput && typeof this.args.onInput === 'function') {
        const result = await this.args.onInput(inputValue);
        if (result) {
          this.setIsNotValid(true);
          this.customError = true;
          resolve({ isValid: false });
          return;
        }
      }
      resolve({ isValid: true });
    });
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.args.unregisterInput(this);
  }
}
