import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import Notify from 'ember-notify';

import { tracked } from '@glimmer/tracking';

export default class AboutModalComponent extends Component {
  @service cryptoboxURLs;
  @service autoUpdate;
  @service connection;
  @service modalManager;
  updateNotify = Notify.create();
  @tracked toast = null;

  constructor() {
    super(...arguments);
    this.autoUpdate.registerUpdateAvailableCallback(() => {
      if (!this.modalManager.isModalOpen('about-modal')) {
        this.toast = this.updateNotify.success('', {
          closeAfter: null,
          radius: true,
        });
      }
    });
  }

  get isLoading() {
    return (
      this.autoUpdate.isUpdateVerifying || this.autoUpdate.isUpdateDownloading
    );
  }

  get updateAvailable() {
    return this.autoUpdate.isUpdateAvailable;
  }

  @action
  clickEvent() {
    if (this.autoUpdate.isUpdateAvailable) {
      this.autoUpdate.restart();
    } else {
      this.autoUpdate.check();
    }
  }

  @action
  clickToastLater() {
    if (this.toast) this.toast.close();
    this.autoUpdate.showUpdateNotification = true;
  }

  @action
  clickToastRestart() {
    this.autoUpdate.restart();
  }

  @action
  onAboutModalClose() {
    if (this.autoUpdate.isUpdateAvailable) {
      this.autoUpdate.showUpdateNotification = true;
    }
    this.args.closeAboutModal();
  }
}
