import Mixin from '@ember/object/mixin';

export default Mixin.create({
  actions:{
    modalIsOpen() {
      let parentView = this.get('parentView');
      if(parentView) {
        parentView.set('modalIsOpen', true);
      }
    },
    modalIsClose() {
      let parentView = this.get('parentView');
      if(parentView) {
        parentView.set('modalIsOpen', false);
      }
    }
  },
  
  generateContextId(baseName) {
    let contextualId = baseName;
    if(this.get('parentView').get('classNames')[1] === 'details-pane-content'){
      contextualId += "-details";
    }
    if(this.get('parentView').get('classNames')[1] === 'context_menu'){
      contextualId += "-context-menu";
    }
    return contextualId;
  },
});
