import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class DepositBoxCard extends Component {
  @service intl;
  @service router;

  get depositBox() {
    return this.args.depositBox;
  }

  get isBlocked() {
    return this.depositBox.maxCodeAttemptsReached;
  }
  @action
  goToBox() {
    this.router.transitionTo('deposit-boxes.deposit-box', this.depositBox.id);
  }
}
