import Component from '@glimmer/component';

export default class NodeNameComponent extends Component {
  get iconSize() {
    return this.args.iconSize ? this.args.iconSize : 'auto';
  }

  get iconSvgClass() {
    return this.args.iconSvgClass ? this.args.iconSvgClass : 'fill-none';
  }

  get name() {
    return this.args.node.name;
  }

  get fileName() {
    const name = this.name;
    const lastDotIndex = name.lastIndexOf('.');
    if (lastDotIndex === -1) {
      return name;
    }
    return name.substring(0, lastDotIndex);
  }


  get fileExtension() {
    const name = this.name;
    const lastDotIndex = name.lastIndexOf('.');
    if (lastDotIndex === -1 || lastDotIndex === name.length - 1) {
      return '';
    }
    return `.${ name.substring(lastDotIndex + 1) }`;
  }

}
