/* global libcryptobox */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { debounce } from '@ember/runloop';
import { sort } from '@ember/object/computed';
import getDocumentItemId from '../../../../../../utils/getDocumentItemId';
import { tracked } from '@glimmer/tracking';

const ACTION_KIND = {
  MOVE: 'move',
  COPY: 'copy',
};

export default class CopyMoveModal extends Component {
  @tracked loadingModifyFile = false;
  @tracked modalIsOpen = false;
  @tracked processing = false;
  @tracked actionKind = null;
  @tracked spaces = [];
  @tracked sortedDefinitionWorkspaces = ['name'];

  @service store;
  @service notify;
  @service intl;
  @service modalManager;
  @service commonService;

  @sort('workspacesFiltered', 'sortedDefinitionWorkspaces')
  sortedWorkspacesFiltered;

  get workspacesFiltered() {
    return this.workspaces.filter((space) => {
      const deleted = space.get('isDeleted');
      return !deleted;
    });
  }

  get workspaces() {
    return this.spaces.filter((space) => {
      const kind = space.get('kind');
      return (
        kind === libcryptobox.SpaceKind.Workspace ||
        kind === libcryptobox.SpaceKind.PersonalSpace
      );
    });
  }

  get disableMoveAction() {
    return this.args.wsDeleted || this.processing || !this.args.canMove;
  }

  get disableCopyAction() {
    return this.processing || this.sortedWorkspacesFiltered.length === 0;
  }

  get selectedSpaceAndDestination() {
    if (
      this.commonService.selectedSpace &&
      this.commonService.destinationNode
    ) {
      let text = ` > ${this.commonService.selectedSpace?.get('name')}`;
      if (this.commonService.destinationNode) {
        text += ` > ${this.commonService.destinationNode}`;
      }
      return text;
    }
    return '';
  }

  @action
  loadData() {
    this.store.findAll('space', { adapterOptions: { subscribe: this } });
    this.spaces = this.store.peekAll('space');
  }

  @action
  onClose() {
    this.modalManager.close(this.args.modalName);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.store.adapterFor('space').unsubscribe(this);
  }

  async _isValidDestinationFileNames(spaceId, path) {
    const store = this.store;
    const sourcesName = this.args.selectedNodes.map((file) => file.name);
    const filesTarget = await store.query('file', {
      spaceId: spaceId,
      revision: 0,
      path: path,
    });
    const filesName = filesTarget.map((file) =>
      file.id.split('/').pop(),
    );
    let valid = true;

    sourcesName.forEach((file) => {
      if (filesName.indexOf(file) > -1) {
        valid = false;
      }
    });

    return valid;
  }

  notifySuccess(action) {
    const contentTradKey = `notifications.${action}.success`;
    const titleTradKey = `notifications.${action}.title`;

    this.notify.success(this.intl.t(contentTradKey), {
      title: this.intl.t(titleTradKey),
      icon: 'file',
    });
  }

  notifyError(action) {
    const contentTradKey = `errors.${action}`;
    const titleTradKey = `notifications.${action}.title`;
    this.notify.warning(this.intl.t(contentTradKey), {
      title: this.intl.t(titleTradKey),
      icon: 'file',
    });
  }

  async call(spaceId, path) {
    if (this.loadingModifyFile) {
      throw new Error('File modification is already in progress');
    }

    this.loadingModifyFile = true;

    try {
      let source;
      const target = spaceId + ':' + path;

      if (!this.args.isDepositBox) {
        source = this.args.selectedNodes.map((file) =>
          getDocumentItemId(file.get('space.id'), file.get('path')),
        );

        switch (this.actionKind) {
          case ACTION_KIND.MOVE:
            await tBoxClient.file.move(source, target);
            break;
          case ACTION_KIND.COPY:
            await tBoxClient.file.copy(source, target);
            break;
          default:
            throw new Error('Invalid action kind');
        }
      } else {
        if (!this.args.depositBoxId) {
          throw new Error('Invalid deposit box id');
        }
        await tBoxClient.depositBox.copy(
          this.args.depositBoxId,
          this.args.selectedNodes.map((file) => file.name),
          spaceId,
          path,
        );
      }

      this.modalManager.close(this.args.modalName);
      this.notifySuccess(this.actionKind);
    } finally {
      this.loadingModifyFile = false;
    }
  }

  async _copyMove(actionKind) {
    if (this.processing) return;
    this.processing = true;

    const path = this.commonService.destinationNode || '/';
    const spaceId = this.commonService.selectedSpace.get('id');
    this.actionKind = actionKind;

    try {
      if (await this._isValidDestinationFileNames(spaceId, path)) {
        await this.call(spaceId, path);
      } else {
        this.modalManager.close(this.args.modalName);
        this.modalManager.open('valid-crushing');
      }
    } catch (e) {
      console.error(e);
      this.notifyError(actionKind);
    } finally {
      this.processing = false;
    }
  }

  @action
  setWorkspace(ws) {
    this.commonService.selectedSpace = ws;
  }

  @action
  copyMove(actionKind) {
    if (this.disableMoveAction && actionKind === ACTION_KIND.MOVE) {
      return;
    }
    debounce(this, this._copyMove, actionKind, 500);
  }

  @action
  crush() {
    const path = this.commonService.destinationNode || '/';
    const spaceId = this.commonService.selectedSpace.get('id');
    this.modalManager.close('valid-crushing');

    this.modalManager.open(this.args.modalName);

    void this.call(spaceId, path);
  }

  @action
  cancelConfirm() {
    this.processing = false;
    this.modalManager.close('valid-crushing');
  }

  @action
  cancel() {
    this.processing = false;
    this.modalManager.close(this.args.modalName);
  }
}
