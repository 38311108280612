import { helper } from "@ember/component/helper";
import { FILE_TYPE_WITH_PREVIEW_LIST } from "../utils/enums";

export function fileWithPreview(params) {
  if (!params[0]) {
    return "";
  }

  return FILE_TYPE_WITH_PREVIEW_LIST.includes(params[0]);
}

export default helper(fileWithPreview);
