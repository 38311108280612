import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import FormatDateHelper from '../../../helpers/format-date';
import { isToday } from '../../../utils/isToday';

export default class FileListItems extends Component {
  rootElement = document.querySelector('.file-list-items-container');
  visibleGroups = new Set();

  @service store;
  @service('file-history') fileHistoryService;
  @service commonService;
  @service intl;

  get currentYear() {
    return new Date().getFullYear();
  }

  get model() {
    return this.args.model;
  }

  get canShowNoFileHistory() {
    if (this.fileHistoryService.isLoadingFileHistoryData === true) {
      return false;
    }
    if (this.fileHistoryService.fileHistoryDataTransformed.length === 0) {
      return true;
    }
    return (
      !this.fileHistoryService.selectedDate &&
      this.fileHistoryService.areRevisionDatesIdentical
    );
  }
  get noItemText() {
    if (
      !this.fileHistoryService.fileHistoryData?.length ||
      !this.fileHistoryService.fileHistoryData.at(-1)?.date
    ) {
      return this.intl.t('fileHistory.noFileItem.today');
    }

    const lastRevisionDate =
      this.fileHistoryService.fileHistoryData.at(-1).date;

    if (isToday(lastRevisionDate)) {
      return this.intl.t('fileHistory.noFileItem.today');
    }

    const dateFormatHelper = new FormatDateHelper();
    dateFormatHelper.intl = this.intl;
    const formattedDate = dateFormatHelper.compute([lastRevisionDate], {
      includeToday: true,
      format: 'DD',
    });

    return this.intl.t('fileHistory.noFileItem.before', {
      date: formattedDate,
    });
  }

  @action
  async setupScrollListener(event) {
    if (this.fileHistoryService.automaticScroll) return;

    const container = event.target;

    const groups = event.target.querySelectorAll('.file-year-group');
    this.visibleGroups.clear();

    groups.forEach((group) => {
      const rect = group.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();
      const isVisible =
        rect.top < containerRect.bottom && rect.bottom > containerRect.top;

      if (isVisible) {
        this.visibleGroups.add(group.querySelector('.list-item-date').id);
      }
    });

    await this.handleUpdateHeatmap([...this.visibleGroups].at(0));
  }

  @action
  teardownFileHistory(element) {
    element.removeEventListener('scroll', this.setupScrollListener);
  }

  @action
  handleUpdateHeatmap(element) {
    if (!element) return;

    if (this.fileHistoryService.automaticScroll) return;

    if (this.fileHistoryService.fileHistoryData.length === 0) return;
    const visibilityYear = Number(element.split('-').at(-1));
    if (this.fileHistoryService.year !== visibilityYear) {
      this.fileHistoryService.year = visibilityYear;
    }
  }

  @action
  async loadMoreScroll(entry) {
    if (entry.isIntersecting === false) return;

    if (
      this.fileHistoryService.fileHistoryDataTransformed.length === 0 ||
      this.canShowNoFileHistory
    ) {
      return;
    }
    const mainContainer = document.querySelector('.file-list-items-container');

    requestAnimationFrame(async () => {
      const beforeScroll = mainContainer.scrollTop;
      await this.loadMore();
      mainContainer.scrollTop = beforeScroll;
    });
  }

  @action
  async initializeFileHistory() {
    await this.model;
    await this.fileHistoryService.fetchFileHistory();
  }

  @action
  async loadMore() {
    if (
      !this.fileHistoryService.isLoadingFileHistoryData &&
      this.fileHistoryService.fileHistoryData.at(-1)?.fileVersionNumber !== 1
    ) {
      await this.fileHistoryService.loadMoreFileHistoryData();
    }
  }

}
