import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { debounce } from '@ember/runloop';
import { action } from '@ember/object';
import checkName from '../../../../../../utils/checkName';
import {
  NODE_NAME_ERROR,
  RENAME_NODE_MODAL_NAME,
  NODE_EXTENSION_CHANGE_MODAL_NAME,
} from '../../../../../../utils/enums';

const SEPARATOR = '/';
const ERROR_TO_TRAD_KEY = {
  //[NODE_NAME_ERROR.NO_CHANGE]: "NO_CHANGE",
  [NODE_NAME_ERROR.EMPTY]: 'errors.empty',
  [NODE_NAME_ERROR.FORBIDDEN]: 'errors.forbiddenName',
  [NODE_NAME_ERROR.TOO_LONG]: 'errors.tooLong',
  [NODE_NAME_ERROR.SLASH]: 'errors.slash',
  [NODE_NAME_ERROR.NODE_CONFLICT]: 'errors.nodeConflict',
};

export default class RenameNodeModalComponent extends Component {
  renameNodeModalName = RENAME_NODE_MODAL_NAME;
  extensionChangedWarningModalName = NODE_EXTENSION_CHANGE_MODAL_NAME;
  renameNodeInputElement = null;
  // To handle the case were the second modal is open (extensionChangedWarningModalName)
  validatingExtension = false;

  @tracked renameNodeInputValue = '';
  @tracked errorMessage = '';

  @service store;
  @service router;
  @service intl;
  @service modalManager;

  get modalOptions() {
    return this.modalManager.getOptions(this.renameNodeModalName);
  }
  isExtensionChange() {
    if (this.node.isFolder) {
      return false;
    }

    const currentNodeNameExtension = this.node.name.split('.').pop();
    const newNodeNameExtension = this.renameNodeInputValue.split('.').pop();
    return currentNodeNameExtension !== newNodeNameExtension;
  }
  get node() {
    return this.modalOptions.node;
  }
  get nodePath() {
    const nameSplit = this.node.path.split(SEPARATOR).slice(0, -1);
    return nameSplit.length === 1 ? SEPARATOR : nameSplit.join(SEPARATOR);
  }

  async isNameInConflict(value) {
    const id = this.node.space.get('id');

    return this.store
      .queryRecord('file', {
        spaceId: id,
        revision: 0,
        path: `${this.nodePath}${SEPARATOR}${value}`,
      })
      .then(() => true)
      .catch(() => false);
  }

  async validateName() {
    const value = this.renameNodeInputValue;
    let params = {};
    let error = await checkName(this.node.name, value);

    if (error === NODE_NAME_ERROR.NO_CHANGE) {
      this.errorMessage = '';
      return error;
    }

    if (!error) {
      const isNameInConflict = await this.isNameInConflict(value);
      if (isNameInConflict) {
        error = NODE_NAME_ERROR.NODE_CONFLICT;
        params = { oldName: this.node.name, newName: value };
      }
    }
    this.errorMessage = error
      ? this.intl.t(ERROR_TO_TRAD_KEY[error] || '', params)
      : '';

    return error;
  }

  clear() {
    this.renameNodeInputValue = '';
    this.errorMessage = '';
    this.validatingExtension = false;
  }

  renameNode() {
    const value = this.renameNodeInputValue;
    const spaceId = this.node.space.get('id');

    return this.node.rename(value).then((event) => {
      if (this.currentPath === event.oldPath) {
        this.router.transitionTo('my-groups.group.documents', {
          queryParams: {
            fileRevision: 0,
            spaceId,
            path: event.newPath,
          },
        });
      }

      event.oldRecord.deleteRecord();
    });
  }

  async onConfirmNodeName() {
    const error = await this.validateName();

    if (error && error !== NODE_NAME_ERROR.NO_CHANGE) {
      return;
    }

    this.validatingExtension = false;

    if (this.isExtensionChange()) {
      this.validatingExtension = true;
      this.modalManager.close(this.renameNodeModalName, {
        node: this.node,
      });
      this.modalManager.open(this.extensionChangedWarningModalName);
      return;
    }

    if (error !== NODE_NAME_ERROR.NO_CHANGE) {
      this.renameNode();
    }

    // We do not use use the "closeOnConfirm" arg on Modal component
    // => We close here to get the same behaviour with onPressEnterInput
    this.modalManager.close(this.renameNodeModalName);
  }

  // Form

  @action
  didInsertRenameNodeInput(e) {
    this.renameNodeInputElement = e;
  }

  @action
  onChangeInputValue(value) {
    this.renameNodeInputValue = value; //.trim() ?
    debounce(this, this.validateName, '', 500);
  }

  @action
  onPressEnterInput() {
    void this.onConfirmNodeName();
  }

  // RENAME_NODE_MODAL_NAME

  @action
  async onConfirm() {
    void this.onConfirmNodeName();
  }

  @action
  onCloseModal() {
    if (!this.validatingExtension) {
      this.clear();
    }
    this.renameNodeInputValue = '';
    this.modalManager.close(this.renameNodeModalName);
  }

  @action
  onOpeningModal() {
    this.renameNodeInputValue = this.node?.name;
    this.renameNodeInputElement.focus();
  }

  // Extension change modal

  @action
  onConfirmExtensionChange() {
    this.renameNode();
    this.modalManager.close(this.extensionChangedWarningModalName);
    this.onCloseModal();
  }

  @action
  onCancelExtensionChange() {
    this.validatingExtension = false;
    this.modalManager.close(this.extensionChangedWarningModalName);
    this.modalManager.open(this.renameNodeModalName, {
      node: this.node,
    });
  }

  @action
  onCloseExtensionChange() {
    this.clear();
    this.modalManager.close(this.extensionChangedWarningModalName);
  }
}
