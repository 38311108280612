import { hash } from 'rsvp';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class MyNotifications extends Route {

  @service store;
  @service intl;
  @service authService;

  beforeModel() {
    this.authService.loginIfNotAuthenticated();
  }

  model() {
    return hash({
      notifications: this.store.findAll('notification', { adapterOptions: { subscribe: this } }),
      spaces: this.store.findAll('space', { adapterOptions: { subscribe: this } }),
    });
  }

  afterModel() {
    document.title = 'Cryptobox - ' + this.intl.t('navigation.my.notifications');
  }

  deactivate() {
    this.store.adapterFor('notification').unsubscribe(this);
    this.store.adapterFor('space').unsubscribe(this);
  }
}
