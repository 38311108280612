/* global libcryptobox */
import BaseModel from 'client/toolbox/generated/models/message';
import DS from 'ember-data';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { alias } from '@ember/object/computed';
// import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import {DateTime} from "luxon";
import { belongsTo } from '@ember-data/model';

export default class Message extends BaseModel {
  @service account;
  @service intl;

  @alias('space') conversation;
  @alias('recipients') readFlags;
  @belongsTo('message', {async:true, inverse: 'nextMessage' }) previousMessage;
  @belongsTo('message', {async: true, inverse: 'previousMessage' }) nextMessage;


  get isDelivered() {
    const status = this.status;
    return status === libcryptobox.MessageStatus.Delivered;
  }


  get isRejected() {
    const status = this.status;
    return status === libcryptobox.MessageStatus.Rejected;
  }


  get isSending() {
    const status = this.status;
    return (
      status === libcryptobox.MessageStatus.Inflight ||
      status === libcryptobox.MessageStatus.Pending
    );
  }


  get read() {
    const recipients = this.recipients;
    return recipients.length > 0 && recipients.isAny('hasRead');
  }


  get readByAll() {
    const conversationMembers = this.space.get("members");
    const emails = conversationMembers?.map(conversationMember => conversationMember.email);

    return (
      emails &&
      emails.length > 0 &&
      this.recipients?.every((recipient) => {
        const recipientEmail = recipient.get('email');
        return emails.includes(recipientEmail) && recipient.get('hasRead');
      })
    );
  }

  @alias('date') formatedTimestamp;


  get isFromToday() {
    const todayDate =
      DateTime.now().toFormat('dd/MM/yyyy');

    const timestamp = this.formatedTimestamp;
    const timestampDate =
      DateTime.fromISO(timestamp).toFormat('dd/MM/yyyy');
    return todayDate === timestampDate;
  }


  get messageTime() {
    const timestamp = this.formatedTimestamp;
    const parsedTimestamp = DateTime.fromISO(timestamp);
    return parsedTimestamp.toFormat('HH:mm') + ':' + parsedTimestamp.toFormat('MMMM');
  }


  get formatedCompleteDate() {
    return DateTime.fromISO(this.formatedTimestamp).toFormat(
      this.intl.t('messaging.dateFormat')
    );
  }


  get formatedHourOnly() {
    return DateTime.fromISO(this.formatedTimestamp).toFormat('HH:mm');
  }


  get fromNow() {
    return DateTime.fromISO(this.formatedTimestamp).toRelative()
  }


  get parsedTimestamp() {
    return parseInt(this.timestamp);
  }

  get groupMessageStatus() {
    const currentMessage = this;
    const previousMessage = this.previousMessage;
    const nextMessage = this.nextMessage;

    const authors = [currentMessage.get('author')];
    if (previousMessage) {
      authors.push(previousMessage.get('author'));
    }
    if (nextMessage) {
      authors.push(nextMessage.get('author'));
    }

    if (authors.length === 1) {
      return 'alone';
    } else if (authors.length === 2) {
      let currentMessageHour = currentMessage.get('formatedHourOnly');
      if (authors[0] === authors[1]) {
        if (!previousMessage) {
          let nextMessageHour = nextMessage.get('formatedHourOnly');

          if (currentMessageHour === nextMessageHour) {
            return 'block first';
          }
        } else if (!nextMessage) {
          let previousMessageHour = previousMessage.get('formatedHourOnly');

          if (currentMessageHour === previousMessageHour) {
            return 'block last';
          }
        }
      }
      return 'alone';
    } else {
      let currentMessageHour = currentMessage.get('formatedHourOnly');
      let previousMessageHour = previousMessage.get('formatedHourOnly');
      let nextMessageHour = nextMessage.get('formatedHourOnly');
      let currentMessageAuthor = authors[0];
      let previousMessageAuthor = authors[1];
      let nextMessageAuthor = authors[2];

      if (
        currentMessageAuthor === previousMessageAuthor &&
        currentMessageAuthor === nextMessageAuthor
      ) {
        if (
          currentMessageHour === previousMessageHour &&
          currentMessageHour === nextMessageHour
        ) {
          return 'block';
        } else if (
          currentMessageHour !== previousMessageHour &&
          currentMessageHour !== nextMessageHour
        ) {
          return 'alone';
        } else if (currentMessageHour === nextMessageHour) {
          return 'block first';
        } else if (currentMessageHour === previousMessageHour) {
          return 'block last';
        } else {
          throw 'No matching';
        }
      } else if (currentMessageAuthor === previousMessageAuthor) {
        if (currentMessageHour === previousMessageHour) {
          return 'block last';
        }
      } else if (currentMessageAuthor === nextMessageAuthor) {
        if (currentMessageHour === nextMessageHour) {
          return 'block first';
        }
      }
      return 'alone';
    }
  }

  post() {
    return tBoxClient.message.post(this.id);
  }

  deleteMessage() {
    return tBoxClient.message.delete(this.id);
  }
}
