import BaseAdapter from 'client/adapters/application';
import { tBoxClient } from 'client/initializers/init-toolbox';

export default BaseAdapter.extend({
  findAll() {
    const localAccounts = [];

    return tBoxClient.account.list().then(accounts => {
      let cpt = 1;
      let colorId = 0;
      accounts.forEach((item) => {
        if((cpt%2) === 0) {
          colorId = 0;
        } else {
          if((cpt%3) === 0)
              colorId = 1;
          else
              colorId = 2;
        }
        cpt ++;

        let account = {
          id: item.id,
          givenName: item.user.givenName,
          surName: item.user.surname,
          userEmail: item.user.email,
          serverUrl: item.serverUrl,
          color: colorId,
        };
        localAccounts.push(account);
      });
      return localAccounts;
    })
    .catch((error) => {
      console.error(error);
      return localAccounts;
    })
  }
});
