import { modifier } from 'ember-modifier';

export default modifier((element, [intersectionCallback, options]) => {
  const _options = {
    threshold: 0.1,
    ...options,
  };
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      intersectionCallback(entry);
    });
  }, _options);

  observer.observe(element);

  return () => observer.disconnect();
});
