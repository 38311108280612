import { tBoxClient } from 'client/initializers/init-toolbox';

export function initialize(applicationInstance) {
  var factory = applicationInstance.lookup('service:observer-factory');
  tBoxClient.setGlobalErrorHandler(factory.getErrorManagement());
}

export default {
  initialize
};
