import Component from '@glimmer/component';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ModalComponent extends Component {
  @service intl;
  @service modalManager;

  get isOpen() {
    return this.modalManager.isModalOpen(this.args.name);
  }

  @action
  onOpening() {
    if (this.args.onOpening) {
      this.args.onOpening();
    }
  }

  @action
  onClosing() {
    if (this.args.onClosing) {
      this.args.onClosing();
    }
  }

  get clickOutsideToClose() {
    const { clickOutsideToClose } = this.args;
    if (typeof clickOutsideToClose === 'boolean') {
      return clickOutsideToClose;
    }
    return true;
  }

  get cancel() {
    return this.args.cancelButton || this.intl.t('button.cancel');
  }

  get confirm() {
    return this.args.confirmButton || this.intl.t('button.confirm');
  }

  @action
  handleClickOverlay() {
    if (this.clickOutsideToClose) {
      this.handleCancel();
    }
  }

  @action
  handleClose() {
    if (!this.isOpen) return;

    const { onClose } = this.args;

    if (onClose) {
      return onClose();
    }
    if (onClose === undefined) {
      return this.modalManager.close(this.args.name);
    }
  }

  @action
  handleCancel() {
    const { onCancel, closeOnCancel } = this.args;
    if (onCancel) {
      onCancel();
    }
    if (onCancel === undefined || closeOnCancel) {
      return this.modalManager.close(this.args.name);
    }
  }

  @action
  setupModal(element) {
    this.modalElement = element;
    window.addEventListener('keydown', this.handleKeydown);
    this.focusModal();
    this.onOpening();
  }

  @action
  teardownModal() {
    window.removeEventListener('keydown', this.handleKeydown);
    this.onClosing();
  }

  focusModal() {
    if (this.modalElement && !this.hasFocus) {
      this.modalElement.focus();
      this.hasFocus = true;
    }
  }

  @action
  handleKeydown(event) {
    if (event.key === 'Escape') {
      this.handleCancel();
    }
  }

  @action
  handleSubmit() {
    const { onConfirm, closeOnConfirm } = this.args;
    if (onConfirm) {
      onConfirm();
    }
    if (onConfirm === undefined || closeOnConfirm) {
      this.modalManager.close(this.args.name);
    }
  }

  willDestroy() {
    super.willDestroy();
    window.removeEventListener('keydown', this.handleKeydown);
    this.handleClose();
  }
}
