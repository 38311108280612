import Component from '@glimmer/component';
import { Info } from 'luxon';
import { action } from '@ember/object';

export default class CustomPikadayInputComponent extends Component {

  get i18n() {
    let locale = navigator.language || navigator.userLanguage || 'en-US';
    Info.defaultLocale = locale;
    const weekdays = Info.weekdays('long', { locale });
    const weekdaysShort = Info.weekdays('short', { locale });

    return {
      months: Info.months('long', { locale }),
      weekdays: [weekdays[6], ...weekdays.slice(0, 6)],
      weekdaysShort: [weekdaysShort[6], ...weekdaysShort.slice(0, 6)],
    };
  }

  @action
  handleSelection(date) {
    if (!date) return;
    this.args.onSelection(date);
  }

}
